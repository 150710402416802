import { useState, useRef } from "react";
import { useMaterialUIController, setJoyrideRun } from "context";
import Joyride, { STATUS } from "react-joyride";
import TourIntroduction from "../components/TourIntroduction";

export default function JoyrideComponent() {
  const [controller, dispatch] = useMaterialUIController();
  const { joyrideRun } = controller;
  const tourRef = useRef(null);
  const stepsLength = 11;
  const [steps] = useState([
    {
      disableBeacon: true,
      content: (
        <TourIntroduction
          tourRef={tourRef}
          stepsLength={stepsLength}
          stepIndex={0}
          headerText="Dashboard Table"
          bodyText="General charts and information about the entire application are shown in this table"
        />
      ),
      placement: "bottom",
      styles: {
        tooltip: {
          display: "flex",
          flexDirection: "column",
          justifyContent: "top",
          width: "400px",
          top: "-15px",
        },
        spotlight: {
          borderRadius: "8px",
        },
      },
      target: ".dashboardJoyRide",
    },
    {
      disableBeacon: true,
      content: (
        <TourIntroduction
          tourRef={tourRef}
          stepsLength={stepsLength}
          stepIndex={1}
          headerText="Lawyers Table"
          bodyText="Each lawyer's full profile is shown in the relevant row in this table."
        />
      ),
      placement: "bottom",
      styles: {
        tooltip: {
          display: "flex",
          flexDirection: "column",
          justifyContent: "top",
          width: "400px",
          top: "-15px",
        },
        spotlight: {
          borderRadius: "8px",
        },
      },
      target: ".lawyersJoyRide",
    },
    {
      disableBeacon: true,
      content: (
        <TourIntroduction
          tourRef={tourRef}
          stepsLength={stepsLength}
          stepIndex={2}
          headerText="Clients Table"
          bodyText="Each client's full profile is shown in the relevant row in this table."
        />
      ),
      placement: "bottom",
      styles: {
        tooltip: {
          display: "flex",
          flexDirection: "column",
          justifyContent: "top",
          width: "400px",
          top: "-15px",
        },
        spotlight: {
          borderRadius: "8px",
        },
      },
      target: ".clientJoyRide",
    },
    {
      disableBeacon: true,
      content: (
        <TourIntroduction
          tourRef={tourRef}
          stepsLength={stepsLength}
          stepIndex={3}
          headerText="Lives Table"
          bodyText="Full information of the live, including the date and time, is displayed in the relevant row of each live."
        />
      ),
      placement: "bottom",
      styles: {
        tooltip: {
          display: "flex",
          flexDirection: "column",
          justifyContent: "top",
          width: "400px",
          top: "-15px",
        },
        spotlight: {
          borderRadius: "8px",
        },
      },
      target: ".livesJoyRide",
    },
    {
      disableBeacon: true,
      content: (
        <TourIntroduction
          tourRef={tourRef}
          stepsLength={stepsLength}
          stepIndex={4}
          headerText="Cases Table"
          bodyText="Each percase’s information is provided in the relevant row in this table."
        />
      ),
      placement: "bottom",
      styles: {
        tooltip: {
          display: "flex",
          flexDirection: "column",
          justifyContent: "top",
          width: "400px",
          top: "-15px",
        },
        spotlight: {
          borderRadius: "8px",
        },
      },
      target: ".casesJoyRide",
    },
    {
      disableBeacon: true,
      content: (
        <TourIntroduction
          tourRef={tourRef}
          stepsLength={stepsLength}
          stepIndex={5}
          headerText="Payments Table"
          bodyText="This table displays all of the payment information as well as specifics about each payment."
        />
      ),
      placement: "bottom",
      styles: {
        tooltip: {
          display: "flex",
          flexDirection: "column",
          justifyContent: "top",
          width: "400px",
          top: "-15px",
        },
        spotlight: {
          borderRadius: "8px",
        },
      },
      target: ".paymentsJoyRide",
    },
    {
      disableBeacon: true,
      content: (
        <TourIntroduction
          tourRef={tourRef}
          stepsLength={stepsLength}
          stepIndex={6}
          headerText="Transfers Table"
          bodyText="Each transfer’s information is provided in the relevant row in this table."
        />
      ),
      placement: "bottom",
      styles: {
        tooltip: {
          display: "flex",
          flexDirection: "column",
          justifyContent: "top",
          width: "400px",
          top: "-15px",
        },
        spotlight: {
          borderRadius: "8px",
        },
      },
      target: ".transfersJoyRide",
    },
    {
      disableBeacon: true,
      content: (
        <TourIntroduction
          tourRef={tourRef}
          stepsLength={stepsLength}
          stepIndex={7}
          headerText="Transactions Table"
          bodyText="Each transaction’s information is provided in the relevant row in this table."
        />
      ),
      placement: "bottom",
      styles: {
        tooltip: {
          display: "flex",
          flexDirection: "column",
          justifyContent: "top",
          width: "400px",
          top: "-15px",
        },
        spotlight: {
          borderRadius: "8px",
        },
      },
      target: ".transactionsJoyRide",
    },
    {
      disableBeacon: true,
      content: (
        <TourIntroduction
          tourRef={tourRef}
          stepsLength={stepsLength}
          stepIndex={8}
          headerText="Subscriptions Table"
          bodyText="This table displays all of the payment information as well as specifics about each payment."
        />
      ),
      placement: "bottom",
      styles: {
        tooltip: {
          display: "flex",
          flexDirection: "column",
          justifyContent: "top",
          width: "400px",
          top: "-15px",
        },
        spotlight: {
          borderRadius: "8px",
        },
      },
      target: ".subscriptionsJoyRide",
    },
    {
      disableBeacon: true,
      content: (
        <TourIntroduction
          tourRef={tourRef}
          stepsLength={stepsLength}
          stepIndex={9}
          headerText="Session Table"
          bodyText="Each session’s information is provided in the relevant row in this table."
        />
      ),
      placement: "bottom",
      styles: {
        tooltip: {
          display: "flex",
          flexDirection: "column",
          justifyContent: "top",
          width: "400px",
          top: "-15px",
        },
        spotlight: {
          borderRadius: "8px",
        },
      },
      target: ".sessionsJoyRide",
    },
    {
      disableBeacon: true,
      content: (
        <TourIntroduction
          tourRef={tourRef}
          stepsLength={stepsLength}
          stepIndex={10}
          headerText="Messages Table"
          bodyText="Each message’s information is provided in the relevant row in this table."
        />
      ),
      placement: "bottom",
      styles: {
        tooltip: {
          display: "flex",
          flexDirection: "column",
          justifyContent: "top",
          width: "400px",
          top: "-15px",
        },
        spotlight: {
          borderRadius: "8px",
        },
      },
      target: ".contactUsJoyRide",
    },
  ]);

  const handleJoyrideCallback = (data) => {
    const { status } = data;
    const finishedStatuses = [STATUS.FINISHED, STATUS.SKIPPED];
    if (finishedStatuses.includes(status)) {
      setJoyrideRun(dispatch, false);
      tourRef.current.store.skip();
    }
  };
  return (
    <Joyride
      callback={handleJoyrideCallback}
      ref={tourRef}
      continuous
      // disableScrolling
      hideCloseButton
      hideBackButton
      scrollOffset={100}
      run={joyrideRun}
      steps={steps}
      styles={{
        buttonNext: {
          display: "none",
        },
        tooltipContent: {
          padding: "0px",
        },
        tooltipContainer: {
          padding: "0px",
        },
        tooltipFooter: {
          display: "none",
        },
        options: {
          width: "fit-content",
          arrowColor: "transparent",
          zIndex: 10000,
        },
      }}
    />
  );
}
