// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import "../../styles/generalStyles.css";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import DataTable from "examples/TablesDetailPaymentLawyer/DataTable";
import authorsTableData from "layouts/faq/data/authorsTableData";
import { useMaterialUIController, setCircularProgress, setFAQData } from "context";
import { useLocation, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { Pagination } from "@mui/material";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import FAQsNavbar from "examples/Navbars/FAQsNavbar";
import { getAllFAQs } from "api/api_faq";
import ConfirmModal from "./ConfirmModal";

function FAQs() {
  // eslint-disable-next-line no-unused-vars
  const [controller, dispatch] = useMaterialUIController();
  const navigate = useNavigate();
  const { columns, rows } = authorsTableData();
  const [paginationCount, setPaginationCount] = useState(1);
  const [faqPage, setFAQPage] = useState(1);
  const { pathname } = useLocation();
  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const paramsObj = Array.from(params.keys()).reduce(
      (acc, val) => ({ ...acc, [val]: params.get(val) }),
      {}
    );
    let param = "";
    if (!paramsObj.page) param += `?page=1`;
    else param += `?page=${paramsObj.page}`;
    setCircularProgress(dispatch, true);
    getAllFAQs((isOk, data) => {
      if (!isOk) {
        console.log("get FAQs List from api is not working", data);
      } else {
        setFAQData(dispatch, data.data);
        setPaginationCount(data.meta.last_page);
      }
      setCircularProgress(dispatch, false);
    }, param);
  }, [pathname, window.location.search]);

  const handlePaginationChange = (event, value) => {
    setFAQPage(value);
    let url = "/faqs";
    url += `?page=${value}`;
    navigate(url);
  };
  return (
    <DashboardLayout>
      <FAQsNavbar />
      <ConfirmModal />
      <MDBox pt={6} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
                style={{ display: "flex" }}
              >
                <MDTypography variant="h6" color="white">
                  FAQ Table
                </MDTypography>
              </MDBox>
              <MDBox pt={3}>
                <DataTable
                  table={{ columns, rows }}
                  isSorted={false}
                  entriesPerPage={false}
                  showTotalEntries={false}
                  noEndBorder
                />
              </MDBox>
            </Card>
          </Grid>
        </Grid>
        <MDBox style={{ display: "flex", justifyContent: "center" }}>
          <Pagination
            page={faqPage}
            onChange={handlePaginationChange}
            count={paginationCount}
            color="info"
          />
        </MDBox>
      </MDBox>
    </DashboardLayout>
  );
}

export default FAQs;
