import { setOpenPayModal, useMaterialUIController } from "context";
import PropTypes from "prop-types";

function Navbar({ tab, setTab }) {
  const [controller, dispatch] = useMaterialUIController();
  if (false) console.log(controller);
  const tabList = [
    { title: "Overview", id: 1, className: "lawyerDetailOverviewTitle" },
    { title: "Certificates", id: 2, className: "lawyerDetailCertificateTitle" },
    { title: "Offices", id: 3, className: "lawyerDetailOfficesTitle" },
    { title: "Lives", id: 4, className: "lawyerDetailLivesTitle" },
    { title: "Cases", id: 5, className: "lawyerDetailCasesTitle" },
    { title: "Payments", id: 6, className: "lawyerDetailPaymentsTitle" },
    { title: "Sessions", id: 7, className: "lawyerDetailSessionsTitle" },
    { title: "Transfers", id: 8, className: "lawyerDetailTransfersTitle" },
    { title: "Subscriptions", id: 9, className: "lawyerDetailSubscriptionsTitle" },
  ];
  return (
    <div className="flex flex-row w-[100%]">
      {tabList.map(({ title, id, className }) => (
        <div
          role="presentation"
          key={id}
          onClick={() => setTab(id)}
          className={`${className} font-bold ${
            id === tab ? "border-b-2 border-[#0295E9] text-[#0295E9]" : "text-[#919090]"
          } hover:border-b-2 hover:border-[#0295E9] cursor-pointer mr-4 text-[16px]`}
        >
          {title}
        </div>
      ))}
      <div
        role="presentation"
        onClick={() => setOpenPayModal(dispatch, true)}
        className="font-bold cursor-pointer hover:bg-blue-500 mr-4 text-[14px] flex items-center justify-center px-2 rounded-[10px] bg-[#0295E9] text-white ml-auto"
      >
        Pay To Laywer
      </div>
    </div>
  );
}
Navbar.propTypes = {
  tab: PropTypes.number,
  setTab: PropTypes.func,
};
Navbar.defaultProps = {
  tab: 1,
  setTab: () => {},
};
export default Navbar;
