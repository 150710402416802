import PropTypes from "prop-types";

export default function TourIntroduction(props) {
  const { tourRef, stepsLength, stepIndex, headerText, bodyText } = props;
  return (
    <div className="w-full justify-center items-start">
      <div className="text-left text-gray-700 font-bold text-[18px]">{headerText}</div>
      <div className="text-left text-gray-600 text-[13px] mt-[10px]">{bodyText}</div>
      <div className="flex w-full mt-[20px]">
        <div className="flex items-center">
          {Array(stepsLength)
            .fill(null)
            .map((value, index) => (
              <div
                className={`w-[7px] h-[7px] mx-[3px] rounded-[100%] ${
                  index === stepIndex ? "bg-gray-500" : "bg-gray-300"
                }`}
              />
            ))}
        </div>
        <div className="flex ml-auto">
          {stepIndex > 0 && (
            <div
              role="presentation"
              onClick={() => tourRef.current.store.prev()}
              className="transition-all py-[5px] text-gray-500 bg-white hover:bg-gray-500 hover:text-white px-[10px] rounded-[8px] cursor-pointer mx-[4px] text-[16px] line-[22px] border-[1px]"
            >
              previuos
            </div>
          )}
          {stepIndex < stepsLength - 1 && (
            <div
              role="presentation"
              onClick={() => tourRef.current.store.next()}
              className="transition-all py-[5px] text-gray-500 bg-white hover:bg-gray-500 hover:text-white px-[10px] rounded-[8px] cursor-pointer mx-[4px] text-[16px] line-[22px] border-[1px]"
            >
              next
            </div>
          )}
          {stepIndex === stepsLength - 1 && (
            <div
              role="presentation"
              onClick={() => tourRef.current.store.next()}
              className="transition-all py-[5px] text-gray-500 bg-white hover:bg-gray-500 hover:text-white px-[10px] rounded-[8px] cursor-pointer mx-[4px] text-[16px] line-[22px] border-[1px]"
            >
              start usage
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

TourIntroduction.propTypes = {
  tourRef: PropTypes.any,
  stepsLength: PropTypes.number,
  stepIndex: PropTypes.number,
  headerText: PropTypes.string,
  bodyText: PropTypes.string,
};
TourIntroduction.defaultProps = {
  tourRef: null,
  stepsLength: 0,
  stepIndex: 0,
  headerText: "",
  bodyText: "",
};
