import { Grid, Icon } from "@mui/material";
import MDBox from "components/MDBox";
import { useEffect } from "react";
import PropTypes from "prop-types";
import { setOpenDetailMessageModal, setMessageId, useMaterialUIController } from "context";
import Modal from "../modal";

function MessageList({ messagesList }) {
  const [controller, dispatch] = useMaterialUIController();
  if (false) console.log(controller);
  useEffect(() => {
    console.log(messagesList);
  }, []);
  const getSendDateText = (date) => {
    let now = new Date();
    now = now.toISOString();
    const year = parseInt(date.split("T")[0].split("-")[0], 10);
    const month = parseInt(date.split("T")[0].split("-")[1], 10);
    const day = parseInt(date.split("T")[0].split("-")[2], 10);
    const hour = parseInt(date.split("T")[1].split(".")[0].split(":")[0], 10);
    const minute = parseInt(date.split("T")[1].split(".")[0].split(":")[1], 10);
    const yearNow = parseInt(now.split("T")[0].split("-")[0], 10);
    const monthNow = parseInt(now.split("T")[0].split("-")[1], 10);
    const dayNow = parseInt(now.split("T")[0].split("-")[2], 10);
    const hourNow = parseInt(now.split("T")[1].split(".")[0].split(":")[0], 10);
    const minuteNow = parseInt(now.split("T")[1].split(".")[0].split(":")[1], 10);
    if (year === yearNow && month === monthNow && day === dayNow) {
      if (hourNow === hour && minuteNow === minute) return "just now";
      const sumMin = hourNow * 60 + minuteNow - (hour * 60 + minute);
      const h = Math.floor(sumMin / 60);
      const m = sumMin % 60;
      if (h === 0) return `${m} minutes ago`;
      return `${h}:${m} ago`;
    }
    const date1 = new Date(year, month, day);
    const date2 = new Date(yearNow, monthNow, dayNow);
    const days = Math.floor((date2 - date1) / 86400000);
    return `${days} days ago`;
  };
  return (
    <>
      <Modal />
      <div>
        <MDBox className="px-2">
          <Grid
            container
            className="my-2 p-2 border-b border shadow bg-white rounded flex flex-col items-center"
          >
            <Grid item xs={2} className="text-[12px]">
              Sender name
            </Grid>
            <Grid item xs={3} className="text-[12px]">
              Email
            </Grid>
            <Grid item xs={3} className="text-[12px] text-center items-center flex justify-center">
              Message
            </Grid>
            <Grid item xs={1} className="text-[12px] text-center items-center flex justify-center">
              Replied
            </Grid>
            <Grid item xs={2} className="text-[12px]">
              Send on
            </Grid>
            <Grid item xs={1} className="text-[12px] text-center items-center flex justify-center">
              Action
            </Grid>
          </Grid>
          {messagesList.map((value) => (
            <Grid
              container
              className="my-2 p-2 border-b hover:shadow bg-white rounded flex flex-col items-center"
              key={value.id}
            >
              <Grid
                item
                xs={2}
                onClick={() => {
                  setOpenDetailMessageModal(dispatch, true);
                  setMessageId(dispatch, value.id);
                }}
                className={`text-[16px] ${
                  value.replies.length === 0 && "font-bold"
                } text-ellipsis whitespace-nowrap overflow-hidden cursor-pointer`}
              >
                {value.name}
              </Grid>
              <Grid
                item
                xs={3}
                className={`text-[14px] ${
                  value.replies.length === 0 && "text-black font-bold"
                } text-ellipsis whitespace-nowrap overflow-hidden mx-1`}
              >
                {value.email}
              </Grid>
              <Grid
                item
                xs={3}
                className={`text-[14px] ${
                  value.replies.length === 0 && "text-black font-bold"
                } text-ellipsis whitespace-nowrap overflow-hidden`}
              >
                {value.description}
              </Grid>
              <Grid
                item
                xs={1}
                className={`text-[14px] ${
                  value.replies.length === 0 && "text-black font-bold"
                } text-ellipsis whitespace-nowrap overflow-hidden flex items-center justify-center`}
              >
                {value.replies.length === 0 ? <Icon>check</Icon> : <Icon>done_all</Icon>}
              </Grid>
              <Grid
                item
                xs={2}
                className={`text-[15px] ${
                  value.replies.length === 0 && "text-black font-bold"
                } text-ellipsis whitespace-nowrap overflow-hidden`}
              >
                {getSendDateText(value.created_at)}
              </Grid>
              <Grid
                item
                xs={1}
                className={`text-[13px] ${
                  value.replies.length === 0 && "text-black font-bold"
                } cursor-pointer py-1`}
              >
                <div
                  role="presentation"
                  onClick={() => {
                    setOpenDetailMessageModal(dispatch, true);
                    setMessageId(dispatch, value.id);
                  }}
                  className={`text-gray-500 ${
                    value.replies.length === 0 && "text-black font-bold"
                  } hover:text-gray-400 flex items-center justify-center underline cursor-pointer`}
                >
                  reply<Icon className="ml-2">reply</Icon>
                </div>
              </Grid>
            </Grid>
          ))}
        </MDBox>
      </div>
    </>
  );
}
MessageList.propTypes = {
  messagesList: PropTypes.array,
};
MessageList.defaultProps = {
  messagesList: [],
};
export default MessageList;
