/* eslint-disable react/no-array-index-key */
/* eslint-disable react/prop-types */
// import MDBox from "components/MDBox";
import React, { useEffect, useState } from "react";
import { setCircularProgress, useMaterialUIController, setTab } from "context";
import { Card, Grid, Icon } from "@mui/material";
import GradientLineChart from "examples/Charts/LineCharts/GradientLineChart";
import { getLawyerActivities } from "api/api_lawyer";

// import MDButton from "components/MDButton";

function Activities({ id }) {
  const [controller, dispatch] = useMaterialUIController();
  const [activities, setActivities] = useState({});
  const [labelsData, setLabelsData] = useState([]);
  const [dataSetsData, setDataSetsData] = useState([]);
  const [dataSetsData2, setDataSetsData2] = useState([]);
  const [dataSetsData3, setDataSetsData3] = useState([]);
  const [dataSetsTooltip, setDataSetsTooltip] = useState([]);
  const [dataSetsTooltip2, setDataSetsTooltip2] = useState([]);
  const [dataSetsTooltip3, setDataSetsTooltip3] = useState([]);
  const [chartData, setChartData] = useState({
    labels: labelsData,
    datasets: [
      {
        label: "last year lives",
        color: "info",
        data: dataSetsData,
      },
    ],
  });
  if (false) console.log(controller);
  useEffect(() => {
    setChartData({
      labels: labelsData,
      datasets: [
        {
          label: "lives income",
          color: "info",
          data: dataSetsData,
        },
        {
          label: "cases income",
          color: "warning",
          data: dataSetsData2,
        },
        {
          label: "sessions income",
          color: "success",
          data: dataSetsData3,
        },
      ],
      tooltips: [dataSetsTooltip, dataSetsTooltip2, dataSetsTooltip3],
    });
  }, [
    labelsData,
    dataSetsData,
    dataSetsData2,
    dataSetsData3,
    dataSetsTooltip,
    dataSetsTooltip2,
    dataSetsTooltip3,
  ]);
  const doubleNumber = (number) => {
    if (number < 10) {
      return `0${number}`;
    }
    return number;
  };
  useEffect(() => {
    setCircularProgress(dispatch, true);
    getLawyerActivities((isOk, data) => {
      if (!isOk) {
        console.log("get lawyer actvities from api is not working!");
        setCircularProgress(dispatch, false);
      } else {
        setActivities(data);
        setCircularProgress(dispatch, false);
        let labels = [];
        const currentDate = new Date();
        const currentYear = currentDate.getFullYear();
        const currentMonth = currentDate.getMonth() + 1;
        for (let i = 0; i < 12; i += 1) {
          if (i >= currentMonth) {
            labels.push(`${currentYear - 1}-${doubleNumber(currentMonth + (12 - i))}`);
          } else {
            labels.push(`${currentYear}-${doubleNumber(currentMonth - i)}`);
          }
        }
        labels = labels.reverse();
        const datasets = [];
        const datasets2 = [];
        const datasets3 = [];
        const datasetTooltip = [];
        const datasetTooltip2 = [];
        const datasetTooltip3 = [];
        for (let i = 0; i < 12; i += 1) {
          const liveDateList = data.livesList.filter((value) => value.live_date === labels[i]);
          if (liveDateList.length === 0) {
            datasets.push(0);
            datasetTooltip.push("0.00");
          } else {
            datasetTooltip.push(parseInt(liveDateList[0].total_money, 10) / 100);
            datasets.push(liveDateList[0].data);
          }
          const caseDateList = data.casesList.filter((value) => value.case_date === labels[i]);
          if (caseDateList.length === 0) {
            datasets2.push(0);
            datasetTooltip2.push("0.00");
          } else {
            datasetTooltip2.push(parseInt(caseDateList[0].total_money, 10) / 100);
            datasets2.push(caseDateList[0].data);
          }
          const sessionDateList = data.sessionsList.filter(
            (value) => value.session_date === labels[i]
          );
          if (sessionDateList.length === 0) {
            datasets3.push(0);
            datasetTooltip3.push("0.00");
          } else {
            datasetTooltip3.push(parseInt(sessionDateList[0].total_money, 10) / 100);
            datasets3.push(sessionDateList[0].data);
          }
        }
        setLabelsData(labels);
        setDataSetsData(datasets);
        setDataSetsData2(datasets2);
        setDataSetsData3(datasets3);
        setDataSetsTooltip(datasetTooltip);
        setDataSetsTooltip2(datasetTooltip2);
        setDataSetsTooltip3(datasetTooltip3);
      }
    }, id);
  }, []);
  return (
    <div className="px-4 py-4 mt-4">
      <Grid container className="flex items-center">
        <Grid xs={3}>
          <Card className="px-4 py-2 m-1 h-[150px] rounded">
            <div className="flex">
              <div className="text-[13px] flex items-center">Total Lives</div>
              <div className="flex items-center bg-[#227BEA] animate-pulse ml-2 rounded-full">
                <Icon className="text-white">play_arrow</Icon>
              </div>
            </div>
            <div className="mt-2">
              <div className="flex items-center justify-center">
                <div className="text-[25px] font-bold flex">
                  {Math.round((activities.livesPayment / 100) * 100) / 100 || 0}
                  <div className="text-[25px] text-[#D4AF37] flex items-center">$</div>
                </div>
                <div className="text-[10px] mt-[12px] ml-1">Earned</div>
              </div>
              <div className="flex items-center justify-center mt-[10px]">
                <div className="text-[10px] mt-[5px] mr-1">in</div>
                <div className="text-[20px] font-bold">{activities.lives}</div>
                <div className="text-[10px] mt-[5px] ml-1">Times</div>
              </div>
            </div>
            <div
              role="presentation"
              onClick={() => setTab(dispatch, 4)}
              className="text-[10px] mt-auto text-[#227BEA] cursor-pointer"
            >
              View all Lives
            </div>
          </Card>
          <Card className="px-4 py-2 m-1 h-[150px] mt-2 rounded">
            <div className="flex">
              <div className="text-[13px] flex items-center">Cases</div>
              <div className="flex items-center animate-ping ml-2 rounded-full">
                <Icon className="text-[#227BEA]">article</Icon>
              </div>
            </div>
            <div className="mt-2">
              <div className="flex items-center justify-center">
                <div className="text-[25px] font-bold flex">
                  {Math.round((activities.casesPayment / 100) * 100) / 100 || 0}
                  <div className="text-[25px] text-[#D4AF37] flex items-center">$</div>
                </div>
                <div className="text-[10px] mt-[12px] ml-1">Earned</div>
              </div>
              <div className="flex items-center justify-center mt-[10px]">
                <div className="text-[10px] mt-[5px] mr-1">in</div>
                <div className="text-[20px] font-bold">{activities.cases}</div>
                <div className="text-[10px] mt-[5px] ml-1">Times</div>
              </div>
            </div>
            <div
              role="presentation"
              onClick={() => setTab(dispatch, 5)}
              className="text-[10px] mt-auto text-[#227BEA] cursor-pointer"
            >
              View all Cases
            </div>
          </Card>
          <Card className="px-4 py-2 m-1 h-[150px] mt-2 rounded">
            <div className="flex">
              <div className="text-[13px] flex items-center">Sessions</div>
              <div className="flex items-center animate-pulse ml-2 rounded-full">
                <Icon className="text-[#227BEA]">dialer_sip</Icon>
              </div>
            </div>
            <div className="mt-2">
              <div className="flex items-center justify-center">
                <div className="text-[25px] font-bold flex">
                  {Math.round((activities.sesionsPayment / 100) * 100) / 100 || 0}
                  <div className="text-[25px] text-[#D4AF37] flex items-center">$</div>
                </div>
                <div className="text-[10px] mt-[12px] ml-1">Earned</div>
              </div>
              <div className="flex items-center justify-center mt-[10px]">
                <div className="text-[10px] mt-[5px] mr-1">in</div>
                <div className="text-[20px] font-bold">{activities.sessions}</div>
                <div className="text-[10px] mt-[5px] ml-1">Times</div>
              </div>
            </div>
            <div
              role="presentation"
              onClick={() => setTab(dispatch, 7)}
              className="text-[10px] mt-auto text-[#227BEA] cursor-pointer"
            >
              View all Sessions
            </div>
          </Card>
        </Grid>
        <Grid xs={9}>
          <div>
            <GradientLineChart
              icon={{ color: "info", component: "multiline_chart_icon " }}
              title="Activities data"
              description="total money of lives and cases and session from last year"
              chart={chartData}
              height="380px"
            />
          </div>
        </Grid>
      </Grid>
    </div>
  );
}

export default Activities;
