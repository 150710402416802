// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
// Material Dashboard 2 React components
import MDBox from "components/MDBox";
// import MDTypography from "components/MDTypography";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import { useEffect } from "react";
// import { useMaterialUIController } from "context";
import { useLocation, useNavigate } from "react-router-dom";
import {
  useMaterialUIController,
  setPersonInfo,
  setClientTab,
  setScrollInDetail,
  setCircularProgress,
} from "context";
// import Certificate from "./Certificate";
import ClientDetailNavbar from "examples/Navbars/clientDetailNavbar";
import { getAClientDetails } from "api/api_client";
import UserProfile from "./UserProfile";
import Addresses from "./Addresses";
import Lives from "./Lives";
import Cases from "./Cases";
import Payments from "./Payments";
import Transactions from "./Transactions";
import Subscription from "./Subscription";
import Overview from "./Overview";
import Sessions from "./Sessions";

function Tables() {
  const [controller, dispatch] = useMaterialUIController();
  const { personInfo, clientTab } = controller;
  const setTabFunction = (num) => {
    setClientTab(dispatch, num);
  };
  const navigate = useNavigate();
  const { pathname } = useLocation();
  let id = pathname.split("/");
  id = id[id.length - 1];
  id = id.split("?");
  [id] = id;
  useEffect(() => {
    setCircularProgress(dispatch, true);
    getAClientDetails((isOk, data) => {
      if (!isOk) {
        console.log("get client details from api is not working!");
        navigate("/clients?page=1");
        setCircularProgress(dispatch, false);
      } else {
        setCircularProgress(dispatch, false);
        setPersonInfo(dispatch, data);
      }
    }, id);
  }, []);
  const renderInnerTable = () => {
    if (clientTab === 1) return <Overview id={id} />;
    if (clientTab === 2) return <Addresses id={id} />;
    if (clientTab === 3) return <Lives />;
    if (clientTab === 4) return <Cases />;
    if (clientTab === 5) return <Payments />;
    if (clientTab === 6) return <Transactions />;
    if (clientTab === 7) return <Subscription id={id} />;
    if (clientTab === 8) return <Sessions />;
    return "";
  };
  useEffect(() => {
    navigate(`/clients/detail/${id}`);
  }, [clientTab]);
  // const [scrollTop, setScrollTop] = useState(0);
  const handleScroll = () => {
    setScrollInDetail(dispatch, window.scrollY);
  };
  useEffect(() => {
    setScrollInDetail(dispatch, 0);
    window.addEventListener("scroll", handleScroll);
  }, []);
  if (Object.keys(personInfo).length === 0) return <div />;
  return (
    <DashboardLayout>
      <ClientDetailNavbar />
      <MDBox pt={6} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <UserProfile personInfo={personInfo} tab={clientTab} setTab={setTabFunction} />
          </Grid>
        </Grid>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card className="mt-4">{renderInnerTable()}</Card>
          </Grid>
        </Grid>
      </MDBox>
    </DashboardLayout>
  );
}

export default Tables;
