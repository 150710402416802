import { Card, Grid, Pagination } from "@mui/material";
import { getAllMessages } from "api/api_messages";
import MDBox from "components/MDBox";
import { useMaterialUIController, setCircularProgress } from "context";

import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import MessagesNavbar from "examples/Navbars/messagesNavbar";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import MessageList from "./messgaeList";

function Messages() {
  const [controller, dispatch] = useMaterialUIController();
  const [paginationCount, setPaginationCount] = useState(1);
  const [messagesList, setMessagesList] = useState([]);
  const [messagesPage, setMessagesPage] = useState(1);
  const { searchBarValueMessages, sortMessages, orderMessages, openDetailMessageModal } =
    controller;
  const navigate = useNavigate();
  const { pathname } = useLocation();
  let id = pathname.split("/");
  id = id[id.length - 1];
  id = id.split("?");
  [id] = id;
  useEffect(() => {
    let url = "/contactUs";
    url += `?page=${messagesPage}`;
    if (searchBarValueMessages !== "") url += `&search=${searchBarValueMessages}`;
    if (sortMessages !== "") url += `&sort=${sortMessages}`;
    if (orderMessages !== "") url += `&order=${orderMessages}`;
    if (false) console.log(messagesList);
    navigate(url);
  }, [searchBarValueMessages, messagesPage, sortMessages, orderMessages]);
  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const paramsObj = Array.from(params.keys()).reduce(
      (acc, val) => ({ ...acc, [val]: params.get(val) }),
      {}
    );
    if (!paramsObj.page) setMessagesPage(1);
    else setMessagesPage(parseInt(paramsObj.page, 10));
    let param = "";
    param += `?page=${paramsObj.page}`;
    if (paramsObj.search !== "" && paramsObj.search) param += `&search=${paramsObj.search}`;
    if (paramsObj.order !== "" && paramsObj.order) param += `&sort=${paramsObj.sort}`;
    if (paramsObj.order !== "" && paramsObj.order) param += `&order=${paramsObj.order}`;
    if (!openDetailMessageModal) {
      setCircularProgress(dispatch, true);
      getAllMessages((isOk, data) => {
        if (!isOk) {
          console.log("get Messages List from api is not working", data);
          setCircularProgress(dispatch, false);
        } else {
          setMessagesList(data.data);
          setPaginationCount(data.meta.last_page);
          setCircularProgress(dispatch, false);
        }
      }, param);
    }
  }, [pathname, window.location.search, openDetailMessageModal]);

  const handlePaginationChange = (event, value) => {
    let url = "";
    url += "/contactUs";
    url += `?page=${value}`;
    if (searchBarValueMessages !== "") url += `&search=${searchBarValueMessages}`;
    if (sortMessages !== "") url += `&order=${sortMessages}`;
    navigate(url);
  };
  useEffect(() => {
    setMessagesPage(1);
  }, [searchBarValueMessages]);
  return (
    <DashboardLayout>
      <MessagesNavbar />
      <Grid container spacing={6}>
        <Grid item xs={12}>
          {messagesList.length === 0 ? (
            <Card
              style={{
                minHeight: "300px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <MDBox
                pt={3}
                style={{ display: "flex", alignItems: "center", justifyContent: "center" }}
              >
                empty
              </MDBox>
            </Card>
          ) : (
            <MessageList messagesList={messagesList} />
          )}
        </Grid>
      </Grid>
      <MDBox style={{ display: "flex", justifyContent: "center" }}>
        <Pagination
          page={messagesPage}
          onChange={handlePaginationChange}
          count={paginationCount}
          color="info"
        />
      </MDBox>
    </DashboardLayout>
  );
}

export default Messages;
