// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/LivesNavbar";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import AccountBalanceIcon from "@mui/icons-material/AccountBalance";
import GroupIcon from "@mui/icons-material/Group";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import {
  useMaterialUIController,
  setLivesTabValue,
  setSearchBarValueLive,
  setSearchBarValueDetailLive,
} from "context";
import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import LawyersLivesTable from "./lawyersLiveTable";
import ClientsLivesTable from "./clientsLiveTable";
import CurrentLivesTable from "./currentLiveTable";

function Lives() {
  const [controller, dispatch] = useMaterialUIController();
  const { livesTabValue } = controller;
  const { pathname } = useLocation();
  const navigate = useNavigate();
  useEffect(() => {
    if (pathname === "/lives" && window.location.search === "") {
      if (livesTabValue === 0) navigate("/lives?lawyers");
      else if (livesTabValue === 1) navigate("/lives?clients");
      else if (livesTabValue === 2) navigate("/lives?current");
    }
    if (window.location.search === "?lawyers") {
      setLivesTabValue(dispatch, 0);
    } else if (window.location.search === "?clients") {
      setLivesTabValue(dispatch, 1);
    } else if (window.location.search === "?current") {
      setLivesTabValue(dispatch, 2);
    }
  }, []);
  useEffect(() => {
    setSearchBarValueLive(dispatch, "");
    setSearchBarValueDetailLive(dispatch, "");
  }, [livesTabValue]);
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <Tabs
        value={livesTabValue}
        onChange={(e, newValue) => {
          setLivesTabValue(dispatch, newValue);
          if (newValue === 0) navigate("/lives?lawyers");
          if (newValue === 1) navigate("/lives?clients");
          if (newValue === 2) navigate("/lives?current");
        }}
        aria-label="User Type Tab"
      >
        <Tab
          icon={<AccountBalanceIcon />}
          style={{
            fontWeight: livesTabValue === 0 && "bold",
          }}
          label="LAWYERS"
        />
        <Tab
          icon={<GroupIcon />}
          style={{
            fontWeight: livesTabValue === 1 && "bold",
          }}
          label="CLIENTS"
        />
        <Tab
          icon={<PlayArrowIcon />}
          style={{
            fontWeight: livesTabValue === 2 && "bold",
          }}
          label="CURRENT LIVES"
        />
      </Tabs>
      {livesTabValue === 0 && <LawyersLivesTable />}
      {livesTabValue === 1 && <ClientsLivesTable />}
      {livesTabValue === 2 && <CurrentLivesTable />}
    </DashboardLayout>
  );
}

export default Lives;
