import Grid from "@mui/material/Grid";
// Material Dashboard 2 React components
import MDBox from "components/MDBox";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import ComplexStatisticsCard from "examples/Cards/StatisticsCards/ComplexStatisticsCard";
import {
  useMaterialUIController,
  setLawyersData,
  setClientsData,
  setLawyersNumber,
  setClientsNumber,
  setLivesNumber,
  setCircularProgress,
  setCasesNumber,
  setLivesOverview,
  setPaymentsOverviews,
  setCasesData,
} from "context";
import { getLawyersList } from "api/api_lawyer";
import { getClientsList } from "api/api_client";
import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { getAllLives, getAllLivesReport } from "api/api_lives";
import { getAllCases } from "api/api_cases";
import ReportsLineChart from "examples/Charts/LineCharts/ReportsLineChart";
import ReportsBarChart from "examples/Charts/BarCharts/ReportsBarChart";
import { getAllPaymentsReport } from "api/api_payment";

function Dashboard() {
  const [controller, dispatch] = useMaterialUIController();
  const { lawyersCount, clientsCount, clientsPage, lawyersPage, livesCount, casesCount } =
    controller;

  const [fromDateStr, setFromDateStr] = useState({});
  const [percent, setPercent] = useState(0);
  const [toDateStr, setToDateStr] = useState({});
  const [livesReportBarChartData, setLivesReportBarChartData] = useState({});
  const [paymentsReportBarChartData, setPaymentsReportBarChartData] = useState({});
  const navigate = useNavigate();
  useEffect(() => {
    if (!localStorage.getItem("x-auth-token")) {
      navigate("/authentication/verify-number");
    }
  }, []);
  const convertToNormalFormat = (date) => {
    const deteils = date.split("-");
    const year = deteils[0].length === 1 ? `0${deteils[0]}` : deteils[0];
    const month = deteils[1].length === 1 ? `0${deteils[1]}` : deteils[1];
    const day = deteils[2].length === 1 ? `0${deteils[2]}` : deteils[2];
    return `${year}-${month}-${day}`;
  };
  const getLawyersObjectFromApi = () => {
    setCircularProgress(dispatch, true);
    getLawyersList((isOk, data) => {
      if (!isOk) {
        console.log("get Lawyers data from api is not working", data);
      } else {
        setLawyersData(dispatch, data);
        setLawyersNumber(dispatch, data.meta.total);
      }
      setCircularProgress(dispatch, false);
    }, `${lawyersPage}`);
  };
  const getClientsObjectFromApi = () => {
    setCircularProgress(dispatch, true);
    getClientsList((isOk, data) => {
      if (!isOk) {
        console.log("get Clients data from api is not working", data);
      } else {
        setClientsData(dispatch, data);
        setClientsNumber(dispatch, data.meta.total);
      }
      setCircularProgress(dispatch, false);
    }, `${clientsPage}`);
  };
  const getLivesObjectFromApi = () => {
    setCircularProgress(dispatch, true);
    getAllLives((isOk, data) => {
      if (!isOk) {
        console.log("get Lives Object data from api is not working", data);
      } else {
        setLivesNumber(dispatch, data.meta.total);
      }
      setCircularProgress(dispatch, false);
    });
  };
  const getCasesObjectFromApi = () => {
    setCircularProgress(dispatch, true);
    getAllCases((isOk, data) => {
      if (!isOk) {
        console.log("get CasesObject data from api is not working", data);
      } else {
        setCasesData(dispatch, data.data);
        setCasesNumber(dispatch, data.meta.total);
      }
      setCircularProgress(dispatch, false);
    });
  };
  const weekDays = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
  const monthNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  const getLivesOverviewObjectFromApi = () => {
    setCircularProgress(dispatch, true);
    let currentDate = new Date();
    currentDate = currentDate.toISOString();
    let now = `${parseInt(currentDate.split("T")[0].split("-")[0], 10)}-${parseInt(
      currentDate.split("T")[0].split("-")[1],
      10
    )}-${parseInt(currentDate.split("T")[0].split("-")[2], 10)}`;
    let sevenDaysAgo = new Date(Date.now() - 7 * 24 * 60 * 60 * 1000);
    sevenDaysAgo = `${sevenDaysAgo.getFullYear()}-${
      sevenDaysAgo.getMonth() + 1
    }-${sevenDaysAgo.getDate()}`;
    now = convertToNormalFormat(now);
    sevenDaysAgo = convertToNormalFormat(sevenDaysAgo);
    setFromDateStr(sevenDaysAgo);
    setToDateStr(now);
    getAllLivesReport((isOk, data) => {
      if (!isOk) {
        console.log("get LivesOverviewObject data from api is not working", data);
      } else {
        setLivesOverview(dispatch, data.data);
        const livesLablesTemp = [];
        const livesDatasetsTemp = [];
        for (let i = 0; i < data.data.length; i += 1) {
          const dateArray = data.data[i].live_date.split("-");
          const thisDate = weekDays[new Date(dateArray[0], dateArray[1], dateArray[2]).getDay()];
          livesLablesTemp.push(thisDate);
          livesDatasetsTemp.push(Math.round((data.data[i].total_money / 100) * 100) / 100);
        }
        setLivesReportBarChartData({
          labels: livesLablesTemp,
          datasets: { label: "total money", data: livesDatasetsTemp },
        });
      }
      setCircularProgress(dispatch, false);
    }, `?unit=day&from=${sevenDaysAgo}&to=${now}`);
  };
  const getPaymentsOverviewObjectFromApi = () => {
    setCircularProgress(dispatch, true);
    let currentDate = new Date();
    currentDate = currentDate.toISOString();
    let now = `${parseInt(currentDate.split("T")[0].split("-")[0], 10)}-${parseInt(
      currentDate.split("T")[0].split("-")[1],
      10
    )}-${parseInt(currentDate.split("T")[0].split("-")[2], 10)}`;
    let pastYear = new Date(Date.now() - 365 * 24 * 60 * 60 * 1000);
    pastYear = `${pastYear.getFullYear()}-${pastYear.getMonth() + 1}-${pastYear.getDate()}`;
    now = convertToNormalFormat(now);
    pastYear = convertToNormalFormat(pastYear);

    getAllPaymentsReport((isOk, data) => {
      if (!isOk) {
        console.log("get payments Overview Object data from api is not working", data);
      } else {
        setPaymentsOverviews(dispatch, data.data);
        const paymentsLablesTemp = [];
        const paymentsDatasetsTemp = [];
        for (let i = 0; i < data.data.length; i += 1) {
          const month = monthNames[parseInt(data.data[i].payment_date.split("-")[1], 10) - 1];
          paymentsLablesTemp.push(month);
          paymentsDatasetsTemp.push(Math.round((data.data[i].money / 100) * 100) / 100);
        }
        setPaymentsReportBarChartData({
          labels: paymentsLablesTemp,
          datasets: { label: "money paid", data: paymentsDatasetsTemp },
        });
        if (data.data.length >= 2) {
          const lastMonth = data.data[data.data.length - 1]?.money;
          const beforeLastMonth = data.data[data.data.length - 2]?.money;
          const prec =
            Math.round(parseFloat((lastMonth - beforeLastMonth) / beforeLastMonth) * 100 * 100) /
            100;
          setPercent(prec);
        }
      }
      setCircularProgress(dispatch, false);
    }, `?unit=month&from=${pastYear}&to=${now}`);
  };
  useEffect(() => {
    getLawyersObjectFromApi();
    getClientsObjectFromApi();
    getLivesObjectFromApi();
    getLivesOverviewObjectFromApi();
    getPaymentsOverviewObjectFromApi();
    getCasesObjectFromApi();
  }, [localStorage.getItem("x-auth-token")]);
  const getPercentInfo = (perc) => {
    if (perc === 0) {
      return <span>{`${perc}%`}</span>;
    }
    if (perc > 0) {
      return <span className="text-green-500">{`${perc}%`}</span>;
    }
    if (perc < 0) {
      return <span className="text-red-500">{`${perc}%`}</span>;
    }
    return `${perc}%`;
  };
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox py={3}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={6} lg={3}>
            <Link to="/lawyers" mb={1.5}>
              <ComplexStatisticsCard
                color="info"
                icon="account_balance"
                title="Lawyers"
                count={lawyersCount}
                percentage={{
                  color: "success",
                  amount: "",
                  label: <div className="underline hover:text-black">more about lawyers</div>,
                }}
              />
            </Link>
          </Grid>
          <Grid item xs={12} md={6} lg={3}>
            <Link to="/clients" mb={1.5}>
              <ComplexStatisticsCard
                color="primary"
                icon="group"
                title="Clients"
                count={clientsCount}
                percentage={{
                  color: "success",
                  amount: "",
                  label: <div className="underline hover:text-black">more about clients</div>,
                }}
              />
            </Link>
          </Grid>
          <Grid item xs={12} md={6} lg={3}>
            <Link to="/lives" mb={1.5}>
              <ComplexStatisticsCard
                color="error"
                icon="play_arrow"
                title="Lives"
                count={livesCount}
                percentage={{
                  color: "success",
                  amount: "",
                  label: <div className="underline hover:text-black">more about lives</div>,
                }}
              />
            </Link>
          </Grid>
          <Grid item xs={12} md={6} lg={3}>
            <Link to="/cases" mb={1.5}>
              <ComplexStatisticsCard
                color="success"
                icon="cases"
                title="Proposals"
                count={casesCount}
                percentage={{
                  color: "success",
                  amount: "",
                  label: <div className="underline hover:text-black">more about cases</div>,
                }}
              />
            </Link>
          </Grid>
        </Grid>
      </MDBox>

      <MDBox py={3}>
        <MDBox mt={4.5}>
          <Grid container spacing={3}>
            <Grid item xs={4} md={4} lg={4}>
              <MDBox mb={3}>
                <ReportsBarChart
                  moreInfoDesc="The costs of the lives from the previous week are displayed in this chart."
                  color="info"
                  title="lives report"
                  description={`report of lives in date from ${fromDateStr} to ${toDateStr}`}
                  chart={livesReportBarChartData}
                />
              </MDBox>
            </Grid>

            <Grid item xs={8} md={8} lg={8}>
              <MDBox mb={3}>
                <ReportsLineChart
                  moreInfoDesc="The income of the payments from the previous year are displayed in this chart."
                  color="dark"
                  title="payments report"
                  description={
                    <>
                      (<strong>{getPercentInfo(percent)}</strong>) {percent > 0 && "increase"}
                      {percent < 0 && "reduction"} in this month payments.
                    </>
                  }
                  chart={paymentsReportBarChartData}
                />
              </MDBox>
            </Grid>
          </Grid>
        </MDBox>
      </MDBox>
    </DashboardLayout>
  );
}

export default Dashboard;
