// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import "../../styles/generalStyles.css";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import DataTable from "examples/TablesDetailPaymentLawyer/DataTable";
import authorsTableData from "layouts/transfers/data/authorsTableData";
import { useMaterialUIController, setCircularProgress, setTransfersData } from "context";
import { useLocation } from "react-router-dom";
import { useEffect } from "react";
// import { Pagination } from "@mui/material";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import TransactionsNavbar from "examples/Navbars/TransactionsNavbar";
import { getAllTransfers } from "api/api_payments";

function Transactions() {
  const [controller, dispatch] = useMaterialUIController();
  if (false) console.log(controller);
  const { columns, rows } = authorsTableData();

  const { pathname } = useLocation();
  useEffect(() => {
    setCircularProgress(dispatch, true);
    getAllTransfers((isOk, data) => {
      if (!isOk) {
        console.log("get Transfers List from api is not working", data);
      } else {
        setTransfersData(dispatch, data.data);
      }
      setCircularProgress(dispatch, false);
    });
  }, [pathname, window.location.search]);

  return (
    <DashboardLayout>
      <TransactionsNavbar />
      <MDBox pt={6} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
                style={{ display: "flex" }}
              >
                <MDTypography variant="h6" color="white">
                  Transfers Table
                </MDTypography>
              </MDBox>
              <MDBox pt={3}>
                <DataTable
                  table={{ columns, rows }}
                  isSorted={false}
                  entriesPerPage={false}
                  showTotalEntries={false}
                  noEndBorder
                />
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
    </DashboardLayout>
  );
}

export default Transactions;
