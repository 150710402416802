import Divider from "@mui/material/Divider";
import Icon from "@mui/material/Icon";

import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import DrawerRightRoot from "examples/DrawerRight/DrawerRightRoot";
import {
  useMaterialUIController,
  setOpenDrawerRight,
  setCircularProgress,
  setAllCategoriesData,
  setAlertText,
  setAlertOpen,
  setAlertColor,
} from "context";
import { useState } from "react";
import MDInput from "components/MDInput";
import { createNewCategory, getAllCategories } from "api/api_category";

function CreateCategoryDrawer() {
  const [localName, setLocalName] = useState("");
  const [controller, dispatch] = useMaterialUIController();
  const { openDrawerRight, darkMode } = controller;
  const createCategory = () => {
    const body = {
      name: localName,
    };
    setCircularProgress(dispatch, true);
    setOpenDrawerRight(dispatch, false);
    createNewCategory((isOk, data) => {
      if (!isOk) {
        console.log("create a new category api is not working", data);
        setAlertText(dispatch, `create a new category is not working ${data.error?.message}`);
        setAlertOpen(dispatch, true);
        setAlertColor(dispatch, "error");
        setCircularProgress(dispatch, false);
      } else {
        setAlertText(dispatch, "create category is successfull");
        setAlertOpen(dispatch, true);
        setAlertColor(dispatch, "success");
        getAllCategories((isOk2, data2) => {
          if (!isOk2) {
            console.log("get categories data from api is not working", data);
          } else {
            setAllCategoriesData(dispatch, data2);
            setLocalName("");
          }
          setCircularProgress(dispatch, false);
        }, window.location.search);
      }
    }, body);
  };
  const handleCloseDrawerRight = () => setOpenDrawerRight(dispatch, false);
  return (
    <DrawerRightRoot variant="permanent" ownerState={{ openDrawerRight }}>
      <MDBox
        display="flex"
        justifyContent="space-between"
        alignItems="baseline"
        pt={4}
        pb={0.5}
        px={3}
      >
        <MDBox>
          <MDTypography variant="h5">Admin Panel Editor</MDTypography>
          <MDTypography variant="body2" color="text">
            Create new category
          </MDTypography>
        </MDBox>

        <Icon
          sx={({ typography: { size }, palette: { dark, white } }) => ({
            fontSize: `${size.lg} !important`,
            color: darkMode ? white.main : dark.main,
            stroke: "currentColor",
            strokeWidth: "2px",
            cursor: "pointer",
            transform: "translateY(5px)",
          })}
          onClick={handleCloseDrawerRight}
        >
          close
        </Icon>
      </MDBox>
      <Divider />
      <MDBox mb={2} mt={2}>
        <MDInput
          value={localName}
          onChange={(e) => setLocalName(e.target.value)}
          id="statusInputCreateCategory"
          label="Name"
          fullWidth
        />
      </MDBox>
      <MDBox mt={4} mb={1}>
        <MDButton onClick={() => createCategory()} variant="gradient" color="info" fullWidth>
          Create
        </MDButton>
      </MDBox>
    </DrawerRightRoot>
  );
}

export default CreateCategoryDrawer;
