/* eslint-disable no-nested-ternary */
// @mui material components
import Divider from "@mui/material/Divider";
import Icon from "@mui/material/Icon";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";

// Custom styles for the Configurator
import DrawerRightRoot from "examples/DrawerRight/DrawerRightRoot";

// Material Dashboard 2 React context
import {
  useMaterialUIController,
  setOpenDrawerRight,
  setFirstNameInDrawerRight,
  setLastNameInDrawerRight,
  setEmailInDrawerRight,
  setBirthdayInDrawerRight,
  setMobileInDrawerRight,
  setGenderInDrawerRight,
  setCircularProgress,
  setLawyersData,
  setCustomGenderInDrawerRight,
  setAlertText,
  setAlertOpen,
  setAlertColor,
} from "context";
import MDInput from "components/MDInput";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import { createLawyer, getLawyersList } from "api/api_lawyer";
import createUser from "model/createUserModel";

function DrawerRightForCreateLawyer() {
  const [controller, dispatch] = useMaterialUIController();
  const {
    openDrawerRight,
    darkMode,
    firstNameInDrawerRight,
    lastNameInDrawerRight,
    birthdayInDrawerRight,
    emailInDrawerRight,
    mobileInDrawerRight,
    genderInDrawerRight,
    customGenderInDrawerRight,
  } = controller;
  const handleCloseDrawerRight = () => setOpenDrawerRight(dispatch, false);
  const createUserBtnClicked = () => {
    const body = {
      first_name: firstNameInDrawerRight,
      last_name: lastNameInDrawerRight,
      birthday: birthdayInDrawerRight,
      email: emailInDrawerRight,
      mobile: mobileInDrawerRight,
      sex: genderInDrawerRight,
      status: "deactive",
    };
    if (parseInt(genderInDrawerRight, 10) === 2) body.custom_gender = customGenderInDrawerRight;
    setOpenDrawerRight(dispatch, false);
    setCircularProgress(dispatch, true);
    createUser
      .validate(body, { abortEarly: false })
      .then((valid) => {
        createLawyer((isOk, data) => {
          if (!isOk) {
            console.log("create Lawyer information is not successful: ", data);
            setCircularProgress(dispatch, false);
            setAlertText(dispatch, data.response.data.message);
            setAlertOpen(dispatch, true);
            setAlertColor(dispatch, "error");
          } else {
            getLawyersList((isOk2, data2) => {
              if (!isOk2) {
                console.log("get Lawyers data from api is not working", data2);
                setCircularProgress(dispatch, false);
                setAlertText(dispatch, data2.response.data.message);
                setAlertOpen(dispatch, true);
                setAlertColor(dispatch, "error");
              } else {
                setLawyersData(dispatch, data2);
                setCircularProgress(dispatch, false);
                setAlertText(dispatch, "create an lawyer is successfull!");
                setAlertOpen(dispatch, true);
                setAlertColor(dispatch, "success");
              }
            }, `${window.location.search}`);
          }
        }, valid);
      })
      .catch((err) => {
        setCircularProgress(dispatch, false);
        setAlertText(dispatch, err.errors[0].split(",")[0]);
        setAlertOpen(dispatch, true);
        setAlertColor(dispatch, "error");
      });
  };
  const setDateFunc = (e) => {
    const now = new Date();
    const selectedSDate = new Date(e.target.value);
    if (now < selectedSDate) {
      setAlertText(dispatch, "You can only select past dates");
      setAlertOpen(dispatch, true);
      setAlertColor(dispatch, "warning");
      return;
    }
    setBirthdayInDrawerRight(dispatch, e.target.value);
  };
  return (
    <DrawerRightRoot variant="permanent" ownerState={{ openDrawerRight }}>
      <MDBox
        display="flex"
        justifyContent="space-between"
        alignItems="baseline"
        pt={4}
        pb={0.5}
        px={3}
      >
        <MDBox>
          <MDTypography variant="h5">Admin Panel Editor</MDTypography>
          <MDTypography variant="body2" color="text">
            Add a new lawyer
          </MDTypography>
        </MDBox>

        <Icon
          sx={({ typography: { size }, palette: { dark, white } }) => ({
            fontSize: `${size.lg} !important`,
            color: darkMode ? white.main : dark.main,
            stroke: "currentColor",
            strokeWidth: "2px",
            cursor: "pointer",
            transform: "translateY(5px)",
          })}
          onClick={handleCloseDrawerRight}
        >
          close
        </Icon>
      </MDBox>
      <Divider />
      <MDBox component="form" role="form">
        <MDBox mb={2}>
          <MDInput
            value={mobileInDrawerRight}
            onChange={(e) => setMobileInDrawerRight(dispatch, e.target.value)}
            id="phoneNumberInput"
            type="text"
            label="Phone number"
            fullWidth
          />
        </MDBox>
        <MDBox mb={2}>
          <MDInput
            value={firstNameInDrawerRight}
            onChange={(e) => setFirstNameInDrawerRight(dispatch, e.target.value)}
            id="firstNameInput"
            type="text"
            label="First name"
            fullWidth
          />
        </MDBox>
        <MDBox mb={2}>
          <MDInput
            value={lastNameInDrawerRight}
            onChange={(e) => setLastNameInDrawerRight(dispatch, e.target.value)}
            id="lastNameInput"
            type="text"
            label="Last name"
            fullWidth
          />
        </MDBox>
        <MDBox mb={2}>
          <MDInput
            value={birthdayInDrawerRight}
            label="Birthday"
            onChange={(e) => setDateFunc(e)}
            id="date Input"
            type="date"
            fullWidth
          />
        </MDBox>
        <MDBox mb={2}>
          <MDInput
            value={emailInDrawerRight}
            onChange={(e) => setEmailInDrawerRight(dispatch, e.target.value)}
            id="emailInput"
            type="text"
            label="Email"
            fullWidth
          />
        </MDBox>
        <MDBox>
          <MDTypography style={{ fontSize: "1rem" }}>Select Your gender</MDTypography>
        </MDBox>
        <MDBox mb={2} fullWidth>
          <RadioGroup
            value={genderInDrawerRight}
            onChange={(e) => setGenderInDrawerRight(dispatch, e.target.value)}
            row
            aria-labelledby="gender"
            name="gender"
            defaultValue="offline"
          >
            <FormControlLabel value={0} control={<Radio />} label="male" labelPlacement="start" />
            <FormControlLabel value={1} control={<Radio />} label="female" labelPlacement="start" />
            <FormControlLabel value={2} control={<Radio />} label="other" labelPlacement="start" />
          </RadioGroup>
        </MDBox>
        {parseInt(genderInDrawerRight, 10) === 2 && (
          <MDBox mb={2}>
            <MDInput
              value={customGenderInDrawerRight}
              onChange={(e) => setCustomGenderInDrawerRight(dispatch, e.target.value)}
              id="customGenderInput"
              type="text"
              label="Custom Gender"
              fullWidth
            />
          </MDBox>
        )}
        <MDBox mt={4} mb={1}>
          <MDButton
            onClick={() => createUserBtnClicked()}
            variant="gradient"
            color="info"
            fullWidth
          >
            Add
          </MDButton>
        </MDBox>
      </MDBox>
    </DrawerRightRoot>
  );
}
export default DrawerRightForCreateLawyer;
