import Divider from "@mui/material/Divider";
import Icon from "@mui/material/Icon";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import DrawerRightRoot from "examples/DrawerRight/DrawerRightRoot";
import {
  useMaterialUIController,
  setOpenDrawerRight,
  setCircularProgress,
  setAlertText,
  setAlertOpen,
  setAlertColor,
  setAllLawyerOffices,
} from "context";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import { useLocation } from "react-router-dom";
import { useState, useEffect } from "react";
import { updateLawyerOffice } from "api/api_lawyer";
import MDInput from "components/MDInput";

function StatusDrawer() {
  const [controller, dispatch] = useMaterialUIController();
  const {
    openDrawerRight,
    darkMode,
    currentLawyerStatusId,
    currentCertificateStatusId,
    lawyerOffices,
  } = controller;
  const { pathname } = useLocation();
  const [localStatus, setLocalStatus] = useState("");
  const [localName, setLocalName] = useState("");
  const [localPhone, setLocalPhone] = useState("");
  const [localCity, setLocalCity] = useState("");
  const [localState, setLocalState] = useState("");
  const [localPostCode, setLocalPostCode] = useState("");
  const [localAddress, setLocalAddress] = useState("");
  const [myList, setMyList] = useState(lawyerOffices);
  useEffect(() => {
    setMyList(lawyerOffices);
  }, [pathname, lawyerOffices]);
  useEffect(() => {
    const foundedIndex = myList.findIndex((value) => value.id === currentCertificateStatusId);
    setLocalStatus(myList[foundedIndex] ? myList[foundedIndex].status : "");
    setLocalName(myList[foundedIndex] ? myList[foundedIndex].name : "");
    setLocalPhone(myList[foundedIndex] ? myList[foundedIndex].phone : "");
    setLocalCity(myList[foundedIndex] ? myList[foundedIndex].city : "");
    setLocalState(myList[foundedIndex] ? myList[foundedIndex].state : "");
    setLocalPostCode(myList[foundedIndex] ? myList[foundedIndex].post_code : "");
    setLocalAddress(myList[foundedIndex] ? myList[foundedIndex].address : "");
  }, [openDrawerRight]);
  const saveOffice = () => {
    setOpenDrawerRight(dispatch, false);

    const body = {
      status: localStatus,
      name: localName,
      phone: localPhone,
      city: localCity,
      state: localState,
      post_code: localPostCode,
      address: localAddress,
    };
    setCircularProgress(dispatch, true);
    updateLawyerOffice(
      (isOk, data) => {
        if (!isOk) {
          console.log("update Office information is un successfull", data);
          setCircularProgress(dispatch, false);
          setAlertText(dispatch, "update Office information is un successfull");
          setAlertOpen(dispatch, true);
          setAlertColor(dispatch, "error");
        } else {
          setAlertText(dispatch, "update Office information is un successfull");
          setAlertOpen(dispatch, true);
          setAlertColor(dispatch, "success");
          const temp = [];
          for (let i = 0; i < lawyerOffices.length; i += 1) {
            if (lawyerOffices[i].id === currentCertificateStatusId) {
              temp.push(data);
            } else {
              temp.push(lawyerOffices[i]);
            }
          }
          setAllLawyerOffices(dispatch, temp);
          setCircularProgress(dispatch, false);
        }
      },
      body,
      currentLawyerStatusId,
      currentCertificateStatusId
    );
  };
  const handleCloseDrawerRight = () => setOpenDrawerRight(dispatch, false);
  return (
    <DrawerRightRoot variant="permanent" ownerState={{ openDrawerRight }}>
      <MDBox
        display="flex"
        justifyContent="space-between"
        alignItems="baseline"
        pt={4}
        pb={0.5}
        px={3}
      >
        <MDBox>
          <MDTypography variant="h5">Admin Panel Editor</MDTypography>
          <MDTypography variant="body2" color="text">
            edit certificate status of user
          </MDTypography>
        </MDBox>
        <Icon
          sx={({ typography: { size }, palette: { dark, white } }) => ({
            fontSize: `${size.lg} !important`,
            color: darkMode ? white.main : dark.main,
            stroke: "currentColor",
            strokeWidth: "2px",
            cursor: "pointer",
            transform: "translateY(5px)",
          })}
          onClick={handleCloseDrawerRight}
        >
          close
        </Icon>
      </MDBox>
      <Divider />
      <MDBox mb={2}>
        <MDInput
          value={localName}
          onChange={(e) => setLocalName(e.target.value)}
          id="NameInput"
          type="text"
          label="Name"
          fullWidth
        />
      </MDBox>
      <MDBox mb={2}>
        <MDInput
          value={localPhone}
          onChange={(e) => setLocalPhone(e.target.value)}
          id="PhoneInput"
          type="text"
          label="Phone"
          fullWidth
        />
      </MDBox>
      <MDBox mb={2}>
        <MDInput
          value={localCity}
          onChange={(e) => setLocalCity(e.target.value)}
          id="CityInput"
          type="text"
          label="City"
          fullWidth
        />
      </MDBox>
      <MDBox mb={2}>
        <MDInput
          value={localState}
          onChange={(e) => setLocalState(e.target.value)}
          id="StateInput"
          type="text"
          label="State"
          fullWidth
        />
      </MDBox>
      <MDBox mb={2}>
        <MDInput
          value={localPostCode}
          onChange={(e) => setLocalPostCode(e.target.value)}
          id="CodeInput"
          type="text"
          label="Code"
          fullWidth
        />
      </MDBox>
      <MDBox mb={2}>
        <MDInput
          value={localAddress}
          onChange={(e) => setLocalAddress(e.target.value)}
          id="AddressInput"
          type="text"
          label="Address"
          fullWidth
        />
      </MDBox>

      <MDBox>
        <MDBox style={{ fontSize: "1rem" }}>status</MDBox>
      </MDBox>
      <MDBox mb={2} fullWidth>
        <RadioGroup
          row
          value={localStatus}
          onChange={(e) => setLocalStatus(e.target.value)}
          aria-labelledby="Status"
          name="status"
          defaultValue="active"
        >
          <FormControlLabel
            value="accepted"
            control={<Radio />}
            label="accepted"
            labelPlacement="start"
          />
          <FormControlLabel
            value="denied"
            control={<Radio />}
            label="denied"
            labelPlacement="start"
          />
          <FormControlLabel
            value="pending"
            control={<Radio />}
            label="pending"
            labelPlacement="start"
          />
        </RadioGroup>
      </MDBox>
      <Divider />
      <MDBox mt={4} mb={1}>
        <MDButton
          onClick={() => {
            saveOffice();
          }}
          variant="gradient"
          color="info"
          fullWidth
        >
          Save
        </MDButton>
      </MDBox>
    </DrawerRightRoot>
  );
}

export default StatusDrawer;
