import { AxiosInstanceWithXAuthToken } from "./api";

export const getAllFAQs = (callback, params = "") => {
  AxiosInstanceWithXAuthToken()
    .get(`/api/v1/admins/faq${params}`)
    .then((res) => callback(true, res.data))
    .catch((err) => callback(false, err));
};
export const updateAnFAQ = (callback, id, body, params = "") => {
  console.log(id, body);
  AxiosInstanceWithXAuthToken()
    .patch(`/api/v1/admins/faq/${id}${params}`, body)
    .then((res) => callback(true, res.data))
    .catch((err) => callback(false, err));
};
export const deleteAnFAQ = (callback, id, params = "") => {
  AxiosInstanceWithXAuthToken()
    .delete(`/api/v1/admins/faq/${id}${params}`)
    .then((res) => callback(true, res.data))
    .catch((err) => callback(false, err));
};
export const addAnFAQ = (callback, body, params = "") => {
  AxiosInstanceWithXAuthToken()
    .post(`/api/v1/admins/faq/${params}`, body)
    .then((res) => callback(true, res.data))
    .catch((err) => callback(false, err));
};
