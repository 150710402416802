// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Pagination from "@mui/material/Pagination";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import LawyersNavbar from "examples/Navbars/DashboardNavbar";
import DataTable from "examples/TablesDetailPaymentLawyer/DataTable";
import authorsTableData from "layouts/adminTable/data/authorsTableData";
import { useMaterialUIController, setCircularProgress, setAdminsData } from "context";
import { useLocation, useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";
import { getAdminsList } from "api/api_admin";

function Tables() {
  const [controller, dispatch] = useMaterialUIController();
  const navigate = useNavigate();
  const { columns, rows } = authorsTableData();
  const { pathname } = useLocation();
  const [paginationCount, setPaginationCount] = useState(1);
  const [adminsPage, setAdminsPage] = useState(1);
  useEffect(() => {
    if (false) console.log(controller);
    let url = "/admins";
    url += `?page=${adminsPage}`;
    navigate(url);
  }, [adminsPage]);
  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const paramsObj = Array.from(params.keys()).reduce(
      (acc, val) => ({ ...acc, [val]: params.get(val) }),
      {}
    );
    if (!paramsObj.page) setAdminsPage(1);
    else setAdminsPage(parseInt(paramsObj.page, 10));
    setCircularProgress(dispatch, true);
    getAdminsList((isOk, data) => {
      if (!isOk) {
        console.log("get Admins data from api is not working", data);
      } else {
        setAdminsData(dispatch, data.data);
        setPaginationCount(data.meta.last_page);
      }
      setCircularProgress(dispatch, false);
    }, `${window.location.search}`);
  }, [pathname, window.location.search]);

  const handlePaginationChange = (event, value) => {
    let url = "/admins";
    url += `?page=${value}`;
    navigate(url);
  };
  return (
    <DashboardLayout>
      <LawyersNavbar />
      <MDBox pt={6} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
              >
                <MDTypography variant="h6" color="white">
                  Admins Table
                </MDTypography>
              </MDBox>
              <MDBox pt={3}>
                <DataTable
                  table={{ columns, rows }}
                  isSorted={false}
                  entriesPerPage={false}
                  showTotalEntries={false}
                  noEndBorder
                />
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <MDBox style={{ display: "flex", justifyContent: "center" }}>
        <Pagination
          page={adminsPage}
          onChange={handlePaginationChange}
          count={paginationCount}
          color="info"
        />
      </MDBox>
    </DashboardLayout>
  );
}

export default Tables;
