import * as React from "react";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import {
  Divider,
  FormControl,
  FormControlLabel,
  FormLabel,
  Icon,
  Radio,
  RadioGroup,
  Slider,
  TextField,
} from "@mui/material";
import MDBox from "components/MDBox";
import { styled } from "@mui/material/styles";
import { useState, useEffect } from "react";
import { setAlertColor, setAlertOpen, setAlertText, useMaterialUIController } from "context";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import GetSliderData from "./getSliderData";
import Modal from "./ConfirmModal";

export default function BasicMenu() {
  const [controller, dispatch] = useMaterialUIController();
  if (false) console.log(controller);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const [versionsInSlider, setVersionsInSlider] = useState([]);
  const [loading, setLoading] = useState(false);
  const [addedNewVersion, setAddedNewVersion] = useState(false);
  const [versionsValues, setVersionsValues] = useState([0, 100]);
  const [openDialog, setOpenDialog] = useState(false);
  const [addNewVersion, setAddNewVersion] = useState(false);
  const [choosenPlatform, setChoosenPlatform] = useState("ios");
  const [userType, setUserType] = useState("client");
  const [minVersion, setMinVersion] = useState("");
  const [currentVersion, setCurrentVersion] = useState("");
  const [minVersionDefault, setMinVersionDefault] = useState("");
  const [currentVersionDefault, setCurrentVersionDefault] = useState("");

  useEffect(() => {
    setMinVersion(minVersionDefault);
    setCurrentVersion(currentVersionDefault);
  }, [minVersionDefault, currentVersionDefault]);
  const inputController = (e) => {
    if (
      (e.which > 57 || e.which < 48) &&
      e.which !== 190 &&
      e.which !== 8 &&
      e.which !== 37 &&
      e.which !== 39
    ) {
      e.preventDefault();
    }
  };
  const addNewVersionClicked = () => {
    if (minVersion === minVersionDefault && currentVersion === currentVersionDefault) {
      setAlertText(dispatch, "This Version is the current version...Enter a new version");
      setAlertOpen(dispatch, true);
      setAlertColor(dispatch, "warning");
      return;
    }
    if (minVersion.split(".").length !== 3) {
      setAlertText(dispatch, "Minimum version is not in correct template");
      setAlertOpen(dispatch, true);
      setAlertColor(dispatch, "error");
      return;
    }
    if (currentVersion.split(".").length !== 3) {
      setAlertText(dispatch, "Current version is not in correct template");
      setAlertOpen(dispatch, true);
      setAlertColor(dispatch, "error");
      return;
    }
    setOpenDialog(true);
  };
  const iOSBoxShadow =
    "0 3px 1px rgba(0,0,0,0.1),0 4px 8px rgba(0,0,0,0.13),0 0 0 1px rgba(0,0,0,0.02)";
  const VersionSlider = styled(Slider)(({ theme }) => ({
    color: theme.palette.mode === "dark" ? "#3880ff" : "#3880ff",
    height: 2,
    padding: "15px 0",
    "& .MuiSlider-thumb": {
      height: 15,
      width: 15,
      backgroundColor: "#fff",
      boxShadow: iOSBoxShadow,
      "&:focus, &:hover, &.Mui-active": {
        boxShadow: "0 3px 1px rgba(0,0,0,0.1),0 4px 8px rgba(0,0,0,0.3),0 0 0 1px rgba(0,0,0,0.02)",
        // Reset on touch devices, it doesn't add specificity
        "@media (hover: none)": {
          boxShadow: iOSBoxShadow,
        },
      },
    },
    "& .MuiSlider-valueLabel": {
      display: "none",
      fontSize: 12,
      fontWeight: "normal",
      top: -6,
      backgroundColor: "unset",
      color: theme.palette.text.primary,
      "&:before": {
        display: "none",
      },
      "& *": {
        background: "transparent",
        color: theme.palette.mode === "dark" ? "#fff" : "#000",
      },
    },
    "& .MuiSlider-track": {
      border: "none",
    },
    "& .MuiSlider-rail": {
      opacity: 0.5,
      backgroundColor: "#bfbfbf",
    },
    "& .MuiSlider-markLabel ": {
      fontSize: "10px",
    },
    "& .MuiSlider-mark": {
      backgroundColor: "#bfbfbf",
      height: 8,
      width: 1,
      "&.MuiSlider-markActive": {
        opacity: 1,
        backgroundColor: "currentColor",
      },
    },
  }));
  return (
    <div>
      <Modal
        openDialog={openDialog}
        setOpenDialog={setOpenDialog}
        setAddedNewVersion={setAddedNewVersion}
        setAnchorEl={setAnchorEl}
        setLoading={setLoading}
        minVersion={minVersion}
        currentVersion={currentVersion}
        choosenPlatform={choosenPlatform}
        userType={userType}
        setCurrentVersion={setCurrentVersion}
        setMinVersion={setMinVersion}
        setAddNewVersion={setAddNewVersion}
      />
      <GetSliderData
        setVersionsInSlider={setVersionsInSlider}
        setVersionsValues={setVersionsValues}
        choosenPlatform={choosenPlatform}
        userType={userType}
        setLoading={setLoading}
        addedNewVersion={addedNewVersion}
        setMinVersionDefault={setMinVersionDefault}
        setCurrentVersionDefault={setCurrentVersionDefault}
      />
      <Button
        id="basic-button"
        aria-controls={open ? "basic-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
      >
        <Icon style={{ color: "#7B809A" }}>update</Icon>
      </Button>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        <div>
          <div className="flex flex-col items-center px-4 justify-center">
            <div className="text-[20px] text-[#61718A]">VERSIONS STATUS</div>
            <div className="text-[15px]">
              interval is between minimum version and current version
            </div>
          </div>
          <Divider />
          {versionsInSlider.length === 0 ? (
            <div className="w-[100%] px-4 h-[80px] flex flex-col items-center justify-center">
              {loading ? (
                <div>Loading...</div>
              ) : (
                <div>No Version has been added yet in this options!</div>
              )}
            </div>
          ) : (
            <>
              <div className="w-[100%] px-4 flex flex-col items-center justify-center">
                {loading ? (
                  <div className="h-[60px] flex flex-col items-center justify-center">
                    Loading...
                  </div>
                ) : (
                  <MDBox sx={{ width: 300 }}>
                    <VersionSlider
                      defaultValue={[0, 100]}
                      value={versionsValues}
                      marks={versionsInSlider}
                      valueLabelDisplay="on"
                    />
                  </MDBox>
                )}
              </div>
              <div className="flex items-center justify-center twxt-black text-[12px]">
                current versions in {userType.toUpperCase()} type and{" "}
                {choosenPlatform.toUpperCase()} platform
              </div>
            </>
          )}
          <div className="flex pt-4">
            <div className="flex flex-1">
              <FormControl>
                <FormLabel id="platform" style={{ fontSize: "15px", color: "black" }}>
                  Platform:{" "}
                </FormLabel>
                <RadioGroup
                  className="ml-[20px]"
                  aria-labelledby="platform"
                  name="platform-buttons"
                  value={choosenPlatform}
                  onChange={(e) => setChoosenPlatform(e.target.value)}
                >
                  <FormControlLabel selected value="ios" control={<Radio />} label="IOS" />
                  <FormControlLabel value="android" control={<Radio />} label="Android" />
                </RadioGroup>
              </FormControl>
            </div>
            <div className="flex flex-1">
              <FormControl>
                <FormLabel id="user-type" style={{ fontSize: "15px", color: "black" }}>
                  User Type:{" "}
                </FormLabel>
                <RadioGroup
                  className="ml-[20px]"
                  aria-labelledby="user-type"
                  name="user-type-buttons"
                  value={userType}
                  onChange={(e) => setUserType(e.target.value)}
                >
                  <FormControlLabel selected value="client" control={<Radio />} label="Client" />
                  <FormControlLabel value="lawyer" control={<Radio />} label="Lawyer" />
                </RadioGroup>
              </FormControl>
            </div>
          </div>
          <div className="flex items-center justify-center mt-4">
            <div className="text-[20px] font-bold flex border rounded-md mr-1 hover:bg-blue-500 items-center justify-center">
              <KeyboardArrowDownIcon
                onClick={() => setAddNewVersion(!addNewVersion)}
                className={`${addNewVersion ? "rotate-180" : "rotate-0"} cursor-pointer`}
              />
            </div>
            <div className="font-bold">Are You want to add new version?</div>
          </div>
          {addNewVersion && (
            <div>
              <div className="flex items-center justify-center mt-4">
                <div>
                  You are adding new version to {userType} {choosenPlatform} app...
                </div>
              </div>
              <div className="flex flex-col items-center justify-center mt-4">
                <div className="flex py-1">
                  <TextField
                    value={minVersion}
                    onKeyDown={(e) => inputController(e)}
                    onChange={(e) => {
                      if (e.target.value.length > 1) {
                        if (
                          e.target.value[e.target.value.length - 2] !== "." ||
                          e.target.value[e.target.value.length - 1] !== "."
                        ) {
                          setMinVersion(e.target.value);
                        }
                      } else {
                        setMinVersion(e.target.value);
                      }
                    }}
                    id="minVersion"
                    label="Minimum Version"
                    variant="filled"
                  />
                </div>
                <div className="flex py-1">
                  <TextField
                    value={currentVersion}
                    onKeyDown={(e) => inputController(e)}
                    onChange={(e) => {
                      if (e.target.value.length > 1) {
                        if (
                          e.target.value[e.target.value.length - 2] !== "." ||
                          e.target.value[e.target.value.length - 1] !== "."
                        ) {
                          setCurrentVersion(e.target.value);
                        }
                      } else {
                        setCurrentVersion(e.target.value);
                      }
                    }}
                    id="currentVersion"
                    label="Current Version"
                    variant="filled"
                  />
                </div>
              </div>
              <div className="flex items-center justify-center mt-4">
                <Button
                  variant="contained"
                  style={{ color: "white", padding: "0 50px 0 50px", cursor: "pointer" }}
                  onClick={() => addNewVersionClicked()}
                >
                  Add
                </Button>
              </div>
            </div>
          )}
        </div>
      </Menu>
    </div>
  );
}
