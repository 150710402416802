import { io } from "socket.io-client";

const socket = io(process.env.REACT_APP_BASE_URL_CHAT, {
  transports: ["websocket", "polling"],
  reconnection: true,
  autoConnect: false,
  query: {
    token: localStorage.getItem("x-auth-token"),
    sessionId: "live_list_lawyer",
  },
});
export default socket;
