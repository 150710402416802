// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import MDBox from "components/MDBox";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
  useMaterialUIController,
  setPersonInfo,
  setTab,
  setScrollInDetail,
  setCircularProgress,
  setLawyerRating,
  setLawyerStripeAccount,
  setLawyerCostInfo,
} from "context";
// import Certificate from "./Certificate";
import LawyerDetailNavbar from "examples/Navbars/lawyerDetailNavbar";
import { getALawyerDetails, getLawyerRating, getALawyerDetailsCostInfo } from "api/api_lawyer";
import { getStripeProfile } from "api/api_payments";
import UserProfile from "./UserProfile";
import Certificate from "./Certificate";
import Offices from "./Offices";
import Lives from "./Lives";
import Cases from "./Cases";
import Payments from "./Payments";
import Sessions from "./Sessions";
import Transfers from "./Transfers";
import Overview from "./Overview";
import Subscription from "./Subscription";

function Tables() {
  const [controller, dispatch] = useMaterialUIController();
  const { personInfo, tab, lawyerCostInfo } = controller;
  const setTabFunction = (num) => {
    setTab(dispatch, num);
  };
  const handleScroll = () => {
    setScrollInDetail(dispatch, window.scrollY);
  };
  useEffect(() => {
    setScrollInDetail(dispatch, 0);
    window.addEventListener("scroll", handleScroll);
  }, []);
  const navigate = useNavigate();
  const { pathname } = useLocation();
  let id = pathname.split("/");
  id = id[id.length - 1];
  id = id.split("?");
  [id] = id;
  useEffect(() => {
    setCircularProgress(dispatch, true);
    getALawyerDetails((isOk, data) => {
      if (!isOk) {
        console.log("get lawyer details from api is not working!");
        navigate("/lawyers?page=1");
        setCircularProgress(dispatch, false);
      } else {
        setCircularProgress(dispatch, false);
        setPersonInfo(dispatch, data);
      }
    }, id);
    getALawyerDetailsCostInfo((isOk, data) => {
      if (!isOk) {
        console.log("get lawyer details from api is not working!");
        navigate("/lawyers?page=1");
        setCircularProgress(dispatch, false);
      } else {
        setCircularProgress(dispatch, false);
        setLawyerCostInfo(dispatch, data.data);
      }
    }, id);
    getStripeProfile(
      (isOk, data) => {
        if (!isOk) {
          console.log("get lawyer stripe details from api is not working!", data);
          // navigate("/lawyers?page=1");
          setCircularProgress(dispatch, false);
        } else {
          setCircularProgress(dispatch, false);
          setLawyerStripeAccount(dispatch, data.data);
        }
      },
      { userId: id }
    );
    getLawyerRating((isOk, data) => {
      if (!isOk) {
        console.log("get lawyer rating from api is not working!", data);
        setCircularProgress(dispatch, false);
      } else {
        setCircularProgress(dispatch, false);
        setLawyerRating(dispatch, data);
      }
    }, id);
  }, []);
  const renderInnerTable = () => {
    if (tab === 1) return <Overview id={id} />;
    if (tab === 2) return <Certificate id={id} />;
    if (tab === 3) return <Offices id={id} />;
    if (tab === 4) return <Lives />;
    if (tab === 5) return <Cases />;
    if (tab === 6) return <Payments />;
    if (tab === 7) return <Sessions />;
    if (tab === 8) return <Transfers />;
    if (tab === 9) return <Subscription id={id} />;
    return "";
  };
  useEffect(() => {
    navigate(`/lawyers/detail/${id}`);
  }, [tab]);
  if (Object.keys(personInfo).length === 0) return <div />;
  return (
    <DashboardLayout>
      <LawyerDetailNavbar />
      <MDBox pt={6} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <UserProfile
              personInfo={personInfo}
              lawyerCostInfo={lawyerCostInfo}
              tab={tab}
              setTab={setTabFunction}
            />
          </Grid>
        </Grid>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card className="mt-4">{renderInnerTable()}</Card>
          </Grid>
        </Grid>
      </MDBox>
    </DashboardLayout>
  );
}

export default Tables;
