import Card from "@mui/material/Card";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";

// Authentication layout components
import BasicLayout from "layouts/authentication/components/BasicLayout";

// Images
// import bgImage from "assets/images/bg-sign-in-basic.jpeg";
import { loginToDashboard } from "api/api_auth";
import { useNavigate } from "react-router-dom";
import { useMaterialUIController, setCloseSignInAlert, setAlertStatus } from "context";
import MDAlert from "components/MDAlert";
import Collapse from "@mui/material/Collapse";
import { useEffect, useState } from "react";
import SignInSchema from "../../../model/SignInSchema";

function Basic() {
  const [controller, dispatch] = useMaterialUIController();
  const { closeSignInAlert } = controller;
  const navigate = useNavigate();
  const [emptySignInTxt, setEmptySignInTxt] = useState("please fill out the blanks correctly");

  const signInBtnClicked = () => {
    const code = document.getElementById("verifyCodeInput").value;
    const number = localStorage.getItem("mobileNumber");
    if (code === "") setCloseSignInAlert(dispatch, true);
    if (number === "") setCloseSignInAlert(dispatch, true);
    const body = {
      mobile: number,
      user_type: process.env.REACT_APP_USER_TYPE,
      device_name: process.env.REACT_APP_DEVICE_NAME,
      verification_code: code,
    };
    SignInSchema.validate(body, { abortEarly: false })
      .then((valid) => {
        loginToDashboard(valid, (isOk, res) => {
          if (!isOk) {
            setEmptySignInTxt(res.response.data.message);
            setCloseSignInAlert(dispatch, true);
          } else {
            localStorage.setItem("x-auth-token", res.token);
            navigate("/dashboard");
          }
        });
      })
      .catch((err) => {
        setEmptySignInTxt(err.errors[0].split(",")[0]);
        setCloseSignInAlert(dispatch, true);
      });
  };

  const handleCloseAlert = () => {
    setCloseSignInAlert(dispatch, false);
  };

  useEffect(() => {
    setCloseSignInAlert(dispatch, false);
  }, []);
  useEffect(() => {
    if (closeSignInAlert) {
      setAlertStatus(dispatch, "mount");
    } else {
      setAlertStatus(dispatch, "fadeOut");
    }
  }, [closeSignInAlert]);

  const handleBacktoVerify = () => {
    navigate("/authentication/verify-number");
  };
  const submitSignInForm = (e) => {
    e.preventDefault();
    signInBtnClicked();
  };
  return (
    <BasicLayout image="https://source.unsplash.com/random/1800x800?natural">
      <Collapse in={closeSignInAlert}>
        <MDAlert color="warning" dismissible handleCloseAlert={handleCloseAlert}>
          <div>{emptySignInTxt}</div>
        </MDAlert>
      </Collapse>
      <Card>
        <MDBox
          variant="gradient"
          bgColor="info"
          borderRadius="lg"
          coloredShadow="info"
          mx={2}
          mt={-3}
          p={2}
          mb={1}
          textAlign="center"
        >
          <MDTypography variant="h4" fontWeight="medium" color="white" mt={1}>
            Sign in
          </MDTypography>
        </MDBox>
        <MDBox pt={4} pb={3} px={3}>
          <MDBox display="flex" alignItems="center" fontSize="small" ml={2} mb={2}>
            <MDBox>are you sure?{localStorage.getItem("mobileNumber")}&nbsp;&nbsp;</MDBox>
            <MDBox
              onClick={handleBacktoVerify}
              color="info"
              sx={{ cursor: "pointer", userSelect: "none", textDecoration: "underline" }}
            >
              edit
            </MDBox>
          </MDBox>
          <MDBox component="form" onSubmit={(e) => submitSignInForm(e)} role="form">
            <MDBox mb={2}>
              <MDInput id="verifyCodeInput" type="number" label="Verify Code" fullWidth />
            </MDBox>
            <MDBox mt={4} mb={1}>
              <MDButton
                onClick={(e) => signInBtnClicked(e)}
                variant="gradient"
                color="info"
                fullWidth
              >
                sign in
              </MDButton>
            </MDBox>
          </MDBox>
        </MDBox>
      </Card>
    </BasicLayout>
  );
}

export default Basic;
