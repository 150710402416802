import { Dialog, DialogContent, DialogContentText, DialogTitle, Grid, Icon } from "@mui/material";
import { getTransferInfo } from "api/api_payments";
import { setCircularProgress, useMaterialUIController, setOpenTransferDataModal } from "context";

import React, { useEffect, useState } from "react";

function TransferDetailModal() {
  const [controller, dispatch] = useMaterialUIController();
  const { openTransferDataModal, transferDataId } = controller;
  const [transferInfo, setTransferInfo] = useState({});
  const handleClose = () => setOpenTransferDataModal(dispatch, false);
  useEffect(() => {
    if (transferDataId.transferId && openTransferDataModal) {
      setCircularProgress(dispatch, true);
      getTransferInfo(
        (isOk, data) => {
          if (!isOk) {
            console.log("get Transfer info from api is not working", data);
          } else {
            setTransferInfo(data.data);
          }
          setCircularProgress(dispatch, false);
        },
        transferDataId.transferId,
        { userId: transferDataId.lawyerId }
      );
    }
  }, [transferDataId, openTransferDataModal]);
  if (!transferInfo.createdAt) return <div />;
  return (
    <div>
      <Dialog
        open={openTransferDataModal}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
        maxWidth="1000px"
      >
        <DialogTitle>
          <div className="flex">
            <div>Transfer Detail Paper</div>
            <div className="ml-auto">
              <Icon className="cursor-pointer" onClick={handleClose}>
                close
              </Icon>
            </div>
          </div>
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            <Grid container>
              <Grid xs={6} className="px-6">
                <div className="flex">
                  <div>Id</div>
                  <div className="ml-auto">{transferInfo.id}</div>
                </div>
              </Grid>
              <Grid xs={6} className="px-6">
                <div className="flex">
                  <div className="font-bold text-black">Amount</div>
                  <div className="ml-auto font-bold text-black">{transferInfo.amount / 100}$</div>
                </div>
              </Grid>
              <Grid xs={6} className="px-6">
                <div className="flex">
                  <div className="font-bold text-black">Amount reversed</div>
                  <div className="ml-auto font-bold text-black">
                    {transferInfo.amount_reversed / 100}$
                  </div>
                </div>
              </Grid>
              <Grid xs={6} className="px-6">
                <div className="flex">
                  <div>Balance transaction</div>
                  <div className="ml-auto">{transferInfo.balance_transaction}</div>
                </div>
              </Grid>
              <Grid xs={6} className="px-6">
                <div className="flex">
                  <div>Commission</div>
                  <div className="ml-auto">{transferInfo.commission}</div>
                </div>
              </Grid>
              <Grid xs={6} className="px-6">
                <div className="flex">
                  <div>Transfer time</div>
                  <div className="ml-auto flex">
                    <div>{transferInfo.createdAt.split("T")[0]}</div>
                    <div>{transferInfo.createdAt.split("T")[1].split(".")[0]}</div>
                  </div>
                </div>
              </Grid>
              <Grid xs={6} className="px-6">
                <div className="flex">
                  <div>Description</div>
                  <div className="ml-auto w-[400px] overflow-auto">
                    <div>{transferInfo.description}</div>
                  </div>
                </div>
              </Grid>
              <Grid xs={6} className="px-6">
                <div className="flex">
                  <div>Destination</div>
                  <div className="ml-auto">
                    <div>{transferInfo.destination}</div>
                  </div>
                </div>
              </Grid>
              <Grid xs={6} className="px-6">
                <div className="flex">
                  <div>Destination payment</div>
                  <div className="ml-auto">
                    <div>{transferInfo.destination_payment}</div>
                  </div>
                </div>
              </Grid>
              <Grid xs={6} className="px-6">
                <div className="flex">
                  <div>Lawyer id</div>
                  <div className="ml-auto">
                    <div>{transferInfo.lawyerArmy_id}</div>
                  </div>
                </div>
              </Grid>
              <Grid xs={6} className="px-6">
                <div className="flex">
                  <div>Source type</div>
                  <div className="ml-auto">
                    <div>{transferInfo.source_type}</div>
                  </div>
                </div>
              </Grid>
            </Grid>
          </DialogContentText>
        </DialogContent>
      </Dialog>
    </div>
  );
}

export default TransferDetailModal;
