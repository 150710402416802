import { AxiosInstanceWithXAuthToken } from "./api";

export const getAllTransactions = (callback, params = "") => {
  AxiosInstanceWithXAuthToken()
    .get(`/api/v1/admins/clients/transactions${params}`)
    .then((res) => callback(true, res.data))
    .catch((err) => callback(false, err));
};
export const getATransactionDetail = (callback, id, params = "") => {
  AxiosInstanceWithXAuthToken()
    .get(`/api/v1/admins/clients/transactions/${id}${params}`)
    .then((res) => callback(true, res.data))
    .catch((err) => callback(false, err));
};
