import Divider from "@mui/material/Divider";
import Icon from "@mui/material/Icon";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
// Custom styles for the Configurator
import DrawerRightRoot from "examples/DrawerRight/DrawerRightRoot";
import {
  useMaterialUIController,
  setOpenDrawerRight,
  setCircularProgress,
  setAllLawyerCertificates,
  setPersonInfo,
  setAlertText,
  setAlertOpen,
  setAlertColor,
} from "context";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import { useState, useEffect } from "react";
import {
  updateLawyerCertificateStatus,
  getAllLawyerCertificates,
  getALawyerDetails,
} from "api/api_lawyer";
import { useLocation, useNavigate } from "react-router-dom";

function StatusDrawer() {
  const getStatusColor = (value) => {
    switch (value) {
      case "accepted":
        return "#17d41a";
      case "denied":
        return "#780b01";
      case "pending":
        return "#bfd611";
      case "pending_office":
        return "#634c01";
      default:
        return "#000d07";
    }
  };
  const [controller, dispatch] = useMaterialUIController();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  let id = pathname.split("/");
  id = id[id.length - 1];
  id = id.split("?");
  [id] = id;
  useEffect(() => {
    setCircularProgress(dispatch, true);
    getALawyerDetails((isOk, data) => {
      if (!isOk) {
        console.log("get lawyer actvities from api is not working!");
        navigate("/lawyers?page=1");
        setCircularProgress(dispatch, false);
      } else {
        setCircularProgress(dispatch, false);
        setPersonInfo(dispatch, data);
      }
    }, id);
  }, []);
  const {
    openDrawerRight,
    darkMode,
    currentLawyerStatusId,
    currentCertificateStatusId,
    personInfo,
    currentCertificateStatus,
  } = controller;
  const [localStatus, setLocalStatus] = useState("");
  const [originalStatus, setOriginalStatus] = useState("");
  // const [userName] = useState("");
  const [openStatusDialog, setOpenStatusDialog] = useState(false);
  useEffect(() => {
    setLocalStatus(currentCertificateStatus);
    setOriginalStatus(currentCertificateStatus);
  }, [openDrawerRight]);
  const saveStatus = () => {
    setOpenStatusDialog(false);
    setOpenDrawerRight(dispatch, false);

    setCircularProgress(dispatch, true);
    if (localStatus === originalStatus) {
      setCircularProgress(dispatch, false);
    } else {
      const body = {
        status: localStatus,
      };
      updateLawyerCertificateStatus(
        (isOk, data) => {
          if (!isOk) {
            console.log("update status of certificate is un successfull", data);
            setAlertText(dispatch, "update status of certificate is un successfull");
            setAlertOpen(dispatch, true);
            setAlertColor(dispatch, "error");
            setCircularProgress(dispatch, false);
          } else {
            setAlertText(dispatch, "update status of certificate is successfull");
            setAlertOpen(dispatch, true);
            setAlertColor(dispatch, "success");
            getAllLawyerCertificates((isOk2, data2) => {
              if (!isOk2) {
                console.log("get lawyer Certificates from api is not working!");
                setCircularProgress(dispatch, false);
              } else {
                setAllLawyerCertificates(dispatch, data2.data);
                setCircularProgress(dispatch, false);
              }
            }, currentLawyerStatusId);
          }
        },
        body,
        currentLawyerStatusId,
        currentCertificateStatusId
      );
    }
  };
  const handleCloseDrawerRight = () => setOpenDrawerRight(dispatch, false);
  return (
    <DrawerRightRoot variant="permanent" ownerState={{ openDrawerRight }}>
      <Dialog
        open={openStatusDialog}
        onClose={() => setOpenStatusDialog(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Activation/Deactivation Certificate</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description" style={{ textAlign: "center" }}>
            you are try to change status of certificate to{" "}
            <span style={{ color: getStatusColor(localStatus) }}>{localStatus}</span> <br />{" "}
            <span style={{ fontSize: "1.2rem", color: "black" }}>are you sure?</span>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <MDButton
            variant="outlined"
            color="info"
            onClick={() => {
              setOpenDrawerRight(dispatch, false);
              setOpenStatusDialog(false);
            }}
          >
            NO
          </MDButton>
          <MDButton variant="contained" color="info" onClick={() => saveStatus()} autoFocus>
            YES
          </MDButton>
        </DialogActions>
      </Dialog>
      <MDBox
        display="flex"
        justifyContent="space-between"
        alignItems="baseline"
        pt={4}
        pb={0.5}
        px={3}
      >
        <MDBox>
          <MDTypography variant="h5">Admin Panel Editor</MDTypography>
          <MDTypography variant="body2" color="text">
            Edit certificate status of user
          </MDTypography>
        </MDBox>
        <Icon
          sx={({ typography: { size }, palette: { dark, white } }) => ({
            fontSize: `${size.lg} !important`,
            color: darkMode ? white.main : dark.main,
            stroke: "currentColor",
            strokeWidth: "2px",
            cursor: "pointer",
            transform: "translateY(5px)",
          })}
          onClick={handleCloseDrawerRight}
        >
          close
        </Icon>
      </MDBox>
      <Divider />
      <MDBox>
        <MDBox style={{ fontSize: "1rem" }}>
          Select the status of {personInfo.first_name}&nbsp;{personInfo.last_name},&nbsp; then click
          on save
        </MDBox>
      </MDBox>
      <MDBox mb={2} fullWidth>
        <RadioGroup
          value={localStatus}
          onChange={(e) => setLocalStatus(e.target.value)}
          aria-labelledby="Status"
          name="status"
          defaultValue="active"
        >
          <FormControlLabel
            value="accepted"
            control={<Radio />}
            label="Accepted"
            labelPlacement="start"
          />
          <FormControlLabel
            value="denied"
            control={<Radio />}
            label="Denied"
            labelPlacement="start"
          />
          <FormControlLabel
            value="pending"
            control={<Radio />}
            label="Pending"
            labelPlacement="start"
          />
          {/* <FormControlLabel
            value="pending_office"
            control={<Radio />}
            label="pending office"
            labelPlacement="start"
          /> */}
        </RadioGroup>
      </MDBox>
      <Divider />
      <MDBox mt={4} mb={1}>
        <MDButton
          onClick={() => {
            if (localStatus !== originalStatus) {
              setOpenStatusDialog(true);
            } else {
              setOpenDrawerRight(dispatch, false);
            }
          }}
          variant="gradient"
          color="info"
          fullWidth
        >
          Save
        </MDButton>
      </MDBox>
    </DrawerRightRoot>
  );
}

export default StatusDrawer;
