import { createContext, useContext, useReducer, useMemo } from "react";

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// Material Dashboard 2 React main context
const MaterialUI = createContext();

// Setting custom name for the context which is visible on react dev tools
MaterialUI.displayName = "MaterialUIContext";

// Material Dashboard 2 React reducer
function reducer(state, action) {
  switch (action.type) {
    case "MINI_SIDENAV": {
      return { ...state, miniSidenav: action.value };
    }
    case "TRANSPARENT_SIDENAV": {
      return { ...state, transparentSidenav: action.value };
    }
    case "WHITE_SIDENAV": {
      return { ...state, whiteSidenav: action.value };
    }
    case "SIDENAV_COLOR": {
      return { ...state, sidenavColor: action.value };
    }
    case "TRANSPARENT_NAVBAR": {
      return { ...state, transparentNavbar: action.value };
    }
    case "FIXED_NAVBAR": {
      return { ...state, fixedNavbar: action.value };
    }
    case "OPEN_CONFIGURATOR": {
      return { ...state, openConfigurator: action.value };
    }
    case "DIRECTION": {
      return { ...state, direction: action.value };
    }
    case "LAYOUT": {
      return { ...state, layout: action.value };
    }
    case "DARKMODE": {
      return { ...state, darkMode: action.value };
    }
    case "VERIFY_ALERT": {
      return { ...state, closeVerificationAlert: action.value };
    }
    case "SIGN_IN_ALERT": {
      return { ...state, closeSignInAlert: action.value };
    }
    case "SET_ALERT_STATUS": {
      return { ...state, alertStatus: action.value };
    }
    case "SET_ALERT_STATUS_LAWYER": {
      return { ...state, alertStatusLawyer: action.value };
    }
    case "SET_ALERT_STATUS_CLIENT": {
      return { ...state, alertStatusClient: action.value };
    }
    case "SET_FAQ_DATA": {
      return { ...state, faqData: action.value };
    }
    case "SET_TRANSACTIONS_DATA": {
      return { ...state, transactionsData: action.value };
    }
    case "SET_TRANSFERS_DATA": {
      return { ...state, transfersData: action.value };
    }
    case "SET_CLIENT_SUBSCRIPTION_DATA": {
      return { ...state, clientSubscriptionData: action.value };
    }
    case "SET_LAWYER_SUBSCRIPTION_DATA": {
      return { ...state, lawyerSubscriptionData: action.value };
    }
    case "SET_LAWYERS_DATA": {
      return { ...state, lawyersData: action.value };
    }
    case "SET_PROFILE_DATA": {
      return { ...state, profileData: action.value };
    }
    case "SET_ADMINS_DATA": {
      return { ...state, adminsData: action.value };
    }
    case "SET_CLIENTS_DATA": {
      return { ...state, clientsData: action.value };
    }
    case "SET_NUMBER_LAWYERS": {
      return { ...state, lawyersCount: action.value };
    }
    case "SET_NUMBER_CLIENTS": {
      return { ...state, clientsCount: action.value };
    }
    case "SET_NUMBER_LIVES": {
      return { ...state, livesCount: action.value };
    }
    case "SET_NUMBER_CASES": {
      return { ...state, casesCount: action.value };
    }
    case "SET_DELETE_FAQ_MODAL": {
      return { ...state, deleteFaqModal: action.value };
    }
    case "SET_CASES_DATA": {
      return { ...state, casesData: action.value };
    }
    case "SET_SESSIONS_DATA": {
      return { ...state, sessionsData: action.value };
    }
    case "SET_LAWYERS_CASES_DATA": {
      return { ...state, lawyersCasesData: action.value };
    }
    case "SET_CLIENTS_CASES_DATA": {
      return { ...state, clientsCasesData: action.value };
    }
    case "SET_LAWYERS_SESSIONS_DATA": {
      return { ...state, lawyersSessionsData: action.value };
    }
    case "SET_CLIENTS_SESSIONS_DATA": {
      return { ...state, clientsSessionsData: action.value };
    }
    case "SET_NUMBER_LIVES_OVERVIEW": {
      return { ...state, livesOverviewCount: action.value };
    }
    case "SET_NUMBER_PAYMENTS_OVERVIEW": {
      return { ...state, paymentsOverviewCount: action.value };
    }
    case "SET_PAGE_LAWYERS": {
      return { ...state, lawyersPage: action.value };
    }
    case "SET_PAGE_CLIENTS": {
      return { ...state, clientsPage: action.value };
    }
    case "OPEN_DRAWER_RIGHT": {
      return { ...state, openDrawerRight: action.value };
    }
    case "SET_FIRSET_NAME_IN_DRAWER_RIGHT": {
      return { ...state, firstNameInDrawerRight: action.value };
    }
    case "SET_LAST_NAME_IN_DRAWER_RIGHT": {
      return { ...state, lastNameInDrawerRight: action.value };
    }
    case "SET_EMAIL_IN_DRAWER_RIGHT": {
      return { ...state, emailInDrawerRight: action.value };
    }
    case "SET_BIRTHDAY_IN_DRAWER_RIGHT": {
      return { ...state, birthdayInDrawerRight: action.value };
    }
    case "SET_MOBILE_IN_RIGHT_DRAWER_RIGHT": {
      return { ...state, mobileInDrawerRight: action.value };
    }
    case "SET_GENDER_IN_DRAWER_RIGHT": {
      return { ...state, genderInDrawerRight: action.value };
    }
    case "SET_CUSTOM_GENDER_IN_DRAWER_RIGHT": {
      return { ...state, customGenderInDrawerRight: action.value };
    }
    case "SET_CREATE_OR_UPDATE": {
      return { ...state, createOrUpdate: action.value };
    }
    case "SET_CIRCULAR_PROGRESS": {
      return { ...state, circularProgress: action.value };
    }
    case "SET_VERSIONS_LIST": {
      return { ...state, versionsList: action.value };
    }
    case "SET_OPEN_DRAWER_REASON": {
      return { ...state, openDrawerReason: action.value };
    }
    case "SET_FAQ_ID": {
      return { ...state, faqId: action.value };
    }
    case "SET_FAQ_QUESTION": {
      return { ...state, faqQuestion: action.value };
    }
    case "SET_FAQ_ANSWER": {
      return { ...state, faqAnswer: action.value };
    }
    case "SET_FAQ_PUBLISH": {
      return { ...state, faqPublish: action.value };
    }
    case "SET_FAQ_TYPE": {
      return { ...state, faqType: action.value };
    }
    case "SET_CURRENT_CLIENT_STATUS_ID": {
      return { ...state, currentClientStatusId: action.value };
    }
    case "SET_CURRENT_LAWYER_STATUS_ID": {
      return { ...state, currentLawyerStatusId: action.value };
    }
    case "SET_CURRENT_COST_ID": {
      return { ...state, currentCostId: action.value };
    }
    case "SET_CURRENT_LAWYER_INFO": {
      return { ...state, currentLawyerInfo: action.value };
    }
    case "SET_CURRENT_CLIENT_INFO": {
      return { ...state, currentClientInfo: action.value };
    }
    case "SET_CURRENT_CERTIFICATE_STATUS_ID": {
      return { ...state, currentCertificateStatusId: action.value };
    }
    case "SET_CURRENT_CERTIFICATE_STATUS": {
      return { ...state, currentCertificateStatus: action.value };
    }
    case "SET_OPEN_PAY_MODAL": {
      return { ...state, openPayModal: action.value };
    }
    case "SET_UPDATE_lawyer_ID": {
      return { ...state, updateLawyerId: action.value };
    }
    case "SET_UPDATE_client_ID": {
      return { ...state, updateClientId: action.value };
    }
    case "SET_TRANSACTION_DETAIL": {
      return { ...state, transactionDetails: action.value };
    }
    case "SET_LAWYER_STATUS": {
      return { ...state, lawyerStatus: action.value };
    }
    case "SET_CLIENT_STATUS": {
      return { ...state, clientStatus: action.value };
    }
    case "SET_OPEN_OFFERS_MODAL": {
      return { ...state, openOffersModal: action.value };
    }
    case "SET_OPEN_SESSION_DETAIL_MODAL": {
      return { ...state, openSessionDetailModal: action.value };
    }
    case "SET_OPEN_DETAIL_MESSAGE_MODAL": {
      return { ...state, openDetailMessageModal: action.value };
    }
    case "SET_OPEN_MORE_LIVE_MODAL": {
      return { ...state, openMoreLiveInfoModal: action.value };
    }
    case "SET_CASE_ID": {
      return { ...state, caseId: action.value };
    }
    case "SET_SESSION_ID": {
      return { ...state, sessionId: action.value };
    }
    case "SET_MESSAGE_ID": {
      return { ...state, messageId: action.value };
    }
    case "SET_LIVE_ID": {
      return { ...state, liveId: action.value };
    }
    case "SET_OFFER_ID": {
      return { ...state, offerId: action.value };
    }
    case "SET_SEARCH_ORDER_LAWYER": {
      return { ...state, searchOrderLawyer: action.value };
    }
    case "SET_SEARCH_BAR_VALUE_LAWYER": {
      return { ...state, searchBarValueLawyer: action.value };
    }
    case "SET_SEARCH_BAR_VALUE_TRANSACTIONS": {
      return { ...state, searchBarValueTransactions: action.value };
    }
    case "SET_SEARCH_BAR_VALUE_SUBSCRIPTION": {
      return { ...state, searchBarValueSubscription: action.value };
    }
    case "SET_SEARCH_BAR_VALUE_TRANSACTION": {
      return { ...state, searchBarValueTransaction: action.value };
    }
    case "SET_SEARCH_BAR_VALUE_MESSAGES": {
      return { ...state, searchBarValueMessages: action.value };
    }
    case "SET_SORT_MESSAGES": {
      return { ...state, sortMessages: action.value };
    }
    case "SET_ORDER_MESSAGES": {
      return { ...state, orderMessages: action.value };
    }
    case "SET_SEARCH_BAR_VALUE_REGION": {
      return { ...state, searchBarValueRegion: action.value };
    }
    case "SET_SEARCH_BAR_VALUE_CATEGORY": {
      return { ...state, searchBarValueCategory: action.value };
    }
    case "SET_SEARCH_BAR_VALUE_PAYMENT": {
      return { ...state, searchBarValuePayment: action.value };
    }
    case "SET_SEARCH_BAR_VALUE_DETAIL_SUBSCRIPTIONS": {
      return { ...state, searchBarValueDetailSubscriptions: action.value };
    }
    case "SET_SEARCH_BAR_VALUE_DETAIL_PAYMENT": {
      return { ...state, searchBarValueDetailPayment: action.value };
    }
    case "SET_SEARCH_BAR_VALUE_LIVE": {
      return { ...state, searchBarValueLive: action.value };
    }
    case "SET_SEARCH_BAR_VALUE_DETAIL_LIVE": {
      return { ...state, searchBarValueDetailLive: action.value };
    }
    case "SET_SEARCH_BAR_VALUE_CASE": {
      return { ...state, searchBarValueCase: action.value };
    }
    case "SET_SEARCH_BAR_VALUE_SESSION": {
      return { ...state, searchBarValueSession: action.value };
    }
    case "SET_SEARCH_BAR_VALUE_DETAIL_CASE": {
      return { ...state, searchBarValueDetailCase: action.value };
    }
    case "SET_SEARCH_BAR_VALUE_DETAIL_SESSION": {
      return { ...state, searchBarValueDetailSession: action.value };
    }
    case "SET_SEARCH_ORDER_CLIENT": {
      return { ...state, searchOrderClient: action.value };
    }
    case "SET_SEARCH_BAR_VALUE_CLIENT": {
      return { ...state, searchBarValueClient: action.value };
    }
    case "SET_SORT_SEARCH_LAWYER": {
      return { ...state, sortSearchLawyer: action.value };
    }
    case "SET_SORT_SEARCH_CLIENT": {
      return { ...state, sortSearchClient: action.value };
    }
    case "SET_SEARCH_BASE_OBJECT_LAWYER": {
      return { ...state, searchBaseObjectLawyer: action.value };
    }
    case "SET_SEARCH_BASE_OBJECT_CLIENT": {
      return { ...state, searchBaseObjectClient: action.value };
    }
    case "SET_ALL_LAWYER_CERTIFICATES": {
      return { ...state, lawyerCertificates: action.value };
    }
    case "SET_ALL_LAWYER_OFFICES": {
      return { ...state, lawyerOffices: action.value };
    }
    case "SET_LAWYER_ACTIVITIES": {
      return { ...state, lawyerActivities: action.value };
    }
    case "SET_CLIENT_ACTIVITIES": {
      return { ...state, clientActivities: action.value };
    }
    case "SET_CLIENT_ADDRESSES": {
      return { ...state, clientAddresses: action.value };
    }
    case "SET_PERSON_INFO": {
      return { ...state, personInfo: action.value };
    }
    case "SET_LAWYER_RATING": {
      return { ...state, lawyerRating: action.value };
    }
    case "SET_OFFICE_INFO": {
      return { ...state, officeInfo: action.value };
    }
    case "SET_DETAIL_OPTION": {
      return { ...state, detailOption: action.value };
    }
    case "SET_CERTIFICATE_DETAIL": {
      return { ...state, certificateDetail: action.value };
    }
    case "SET_ACTIVE_HEADER_ID": {
      return { ...state, activeHeaderId: action.value };
    }
    case "SET_CURRENT_EDIT_CATEGORY_ID": {
      return { ...state, currentEditCategoryId: action.value };
    }
    case "SET_CURRENT_EDIT_CATEGORY_NAME": {
      return { ...state, currentEditCategoryName: action.value };
    }
    case "SET_ALL_CATEGORY_DATA": {
      return { ...state, categoriesData: action.value };
    }
    case "SET_ALL_REGIONS_DATA": {
      return { ...state, regionsData: action.value };
    }
    case "SET_CURRENT_EDIT_REGION_STATUS": {
      return { ...state, currentEditRegionStatus: action.value };
    }
    case "SET_CURRENT_EDIT_REGION_SUBSCRIBE": {
      return { ...state, currentEditRegionSubscribe: action.value };
    }
    case "SET_CURRENT_EDIT_REGION_SUB_PRICE": {
      return { ...state, currentEditRegionSubPrice: action.value };
    }
    case "SET_CURRENT_EDIT_REGION_WALLET": {
      return { ...state, currentEditRegionWallet: action.value };
    }
    case "SET_CURRENT_EDIT_REGION_ID": {
      return { ...state, currentEditRegionId: action.value };
    }
    case "SET_CURRENT_EDIT_REGION_NAME": {
      return { ...state, currentEditRegionName: action.value };
    }
    case "SET_REGION_PAGE": {
      return { ...state, regionPage: action.value };
    }
    case "SET_REGION_PAGE_COUNT": {
      return { ...state, regionsPageCount: action.value };
    }
    case "SET_US_REGIONS_LIST": {
      return { ...state, USRegionsList: action.value };
    }
    case "SET_PAYMENT_TAB_VALUE": {
      return { ...state, paymentTabValue: action.value };
    }
    case "SET_SUBSCRIPTIONS_TAB_VALUE": {
      return { ...state, subscriptionsTabValue: action.value };
    }
    case "SET_LAWYER_PAYMENT_LIST": {
      return { ...state, lawyerPaymentList: action.value };
    }
    case "SET_CLIENT_PAYMENT_LIST": {
      return { ...state, clientPaymentList: action.value };
    }
    case "SET_LAWYER_LIVES_LIST": {
      return { ...state, lawyerLiveList: action.value };
    }
    case "SET_LAWYER_LIVES_DETAILS": {
      return { ...state, lawyerLiveDetails: action.value };
    }
    case "SET_LAWYER_CASES_DETAILS": {
      return { ...state, lawyerCaseDetails: action.value };
    }
    case "SET_CLIENT_CASES_DETAILS": {
      return { ...state, clientCaseDetails: action.value };
    }
    case "SET_LAWYER_SESSIONS_DETAILS": {
      return { ...state, lawyerSessionDetails: action.value };
    }
    case "SET_CLIENT_SESSIONS_DETAILS": {
      return { ...state, clientSessionDetails: action.value };
    }
    case "SET_CLIENT_LIVES_DETAILS": {
      return { ...state, clientLiveDetails: action.value };
    }
    case "SET_CLIENT_LIVES_LIST": {
      return { ...state, clientLiveList: action.value };
    }
    case "SET_CURRENT_LIVES_LIST": {
      return { ...state, currentLiveList: action.value };
    }
    case "SET_LAWYER_PAYMENT_DETAILS": {
      return { ...state, lawyerPaymentDetails: action.value };
    }
    case "SET_CLIENT_PAYMENT_DETAILS": {
      return { ...state, clientPaymentDetails: action.value };
    }
    case "SET_LIVES_TAB_VALUE": {
      return { ...state, livesTabValue: action.value };
    }
    case "SET_CASES_TAB_VALUE": {
      return { ...state, casesTabValue: action.value };
    }
    case "SET_SESSIONS_TAB_VALUE": {
      return { ...state, sessionsTabValue: action.value };
    }
    case "SET_ALERT_TEXT": {
      return { ...state, alertText: action.value };
    }
    case "SET_ALERT_COLOR": {
      return { ...state, alertColor: action.value };
    }
    case "SET_ALERT_OPEN": {
      return { ...state, alertOpen: action.value };
    }
    case "SET_PAYMENTS_LIST": {
      return { ...state, paymentsList: action.value };
    }
    case "SET_PERIOD_OF_LAWYERS_PAYMENT": {
      return { ...state, periodOfLawyersPayments: action.value };
    }
    case "SET_PERIOD_OF_CLIENTS_PAYMENT": {
      return { ...state, periodOfClientsPayments: action.value };
    }
    case "SET_TAB": {
      return { ...state, tab: action.value };
    }
    case "SET_JOYRIDE_RUN": {
      return { ...state, joyrideRun: action.value };
    }
    case "SET_SETTING_JOYRIDE_RUN": {
      return { ...state, settingjoyrideRun: action.value };
    }
    case "SET_LAWYERS_JOYRIDE_RUN": {
      return { ...state, lawyersjoyrideRun: action.value };
    }
    case "SET_CLIENTS_JOYRIDE_RUN": {
      return { ...state, clientsjoyrideRun: action.value };
    }
    case "SET_CLIENT_TAB": {
      return { ...state, clientTab: action.value };
    }
    case "SET_LAWYER_COST_INFO": {
      return { ...state, lawyerCostInfo: action.value };
    }
    case "SET_SCROLL_IN_DETAIL": {
      return { ...state, scrollInDetail: action.value };
    }
    case "SET_STATE_CONTENT": {
      return { ...state, stateContent: action.value };
    }
    case "SET_LAWYER_SESSION_STATUS": {
      return { ...state, lawyerSessionStatus: action.value };
    }
    case "SET_CATEGORIES_PAGE_COUNT": {
      return { ...state, categoriesPageCount: action.value };
    }
    case "SET_CITY_IN_DRAWER_RIGHT": {
      return { ...state, cityInDrawerRight: action.value };
    }
    case "SET_LAWYER_STRIPE_ACCOUNT": {
      return { ...state, lawyerStripeAccount: action.value };
    }
    case "SET_POST_CODE_IN_DRAWER_RIGHT": {
      return { ...state, postCodeInDrawerRight: action.value };
    }
    case "SET_ADDRESS_IN_DRAWER_RIGHT": {
      return { ...state, addressInDrawerRight: action.value };
    }
    case "SET_OPEN_TRANSFER_DATA_MODAL": {
      return { ...state, openTransferDataModal: action.value };
    }
    case "SET_TRANSFER_DATA_ID": {
      return { ...state, transferDataId: action.value };
    }
    case "SET_LAWYER_TRANSFERS_DATA": {
      return { ...state, lawyerTransfersData: action.value };
    }
    case "SET_BUILDING_IN_DRAWER_RIGHT": {
      return { ...state, buildingInDrawerRight: action.value };
    }
    case "SET_REGION_ID_IN_DRAWER_RIGHT": {
      return { ...state, regionIdInDrawerRight: action.value };
    }
    case "SET_RELOAD_TRANSFER": {
      return { ...state, reloadTransfer: action.value };
    }
    default: {
      throw new Error(`Unhandled action type: ${action.type}`);
    }
  }
}

// Material Dashboard 2 React context provider
function MaterialUIControllerProvider({ children }) {
  const paramsLawyers = new URLSearchParams(window.location.search);
  const paramsObjLawyers = Array.from(paramsLawyers.keys()).reduce(
    (acc, val) => ({ ...acc, [val]: paramsLawyers.get(val) }),
    {}
  );
  const initialState = {
    miniSidenav: false,
    transparentSidenav: false,
    whiteSidenav: false,
    sidenavColor: "info",
    transparentNavbar: true,
    fixedNavbar: true,
    openConfigurator: false,
    direction: "ltr",
    layout: "dashboard",
    darkMode: false,
    closeVerificationAlert: false,
    closeSignInAlert: false,
    alertText: "",
    alertColor: "",
    cityInDrawerRight: "",
    postCodeInDrawerRight: "",
    addressInDrawerRight: "",
    buildingInDrawerRight: "",
    regionIdInDrawerRight: "",
    alertOpen: false,
    paymentsList: [],
    categoriesPageCount: 1,
    settingjoyrideRun: false,
    clientsjoyrideRun: false,
    lawyersjoyrideRun: false,
    joyrideRun: false,
    tab: 1,
    clientTab: 1,
    scrollInDetail: 0,
    openTransferDataModal: false,
    transferDataId: {},
    openOffersModal: false,
    openPayModal: false,
    openSessionDetailModal: false,
    openDetailMessageModal: false,
    payConfirmModalOpen: false,
    openMoreLiveInfoModal: false,
    caseId: -1,
    sessionId: -1,
    messageId: -1,
    liveId: -1,
    periodOfLawyersPayments: "monthly",
    periodOfClientsPayments: "monthly",
    alertStatus: "fadeOut",
    alertStatusLawyer: "fadeOut",
    alertStatusClient: "fadeOut",
    faqData: [],
    transactionsData: [],
    transfersData: [],
    clientSubscriptionData: [],
    lawyerSubscriptionData: [],
    lawyersData: {},
    profileData: {},
    adminsData: [],
    stateContent: "",
    lawyerSessionStatus: "",
    versionsList: [],
    reloadTransfer: false,
    clientsData: {},
    lawyerCertificates: {},
    lawyerOffices: {},
    lawyerActivities: {},
    personInfo: {},
    lawyerRating: {},
    officeInfo: {},
    clientActivities: {},
    lawyerStripeAccount: {},
    clientAddresses: {},
    lawyersCount: 0,
    clientsCount: 0,
    deleteFaqModal: false,
    livesCount: 0,
    casesCount: 0,
    casesData: [],
    sessionsData: [],
    lawyersCasesData: [],
    clientsCasesData: [],
    lawyersSessionsData: [],
    clientsSessionsData: [],
    livesOverviewCount: {},
    paymentsOverviewCount: {},
    lawyersPage: 1,
    clientsPage: 1,
    openDrawerRight: false,
    firstNameInDrawerRight: "",
    lastNameInDrawerRight: "",
    birthdayInDrawerRight: "",
    emailInDrawerRight: "",
    mobileInDrawerRight: "",
    genderInDrawerRight: "",
    customGenderInDrawerRight: "",
    createOrUpdate: false,
    circularProgress: false,
    openDrawerReason: "editOrCreate",
    faqId: "",
    faqQuestion: "",
    faqAnswer: "",
    faqPublish: 0,
    faqType: 0,
    currentClientStatusId: 0,
    currentLawyerStatusId: 0,
    currentCertificateStatusId: 0,
    currentCertificateStatus: "accepted",
    currentCostId: 0,
    lawyerCostInfo: {},
    currentLawyerInfo: {},
    currentClientInfo: {},
    updateLawyerId: 0,
    updateClientId: 0,
    transactionDetails: [],
    lawyerStatus: 0,
    clientStatus: 0,
    searchBarValueMessages: paramsObjLawyers.search ? paramsObjLawyers.search : "",
    sortMessages: "",
    orderMessages: "",
    searchOrderLawyer: paramsObjLawyers.order === "desc" ? "desc" : "asc",
    searchBarValueLawyer: paramsObjLawyers.search ? paramsObjLawyers.search : "",
    searchBarValueSubscription: paramsObjLawyers.search ? paramsObjLawyers.search : "",
    searchBarValueTransactions: paramsObjLawyers.search ? paramsObjLawyers.search : "",
    searchBarValueTransaction: paramsObjLawyers.search ? paramsObjLawyers.search : "",
    searchBarValueRegion: paramsObjLawyers.search ? paramsObjLawyers.search : "",
    searchBarValueCategory: paramsObjLawyers.search ? paramsObjLawyers.search : "",
    searchBarValuePayment: paramsObjLawyers.search ? paramsObjLawyers.search : "",
    searchBarValueDetailSubscriptions: "",
    searchBarValueDetailPayment: paramsObjLawyers.search ? paramsObjLawyers.search : "",
    searchBarValueLive: paramsObjLawyers.search ? paramsObjLawyers.search : "",
    searchBarValueDetailLive: paramsObjLawyers.search ? paramsObjLawyers.search : "",
    searchBarValueCase: paramsObjLawyers.search ? paramsObjLawyers.search : "",
    searchBarValueSession: paramsObjLawyers.search ? paramsObjLawyers.search : "",
    searchBarValueDetailCase: paramsObjLawyers.search ? paramsObjLawyers.search : "",
    searchBarValueDetailSession: paramsObjLawyers.search ? paramsObjLawyers.search : "",
    searchOrderClient: paramsObjLawyers.order === "desc" ? "desc" : "asc",
    searchBarValueClient: paramsObjLawyers.search ? paramsObjLawyers.search : "",
    sortSearchLawyer: paramsObjLawyers.sort ? paramsObjLawyers.sort : "id",
    sortSearchClient: paramsObjLawyers.sort ? paramsObjLawyers.sort : "id",
    searchBaseObjectLawyer: {
      first_name: true,
      last_name: true,
      mobile: true,
      cost_per_min: true,
      status: true,
      birthday: true,
    },
    searchBaseObjectClient: {
      first_name: true,
      last_name: true,
      mobile: true,
      status: true,
      birthday: true,
    },
    detailOption: 0,
    certificateDetail: {
      number: "",
      area: "",
      media: [],
      status: "",
      created_at: "",
      category: "",
      offices: [],
    },
    activeHeaderId: "",
    currentEditCategoryId: 0,
    currentEditCategoryName: "",
    categoriesData: {},
    currentEditRegionName: "",
    currentEditRegionSubPrice: 0,
    regionsPageCount: 1,
    regionPage: 1,
    regionsData: {},
    USRegionsList: [],
    subscriptionsTabValue: 0,
    paymentTabValue: 0,
    livesTabValue: 0,
    casesTabValue: 0,
    sessionsTabValue: 0,
    lawyerPaymentList: [],
    lawyerTransfersData: [],
    clientPaymentList: [],
    lawyerLiveList: [],
    lawyerLiveDetails: [],
    clientLiveDetails: [],
    lawyerCaseDetails: [],
    clientCaseDetails: [],
    lawyerSessionDetails: [],
    clientSessionDetails: [],
    clientLiveList: [],
    currentLiveList: [],
    lawyerPaymentDetails: [],
    clientPaymentDetails: [],
  };

  const [controller, dispatch] = useReducer(reducer, initialState);

  const value = useMemo(() => [controller, dispatch], [controller, dispatch]);

  return <MaterialUI.Provider value={value}>{children}</MaterialUI.Provider>;
}

// Material Dashboard 2 React custom hook for using context
function useMaterialUIController() {
  const context = useContext(MaterialUI);

  if (!context) {
    throw new Error(
      "useMaterialUIController should be used inside the MaterialUIControllerProvider."
    );
  }

  return context;
}

// Typechecking props for the MaterialUIControllerProvider
MaterialUIControllerProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

// Context module functions
const setFAQData = (dispatch, value) => dispatch({ type: "SET_FAQ_DATA", value });
const setTransationsData = (dispatch, value) => dispatch({ type: "SET_TRANSACTIONS_DATA", value });
const setTransfersData = (dispatch, value) => dispatch({ type: "SET_TRANSFERS_DATA", value });
const setClientSubscriptionList = (dispatch, value) =>
  dispatch({ type: "SET_CLIENT_SUBSCRIPTION_DATA", value });
const setLawyerSubscriptionList = (dispatch, value) =>
  dispatch({ type: "SET_LAWYER_SUBSCRIPTION_DATA", value });
const setMiniSidenav = (dispatch, value) => dispatch({ type: "MINI_SIDENAV", value });
const setTransparentSidenav = (dispatch, value) => dispatch({ type: "TRANSPARENT_SIDENAV", value });
const setWhiteSidenav = (dispatch, value) => dispatch({ type: "WHITE_SIDENAV", value });
const setSidenavColor = (dispatch, value) => dispatch({ type: "SIDENAV_COLOR", value });
const setTransparentNavbar = (dispatch, value) => dispatch({ type: "TRANSPARENT_NAVBAR", value });
const setFixedNavbar = (dispatch, value) => dispatch({ type: "FIXED_NAVBAR", value });
const setOpenConfigurator = (dispatch, value) => dispatch({ type: "OPEN_CONFIGURATOR", value });
const setDirection = (dispatch, value) => dispatch({ type: "DIRECTION", value });
const setLayout = (dispatch, value) => dispatch({ type: "LAYOUT", value });
const setDarkMode = (dispatch, value) => dispatch({ type: "DARKMODE", value });
const setCloseVerificationAlert = (dispatch, value) => dispatch({ type: "VERIFY_ALERT", value });
const setCloseSignInAlert = (dispatch, value) => dispatch({ type: "SIGN_IN_ALERT", value });
const setAlertStatus = (dispatch, value) => dispatch({ type: "SET_ALERT_STATUS", value });
const setAlertStatusLawyer = (dispatch, value) =>
  dispatch({ type: "SET_ALERT_STATUS_LAWYER", value });
const setAlertStatusClient = (dispatch, value) =>
  dispatch({ type: "SET_ALERT_STATUS_CLIENT", value });
const setLawyersData = (dispatch, value) => dispatch({ type: "SET_LAWYERS_DATA", value });
const setCategoriesPageCount = (dispatch, value) =>
  dispatch({ type: "SET_CATEGORIES_PAGE_COUNT", value });
const setProfileData = (dispatch, value) => dispatch({ type: "SET_PROFILE_DATA", value });
const setAdminsData = (dispatch, value) => dispatch({ type: "SET_ADMINS_DATA", value });
const setClientsData = (dispatch, value) => dispatch({ type: "SET_CLIENTS_DATA", value });
const setLawyersNumber = (dispatch, value) => dispatch({ type: "SET_NUMBER_LAWYERS", value });
const setClientsNumber = (dispatch, value) => dispatch({ type: "SET_NUMBER_CLIENTS", value });
const setLivesNumber = (dispatch, value) => dispatch({ type: "SET_NUMBER_LIVES", value });
const setCasesNumber = (dispatch, value) => dispatch({ type: "SET_NUMBER_CASES", value });
const setCasesData = (dispatch, value) => dispatch({ type: "SET_CASES_DATA", value });
const setDeleteFaqModal = (dispatch, value) => dispatch({ type: "SET_DELETE_FAQ_MODAL", value });
const setSessionsData = (dispatch, value) => dispatch({ type: "SET_SESSIONS_DATA", value });
const setLawyersCasesData = (dispatch, value) =>
  dispatch({ type: "SET_LAWYERS_CASES_DATA", value });
const setClientsCasesData = (dispatch, value) =>
  dispatch({ type: "SET_CLIENTS_CASES_DATA", value });
const setLawyersSessionsData = (dispatch, value) =>
  dispatch({ type: "SET_LAWYERS_SESSIONS_DATA", value });
const setClientsSessionsData = (dispatch, value) =>
  dispatch({ type: "SET_CLIENTS_SESSIONS_DATA", value });
const setPersonInfo = (dispatch, value) => dispatch({ type: "SET_PERSON_INFO", value });
const setLawyerRating = (dispatch, value) => dispatch({ type: "SET_LAWYER_RATING", value });
const setOfficeInfo = (dispatch, value) => dispatch({ type: "SET_OFFICE_INFO", value });
const setLivesOverview = (dispatch, value) =>
  dispatch({ type: "SET_NUMBER_LIVES_OVERVIEW", value });
const setPaymentsOverviews = (dispatch, value) =>
  dispatch({ type: "SET_NUMBER_PAYMENTS_OVERVIEW", value });
const setLawyersPage = (dispatch, value) => dispatch({ type: "SET_PAGE_LAWYERS", value });
const setClientsPage = (dispatch, value) => dispatch({ type: "SET_PAGE_CLIENTS", value });
const setOpenDrawerRight = (dispatch, value) => dispatch({ type: "OPEN_DRAWER_RIGHT", value });
const setFirstNameInDrawerRight = (dispatch, value) =>
  dispatch({ type: "SET_FIRSET_NAME_IN_DRAWER_RIGHT", value });
const setLastNameInDrawerRight = (dispatch, value) =>
  dispatch({ type: "SET_LAST_NAME_IN_DRAWER_RIGHT", value });
const setEmailInDrawerRight = (dispatch, value) =>
  dispatch({ type: "SET_EMAIL_IN_DRAWER_RIGHT", value });
const setBirthdayInDrawerRight = (dispatch, value) =>
  dispatch({ type: "SET_BIRTHDAY_IN_DRAWER_RIGHT", value });
const setMobileInDrawerRight = (dispatch, value) =>
  dispatch({ type: "SET_MOBILE_IN_RIGHT_DRAWER_RIGHT", value });
const setGenderInDrawerRight = (dispatch, value) =>
  dispatch({ type: "SET_GENDER_IN_DRAWER_RIGHT", value });
const setCustomGenderInDrawerRight = (dispatch, value) =>
  dispatch({ type: "SET_CUSTOM_GENDER_IN_DRAWER_RIGHT", value });
const setCreateOrUpdate = (dispatch, value) => dispatch({ type: "SET_CREATE_OR_UPDATE", value });
const setCircularProgress = (dispatch, value) => dispatch({ type: "SET_CIRCULAR_PROGRESS", value });
const setVersionsList = (dispatch, value) => dispatch({ type: "SET_VERSIONS_LIST", value });
const setODR = (dispatch, value) => dispatch({ type: "SET_OPEN_DRAWER_REASON", value });
const setFaqId = (dispatch, value) => dispatch({ type: "SET_FAQ_ID", value });
const setFaqQuestion = (dispatch, value) => dispatch({ type: "SET_FAQ_QUESTION", value });
const setFaqAnswer = (dispatch, value) => dispatch({ type: "SET_FAQ_ANSWER", value });
const setFaqPublish = (dispatch, value) => dispatch({ type: "SET_FAQ_PUBLISH", value });
const setFaqType = (dispatch, value) => dispatch({ type: "SET_FAQ_TYPE", value });
const setReloadTransfer = (dispatch, value) => dispatch({ type: "SET_RELOAD_TRANSFER", value });
const setCurrentClientStatusId = (dispatch, value) =>
  dispatch({ type: "SET_CURRENT_CLIENT_STATUS_ID", value });
const setCurrentLawyerStatusId = (dispatch, value) =>
  dispatch({ type: "SET_CURRENT_LAWYER_STATUS_ID", value });
const setCurrentLawyerInfo = (dispatch, value) =>
  dispatch({ type: "SET_CURRENT_LAWYER_INFO", value });
const setCurrentClientInfo = (dispatch, value) =>
  dispatch({ type: "SET_CURRENT_CLIENT_INFO", value });
const setCurrentCertificateStatusId = (dispatch, value) =>
  dispatch({ type: "SET_CURRENT_CERTIFICATE_STATUS_ID", value });
const setCurrentCertificateStatus = (dispatch, value) =>
  dispatch({ type: "SET_CURRENT_CERTIFICATE_STATUS", value });
const setOpenPayModal = (dispatch, value) => dispatch({ type: "SET_OPEN_PAY_MODAL", value });
const setCurrentCostId = (dispatch, value) => dispatch({ type: "SET_CURRENT_COST_ID", value });
const setUpdateLawyerId = (dispatch, value) => dispatch({ type: "SET_UPDATE_lawyer_ID", value });
const setUpdateClientId = (dispatch, value) => dispatch({ type: "SET_UPDATE_client_ID", value });
const setTransactionDetails = (dispatch, value) =>
  dispatch({ type: "SET_TRANSACTION_DETAIL", value });
const setLawyerStatusInDrawerRight = (dispatch, value) =>
  dispatch({ type: "SET_LAWYER_STATUS", value });
const setClientStatusInDrawerRight = (dispatch, value) =>
  dispatch({ type: "SET_CLIENT_STATUS", value });
const setOpenOffersModal = (dispatch, value) => dispatch({ type: "SET_OPEN_OFFERS_MODAL", value });
const setOpenSessionDetailModal = (dispatch, value) =>
  dispatch({ type: "SET_OPEN_SESSION_DETAIL_MODAL", value });
const setOpenDetailMessageModal = (dispatch, value) =>
  dispatch({ type: "SET_OPEN_DETAIL_MESSAGE_MODAL", value });
const setOpenMoreInfoLiveModal = (dispatch, value) =>
  dispatch({ type: "SET_OPEN_MORE_LIVE_MODAL", value });
const setCaseId = (dispatch, value) => dispatch({ type: "SET_CASE_ID", value });
const setSessionId = (dispatch, value) => dispatch({ type: "SET_SESSION_ID", value });
const setMessageId = (dispatch, value) => dispatch({ type: "SET_MESSAGE_ID", value });
const setLiveId = (dispatch, value) => dispatch({ type: "SET_LIVE_ID", value });
const setSearchBarValueLawyer = (dispatch, value) =>
  dispatch({ type: "SET_SEARCH_BAR_VALUE_LAWYER", value });
const setSearchBarValueTransactions = (dispatch, value) =>
  dispatch({ type: "SET_SEARCH_BAR_VALUE_TRANSACTIONS", value });
const setSearchBarValueSubscription = (dispatch, value) =>
  dispatch({ type: "SET_SEARCH_BAR_VALUE_SUBSCRIPTION", value });
const setSearchBarValueTransaction = (dispatch, value) =>
  dispatch({ type: "SET_SEARCH_BAR_VALUE_TRANSACTION", value });
const setSearchBarValueMessages = (dispatch, value) =>
  dispatch({ type: "SET_SEARCH_BAR_VALUE_MESSAGES", value });
const setOrderMessages = (dispatch, value) => dispatch({ type: "SET_ORDER_MESSAGES", value });
const setSortMessages = (dispatch, value) => dispatch({ type: "SET_SORT_MESSAGES", value });
const setSearchBarValueRegion = (dispatch, value) =>
  dispatch({ type: "SET_SEARCH_BAR_VALUE_REGION", value });
const setSearchBarValueCategory = (dispatch, value) =>
  dispatch({ type: "SET_SEARCH_BAR_VALUE_CATEGORY", value });
const setSearchBarValuePayment = (dispatch, value) =>
  dispatch({ type: "SET_SEARCH_BAR_VALUE_PAYMENT", value });
const setSearchBarValueDetailPayment = (dispatch, value) =>
  dispatch({ type: "SET_SEARCH_BAR_VALUE_DETAIL_PAYMENT", value });
const setSearchBarValueDetailSubscriptions = (dispatch, value) =>
  dispatch({ type: "SET_SEARCH_BAR_VALUE_DETAIL_SUBSCRIPTIONS", value });
const setSearchBarValueLive = (dispatch, value) =>
  dispatch({ type: "SET_SEARCH_BAR_VALUE_LIVE", value });
const setSearchBarValueDetailLive = (dispatch, value) =>
  dispatch({ type: "SET_SEARCH_BAR_VALUE_DETAIL_LIVE", value });
const setSearchBarValueCase = (dispatch, value) =>
  dispatch({ type: "SET_SEARCH_BAR_VALUE_CASE", value });
const setSearchBarValueSession = (dispatch, value) =>
  dispatch({ type: "SET_SEARCH_BAR_VALUE_SESSION", value });
const setSearchBarValueDetailCase = (dispatch, value) =>
  dispatch({ type: "SET_SEARCH_BAR_VALUE_DETAIL_CASE", value });
const setSearchBarValueDetailSession = (dispatch, value) =>
  dispatch({ type: "SET_SEARCH_BAR_VALUE_DETAIL_SESSION", value });
const setSearchOrderLawyer = (dispatch, value) =>
  dispatch({ type: "SET_SEARCH_ORDER_LAWYER", value });
const setSearchOrderClient = (dispatch, value) =>
  dispatch({ type: "SET_SEARCH_ORDER_CLIENT", value });
const setSearchBarValueClient = (dispatch, value) =>
  dispatch({ type: "SET_SEARCH_BAR_VALUE_CLIENT", value });
const setSortSearchLawyer = (dispatch, value) =>
  dispatch({ type: "SET_SORT_SEARCH_LAWYER", value });
const setSortSearchClient = (dispatch, value) =>
  dispatch({ type: "SET_SORT_SEARCH_CLIENT", value });
const setSearchBaseObjectLawyer = (dispatch, value) =>
  dispatch({ type: "SET_SEARCH_BASE_OBJECT_LAWYER", value });
const setSearchBaseObjectClient = (dispatch, value) =>
  dispatch({ type: "SET_SEARCH_BASE_OBJECT_CLIENT", value });
const setAllLawyerCertificates = (dispatch, value) =>
  dispatch({ type: "SET_ALL_LAWYER_CERTIFICATES", value });
const setLawyerActivities = (dispatch, value) => dispatch({ type: "SET_LAWYER_ACTIVITIES", value });
const setClientActivities = (dispatch, value) => dispatch({ type: "SET_CLIENT_ACTIVITIES", value });
const setClientAddresses = (dispatch, value) => dispatch({ type: "SET_CLIENT_ADDRESSES", value });
const setAllLawyerOffices = (dispatch, value) =>
  dispatch({ type: "SET_ALL_LAWYER_OFFICES", value });
const setDetailOption = (dispatch, value) => dispatch({ type: "SET_DETAIL_OPTION", value });
const setCertificateDetail = (dispatch, value) =>
  dispatch({ type: "SET_CERTIFICATE_DETAIL", value });
const setActiveHeaderId = (dispatch, value) => dispatch({ type: "SET_ACTIVE_HEADER_ID", value });
const setUSRegionsList = (dispatch, value) => dispatch({ type: "SET_US_REGIONS_LIST", value });
const setAllRegionsData = (dispatch, value) => dispatch({ type: "SET_ALL_REGIONS_DATA", value });
const setCurrentEditRegionStatus = (dispatch, value) =>
  dispatch({ type: "SET_CURRENT_EDIT_REGION_STATUS", value });
const setCurrentEditRegionSubscribe = (dispatch, value) =>
  dispatch({ type: "SET_CURRENT_EDIT_REGION_SUBSCRIBE", value });
const setCurrentEditRegionWallet = (dispatch, value) =>
  dispatch({ type: "SET_CURRENT_EDIT_REGION_WALLET", value });
const setCurrentEditRegionSubPrice = (dispatch, value) =>
  dispatch({ type: "SET_CURRENT_EDIT_REGION_SUB_PRICE", value });
const setCurrentEditRegionId = (dispatch, value) =>
  dispatch({ type: "SET_CURRENT_EDIT_REGION_ID", value });
const setCurrentEditRegionName = (dispatch, value) =>
  dispatch({ type: "SET_CURRENT_EDIT_REGION_NAME", value });
const setRegionsPageCount = (dispatch, value) => dispatch({ type: "SET_REGION_PAGE_COUNT", value });
const setRegionsPage = (dispatch, value) => dispatch({ type: "SET_REGION_PAGE", value });
const setAllCategoriesData = (dispatch, value) =>
  dispatch({ type: "SET_ALL_CATEGORY_DATA", value });
const setCurrentEditCategoryId = (dispatch, value) =>
  dispatch({ type: "SET_CURRENT_EDIT_CATEGORY_ID", value });
const setCurrentEditCategoryName = (dispatch, value) =>
  dispatch({ type: "SET_CURRENT_EDIT_CATEGORY_NAME", value });
const setPaymentTabValue = (dispatch, value) => dispatch({ type: "SET_PAYMENT_TAB_VALUE", value });
const setSubscriptionsTabValue = (dispatch, value) =>
  dispatch({ type: "SET_SUBSCRIPTIONS_TAB_VALUE", value });
const setLawyerPaymentList = (dispatch, value) =>
  dispatch({ type: "SET_LAWYER_PAYMENT_LIST", value });
const setClientPaymentList = (dispatch, value) =>
  dispatch({ type: "SET_CLIENT_PAYMENT_LIST", value });
const setLawyerLivesList = (dispatch, value) => dispatch({ type: "SET_LAWYER_LIVES_LIST", value });
const setLawyerLivesDetails = (dispatch, value) =>
  dispatch({ type: "SET_LAWYER_LIVES_DETAILS", value });
const setLawyerCasesDetails = (dispatch, value) =>
  dispatch({ type: "SET_LAWYER_CASES_DETAILS", value });
const setClientCasesDetails = (dispatch, value) =>
  dispatch({ type: "SET_CLIENT_CASES_DETAILS", value });
const setLawyerSessionsDetails = (dispatch, value) =>
  dispatch({ type: "SET_LAWYER_SESSIONS_DETAILS", value });
const setClientSessionsDetails = (dispatch, value) =>
  dispatch({ type: "SET_CLIENT_SESSIONS_DETAILS", value });
const setClientLivesDetails = (dispatch, value) =>
  dispatch({ type: "SET_CLIENT_LIVES_DETAILS", value });
const setClientLivesList = (dispatch, value) => dispatch({ type: "SET_CLIENT_LIVES_LIST", value });
const setCurrentLivesList = (dispatch, value) =>
  dispatch({ type: "SET_CURRENT_LIVES_LIST", value });
const setLawyerPaymentDetails = (dispatch, value) =>
  dispatch({ type: "SET_LAWYER_PAYMENT_DETAILS", value });
const setClientPaymentDetails = (dispatch, value) =>
  dispatch({ type: "SET_CLIENT_PAYMENT_DETAILS", value });
const setLivesTabValue = (dispatch, value) => dispatch({ type: "SET_LIVES_TAB_VALUE", value });
const setCasesTabValue = (dispatch, value) => dispatch({ type: "SET_CASES_TAB_VALUE", value });
const setSessionsTabValue = (dispatch, value) =>
  dispatch({ type: "SET_SESSIONS_TAB_VALUE", value });
const setAlertText = (dispatch, value) => dispatch({ type: "SET_ALERT_TEXT", value });
const setAlertColor = (dispatch, value) => dispatch({ type: "SET_ALERT_COLOR", value });
const setAlertOpen = (dispatch, value) => dispatch({ type: "SET_ALERT_OPEN", value });
const setPaymentsList = (dispatch, value) => dispatch({ type: "SET_PAYMENTS_LIST", value });
const setCityInDrawerRight = (dispatch, value) =>
  dispatch({ type: "SET_CITY_IN_DRAWER_RIGHT", value });
const setLawyerStripeAccount = (dispatch, value) =>
  dispatch({ type: "SET_LAWYER_STRIPE_ACCOUNT", value });
const setPostCodeInDrawerRight = (dispatch, value) =>
  dispatch({ type: "SET_POST_CODE_IN_DRAWER_RIGHT", value });
const setAddressInDrawerRight = (dispatch, value) =>
  dispatch({ type: "SET_ADDRESS_IN_DRAWER_RIGHT", value });
const setOpenTransferDataModal = (dispatch, value) =>
  dispatch({ type: "SET_OPEN_TRANSFER_DATA_MODAL", value });
const setTransferDataId = (dispatch, value) => dispatch({ type: "SET_TRANSFER_DATA_ID", value });
const setLawyerTransfersData = (dispatch, value) =>
  dispatch({ type: "SET_LAWYER_TRANSFERS_DATA", value });
const setBuildingInDrawerRight = (dispatch, value) =>
  dispatch({ type: "SET_BUILDING_IN_DRAWER_RIGHT", value });
const setRegionIdInDrawerRight = (dispatch, value) =>
  dispatch({ type: "SET_REGION_ID_IN_DRAWER_RIGHT", value });
const setPeriodOfLawyersPayments = (dispatch, value) =>
  dispatch({ type: "SET_PERIOD_OF_LAWYERS_PAYMENT", value });
const setPeriodOfClientsPayments = (dispatch, value) =>
  dispatch({ type: "SET_PERIOD_OF_CLIENTS_PAYMENT", value });
const setTab = (dispatch, value) => dispatch({ type: "SET_TAB", value });
const setJoyrideRun = (dispatch, value) => dispatch({ type: "SET_JOYRIDE_RUN", value });
const setLawyersJoyrideRun = (dispatch, value) =>
  dispatch({ type: "SET_LAWYERS_JOYRIDE_RUN", value });
const setClientsJoyrideRun = (dispatch, value) =>
  dispatch({ type: "SET_CLIENTS_JOYRIDE_RUN", value });
const setSettingJoyrideRun = (dispatch, value) =>
  dispatch({ type: "SET_SETTING_JOYRIDE_RUN", value });
const setClientTab = (dispatch, value) => dispatch({ type: "SET_CLIENT_TAB", value });
const setScrollInDetail = (dispatch, value) => dispatch({ type: "SET_SCROLL_IN_DETAIL", value });
const setLawyerCostInfo = (dispatch, value) => dispatch({ type: "SET_LAWYER_COST_INFO", value });
const setStateContent = (dispatch, value) => dispatch({ type: "SET_STATE_CONTENT", value });
const setLawyerSessionStatus = (dispatch, value) =>
  dispatch({ type: "SET_LAWYER_SESSION_STATUS", value });
export {
  setLawyersJoyrideRun,
  setClientsJoyrideRun,
  setSettingJoyrideRun,
  setTab,
  setClientTab,
  setCaseId,
  setSessionId,
  setMessageId,
  setLiveId,
  setScrollInDetail,
  setOpenOffersModal,
  setOpenSessionDetailModal,
  setOpenDetailMessageModal,
  setOpenMoreInfoLiveModal,
  setAlertText,
  setAlertOpen,
  setSearchBarValueTransactions,
  setSearchBarValueSubscription,
  setSearchBarValueTransaction,
  setAlertColor,
  setActiveHeaderId,
  setLawyerTransfersData,
  setCasesTabValue,
  setSessionsTabValue,
  setUSRegionsList,
  setDeleteFaqModal,
  setTransactionDetails,
  setLawyerPaymentDetails,
  setClientPaymentDetails,
  setLawyerPaymentList,
  setClientPaymentList,
  setLawyerLivesList,
  setLawyerLivesDetails,
  setLawyerCasesDetails,
  setClientCasesDetails,
  setLawyerSessionsDetails,
  setClientSessionsDetails,
  setClientLivesDetails,
  setClientSubscriptionList,
  setLawyerSubscriptionList,
  setClientLivesList,
  setCurrentLivesList,
  setSubscriptionsTabValue,
  setPaymentTabValue,
  setCertificateDetail,
  setDetailOption,
  setOpenPayModal,
  MaterialUIControllerProvider,
  setCircularProgress,
  useMaterialUIController,
  setMiniSidenav,
  setTransparentSidenav,
  setWhiteSidenav,
  setSidenavColor,
  setSearchBarValueDetailSubscriptions,
  setVersionsList,
  setTransparentNavbar,
  setFixedNavbar,
  setStateContent,
  setLawyerCostInfo,
  setPersonInfo,
  setReloadTransfer,
  setJoyrideRun,
  setLawyerRating,
  setOfficeInfo,
  setOpenConfigurator,
  setDirection,
  setCategoriesPageCount,
  setLayout,
  setDarkMode,
  setCloseVerificationAlert,
  setCloseSignInAlert,
  setAlertStatus,
  setLawyersData,
  setProfileData,
  setAdminsData,
  setFaqId,
  setFaqQuestion,
  setFaqAnswer,
  setFaqPublish,
  setFaqType,
  setClientsData,
  setLawyersNumber,
  setClientsNumber,
  setLivesNumber,
  setCasesNumber,
  setLawyerStripeAccount,
  setCasesData,
  setSessionsData,
  setLawyersCasesData,
  setClientsCasesData,
  setLawyersSessionsData,
  setClientsSessionsData,
  setLivesOverview,
  setPaymentsOverviews,
  setLawyersPage,
  setClientsPage,
  setOpenDrawerRight,
  setFirstNameInDrawerRight,
  setLastNameInDrawerRight,
  setEmailInDrawerRight,
  setBirthdayInDrawerRight,
  setMobileInDrawerRight,
  setGenderInDrawerRight,
  setCustomGenderInDrawerRight,
  setCreateOrUpdate,
  setFAQData,
  setTransationsData,
  setTransfersData,
  setODR,
  setCurrentClientStatusId,
  setCurrentLawyerStatusId,
  setPaymentsList,
  setCurrentLawyerInfo,
  setCurrentClientInfo,
  setCurrentCertificateStatusId,
  setCurrentCertificateStatus,
  setCurrentCostId,
  setUpdateLawyerId,
  setUpdateClientId,
  setCityInDrawerRight,
  setPostCodeInDrawerRight,
  setAddressInDrawerRight,
  setBuildingInDrawerRight,
  setRegionIdInDrawerRight,
  setLawyerStatusInDrawerRight,
  setClientStatusInDrawerRight,
  setSearchOrderLawyer,
  setSearchBarValueLawyer,
  setSearchBarValueMessages,
  setSortMessages,
  setOrderMessages,
  setOpenTransferDataModal,
  setTransferDataId,
  setSearchBarValueRegion,
  setSearchBarValueCategory,
  setSearchBarValuePayment,
  setSearchBarValueDetailPayment,
  setSearchBarValueLive,
  setSearchBarValueDetailLive,
  setSearchBarValueCase,
  setSearchBarValueSession,
  setSearchBarValueDetailCase,
  setSearchBarValueDetailSession,
  setSearchOrderClient,
  setSearchBarValueClient,
  setAlertStatusLawyer,
  setAlertStatusClient,
  setPeriodOfLawyersPayments,
  setPeriodOfClientsPayments,
  setSortSearchLawyer,
  setSortSearchClient,
  setSearchBaseObjectLawyer,
  setSearchBaseObjectClient,
  setAllLawyerCertificates,
  setAllLawyerOffices,
  setAllRegionsData,
  setCurrentEditRegionStatus,
  setCurrentEditRegionSubscribe,
  setCurrentEditRegionWallet,
  setCurrentEditRegionSubPrice,
  setCurrentEditRegionId,
  setCurrentEditRegionName,
  setRegionsPageCount,
  setRegionsPage,
  setAllCategoriesData,
  setCurrentEditCategoryName,
  setCurrentEditCategoryId,
  setLawyerActivities,
  setClientActivities,
  setClientAddresses,
  setLivesTabValue,
  setLawyerSessionStatus,
};
