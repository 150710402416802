import MDAvatar from "components/MDAvatar";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import { useMaterialUIController, setClientTab } from "context";
import { useState, useEffect } from "react";
import PropTypes from "prop-types";
import person from "assets/images/person.png";
import { Link } from "react-router-dom";

export default function data() {
  const [controller, dispatch] = useMaterialUIController();
  const { clientsSessionsData } = controller;
  const [currentList, setCurrentList] = useState([]);
  useEffect(() => {
    setCurrentList(clientsSessionsData);
  }, [clientsSessionsData]);

  const getFullName = (first, last) => {
    if (first === null && last === null) return "not Found";
    let returndText = "";
    if (first === null) returndText += "";
    else returndText += first;
    returndText += " ";
    if (last === null) returndText += "";
    else returndText += last;
    return returndText;
  };
  function Author({ image, name, email, id }) {
    return (
      <Link
        onClick={() => {
          setClientTab(dispatch, 8);
        }}
        to={`/clients/detail/${id}`}
        style={{ cursor: "pointer", display: "flex", alignItems: "center" }}
      >
        <MDTypography
          style={{ minWidth: "50px" }}
          display="block"
          variant="button"
          fontWeight="medium"
        >
          #{id}
        </MDTypography>
        <MDAvatar src={image} name={name} size="sm" />
        <MDBox ml={2} lineHeight={1}>
          <MDTypography display="block" variant="button" fontWeight="medium">
            {name}
          </MDTypography>
          <MDTypography variant="caption">{email}</MDTypography>
        </MDBox>
      </Link>
      // </Link>
    );
  }
  Author.propTypes = {
    image: PropTypes.string,
    name: PropTypes.string,
    email: PropTypes.string,
    id: PropTypes.number,
  };
  Author.defaultProps = {
    image: "",
    name: "",
    email: "",
    id: "",
  };
  const getRows = () => {
    const personsInfo = currentList.map((value) => ({
      client: (
        <Author
          image={value.client.avatars === null ? person : value.client.avatars.thumbnail}
          name={getFullName(value.client.first_name, value.client.last_name)}
          email={value.client.email}
          id={value.client.id}
        />
      ),
      count: (
        <MDBox lineHeight={1} textAlign="center">
          {value.count}
        </MDBox>
      ),
      "total money": (
        <MDBox lineHeight={1} textAlign="center">
          {Math.round((value.total_money / 100) * 100) / 100}$
        </MDBox>
      ),
    }));
    return personsInfo;
  };
  return {
    columns: [
      { Header: "client", accessor: "client", align: "left" },
      { Header: "count", accessor: "count", align: "center" },
      { Header: "total money", accessor: "total money", align: "center" },
    ],

    rows: getRows(),
  };
}
