/* eslint-disable no-nested-ternary */
// @mui material components
import Divider from "@mui/material/Divider";
import Icon from "@mui/material/Icon";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";

// Custom styles for the Configurator
import DrawerRightRoot from "examples/DrawerRight/DrawerRightRoot";

// Material Dashboard 2 React context
import {
  useMaterialUIController,
  setOpenDrawerRight,
  setCircularProgress,
  setFAQData,
  setAlertText,
  setAlertOpen,
  setAlertColor,
} from "context";
import MDInput from "components/MDInput";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import createFaq from "model/createFaqModel";
import { useState } from "react";
import { getAllFAQs, addAnFAQ } from "api/api_faq";

function DrawerRightForCreateClient() {
  const [controller, dispatch] = useMaterialUIController();
  const [type, setType] = useState(0);
  const [question, setQuestion] = useState("");
  const [answer, setAnswer] = useState("");
  const [publish, setPublish] = useState(0);
  const { openDrawerRight, darkMode } = controller;
  const handleCloseDrawerRight = () => setOpenDrawerRight(dispatch, false);
  const createFaqBtnClicked = () => {
    const typesList = ["lawyer", "client", "admin", "general"];
    const body = {
      question,
      answer,
      publish: parseInt(publish, 10) === 0,
      type: typesList[parseInt(type, 10)],
    };
    setOpenDrawerRight(dispatch, false);
    setCircularProgress(dispatch, true);
    createFaq
      .validate(body, { abortEarly: false })
      .then((valid) => {
        addAnFAQ((isOk, data) => {
          if (!isOk) {
            console.log("create faq information is not successful: ", data);
            setCircularProgress(dispatch, false);
            setAlertText(dispatch, data.response.data.message);
            setAlertOpen(dispatch, true);
            setAlertColor(dispatch, "error");
          } else {
            setType(0);
            setQuestion("");
            setAnswer("");
            setPublish(0);
            getAllFAQs((isOk2, data2) => {
              if (!isOk2) {
                console.log("get faq data from api is not working", data2);
                setCircularProgress(dispatch, false);
                setAlertText(dispatch, data2.response.data.message);
                setAlertOpen(dispatch, true);
                setAlertColor(dispatch, "success");
              } else {
                setFAQData(dispatch, data2.data);
                console.log("first", data2);
                setCircularProgress(dispatch, false);
                setAlertColor(dispatch, "error");
              }
            }, `${window.location.search}`);
          }
        }, valid);
      })
      .catch((err) => {
        setCircularProgress(dispatch, false);
        setAlertText(dispatch, err.errors[0].split(",")[0]);
        setAlertOpen(dispatch, true);
        setAlertColor(dispatch, "error");
      });
  };
  return (
    <DrawerRightRoot variant="permanent" ownerState={{ openDrawerRight }}>
      <MDBox
        display="flex"
        justifyContent="space-between"
        alignItems="baseline"
        pt={4}
        pb={0.5}
        px={3}
      >
        <MDBox>
          <MDTypography variant="h5">Admin Panel Editor</MDTypography>
          <MDTypography variant="body2" color="text">
            Add a new faq
          </MDTypography>
        </MDBox>

        <Icon
          sx={({ typography: { size }, palette: { dark, white } }) => ({
            fontSize: `${size.lg} !important`,
            color: darkMode ? white.main : dark.main,
            stroke: "currentColor",
            strokeWidth: "2px",
            cursor: "pointer",
            transform: "translateY(5px)",
          })}
          onClick={handleCloseDrawerRight}
        >
          close
        </Icon>
      </MDBox>
      <Divider />
      <MDBox component="form" role="form">
        <MDBox mb={2}>
          <MDInput
            value={question}
            onChange={(e) => setQuestion(e.target.value)}
            id="questionInput"
            type="text"
            label="Question Input"
            fullWidth
          />
        </MDBox>
        <MDBox mb={2}>
          <MDInput
            value={answer}
            onChange={(e) => setAnswer(e.target.value)}
            id="AnswerInput"
            type="text"
            label="Answer Input"
            fullWidth
          />
        </MDBox>
        <MDBox>
          <MDTypography style={{ fontSize: "1rem" }}>Select Faq Type</MDTypography>
        </MDBox>
        <MDBox mb={2} fullWidth>
          <RadioGroup
            value={type}
            onChange={(e) => setType(e.target.value)}
            row
            aria-labelledby="type"
            name="type"
            defaultValue="lawyer"
          >
            <FormControlLabel value={0} control={<Radio />} label="lawyer" labelPlacement="start" />
            <FormControlLabel value={1} control={<Radio />} label="client" labelPlacement="start" />
            <FormControlLabel value={2} control={<Radio />} label="admin" labelPlacement="start" />
            <FormControlLabel
              value={3}
              control={<Radio />}
              label="general"
              labelPlacement="start"
            />
          </RadioGroup>
        </MDBox>
        <MDBox>
          <MDTypography style={{ fontSize: "1rem" }}>
            Are you want to publish this faq?
          </MDTypography>
        </MDBox>
        <MDBox mb={2} fullWidth>
          <RadioGroup
            value={publish}
            onChange={(e) => setPublish(e.target.value)}
            row
            aria-labelledby="publish"
            name="publish"
            defaultValue="yes"
          >
            <FormControlLabel value={0} control={<Radio />} label="yes" labelPlacement="start" />
            <FormControlLabel value={1} control={<Radio />} label="no" labelPlacement="start" />
          </RadioGroup>
        </MDBox>
        <MDBox mt={4} mb={1}>
          <MDButton onClick={() => createFaqBtnClicked()} variant="gradient" color="info" fullWidth>
            Add
          </MDButton>
        </MDBox>
      </MDBox>
    </DrawerRightRoot>
  );
}
export default DrawerRightForCreateClient;
