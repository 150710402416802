import {
  Box,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  Icon,
  Tooltip,
} from "@mui/material";
import { useMaterialUIController, setOpenOffersModal } from "context";
import React, { useEffect, useState } from "react";
import { getACaseDetail } from "api/api_cases";

function Tables() {
  const [controller, dispatch] = useMaterialUIController();
  const { openOffersModal, caseId } = controller;
  const [offersList, setOffersList] = useState([]);
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    if (caseId !== -1) {
      setLoading(true);
      getACaseDetail((isOk, data) => {
        if (!isOk) {
          console.log("get all Cases List from api is not working", data);
          setLoading(false);
        } else {
          setOffersList(data.offers);
          setLoading(false);
        }
      }, caseId);
    }
  }, [caseId]);
  const handleClose = () => setOpenOffersModal(dispatch, false);
  const getBgColor = (value) => {
    switch (value) {
      case "accepted":
        return "bg-[#1C810C]";
      case "accepted-by-client":
        return "bg-[#1C810C]";
      case "accepted-by-lawyer":
        return "bg-[#1C810C]";
      case "rejected":
        return "bg-[#F47575]";
      case "rejected-by-lawyer":
        return "bg-[#F47575]";
      case "rejected-by-client":
        return "bg-[#F47575]";
      case "pending":
        return "bg-[#bfd611]";
      default:
        return "bg-[#000d07]";
    }
  };
  const getStatusText = (value) => {
    if (value.split("-")[0] === "accepted") return "accepted";
    if (value.split("-")[0] === "rejected") return "rejected";
    return value;
  };
  return (
    <div>
      <Dialog
        open={openOffersModal}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle>
          <div className="flex">
            <div>Offers For This Case</div>
            <div className="ml-auto">
              <Icon className="cursor-pointer" onClick={handleClose}>
                close
              </Icon>
            </div>
          </div>
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            <Box style={{ minWidth: "500px" }}>
              {loading ? (
                <div>Loading....</div>
              ) : (
                <>
                  <Grid container>
                    <Grid xs={3} className="text-[13px]">
                      Lawyer
                    </Grid>
                    <Grid xs={3} className="text-[13px]">
                      Price
                    </Grid>
                    <Grid xs={3} className="text-[13px]">
                      Status
                    </Grid>
                    <Grid xs={3} className="text-[13px]">
                      Offered At
                    </Grid>
                  </Grid>
                  <div className="w-[100%] overflow-y-auto">
                    {offersList.map((value) => (
                      <Grid
                        container
                        className={`${getBgColor(
                          value.status
                        )} flex flex-col items-center justify-center px-1 mb-1 border rounded-md`}
                      >
                        <Grid xs={3} className="text-[13px] font-bold text-white">
                          {value.lawyer?.first_name} {value.lawyer?.last_name}
                        </Grid>
                        <Grid xs={3} className="text-[13px] font-bold text-white">
                          {value.price}
                        </Grid>
                        <Tooltip title={value.status}>
                          <Grid xs={3} className="text-[13px] font-bold text-white ">
                            <div>{getStatusText(value.status)}</div>
                          </Grid>
                        </Tooltip>
                        <Grid
                          xs={3}
                          className="text-[13px] font-bold text-white flex items-center justify-center"
                        >
                          {value.created_at.split("T")[0]}{" "}
                          {value.created_at.split("T")[1].split(".")[0]}
                        </Grid>
                      </Grid>
                    ))}
                  </div>
                </>
              )}
            </Box>
          </DialogContentText>
        </DialogContent>
      </Dialog>
    </div>
  );
}

export default Tables;
