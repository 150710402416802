import {
  Box,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Icon,
  // Tooltip,
} from "@mui/material";
import { useMaterialUIController, setOpenSessionDetailModal } from "context";
import React, { useEffect, useState } from "react";
import { getASessionDetail } from "api/sessionApi";

function Tables() {
  const [controller, dispatch] = useMaterialUIController();
  const { openSessionDetailModal, sessionId } = controller;
  const [sessionDetail, setSessionDetail] = useState([]);
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    if (sessionId !== -1) {
      setLoading(true);
      getASessionDetail((isOk, data) => {
        if (!isOk) {
          console.log("get all sessions List from api is not working", data);
          setLoading(false);
        } else {
          setSessionDetail(data);
          setLoading(false);
        }
      }, sessionId);
    }
  }, [sessionId]);
  const handleClose = () => setOpenSessionDetailModal(dispatch, false);
  return (
    <div>
      <Dialog
        open={openSessionDetailModal}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle>
          <div className="flex">
            <div>Description Of This Session</div>
            <div className="ml-auto">
              <Icon className="cursor-pointer" onClick={handleClose}>
                close
              </Icon>
            </div>
          </div>
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            <Box style={{ minWidth: "500px" }}>
              {loading ? (
                <div>Loading....</div>
              ) : (
                <div className="w-[100%] flex flex-col items-center justify-center">
                  {sessionDetail.description || "without description"}
                </div>
              )}
            </Box>
          </DialogContentText>
        </DialogContent>
      </Dialog>
    </div>
  );
}

export default Tables;
