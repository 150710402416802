// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/CasesNavbar";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import AccountBalanceIcon from "@mui/icons-material/AccountBalance";
import WorkIcon from "@mui/icons-material/Work";
import GroupIcon from "@mui/icons-material/Group";
import {
  useMaterialUIController,
  setCasesTabValue,
  setSearchBarValueCase,
  setSearchBarValueDetailCase,
} from "context";
import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import LawyersCasesTable from "./lawyersCaseTable";
import ClientsCasesTable from "./clientsCaseTable";
import AllCasesTable from "./all";

function Cases() {
  const [controller, dispatch] = useMaterialUIController();
  const { casesTabValue } = controller;
  const { pathname } = useLocation();
  const navigate = useNavigate();
  useEffect(() => {
    setSearchBarValueCase(dispatch, "");
    setSearchBarValueDetailCase(dispatch, "");
  }, [casesTabValue]);
  useEffect(() => {
    if (window.location.search.split("?")[1].split("&")[0] === "all") {
      setCasesTabValue(dispatch, 0);
    }
    if (window.location.search.split("?")[1].split("&")[0] === "lawyers") {
      setCasesTabValue(dispatch, 1);
    }
    if (window.location.search.split("?")[1].split("&")[0] === "clients") {
      setCasesTabValue(dispatch, 2);
    }
  }, [pathname, window.location.pathname]);
  useEffect(() => {
    if (pathname === "/cases") {
      if (casesTabValue === 0) navigate(`/cases?all`);
      else if (casesTabValue === 1) navigate(`/cases?lawyers`);
      else if (casesTabValue === 2) navigate(`/cases?clients`);
    } else {
      navigate(`/cases?all`);
    }
  }, [casesTabValue]);

  const getRelatedTable = () => {
    if (window.location.search === "") return <AllCasesTable />;
    if (window.location.search.split("?")[1].split("&")[0] === "all") return <AllCasesTable />;
    if (window.location.search.split("?")[1].split("&")[0] === "lawyers")
      return <LawyersCasesTable />;
    return <ClientsCasesTable />;
  };
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <Tabs
        value={casesTabValue}
        onChange={(e, newValue) => {
          setCasesTabValue(dispatch, newValue);
        }}
        aria-label="User Type Tab"
      >
        <Tab
          icon={<WorkIcon />}
          style={{
            fontWeight: casesTabValue === 0 && "bold",
          }}
          label="ALL"
        />
        <Tab
          icon={<AccountBalanceIcon />}
          style={{
            fontWeight: casesTabValue === 1 && "bold",
          }}
          label="LAWYERS"
        />
        <Tab
          icon={<GroupIcon />}
          style={{
            fontWeight: casesTabValue === 2 && "bold",
          }}
          label="CLIENTS"
        />
      </Tabs>
      {getRelatedTable()}
    </DashboardLayout>
  );
}

export default Cases;
