import { AxiosInstance } from "./api";

export const getVerificationCode = (phoneInfo, callback) => {
  AxiosInstance()
    .post("/api/v1/verification-sms", phoneInfo)
    .then((res) => callback(true, res.data))
    .catch((err) => callback(false, err));
};
export const loginToDashboard = (body, callback) => {
  AxiosInstance()
    .post("/api/v2/login", body)
    .then((res) => callback(true, res.data))
    .catch((err) => callback(false, err));
};
