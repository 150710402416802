import { useMemo } from "react";

// porp-types is a library for typechecking of props
import PropTypes from "prop-types";

// react-chartjs-2 components
import { Line } from "react-chartjs-2";

// @mui material components
import Card from "@mui/material/Card";
// import Divider from "@mui/material/Divider";
import Icon from "@mui/material/Icon";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import Zoom from "@mui/material/Zoom";
import Tooltip from "@mui/material/Tooltip";

// ReportsLineChart configurations
import configs from "examples/Charts/LineCharts/ReportsLineChart/configs";

function ReportsLineChart({ color, moreInfoDesc, title, description, chart }) {
  const { data, options } = configs(chart.labels || [], chart.datasets || {});
  return (
    <Card sx={{ height: "100%" }}>
      <MDBox padding="1rem">
        {useMemo(
          () => (
            <MDBox
              variant="gradient"
              bgColor={color}
              borderRadius="lg"
              coloredShadow={color}
              py={2}
              pr={0.5}
              mt={-5}
              height="12.5rem"
            >
              <Line data={data} options={options} />
              {/* <Line
                data={data}
                options={{
                  ...options,
                  scales: {
                    y: {
                      ticks: {
                        // Include a dollar sign in the ticks
                        callback(value) {
                          return `${value}$`;
                        },
                      },
                    },
                  },
                  scales: {
                    xAxes: [
                      {
                        gridLines: {
                          borderDash: [8, 4],
                          color: "#348632",
                        },
                      },
                    ],
                    yAxes: [
                      {
                        gridLines: {
                          borderDash: [8, 4],
                          color: "#348632",
                        },
                      },
                    ],
                  },
                }}
              /> */}
            </MDBox>
          ),
          [chart, color]
        )}
        <MDBox pt={3} pb={1} px={1}>
          <MDBox style={{ display: "flex", alignItems: "center" }}>
            <MDTypography variant="h6" textTransform="capitalize">
              {title}
            </MDTypography>
            <Tooltip TransitionComponent={Zoom} title={moreInfoDesc}>
              <Icon sx={{ fontWeight: "bold", marginLeft: "5px", cursor: "pointer" }}>info</Icon>
            </Tooltip>
          </MDBox>
          <MDTypography component="div" variant="button" color="text" fontWeight="light">
            {description}
          </MDTypography>
          {/* <Divider /> */}
          {/* <MDBox display="flex" alignItems="center">
            <MDTypography variant="button" color="text" lineHeight={1} sx={{ mt: 0.15, mr: 0.5 }}>
              <Icon>schedule</Icon>
            </MDTypography>
            <MDTypography variant="button" color="text" fontWeight="light">
              {date}
            </MDTypography>
          </MDBox> */}
        </MDBox>
      </MDBox>
    </Card>
  );
}

// Setting default values for the props of ReportsLineChart
ReportsLineChart.defaultProps = {
  color: "dark",
  description: "",
  // moreInfoDesc: "",
};

// Typechecking props for the ReportsLineChart
ReportsLineChart.propTypes = {
  color: PropTypes.oneOf(["primary", "secondary", "info", "success", "warning", "error", "dark"]),
  title: PropTypes.string.isRequired,
  moreInfoDesc: PropTypes.string.isRequired,
  description: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  // date: PropTypes.string.isRequired,
  chart: PropTypes.objectOf(PropTypes.oneOfType([PropTypes.array, PropTypes.object])).isRequired,
};

export default ReportsLineChart;
