import { AxiosInstanceWithXAuthToken } from "./api";

export const getAllClientsSubscriptions = (callback, params = "") => {
  AxiosInstanceWithXAuthToken()
    .get(`/api/v1/admins/clients/supscriptions${params}`)
    .then((res) => callback(true, res.data))
    .catch((err) => callback(false, err));
};
export const getAClientSubscription = (callback, id) => {
  AxiosInstanceWithXAuthToken()
    .get(`/api/v1/admins/clients/supscription/${id}`)
    .then((res) => callback(true, res.data))
    .catch((err) => callback(false, err));
};
export const getAllLawyersSubscriptions = (callback, params = "") => {
  AxiosInstanceWithXAuthToken()
    .get(`api/v1/admins/lawyers/supscriptions${params}`)
    .then((res) => callback(true, res.data))
    .catch((err) => callback(false, err));
};
export const getALawyerSubscription = (callback, id) => {
  AxiosInstanceWithXAuthToken()
    .get(`/api/v1/admins/lawyers/supscription/${id}`)
    .then((res) => callback(true, res.data))
    .catch((err) => callback(false, err));
};
