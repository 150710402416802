import MDAvatar from "components/MDAvatar";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import { useMaterialUIController, setSessionId, setOpenSessionDetailModal } from "context";
import { useState, useEffect } from "react";
import PropTypes from "prop-types";
import person from "assets/images/person.png";
// import MDButton from "components/MDButton";
import { Icon, Tooltip } from "@mui/material";
import MDButton from "components/MDButton";

export default function data() {
  const [controller, dispatch] = useMaterialUIController();
  const { sessionsData } = controller;
  const [currentList, setCurrentList] = useState([]);
  useEffect(() => {
    setCurrentList(sessionsData);
  }, [sessionsData]);

  const getFullName = (first, last) => {
    if (first === null && last === null) return "not Found";
    let returndText = "";
    if (first === null) returndText += "";
    else returndText += first;
    returndText += " ";
    if (last === null) returndText += "";
    else returndText += last;
    return returndText;
  };
  function Author({ image, name, email, id }) {
    return (
      <Tooltip title={`id: ${id}`}>
        <MDBox display="flex" alignItems="center" lineHeight={1}>
          <MDAvatar ml={2} src={image} name={name} size="sm" />
          <MDBox ml={2} lineHeight={1}>
            <MDTypography display="block" variant="button" fontWeight="medium">
              {name}
            </MDTypography>
            <MDTypography variant="caption">{email}</MDTypography>
          </MDBox>
        </MDBox>
      </Tooltip>
    );
  }
  Author.propTypes = {
    image: PropTypes.string,
    name: PropTypes.string,
    email: PropTypes.string,
    id: PropTypes.number,
  };
  Author.defaultProps = {
    image: "",
    name: "",
    email: "",
    id: "",
  };
  const getStatusColor = (value) => {
    switch (value) {
      case "accepted_by_client":
        return "#17d41a";
      case "pending":
        return "#bfd611";
      default:
        return "#000d07";
    }
  };
  function Category({ name }) {
    return (
      <MDBox display="flex" alignItems="center" lineHeight={1}>
        <MDBox ml={2} lineHeight={1}>
          <MDTypography display="block" variant="button" fontWeight="medium">
            {name}
          </MDTypography>
        </MDBox>
      </MDBox>
    );
  }
  Category.propTypes = {
    name: PropTypes.string,
  };
  Category.defaultProps = {
    name: "",
  };
  const getIsoString = (date) => {
    const currentDate = new Date(
      date.split("T")[0].split("-")[0],
      parseInt(date.split("T")[0].split("-")[1], 10) - 1,
      date.split("T")[0].split("-")[2],
      date.split("T")[1].split(".")[0].split(":")[0],
      date.split("T")[1].split(".")[0].split(":")[1],
      date.split("T")[1].split(".")[0].split(":")[2]
    );
    const tzoffset = new Date().getTimezoneOffset() * 60000; // offset in milliseconds
    const localISOTime = new Date(currentDate - tzoffset - tzoffset);
    return localISOTime.toISOString();
  };
  const getRows = () => {
    const personsInfo = currentList.map((value) => ({
      "session id": (
        <MDButton
          onClick={() => {
            setSessionId(dispatch, value.id);
            setOpenSessionDetailModal(dispatch, true);
          }}
          style={{
            minWidth: "50px",
            cursor: "pointer",
            color: "white",
            backgroundColor: "#add8e6",
          }}
          display="block"
          fontWeight="medium"
          variant="filled"
          color="info"
        >
          #{value.id}
        </MDButton>
      ),
      client: value.client ? (
        <Author
          image={value.client.avatars === null ? person : value.client.avatars.thumbnail}
          name={getFullName(value.client.first_name, value.client.last_name)}
          email={value.client.email}
          id={value.client.id}
        />
      ) : (
        <MDTypography component="span" variant="caption" color="text" fontWeight="medium">
          no client
        </MDTypography>
      ),
      status: (
        <MDBox ml={-1} color={getStatusColor(value.status)} variant="gradient" size="small">
          {value.status}
        </MDBox>
      ),
      lawyer: value.lawyer ? (
        <Author
          image={value.lawyer.avatars === null ? person : value.lawyer.avatars.thumbnail}
          name={getFullName(value.lawyer.first_name, value.lawyer.last_name)}
          email={value.lawyer.email}
          id={value.lawyer.id}
        />
      ) : (
        <MDTypography component="span" variant="caption" color="text" fontWeight="medium">
          Not found
        </MDTypography>
      ),
      category: value.category ? (
        <Category name={value.category.name} />
      ) : (
        <MDTypography component="span" variant="caption" fontWeight="medium">
          Category not selected
        </MDTypography>
      ),
      "start time": (
        <MDTypography component="span" variant="caption" color="text" fontWeight="medium">
          <div>{getIsoString(value.start).split("T")[0]}</div>
          <div>{getIsoString(value.start).split("T")[1].split(".")[0]}</div>
        </MDTypography>
      ),
      "end time": (
        <MDTypography component="span" variant="caption" color="text" fontWeight="medium">
          <div>{getIsoString(value.end).split("T")[0]}</div>
          <div>{getIsoString(value.end).split("T")[1].split(".")[0]}</div>
        </MDTypography>
      ),
      description: (
        <Tooltip title="tap on session id to view full text" placement="bottom">
          <MDTypography component="span" variant="caption" color="text" fontWeight="medium">
            <div className="whitespace-nowrap overflow-hidden w-[200px] text-ellipsis">
              {value.description || "without description"}
            </div>
          </MDTypography>
        </Tooltip>
      ),
      price: (
        <MDTypography component="span" variant="caption" color="text" fontWeight="medium">
          <div>{value.price}</div>
        </MDTypography>
      ),
      media: (
        <MDBox className="flex items-center justify-center" textAlign="center">
          {value.documents.length === 0 ? (
            <div>without media</div>
          ) : (
            <a href={value.documents[0].link} target="_blank" rel="noreferrer">
              <Icon fontSize="medium" className="text-[#3790ED] cursor-pointer">
                download
              </Icon>
            </a>
          )}
        </MDBox>
      ),
    }));
    return personsInfo;
  };
  return {
    columns: [
      { Header: "session id", accessor: "session id", align: "center" },
      { Header: "client", accessor: "client", align: "center" },
      { Header: "status", accessor: "status", align: "center" },
      { Header: "lawyer", accessor: "lawyer", align: "center" },
      { Header: "start time", accessor: "start time", align: "center" },
      { Header: "end time", accessor: "end time", align: "center" },
      { Header: "description", accessor: "description", align: "center" },
      { Header: "category", accessor: "category", align: "center" },
      { Header: "price", accessor: "price", align: "center" },
      { Header: "media", accessor: "media", align: "center" },
    ],

    rows: getRows(),
  };
}
