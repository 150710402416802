import Divider from "@mui/material/Divider";
import Icon from "@mui/material/Icon";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";

// Custom styles for the Configurator
import DrawerRightRoot from "examples/DrawerRight/DrawerRightRoot";

// Material Dashboard 2 React context
import {
  useMaterialUIController,
  setOpenDrawerRight,
  setCityInDrawerRight,
  setPostCodeInDrawerRight,
  setAddressInDrawerRight,
  setCircularProgress,
  setClientAddresses,
  setAlertText,
  setAlertOpen,
  setAlertColor,
  setBuildingInDrawerRight,
} from "context";
import MDInput from "components/MDInput";
import { createOrUpdateClientAddress, getClientAddresses } from "api/api_client";
import { useLocation } from "react-router-dom";
import { Autocomplete, TextField } from "@mui/material";
import { useEffect, useState } from "react";
import { getAllRegions } from "api/api_region";

function DrawerForEditClientAddress() {
  const [controller, dispatch] = useMaterialUIController();
  const [USRegionsListData, setUSRegionsListData] = useState([]);
  const [regionsId, setRegionsId] = useState([]);
  useEffect(() => {
    const list = [];
    const list2 = [];
    getAllRegions((isOk, data) => {
      if (!isOk) {
        console.log("get all US regions api is not working", data);
        setCircularProgress(dispatch, false);
      } else {
        for (let i = 0; i < data.data.length; i += 1) {
          list.push(data.data[i].regionName);
          list2.push(data.data[i]);
        }
        setUSRegionsListData(list);
        setRegionsId(list2);
        setCircularProgress(dispatch, false);
        setOpenDrawerRight(dispatch, true);
      }
    }, "?all=true");
  }, []);
  const {
    darkMode,
    openDrawerRight,
    cityInDrawerRight,
    postCodeInDrawerRight,
    addressInDrawerRight,
    buildingInDrawerRight,
  } = controller;
  const { pathname } = useLocation();
  const [localRegionShortName, setLocalRegionShortName] = useState("");
  const handleCloseDrawerRight = () => setOpenDrawerRight(dispatch, false);
  const updateClientAddressBtnClicked = () => {
    let localRegionShortNameId = 0;
    for (let i = 0; i < USRegionsListData.length; i += 1) {
      if (USRegionsListData[i] === localRegionShortName) {
        localRegionShortNameId = regionsId[i].id;
        break;
      }
    }
    const clientId = pathname.split("/")[3];
    const body = {
      city: cityInDrawerRight,
      region_id: localRegionShortNameId,
      post_code: postCodeInDrawerRight,
      address: addressInDrawerRight,
      building: buildingInDrawerRight,
    };
    setCircularProgress(dispatch, true);
    createOrUpdateClientAddress(
      (isOk, data) => {
        if (!isOk) {
          console.log("edit an client address is unsuccessful!", data);
          setCircularProgress(dispatch, false);
          setAlertText(dispatch, "edit an client address is unsuccessful");
          setAlertOpen(dispatch, true);
          setAlertColor(dispatch, "error");
        } else {
          console.log("Edit new Address is successful: ", data);
          setAlertText(dispatch, "Edit new Address is successful");
          setAlertOpen(dispatch, true);
          setAlertColor(dispatch, "success");
          getClientAddresses((isOk2, data2, status) => {
            if (!isOk2) {
              if (status === 404) {
                console.log("This user havent an address!", data2);
                setClientAddresses(dispatch, []);
                setCircularProgress(dispatch, false);
                setAlertText(dispatch, "This user havent an address!");
                setAlertOpen(dispatch, true);
                setAlertColor(dispatch, "error");
              } else {
                console.log("get client Addresses from api is not working!", data2);
                setCircularProgress(dispatch, false);
              }
            } else {
              setClientAddresses(dispatch, data2);
              setCircularProgress(dispatch, false);
            }
          }, clientId);
        }
      },
      body,
      clientId
    );
    setOpenDrawerRight(dispatch, false);
  };
  return (
    <DrawerRightRoot variant="permanent" ownerState={{ openDrawerRight }}>
      <MDBox
        display="flex"
        justifyContent="space-between"
        alignItems="baseline"
        pt={4}
        pb={0.5}
        px={3}
      >
        <MDBox>
          <MDTypography variant="h5">Admin Panel Editor</MDTypography>
          <MDTypography variant="body2" color="text">
            Edit a Client Address
          </MDTypography>
        </MDBox>

        <Icon
          sx={({ typography: { size }, palette: { dark, white } }) => ({
            fontSize: `${size.lg} !important`,
            color: darkMode ? white.main : dark.main,
            stroke: "currentColor",
            strokeWidth: "2px",
            cursor: "pointer",
            transform: "translateY(5px)",
          })}
          onClick={handleCloseDrawerRight}
        >
          close
        </Icon>
      </MDBox>
      <Divider />
      <MDBox component="form" role="form">
        <MDBox mb={2}>
          <MDInput
            value={cityInDrawerRight}
            onChange={(e) => setCityInDrawerRight(dispatch, e.target.value)}
            id="cityInput"
            type="text"
            label="city"
            fullWidth
          />
        </MDBox>
        <MDBox mb={2}>
          <MDInput
            value={postCodeInDrawerRight}
            onChange={(e) => setPostCodeInDrawerRight(dispatch, e.target.value)}
            id="postcodeInput"
            type="text"
            label="post code"
            fullWidth
          />
        </MDBox>
        <MDBox mb={2}>
          <Autocomplete
            disablePortal
            isOptionEqualToValue={(option, value) => option.regionName === value.regionName}
            value={localRegionShortName}
            onChange={(event, newValue) => {
              setLocalRegionShortName(newValue);
            }}
            options={USRegionsListData}
            sx={{ width: 300 }}
            renderInput={(params) => <TextField {...params} label="Region" />}
          />
        </MDBox>
        <MDBox mb={2}>
          <MDInput
            value={addressInDrawerRight}
            onChange={(e) => setAddressInDrawerRight(dispatch, e.target.value)}
            id="addressInput"
            type="text"
            label="address"
            fullWidth
          />
        </MDBox>
        <MDBox mb={2}>
          <MDInput
            value={buildingInDrawerRight}
            onChange={(e) => setBuildingInDrawerRight(dispatch, e.target.value)}
            id="buildingInput"
            type="text"
            label="swit number"
            fullWidth
          />
        </MDBox>
        <MDBox mt={4} mb={1}>
          <MDButton
            onClick={() => updateClientAddressBtnClicked()}
            variant="gradient"
            color="info"
            fullWidth
          >
            Update
          </MDButton>
        </MDBox>
      </MDBox>
    </DrawerRightRoot>
  );
}
export default DrawerForEditClientAddress;
