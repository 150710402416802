// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import DataTable from "examples/TablesLawyersLive/DataTable";
import {
  useMaterialUIController,
  setCircularProgress,
  setLawyerSessionsDetails,
  setTab,
} from "context";
import { useLocation, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { getOneLawyerSessions } from "api/sessionApi";
import { Icon, Pagination } from "@mui/material";
import MDButton from "components/MDButton";
import authorsTableData from "./data/authorsTableData";
import Modal from "../../sessions/all/modal";

function Tables() {
  const [controller, dispatch] = useMaterialUIController();
  const { columns, rows } = authorsTableData();
  const { pathname } = useLocation();
  const [paginationCount, setPaginationCount] = useState(1);
  const [lawyerSessionPage, setLawyerSessionPage] = useState(1);
  const { searchBarValueDetailSession, lawyerSessionDetails, lawyerSessionStatus } = controller;
  let id = pathname.split("/");
  id = id[id.length - 1];
  id = id.split("?");
  [id] = id;
  const navigate = useNavigate();
  useEffect(() => {
    setLawyerSessionPage(1);
  }, [searchBarValueDetailSession, lawyerSessionStatus]);
  useEffect(() => {
    let url = "";
    url += `/lawyers/detail/${id}`;
    url += `?page=${lawyerSessionPage}`;
    if (lawyerSessionStatus !== "") url += `&status=${lawyerSessionStatus}`;
    if (searchBarValueDetailSession !== "") url += `&search=${searchBarValueDetailSession}`;
    navigate(url);
  }, [searchBarValueDetailSession, lawyerSessionPage, lawyerSessionStatus]);

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const paramsObj = Array.from(params.keys()).reduce(
      (acc, val) => ({ ...acc, [val]: params.get(val) }),
      {}
    );
    if (!paramsObj.page) setLawyerSessionPage(1);
    else setLawyerSessionPage(parseInt(paramsObj.page, 10));
    let param = `?lawyer=${id}`;
    if (paramsObj.page !== "" && paramsObj.page) param += `&page=${paramsObj.page}`;
    if (paramsObj.search !== "" && paramsObj.search) param += `&search=${paramsObj.search}`;
    if (paramsObj.status !== "" && paramsObj.status) param += `&status=${paramsObj.status}`;
    setCircularProgress(dispatch, true);
    getOneLawyerSessions((isOk, data) => {
      if (!isOk) {
        console.log("get lawyers Sessions detail List from api is not working", data);
      } else {
        setLawyerSessionsDetails(dispatch, data.data);
        setPaginationCount(data.meta.last_page);
      }
      setCircularProgress(dispatch, false);
    }, param);
  }, [pathname, window.location.search]);

  const handlePaginationChange = (event, value) => {
    let url = "";
    url += `/lawyers/detail/${id}`;
    url += `?page=${value}`;
    // &status=accepted-by-client
    if (searchBarValueDetailSession !== "") url += `&search=${searchBarValueDetailSession}`;
    navigate(url);
  };
  useEffect(() => {
    setLawyerSessionPage(1);
  }, [searchBarValueDetailSession]);
  if (lawyerSessionDetails?.length === 0)
    return (
      <div className="flex flex-col mb-2 items-center h-[200px] justify-center">
        <div>
          <Icon fontSize="large">emoji_symbols</Icon>
        </div>
        <div className="font-bold text-[20px]">No Sessions were found for this lawyer </div>
        <MDButton
          onClick={() => setTab(dispatch, 1)}
          style={{ backgroundColor: "#0295E9", color: "white", marginTop: "20px" }}
        >
          Back To Oveview
        </MDButton>
      </div>
    );
  return (
    <>
      <Modal />
      <MDBox pb={3}>
        <Grid container>
          <Grid item xs={12}>
            <Card>
              <MDBox pt={3}>
                <DataTable
                  table={{ columns, rows }}
                  isSorted={false}
                  entriesPerPage={false}
                  showTotalEntries={false}
                  noEndBorder
                />
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>

      <MDBox style={{ display: "flex", justifyContent: "center" }}>
        <Pagination
          page={lawyerSessionPage}
          onChange={handlePaginationChange}
          count={paginationCount}
          color="info"
        />
      </MDBox>
    </>
  );
}

export default Tables;
