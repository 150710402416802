// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import DataTable from "examples/TablesLawyersPayment/DataTable";
import authorsTableData from "layouts/payments/clientsPaymentTable/data/authorsTableData";
import {
  useMaterialUIController,
  setCircularProgress,
  setClientPaymentList,
  setPeriodOfClientsPayments,
} from "context";
import { useLocation, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import "../../../styles/generalStyles.css";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { Pagination } from "@mui/material";
import { getAllClientsPayments } from "../../../api/api_payment";

function Tables() {
  const [controller, dispatch] = useMaterialUIController();
  const { searchBarValuePayment, periodOfClientsPayments } = controller;
  const [paginationCount, setPaginationCount] = useState(1);
  const [paymentsPage, setPaymentsPage] = useState(1);
  const navigate = useNavigate();
  const { columns, rows } = authorsTableData();
  const { pathname } = useLocation();
  useEffect(() => {
    let url = "/payments?clients";
    url += `&reference=${periodOfClientsPayments}`;
    if (searchBarValuePayment !== "") url += `&search=${searchBarValuePayment}`;
    navigate(url);
  }, [searchBarValuePayment, periodOfClientsPayments]);
  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const paramsObj = Array.from(params.keys()).reduce(
      (acc, val) => ({ ...acc, [val]: params.get(val) }),
      {}
    );
    let param = "";
    if (paramsObj.reference !== "" && paramsObj.reference)
      param += `?reference=${paramsObj.reference}`;
    if (paramsObj.search !== "" && paramsObj.search) param += `&search=${paramsObj.search}`;
    if (!paramsObj.page) param += `&page=1`;
    else param += `&page=${paramsObj.page}`;
    setCircularProgress(dispatch, true);
    getAllClientsPayments((isOk, data) => {
      if (!isOk) {
        console.log("get Clients Paymens List from api is not working", data);
      } else {
        setClientPaymentList(dispatch, data.data);
        setPaginationCount(data.meta.last_page);
      }
      setCircularProgress(dispatch, false);
    }, param);
  }, [pathname, window.location.search]);
  const handleChange = (event) => {
    setPeriodOfClientsPayments(dispatch, event.target.value);
  };

  const handlePaginationChange = (event, value) => {
    setPaymentsPage(value);
    const params = new URLSearchParams(window.location.search);
    const paramsObj = Array.from(params.keys()).reduce(
      (acc, val) => ({ ...acc, [val]: params.get(val) }),
      {}
    );
    let url = "/payments?clients";
    url += `&reference=${paramsObj.reference}`;
    url += `&page=${value}`;
    if (searchBarValuePayment !== "") url += `&search=${searchBarValuePayment}`;
    navigate(url);
  };
  useEffect(() => {
    setPaymentsPage(1);
  }, [searchBarValuePayment]);

  return (
    <MDBox pt={6} pb={3}>
      <Grid container spacing={6}>
        <Grid item xs={12}>
          <Card>
            <MDBox
              mx={2}
              mt={-3}
              py={3}
              px={2}
              variant="gradient"
              bgColor="info"
              borderRadius="lg"
              coloredShadow="info"
              style={{ display: "flex" }}
            >
              <MDTypography variant="h6" color="white">
                Clients Payment Table
              </MDTypography>
              <MDBox style={{ marginLeft: "auto" }}>
                <FormControl style={{ width: "80px" }}>
                  <InputLabel id="Period">Period</InputLabel>
                  <Select
                    variant="standard"
                    labelId="Period"
                    id="Period"
                    style={{
                      backgroundColor: "transparent",
                      color: "white",
                      marginTop: "10px",
                    }}
                    inputProps={{
                      textAlign: "center",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                    value={periodOfClientsPayments}
                    label="Period"
                    onChange={handleChange}
                  >
                    <MenuItem value="monthly">Monthly</MenuItem>
                    <MenuItem value="weekly">Weekly</MenuItem>
                    <MenuItem value="daily">Daily</MenuItem>
                  </Select>
                </FormControl>
              </MDBox>
            </MDBox>
            <MDBox pt={3}>
              <DataTable
                table={{ columns, rows }}
                isSorted={false}
                entriesPerPage={false}
                showTotalEntries={false}
                noEndBorder
              />
            </MDBox>
          </Card>
        </Grid>
      </Grid>
      <MDBox style={{ display: "flex", justifyContent: "center" }}>
        <Pagination
          page={paymentsPage}
          onChange={handlePaginationChange}
          count={paginationCount}
          color="info"
        />
      </MDBox>
    </MDBox>
  );
}

export default Tables;
