// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import MDBox from "components/MDBox";
import {
  useMaterialUIController,
  setCurrentLivesList,
  setOpenMoreInfoLiveModal,
  setLiveId,
  setCircularProgress,
} from "context";
import { useEffect, useState } from "react";
import MDTypography from "components/MDTypography";
import { FormControl, Icon, InputLabel, MenuItem, Pagination, Select } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import { getAllLives } from "api/api_lives";
import { getAllRegions } from "api/api_region";
import { getAllCategories } from "api/api_category";
import Modal from "./modal";
import socket from "./socket";

function Tables() {
  const [controller, dispatch] = useMaterialUIController();
  const { currentLiveList } = controller;
  const [modalMode, setModalMode] = useState(0);
  const [paginationCount, setPaginationCount] = useState(1);
  const [currenrLivesPage, setCurrenrLivesPage] = useState(1);
  const [socketIsChanged, setSocketIsChanged] = useState(false);
  const { searchBarValueLive } = controller;
  const [categoryId, setCategoryId] = useState("");
  const [stateId, setStateId] = useState("");
  const [categoryName, setCategoryName] = useState("");
  const [stateName, setStateName] = useState("");
  const [regionsList, setRegionsList] = useState([]);
  const [categoriesList, setCategoriesList] = useState([]);
  const { pathname } = useLocation();
  const navigate = useNavigate();
  useEffect(() => {
    if (stateId !== "") {
      setStateName(regionsList.filter((value) => value.id === parseInt(stateId, 10))[0].regionName);
    }
  }, [stateId]);
  useEffect(() => {
    if (categoryId !== "") {
      setCategoryName(
        categoriesList.filter((value) => value.id === parseInt(categoryId, 10))[0].name
      );
    }
  }, [categoryId]);
  useEffect(() => {
    socket.connect();
  }, []);
  useEffect(() => {
    socket.on("connect", () => {
      console.log("socket is Connected");
    });
    socket.on("disconnect", () => {
      console.log("socket is Disconnected");
    });
    socket.on("notification", () => {
      setSocketIsChanged((prev) => !prev);
    });
    return () => {
      socket.off("connect", () => {
        console.log("OFF Connect");
      });
      socket.off("disconnect", () => {
        console.log("OFF Disconnect");
      });
      socket.off("notification", () => {
        console.log("OFF Notification");
      });
    };
  }, []);
  useEffect(() => {
    getAllRegions((isOk, data) => {
      if (!isOk) {
        console.log("get regions data from api is not working", data);
      } else {
        setRegionsList(data.data);
      }
      setCircularProgress(dispatch, false);
    }, "?all=true");
    getAllCategories((isOk, data) => {
      if (!isOk) {
        console.log("get categories data from api is not working", data);
      } else {
        setCategoriesList(data.data);
      }
      setCircularProgress(dispatch, false);
    }, "?all=true");
  }, []);
  useEffect(() => {
    let url = "/lives?current";
    url += `&page=${currenrLivesPage}`;
    if (searchBarValueLive !== "") url += `&search=${searchBarValueLive}`;
    navigate(url);
  }, [searchBarValueLive, currenrLivesPage]);
  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const paramsObj = Array.from(params.keys()).reduce(
      (acc, val) => ({ ...acc, [val]: params.get(val) }),
      {}
    );
    if (!paramsObj.page) setCurrenrLivesPage(1);
    else setCurrenrLivesPage(parseInt(paramsObj.page, 10));
    let param = "";
    param += `?status=active&page=${paramsObj.page}`;
    if (stateId !== "") param += `&region=${stateId}`;
    if (categoryId !== "") param += `&category=${categoryId}`;
    if (paramsObj.search !== "" && paramsObj.search) param += `&search=${paramsObj.search}`;
    setCircularProgress(dispatch, true);
    getAllLives((isOk, data) => {
      if (!isOk) {
        console.log("get Currenrs Lives List from api is not working", data);
        setCircularProgress(dispatch, false);
      } else {
        setCurrentLivesList(dispatch, data.data);
        setPaginationCount(data.meta.last_page);
        setCircularProgress(dispatch, false);
      }
    }, param);
  }, [pathname, window.location.search, socketIsChanged, categoryId, stateId]);

  const handlePaginationChange = (event, value) => {
    let url = "";
    url += "/lives?current";
    url += `&page=${value}`;
    if (searchBarValueLive !== "") url += `&search=${searchBarValueLive}`;
    navigate(url);
  };
  const getIsoString = (date) => {
    const currentDate = new Date(
      date.split("T")[0].split("-")[0],
      parseInt(date.split("T")[0].split("-")[1], 10) - 1,
      date.split("T")[0].split("-")[2],
      date.split("T")[1].split(".")[0].split(":")[0],
      date.split("T")[1].split(".")[0].split(":")[1],
      date.split("T")[1].split(".")[0].split(":")[2]
    );
    const tzoffset = new Date().getTimezoneOffset() * 60000; // offset in milliseconds
    const localISOTime = new Date(currentDate - tzoffset - tzoffset);
    return localISOTime.toISOString();
  };
  return (
    <>
      <Modal modalMode={modalMode} setModalMode={setModalMode} />
      <MDBox pt={6} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-around",
                }}
              >
                <MDBox sx={{ minWidth: 120, color: "white" }} style={{ color: "white" }}>
                  <FormControl fullWidth style={{ color: "white" }} sx={{ color: "white" }}>
                    <InputLabel
                      id="category-label"
                      style={{ color: "white" }}
                      sx={{ color: "white" }}
                    >
                      Category
                    </InputLabel>
                    <Select
                      labelId="category-label"
                      id="category"
                      value={categoryId}
                      label="Category"
                      sx={{ height: 40, color: "white" }}
                      style={{ color: "white" }}
                      onChange={(event) => setCategoryId(event.target.value)}
                    >
                      {categoriesList.map((value) => (
                        <MenuItem
                          style={{ color: "black" }}
                          sx={{ color: "black" }}
                          value={value.id}
                        >
                          {value.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </MDBox>
                <MDTypography
                  variant="h6"
                  color="white"
                  className="flex items-center justify-center text-center"
                >
                  {categoryName === "" && stateName === "" && "All"} Current Lives{" "}
                  {(categoryName || stateName) && "in "}
                  {categoryName !== "" && `${categoryName} category `}
                  {categoryName && stateName && "and in"}{" "}
                  {stateName !== "" && `${stateName} region`}
                </MDTypography>
                <MDBox sx={{ minWidth: 120, color: "white" }} style={{ color: "white" }}>
                  <FormControl fullWidth style={{ color: "white" }} sx={{ color: "white" }}>
                    <InputLabel id="state-label" style={{ color: "white" }} sx={{ color: "white" }}>
                      State
                    </InputLabel>
                    <Select
                      labelId="state-label"
                      id="state"
                      value={stateId}
                      label="State"
                      sx={{ height: 40, color: "white" }}
                      style={{ color: "white" }}
                      onChange={(event) => setStateId(event.target.value)}
                    >
                      {regionsList.map((value) => (
                        <MenuItem
                          key={value.id}
                          style={{ color: "black" }}
                          sx={{ color: "black" }}
                          value={value.id}
                        >
                          {value.regionName}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </MDBox>
              </MDBox>
              <MDBox pt={3}>
                {currentLiveList.length === 0 ? (
                  <div className="flex items-center justify-center pb-5">
                    There are currently no live streams in progress
                  </div>
                ) : (
                  <Grid container>
                    {currentLiveList.map((value) => (
                      <Grid xs={6} className="px-2 py-4">
                        <div className="border border-[#C5E0FF] rounded-lg">
                          <div>
                            <Grid container className="border-b border-[#C5E0FF] h-[200px]">
                              <Grid xs={4} className="border-r border-[#C5E0FF] h-[100%] py-2">
                                <div className="flex flex-col h-[100%] items-center">
                                  <div className="text-[50px]">
                                    <Icon style={{ color: "#0295E9" }}>video_camera_front</Icon>
                                  </div>
                                  <div className="text-[12px] text-[#C5E0FF]">Client:</div>
                                  {value?.owner?.avatar !== null && (
                                    <div>
                                      <img
                                        className="w-[30px] h-[30px] rounded-full"
                                        src={value?.owner?.avatar?.thumbnail}
                                        alt="client_image"
                                      />
                                    </div>
                                  )}
                                  <div className="text-[18px] font-bold">
                                    {value.owner.first_name}
                                  </div>
                                  <div className="text-[16px] font-bold">
                                    {value.owner.last_name}
                                  </div>
                                </div>
                              </Grid>
                              <Grid xs={4} className="border-r border-[#C5E0FF] h-[100%] py-2">
                                <div className="flex flex-col h-[100%] items-center">
                                  <div className="text-[13px] mt-2 text-[#C5E0FF]">Category:</div>
                                  <div className="text-[16px] font-bold text-center">
                                    {value?.category?.name}
                                  </div>
                                  <div className="text-[50px] mt-auto mb-2">
                                    <Icon style={{ color: "#0295E9" }}>category</Icon>
                                  </div>
                                </div>
                              </Grid>
                              <Grid xs={4} className="h-[100%] py-2">
                                <div className="flex flex-col h-[100%] items-center">
                                  <div className="text-[50px]">
                                    <Icon style={{ color: "#0295E9" }}>access_time_filled</Icon>
                                  </div>
                                  <div className="text-[12px] text-[#C5E0FF]">Date:</div>
                                  <div className="text-[16px] font-bold">
                                    {getIsoString(value?.created_at).split("T")[0]}
                                  </div>
                                  <div className="text-[10px] mt-2 text-[#C5E0FF]">Time:</div>
                                  <div className="text-[14px] font-bold">
                                    {getIsoString(value?.created_at).split("T")[1].split(".")[0]}
                                  </div>
                                </div>
                              </Grid>
                            </Grid>
                          </div>
                          <Grid container>
                            <Grid
                              xs={6}
                              className="flex items-center border-r border-[#C5E0FF] justify-center"
                            >
                              <div
                                className="text-[20px] flex cursor-pointer"
                                role="presentation"
                                onClick={() => {
                                  setLiveId(dispatch, value.id);
                                  setOpenMoreInfoLiveModal(dispatch, true);
                                  setModalMode(0);
                                }}
                              >
                                <Icon>info</Icon>
                              </div>
                              <div
                                className="underline text-[16px] cursor-pointer"
                                role="presentation"
                                onClick={() => {
                                  setLiveId(dispatch, value.id);
                                  setOpenMoreInfoLiveModal(dispatch, true);
                                  setModalMode(0);
                                }}
                              >
                                More
                              </div>
                            </Grid>
                            <Grid xs={6} className="flex items-center justify-center">
                              <div
                                role="presentation"
                                onClick={() => {
                                  setLiveId(dispatch, value.id);
                                  setOpenMoreInfoLiveModal(dispatch, true);
                                  setModalMode(1);
                                }}
                                className="text-[25px] flex cursor-pointer"
                              >
                                <Icon>play_arrow</Icon>
                              </div>
                              <div
                                role="presentation"
                                onClick={() => {
                                  setLiveId(dispatch, value.id);
                                  setOpenMoreInfoLiveModal(dispatch, true);
                                  setModalMode(1);
                                }}
                                className="underline text-[16px] cursor-pointer"
                              >
                                Show
                              </div>
                            </Grid>
                          </Grid>
                        </div>
                      </Grid>
                    ))}
                  </Grid>
                )}
              </MDBox>
            </Card>
          </Grid>
        </Grid>
        <MDBox style={{ display: "flex", justifyContent: "center" }}>
          <Pagination
            page={currenrLivesPage}
            onChange={handlePaginationChange}
            count={paginationCount}
            color="info"
          />
        </MDBox>
      </MDBox>
    </>
  );
}

export default Tables;
