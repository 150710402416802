/** 
  All of the routes for the Material Dashboard 2 React are added here,
  You can add a new route, customize the routes and delete the routes here.

  Once you add a new route on this file it will be visible automatically on
  the Sidenav.

  For adding a new route you can follow the existing routes in the routes array.
  1. The `type` key with the `collapse` value is used for a route.
  2. The `type` key with the `title` value is used for a title inside the Sidenav. 
  3. The `type` key with the `divider` value is used for a divider between Sidenav items.
  4. The `name` key is used for the name of the route on the Sidenav.
  5. The `key` key is used for the key of the route (It will help you with the key prop inside a loop).
  6. The `icon` key is used for the icon of the route on the Sidenav, you have to add a node.
  7. The `collapse` key is used for making a collapsible item on the Sidenav that has other routes
  inside (nested routes), you need to pass the nested routes inside an array as a value for the `collapse` key.
  8. The `route` key is used to store the route location which is used for the react router.
  9. The `href` key is used to store the external links location.
  10. The `title` key is only for the item with the type of `title` and its used for the title text on the Sidenav.
  10. The `component` key is used to store the component of its route.
*/

// Material Dashboard 2 React layouts
import Dashboard from "layouts/dashboard";
import LawyerTables from "layouts/lawyerTable";
import ClientTables from "layouts/clientTable";
import AdminTables from "layouts/adminTable";
import VersionsTables from "layouts/versionsTables";
import Profile from "layouts/profile";
import Payments from "layouts/payments";
import Transfers from "layouts/transfers";
import Transactions from "layouts/transactions";
import Subscriptions from "layouts/subscriptions";
import Sessions from "layouts/sessions";
import Lives from "layouts/lives";
import Cases from "layouts/cases";
import Regions from "layouts/regions";
import Faq from "layouts/faq";
import Category from "layouts/category";
import SignIn from "layouts/authentication/sign-in";
import VerifyNumber from "layouts/authentication/verify-number";
import LawyerDetailes from "layouts/lawyerDetails";
import ClientDetailes from "layouts/clientDetails";
import ContactUs from "layouts/messages";

// @mui icons
import Icon from "@mui/material/Icon";
// import PlatformSettings from "layouts/profile/components/PlatformSettings";
// import { createSearchParams } from "react-router-dom";

const routes = [
  {
    type: "collapse",
    name: "Dashboard",
    key: "dashboard",
    icon: <Icon fontSize="small">dashboard</Icon>,
    route: "/dashboard",
    component: <Dashboard />,
    className: "dashboardJoyRide",
  },
  {
    type: "collapse",
    name: "Lawyers",
    key: "lawyers",
    icon: <Icon fontSize="small">account_balance</Icon>,
    route: "/lawyers",
    component: <LawyerTables />,
    className: "lawyersJoyRide",
  },
  {
    type: "collapse",
    name: "Clients",
    key: "clients",
    icon: <Icon fontSize="small">group</Icon>,
    route: "/clients",
    component: <ClientTables />,
    className: "clientJoyRide",
  },
  {
    type: "collapse",
    name: "Lives",
    key: "lives",
    icon: <Icon fontSize="small">play_arrow</Icon>,
    route: "/lives",
    component: <Lives />,
    className: "livesJoyRide",
  },
  {
    type: "collapse",
    name: "Per Cases",
    key: "cases",
    icon: <Icon fontSize="small">cases</Icon>,
    route: "/cases",
    component: <Cases />,
    className: "casesJoyRide",
  },
  {
    type: "collapse",
    name: "Payments",
    key: "payments",
    icon: <Icon fontSize="small">payment</Icon>,
    route: "/payments",
    component: <Payments />,
    className: "paymentsJoyRide",
  },
  {
    type: "collapse",
    name: "Transfers",
    key: "transfers",
    icon: <Icon fontSize="small">price_check</Icon>,
    route: "/transfers",
    component: <Transfers />,
    className: "transfersJoyRide",
  },
  {
    type: "collapse",
    name: "Transactions",
    key: "transactions",
    icon: <Icon fontSize="small">currency_exchange</Icon>,
    route: "/transactions",
    component: <Transactions />,
    className: "transactionsJoyRide",
  },
  {
    type: "collapse",
    name: "Subscriptions",
    key: "subscriptions",
    icon: <Icon fontSize="small">subscriptions</Icon>,
    route: "/subscriptions",
    component: <Subscriptions />,
    className: "subscriptionsJoyRide",
  },
  {
    type: "collapse",
    name: "Sessions",
    key: "sessions",
    icon: <Icon fontSize="small">dialer_sip</Icon>,
    route: "/sessions",
    component: <Sessions />,
    className: "sessionsJoyRide",
  },
  {
    type: "collapse",
    name: "Contact us",
    key: "contactUs",
    icon: <Icon fontSize="small">message</Icon>,
    route: "/contactUs",
    component: <ContactUs />,
    className: "contactUsJoyRide",
  },
  {
    type: "title",
    name: "Settings",
    key: "settings",
    title: "settings",
    icon: <Icon fontSize="small">settings</Icon>,
  },
  {
    type: "collapse",
    name: "Categories",
    key: "categories",
    icon: <Icon fontSize="small">category</Icon>,
    route: "/categories",
    component: <Category />,
    className: "categoriesJoyRide",
  },
  {
    type: "collapse",
    name: "Regions",
    key: "regions",
    icon: <Icon fontSize="small">place</Icon>,
    route: "/regions",
    component: <Regions />,
    className: "regionsJoyRide",
  },
  {
    type: "collapse",
    name: "Faq",
    key: "faq",
    icon: <Icon fontSize="small">psychology_alt_icon</Icon>,
    route: "/faq",
    component: <Faq />,
    className: "faqJoyRide",
  },
  {
    type: "collapse",
    name: "Admins",
    key: "admins",
    icon: <Icon fontSize="small">account_box</Icon>,
    route: "/admins",
    component: <AdminTables />,
    className: "adminsJoyRide",
  },
  {
    type: "collapse",
    name: "Versions",
    key: "versions",
    icon: <Icon fontSize="small">update</Icon>,
    route: "/versions",
    component: <VersionsTables />,
    className: "versionsJoyRide",
  },
  {
    type: "collapse",
    name: "Profile",
    key: "profile",
    icon: <Icon fontSize="small">person</Icon>,
    route: "/profile",
    component: <Profile />,
    className: "profileJoyRide",
  },
  {
    type: "collapse",
    name: "Number Verification",
    key: "number-verification",
    icon: <Icon fontSize="small">login</Icon>,
    route: "/authentication/verify-number",
    component: <VerifyNumber />,
    className: "verifyNumberJoyRide",
  },
  {
    type: "collapse",
    name: "Sign In",
    key: "sign-in",
    icon: <Icon fontSize="small">login</Icon>,
    route: "/authentication/sign-in",
    component: <SignIn />,
  },
  {
    type: "collapse",
    name: "Logout",
    key: "Logout",
    icon: <Icon fontSize="small">logout</Icon>,
    route: "/authentication/verify-number",
    component: <VerifyNumber />,
    className: "logoutJoyRide",
  },
  {
    type: "collapse",
    name: "noSideNav",
    key: "noSideNav",
    icon: <Icon fontSize="small">assignment</Icon>,
    route: "/lawyers/detail/:id",
    component: <LawyerDetailes />,
  },
  {
    type: "collapse",
    name: "noSideNav",
    key: "noSideNav",
    icon: <Icon fontSize="small">assignment</Icon>,
    route: "/clients/detail/:id",
    component: <ClientDetailes />,
  },
];

export default routes;
