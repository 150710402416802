import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import PropTypes from "prop-types";
import React from "react";
import MDButton from "components/MDButton";
import { setAlertColor, setAlertText, setAlertOpen, useMaterialUIController } from "context";
import { postAppVersion } from "api/api_admin";

function Modal({
  openDialog,
  setOpenDialog,
  setAddedNewVersion,
  setLoading,
  setAnchorEl,
  minVersion,
  currentVersion,
  choosenPlatform,
  userType,
  setCurrentVersion,
  setMinVersion,
  setAddNewVersion,
}) {
  const [controller, dispatch] = useMaterialUIController();
  if (false) console.log(controller);
  const AddNewVersion = () => {
    setOpenDialog(false);
    const body = {
      min_version: minVersion,
      current_version: currentVersion,
      platform: choosenPlatform,
      type: userType,
    };

    setLoading(true);
    postAppVersion((isOk, data) => {
      if (!isOk) {
        console.log("create an new version is unsuccessful!", data);
        setAlertText(dispatch, "Current version is not added!");
        setAlertOpen(dispatch, true);
        setAlertColor(dispatch, "error");
        setLoading(false);
      } else {
        console.log("create an new version is successful!", data);
        setAlertText(dispatch, "Current version is added!");
        setAlertOpen(dispatch, true);
        setAlertColor(dispatch, "success");
        setAddedNewVersion((prev) => !prev);
        setLoading(false);
        setCurrentVersion("");
        setMinVersion("");
        setAddNewVersion(false);
      }
    }, body);
  };
  return (
    <Dialog
      open={openDialog}
      onClose={() => setOpenDialog(false)}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">Confirm Add New App Version</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          <div className="text-center">
            You are adding a new version to {userType} {choosenPlatform} app
          </div>
          <div className="text-center text-[20px]">Are you sure?</div>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <MDButton
          variant="outlined"
          color="info"
          onClick={() => {
            setOpenDialog(false);
            setAnchorEl(null);
          }}
        >
          NO
        </MDButton>
        <MDButton variant="contained" color="info" onClick={() => AddNewVersion()} autoFocus>
          YES
        </MDButton>
      </DialogActions>
    </Dialog>
  );
}
Modal.propTypes = {
  openDialog: PropTypes.bool,
  minVersion: PropTypes.string,
  currentVersion: PropTypes.string,
  choosenPlatform: PropTypes.string,
  userType: PropTypes.string,
  setOpenDialog: PropTypes.func,
  setAnchorEl: PropTypes.func,
  setLoading: PropTypes.func,
  setAddedNewVersion: PropTypes.func,
  setCurrentVersion: PropTypes.func,
  setMinVersion: PropTypes.func,
  setAddNewVersion: PropTypes.func,
};
Modal.defaultProps = {
  openDialog: false,
  minVersion: "",
  currentVersion: "",
  choosenPlatform: "",
  userType: "",
  setOpenDialog: () => {},
  setAnchorEl: () => {},
  setLoading: () => {},
  setAddedNewVersion: () => {},
  setCurrentVersion: () => {},
  setMinVersion: () => {},
  setAddNewVersion: () => {},
};
export default Modal;
