/* eslint-disable react/no-array-index-key */
/* eslint-disable react/prop-types */
// import MDBox from "components/MDBox";
import React, { useEffect, useState } from "react";
import MDButton from "components/MDButton";
import { Grid, Icon, Tooltip } from "@mui/material";
import {
  setODR,
  useMaterialUIController,
  setOpenDrawerRight,
  setCurrentLawyerStatusId,
  setCurrentCertificateStatusId,
  setCurrentCertificateStatus,
  setCircularProgress,
} from "context";
import { getAllLawyerCertificates } from "api/api_lawyer";

function Certificate({ id }) {
  const [controller, dispatch] = useMaterialUIController();
  const { lawyerCertificates } = controller;
  const [currentTable, setCurrentTable] = useState([]);
  useEffect(() => {
    setCurrentTable(lawyerCertificates);
  }, [lawyerCertificates]);
  useEffect(() => {
    setCircularProgress(dispatch, true);
    getAllLawyerCertificates((isOk, data) => {
      if (!isOk) {
        console.log("get lawyer Certificates from api is not working!");
        setCircularProgress(dispatch, false);
      } else {
        setCurrentTable(data.data);
        setCircularProgress(dispatch, false);
      }
    }, id);
  }, []);
  const getStatusBg = (value) => {
    switch (value) {
      case "accepted":
        return "bg-[#17d41a]";
      case "denied":
        return "bg-[#780b01]";
      case "pending":
        return "bg-[#bfd611]";
      case "pending_office":
        return "bg-[#634c01]";
      default:
        return "bg-[#000d07]";
    }
  };
  return (
    <Grid container className="flex p-2 items-center justify-center">
      {currentTable.length > 0 &&
        currentTable.map((value) => (
          <Grid key={value.id} xs={6} className="px-2 w-[100%] my-2">
            <div className="flex w-[100%] min-h-[150px] items-center border rounded-lg">
              <div className="bg-[#0295E9] text-white w-[40%] px-2 min-h-[150px] rounded-l-lg flex flex-col justify-center">
                <div className="flex justify-center">
                  <div className="text-[#C5E0FF] text-[12px] flex items-center">
                    NUMBER:&nbsp;&nbsp;
                  </div>
                  <div className="border-b border-dashed grow flex mb-[10px]" />
                  <div className="text-white text-[13px]  flex items-center">{value.number}</div>
                </div>
                <div className="flex justify-center">
                  <div className="text-[#C5E0FF] text-[12px] flex items-center">
                    REGION:&nbsp;&nbsp;
                  </div>
                  <div className="border-b border-dashed grow flex mb-[10px]" />
                  <div className="text-white text-[13px]  flex items-center">{value.area}</div>
                </div>
                <div className="flex justify-center mt-2">
                  {value.media && (
                    <MDButton
                      disabled={!value.media}
                      size="small"
                      variant="contained"
                      color="#0295E9"
                      backgroundColor="white"
                      style={{ fontSize: "12px" }}
                    >
                      <a
                        style={{ color: "#0295E9", fontSize: "12px" }}
                        target="_blank"
                        href={value.media ? value.media.thumbnail : ""}
                        rel="noreferrer"
                      >
                        Download Media
                      </a>
                    </MDButton>
                  )}
                </div>
              </div>
              <div className="bg-white text-[#0295E9] w-[60%] min-h-[150px] rounded-r-lg flex flex-col justify-around">
                <div className="flex justify-between px-2 items-center">
                  <div>
                    <div className="text-[12px]">#{value.id}</div>
                  </div>
                  <div className="flex text-[16px] font-bold">
                    <div>{value.title !== null ? value.title : "No Title"}</div>
                    <Tooltip title={value.status}>
                      <div
                        className={`h-[7px] w-[7px] ml-[5px] rounded-full ${getStatusBg(
                          value.status
                        )}`}
                      />
                    </Tooltip>
                  </div>
                  <Tooltip
                    className="cursor-pointer"
                    title={`from: ${value.created_at.split("T")[0]}`}
                  >
                    <Icon>workspace_premium</Icon>
                  </Tooltip>
                </div>
                <div className="flex px-2 items-center">
                  {value.category ? (
                    <>
                      <div className="text-[#C5E0FF] text-[12px]">Category:&nbsp;&nbsp;</div>
                      <div className="text-[#0295E9] text-[14px] font-bold animate-pulse">
                        {value?.category?.name}
                      </div>
                    </>
                  ) : (
                    <div className="text-[16px] text-red-500">Deleted Category</div>
                  )}
                </div>
                <div className="flex px-2 items-center">
                  {value.offices.length !== 0 || !value.offices ? (
                    <>
                      <div className="text-[#C5E0FF] text-[12px]">Offices:&nbsp;&nbsp;</div>
                      <div className="text-[#0295E9] text-[14px] flex">
                        {value.offices.map((val) => (
                          <div key={val.id}>{val.name}&nbsp;</div>
                        ))}
                      </div>
                    </>
                  ) : (
                    <div className="text-[16px] text-[#0295E9]">No Office</div>
                  )}
                </div>
                <div className="flex px-2 items-center justify-end">
                  <div
                    role="presentation"
                    onClick={() => {
                      setCurrentLawyerStatusId(dispatch, id);
                      setCurrentCertificateStatusId(dispatch, value.id);
                      setCurrentCertificateStatus(dispatch, value.status);
                      setODR(dispatch, "editCertificateLawyer");
                      setTimeout(() => {
                        setOpenDrawerRight(dispatch, true);
                      }, 1);
                    }}
                    className="bg-[#0295E9] text-white text-[12px] py-1 cursor-pointer px-4 rounded-md"
                  >
                    Change status
                  </div>
                </div>
              </div>
            </div>
          </Grid>
        ))}
    </Grid>
  );
}

export default Certificate;
