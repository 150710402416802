import { Dialog, DialogContent, DialogContentText, DialogTitle, Grid, Icon } from "@mui/material";
import { useMaterialUIController, setOpenDetailMessageModal } from "context";
import React, { useEffect, useState } from "react";
import { getAMessageDetail, getAMessageReplies, postReplyForMessage } from "api/api_messages";

function Modal() {
  const [controller, dispatch] = useMaterialUIController();
  const { openDetailMessageModal, messageId } = controller;
  const [messageDetail, setMessageDetail] = useState({});
  const [messageReplies, setMessageReplies] = useState([]);
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    if (messageId !== -1) {
      setLoading(true);
      getAMessageDetail((isOk, data) => {
        if (!isOk) {
          console.log("get Message detail List from api is not working", data);
          setLoading(false);
        } else {
          setMessageDetail(data);
        }
      }, messageId);
      setLoading(true);
      getAMessageReplies((isOk, data) => {
        if (!isOk) {
          console.log("get Message replies List from api is not working", data);
          setLoading(false);
        } else {
          setMessageReplies(data.data);
          setLoading(false);
        }
      }, messageId);
    }
  }, [messageId, openDetailMessageModal]);
  const handleClose = () => {
    setOpenDetailMessageModal(dispatch, false);
    setMessageReplies([]);
    setLoading(true);
  };
  const sendAnReply = () => {
    const body = {
      content: document.getElementById("replyMessageId").value,
      contactus_id: messageId,
    };
    if (body.content === "") {
      alert("The message field must not be empty");
    } else {
      setLoading(true);
      postReplyForMessage((isOk, data) => {
        if (!isOk) {
          console.log("send reply message is not working", data);
        } else {
          getAMessageReplies((isOk2, data2) => {
            if (!isOk2) {
              console.log("get Message replies List from api is not working", data2);
              setLoading(false);
            } else {
              setMessageReplies(data2.data);
              setLoading(false);
            }
          }, messageId);
        }
      }, body);
    }
  };
  const getSendDateText = (date) => {
    let now = new Date();
    now = now.toISOString();
    const year = parseInt(date.split("T")[0].split("-")[0], 10);
    const month = parseInt(date.split("T")[0].split("-")[1], 10);
    const day = parseInt(date.split("T")[0].split("-")[2], 10);
    const hour = parseInt(date.split("T")[1].split(".")[0].split(":")[0], 10);
    const minute = parseInt(date.split("T")[1].split(".")[0].split(":")[1], 10);
    const yearNow = parseInt(now.split("T")[0].split("-")[0], 10);
    const monthNow = parseInt(now.split("T")[0].split("-")[1], 10);
    const dayNow = parseInt(now.split("T")[0].split("-")[2], 10);
    const hourNow = parseInt(now.split("T")[1].split(".")[0].split(":")[0], 10);
    const minuteNow = parseInt(now.split("T")[1].split(".")[0].split(":")[1], 10);
    if (year === yearNow && month === monthNow && day === dayNow) {
      if (hourNow === hour && minuteNow === minute) return "just now";
      const sumMin = hourNow * 60 + minuteNow - (hour * 60 + minute);
      const h = Math.floor(sumMin / 60);
      const m = sumMin % 60;
      if (h === 0) return `${m} minutes ago`;
      return `${h}:${m} ago`;
    }
    const date1 = new Date(year, month, day);
    const date2 = new Date(yearNow, monthNow, dayNow);
    const days = Math.floor((date2 - date1) / 86400000);
    return `${days} days ago`;
  };
  return (
    <div>
      <Dialog
        open={openDetailMessageModal}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle>
          {loading ? (
            <div className="flex w-[500px]">Loading...</div>
          ) : (
            <div className="flex w-[500px]">
              <div>DETAIL OF MESSAGE</div>
              <div className="ml-auto">
                <Icon className="cursor-pointer" onClick={handleClose}>
                  close
                </Icon>
              </div>
            </div>
          )}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            {loading || Object.keys(messageDetail).lenght === 0 ? (
              <div>Loading...</div>
            ) : (
              <div className="flex flex-col w-[500px]">
                {messageReplies.length === 0 ? (
                  <div className="flex items-center justify-center mb-3">Not Answerd Yet</div>
                ) : (
                  <>
                    <div className="pb-4">
                      <div className="text-[18px] text-black">
                        <span className="font-bold">{messageDetail.name}&apos;s </span> Message:
                      </div>
                      <div className="text-[14px] px-2 border rounded">
                        <div className="border-b text-black font-bold py-1">
                          {messageDetail.description}
                        </div>
                        <div className="flex">
                          <div className="text-[10px]">
                            Send at: {messageDetail.created_at.split("T")[0]}{" "}
                            {messageDetail.created_at.split("T")[1].split(".")[0]}
                          </div>
                          <div className="text-[10px] ml-auto">Email:{messageDetail.email}</div>
                        </div>
                      </div>
                    </div>
                    <div className="mt-2 text-black font-bold">Replies: </div>
                    {messageReplies.map((value) => (
                      <Grid container key={value.id} className="px-2 py-1 border-b my-1 rounded-md">
                        <Grid
                          xs={3}
                          item
                          className="text-[12px] font-bold text-black text-ellipsis whitespace-nowrap overflow-hidden"
                        >
                          {value.admin.first_name} {value.admin.last_name}:
                        </Grid>
                        <Grid xs={6} item className="text-[12px] font-bold text-black">
                          <div>{value.content}</div>
                        </Grid>
                        <Grid
                          xs={3}
                          item
                          className="text-[12px] font-bold text-black text-ellipsis whitespace-nowrap overflow-hidden"
                        >
                          {getSendDateText(value.created_at)}
                        </Grid>
                      </Grid>
                    ))}
                  </>
                )}
                <div>
                  <textarea
                    id="replyMessageId"
                    placeholder="write a reply message..."
                    className="rounded-md border text-black mt-4 w-[100%] outline-none h-[120px] px-3"
                  />
                </div>
                <div className="flex items-center justify-end">
                  <div
                    role="presentation"
                    onClick={() => sendAnReply()}
                    className="px-3 bg-[#0295E9] cursor-pointer rounded text-white "
                  >
                    Send
                  </div>
                </div>
              </div>
            )}
          </DialogContentText>
        </DialogContent>
      </Dialog>
    </div>
  );
}

export default Modal;
