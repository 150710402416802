import { AxiosInstanceWithXAuthToken } from "./api";

export const getAllLawyersPayments = (callback, params = "") => {
  AxiosInstanceWithXAuthToken()
    .get(`/api/v1/admins/lawyers/payments${params}`)
    .then((res) => callback(true, res.data))
    .catch((err) => callback(false, err));
};
export const getAllClientsPayments = (callback, params = "") => {
  AxiosInstanceWithXAuthToken()
    .get(`/api/v1/admins/clients/payments${params}`)
    .then((res) => callback(true, res.data))
    .catch((err) => callback(false, err));
};
export const getPaymentOfOneLawyer = (callback, id, params = "") => {
  AxiosInstanceWithXAuthToken()
    .get(`/api/v1/admins/lawyers/payments/${id}${params}`)
    .then((res) => callback(true, res.data))
    .catch((err) => callback(false, err));
};
export const getPaymentOfOneClient = (callback, id, params = "") => {
  AxiosInstanceWithXAuthToken()
    .get(`/api/v1/admins/clients/payments/${id}${params}`)
    .then((res) => callback(true, res.data))
    .catch((err) => callback(false, err));
};
export const getAllPaymentsReport = (callback, params = "") => {
  AxiosInstanceWithXAuthToken()
    .get(`/api/v1/admins/payments/reports${params}`)
    .then((res) => callback(true, res.data))
    .catch((err) => callback(false, err));
};
