import Divider from "@mui/material/Divider";
import Icon from "@mui/material/Icon";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
// Custom styles for the Configurator
import DrawerRightRoot from "examples/DrawerRight/DrawerRightRoot";
import {
  useMaterialUIController,
  setOpenDrawerRight,
  setCircularProgress,
  setAllRegionsData,
  setAlertText,
  setAlertOpen,
  setAlertColor,
} from "context";
import { useState, useEffect } from "react";
// import MDInput from "components/MDInput";
import Switch from "@mui/material/Switch";
import { getAllRegions, editRegion } from "api/api_region";
// import { FormControlLabel, Radio, RadioGroup } from "@mui/material";

function CreateRegionDrawer() {
  const [controller, dispatch] = useMaterialUIController();
  const {
    currentEditRegionStatus,
    currentEditRegionSubscribe,
    currentEditRegionWallet,
    currentEditRegionId,
    openDrawerRight,
    currentEditRegionName,
    currentEditRegionSubPrice,
    darkMode,
  } = controller;
  const [localStatus, setLocalStatus] = useState(currentEditRegionStatus);
  const [localSubscribe, setLocalSubscribe] = useState(currentEditRegionSubscribe);
  const [localWallet, setLocalWallet] = useState(currentEditRegionWallet);
  const [localSubPrice, setLocalSubPrice] = useState(parseInt(currentEditRegionSubPrice, 10));
  useEffect(() => {
    setLocalStatus(parseInt(currentEditRegionStatus, 10));
    setLocalSubscribe(currentEditRegionSubscribe);
    setLocalWallet(currentEditRegionWallet);
    setLocalSubPrice(parseInt(currentEditRegionSubPrice, 10));
  }, [currentEditRegionStatus, currentEditRegionSubscribe, currentEditRegionWallet]);
  const editRegionClicked = () => {
    const body = {
      status: localStatus === 0 ? "0" : "1",
      subscribeAllow: localSubscribe ? 1 : 0,
      walletAllow: localWallet ? 1 : 0,
      subscriptionPrice: (localSubPrice * 100).toString(),
    };
    setCircularProgress(dispatch, true);
    setOpenDrawerRight(dispatch, false);
    editRegion(
      (isOk, data) => {
        if (!isOk) {
          console.log("edit an region api is not working", data);
          setCircularProgress(dispatch, false);
          setAlertText(dispatch, "edit an region is not working");
          setAlertOpen(dispatch, true);
          setAlertColor(dispatch, "error");
          setLocalSubPrice(parseInt(currentEditRegionSubPrice, 10));
        } else {
          setAlertText(dispatch, "edit an region api is successfull!");
          setAlertOpen(dispatch, true);
          setAlertColor(dispatch, "success");
          // setLocalSubPrice(parseInt(currentEditRegionSubPrice, 10));
          getAllRegions((isOk2, data2) => {
            if (!isOk2) {
              console.log("get regions data from api is not working", data);
              setCircularProgress(dispatch, false);
            } else {
              setAllRegionsData(dispatch, data2);
            }
            setCircularProgress(dispatch, false);
          }, "?all=true");
        }
      },
      body,
      currentEditRegionId
    );
  };
  const handleCloseDrawerRight = () => setOpenDrawerRight(dispatch, false);
  return (
    <DrawerRightRoot variant="permanent" ownerState={{ openDrawerRight }}>
      <MDBox
        display="flex"
        justifyContent="space-between"
        alignItems="baseline"
        pt={4}
        pb={0.5}
        px={3}
      >
        <MDBox>
          <MDTypography variant="h5">Admin Panel Editor</MDTypography>
          <MDTypography variant="body2" color="text">
            Edit {currentEditRegionName} region
          </MDTypography>
        </MDBox>

        <Icon
          sx={({ typography: { size }, palette: { dark, white } }) => ({
            fontSize: `${size.lg} !important`,
            color: darkMode ? white.main : dark.main,
            stroke: "currentColor",
            strokeWidth: "2px",
            cursor: "pointer",
            transform: "translateY(5px)",
          })}
          onClick={handleCloseDrawerRight}
        >
          close
        </Icon>
      </MDBox>
      <Divider />
      <MDBox style={{ display: "flex", flexDirection: "column" }}>
        <MDBox style={{ display: "flex" }}>
          <MDBox style={{ flex: 1 }}>
            <Switch
              checked={localSubscribe}
              onChange={(e) => setLocalSubscribe(e.target.checked)}
              inputProps={{ "aria-label": "controlled" }}
            />
            <MDBox style={{ fontSize: "0.8rem" }}>Allow Subscribe</MDBox>
          </MDBox>
          <MDBox style={{ flex: 1 }}>
            <Switch
              checked={localWallet}
              onChange={(e) => setLocalWallet(e.target.checked)}
              inputProps={{ "aria-label": "controlled" }}
            />
            <MDBox style={{ fontSize: "0.8rem" }}>Allow Wallet</MDBox>
          </MDBox>
        </MDBox>
        {localSubscribe && (
          <MDBox mb={2} mt={4}>
            <MDInput
              value={localSubPrice}
              onChange={(e) => setLocalSubPrice(e.target.value)}
              id="priceInput"
              type="number"
              label="Price"
              fullWidth
            />
          </MDBox>
        )}
      </MDBox>
      <MDBox mt={4} mb={1}>
        <MDButton onClick={() => editRegionClicked()} variant="gradient" color="info" fullWidth>
          UPDATE
        </MDButton>
      </MDBox>
    </DrawerRightRoot>
  );
}

export default CreateRegionDrawer;
