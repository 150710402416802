import * as React from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import { Button, CardActions } from "@mui/material";
import PropTypes from "prop-types";
// import { useEffect } from "react";
import MDBox from "components/MDBox";
import {
  setCurrentEditRegionStatus,
  setCurrentEditRegionSubscribe,
  setCurrentEditRegionWallet,
  setCurrentEditRegionId,
  setCurrentEditRegionName,
  setODR,
  useMaterialUIController,
  setOpenDrawerRight,
  setAllRegionsData,
  setCircularProgress,
  setRegionsPageCount,
  setCurrentEditRegionSubPrice,
} from "context";
import { deleteRegion, getAllRegions } from "api/api_region";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import LocationOffIcon from "@mui/icons-material/LocationOff";
import Tooltip from "@mui/material/Tooltip";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import WalletIcon from "@mui/icons-material/Wallet";
import { useState } from "react";
// import { useState } from "react";
// import { deleteRegion } from "api/api_region";

export default function RegionCard({ value, index }) {
  const [controller, dispatch] = useMaterialUIController();
  const { darkMode } = controller;
  const [thisCategoryName, setThisCategoryName] = useState("");
  const [thisCategoryId, setThisCategoryId] = useState(0);
  const [open, setOpen] = React.useState(false);

  // const handleClick = () => {
  //   console.info("You clicked the Chip.");
  // };
  // const handleDelete = () => {
  //   console.info("You clicked the delete icon.");
  // };
  const editBTNClicked = (val) => {
    setODR(dispatch, "editRegion");
    setTimeout(() => {
      setOpenDrawerRight(dispatch, true);
    }, 1);
    setCurrentEditRegionStatus(dispatch, val.status);
    setCurrentEditRegionSubscribe(dispatch, val.subscribeAllow);
    setCurrentEditRegionWallet(dispatch, val.walletAllow);
    setCurrentEditRegionId(dispatch, val.id);
    setCurrentEditRegionName(dispatch, val.regionName);
    setCurrentEditRegionSubPrice(dispatch, val.subscriptionPrice / 100);
  };
  const deleteBTNClicked = (val) => {
    setOpen(false);
    setCircularProgress(dispatch, true);
    deleteRegion((isOk, data) => {
      if (!isOk) {
        console.log("delete an region is unsuccessfull", data);
        setCircularProgress(dispatch, false);
      } else {
        setCircularProgress(dispatch, true);
        getAllRegions((isOk2, data2) => {
          if (!isOk2) {
            console.log("get regions data from api is not working", data2);
          } else {
            setRegionsPageCount(dispatch, data2.meta.last_page);
            setAllRegionsData(dispatch, data2);
          }
          setCircularProgress(dispatch, false);
        }, window.location.search);
      }
    }, val);
  };
  const handleDialogOpen = (val) => {
    setOpen(true);
    setThisCategoryName(val.name);
    setThisCategoryId(val.id);
  };

  return (
    <>
      <div>
        <Dialog
          open={open}
          onClose={() => setOpen(false)}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">Confirm Category Delete</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              are you want to delete {thisCategoryName} category?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              style={{ backgroundColor: "#32cd32", color: "white" }}
              onClick={() => setOpen(false)}
            >
              Disagree
            </Button>
            <Button
              style={{ backgroundColor: "#dc143c", color: "white" }}
              onClick={() => deleteBTNClicked(thisCategoryId)}
              autoFocus
            >
              Agree
            </Button>
          </DialogActions>
        </Dialog>
      </div>
      <div key={`${index}${darkMode}`}>
        <Card mt={2}>
          <CardContent>
            <MDBox
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <div
                className={`w-[80px] mt-2 mb-2 text-center text-[50px] bg-slate-700 rounded-full ${
                  value.status === "0" ? "text-white" : "text-red-700"
                }`}
              >
                <Tooltip title={value.status === "0" ? "active" : "Not Active"}>
                  {value.status === "0" ? <LocationOnIcon /> : <LocationOffIcon />}
                </Tooltip>
              </div>
              <Typography
                gutterBottom
                variant="h4"
                style={{ fontSize: "20px" }}
                color="text.secondary"
                component="div"
              >
                {`# ${value.id}`}
              </Typography>
              <Tooltip title={value.regionName}>
                <Typography
                  gutterBottom
                  style={{ fontSize: "18px", color: "black" }}
                  color="text.secondary"
                  component="div"
                >
                  {value.regionName.length < 12
                    ? `${value.regionName} (${value.regionCode})`
                    : `${value.regionName.slice(0, 12)}... (${value.regionCode})`}
                </Typography>
              </Tooltip>
              <Typography
                gutterBottom
                style={{ fontSize: "15px" }}
                color="text.secondary"
                component="div"
              >
                Subscription price
              </Typography>
              <Typography
                gutterBottom
                style={{ fontSize: "15px" }}
                color="text.secondary"
                component="div"
              >
                {`${value.subscriptionPrice / 100} $`}
              </Typography>
            </MDBox>
          </CardContent>
          <CardActions>
            <div className="w-full flex flex-row justify-around mb-2 opacity-[0.9]">
              <Tooltip title="Subscription">
                <div
                  className={`${
                    value.subscribeAllow ? "bg-green-800" : "bg-red-700"
                  } text-[25px] px-[9px] text-white rounded-md`}
                  onClick={() => editBTNClicked(value)}
                  onKeyPress={(event) => {
                    if (event.key === "Enter") {
                      editBTNClicked(value);
                    }
                  }}
                  tabIndex={0}
                  role="button"
                >
                  <AttachMoneyIcon />
                </div>
              </Tooltip>
              <Tooltip title="Wallet">
                <div
                  className={`${
                    value.subscribeAllow ? "bg-green-800" : "bg-red-700"
                  } text-[25px] px-[9px] text-white rounded-md`}
                  onClick={() => editBTNClicked(value)}
                  onKeyPress={(event) => {
                    if (event.key === "Enter") {
                      editBTNClicked(value);
                    }
                  }}
                  tabIndex={0}
                  role="button"
                >
                  <WalletIcon />
                </div>
              </Tooltip>
              <Tooltip title="Edit">
                <div
                  className="bg-blue-900 text-[25px] px-[9px] text-white rounded-md"
                  onClick={() => editBTNClicked(value)}
                  onKeyPress={(event) => {
                    if (event.key === "Enter") {
                      editBTNClicked(value);
                    }
                  }}
                  tabIndex={0}
                  role="button"
                >
                  <EditIcon />
                </div>
              </Tooltip>
              <Tooltip title="Delete">
                <div
                  className="bg-slate-700 text-[25px] px-[9px] text-white rounded-md"
                  onClick={() => handleDialogOpen(value)}
                  onKeyPress={(event) => {
                    if (event.key === "Enter") {
                      handleDialogOpen(value);
                    }
                  }}
                  tabIndex={0}
                  role="button"
                >
                  <DeleteIcon />
                </div>
              </Tooltip>
            </div>
          </CardActions>
          {/* <CardActions>
            <Button
              variant="contained"
              onClick={() => editBTNClicked(value)}
              size="small"
              style={{
                color: "rgb(58,58,65)",
                backgroundColor: "white",
                border: "1px solid rgb(58,58,65)",
                boxShadow: "none",
              }}
              fullWidth
              disabled
            >
              {value.status !== "0" ? "active" : "deactive"}
            </Button>
          </CardActions> */}
        </Card>
      </div>
    </>
  );
}
// Setting default values for the props of RegionCard
RegionCard.defaultProps = {
  value: "",
  index: 1,
};

// Typechecking props of the RegionCard
RegionCard.propTypes = {
  value: PropTypes.object,
  index: PropTypes.number,
};
