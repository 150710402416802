import { useState, useRef } from "react";
import { useMaterialUIController, setSettingJoyrideRun } from "context";
import Joyride, { STATUS } from "react-joyride";
import TourIntroduction from "../components/TourIntroduction";

export default function SettingJoyrideComponent() {
  const [controller, dispatch] = useMaterialUIController();
  const { settingJoyrideRun } = controller;
  const tourRef = useRef(null);
  const stepsLength = 7;
  const [steps] = useState([
    {
      disableBeacon: true,
      content: (
        <TourIntroduction
          tourRef={tourRef}
          stepsLength={stepsLength}
          stepIndex={0}
          headerText="Categories Table"
          bodyText="Each message’s information is provided in the relevant row in this table."
        />
      ),
      placement: "bottom",
      styles: {
        tooltip: {
          display: "flex",
          flexDirection: "column",
          justifyContent: "top",
          width: "400px",
          top: "-15px",
        },
        spotlight: {
          borderRadius: "8px",
        },
      },
      target: ".categoriesJoyRide",
    },
    {
      disableBeacon: true,
      content: (
        <TourIntroduction
          tourRef={tourRef}
          stepsLength={stepsLength}
          stepIndex={1}
          headerText="Regions Table"
          bodyText="This section displays every region along with the relevant data."
        />
      ),
      placement: "bottom",
      styles: {
        tooltip: {
          display: "flex",
          flexDirection: "column",
          justifyContent: "top",
          width: "400px",
          top: "-15px",
        },
        spotlight: {
          borderRadius: "8px",
        },
      },
      target: ".regionsJoyRide",
    },
    {
      disableBeacon: true,
      content: (
        <TourIntroduction
          tourRef={tourRef}
          stepsLength={stepsLength}
          stepIndex={2}
          headerText="Faq Table"
          bodyText="Management list and access to FAQ"
        />
      ),
      placement: "bottom",
      styles: {
        tooltip: {
          display: "flex",
          flexDirection: "column",
          justifyContent: "top",
          width: "400px",
          top: "-15px",
        },
        spotlight: {
          borderRadius: "8px",
        },
      },
      target: ".faqJoyRide",
    },
    {
      disableBeacon: true,
      content: (
        <TourIntroduction
          tourRef={tourRef}
          stepsLength={stepsLength}
          stepIndex={3}
          headerText="Admins Table"
          bodyText="Table of administrators' information and management"
        />
      ),
      placement: "bottom",
      styles: {
        tooltip: {
          display: "flex",
          flexDirection: "column",
          justifyContent: "top",
          width: "400px",
          top: "-15px",
        },
        spotlight: {
          borderRadius: "8px",
        },
      },
      target: ".adminsJoyRide",
    },
    {
      disableBeacon: true,
      content: (
        <TourIntroduction
          tourRef={tourRef}
          stepsLength={stepsLength}
          stepIndex={4}
          headerText="Versions Table"
          bodyText="The table of Versions and information about each Version is given in the corresponding row"
        />
      ),
      placement: "bottom",
      styles: {
        tooltip: {
          display: "flex",
          flexDirection: "column",
          justifyContent: "top",
          width: "400px",
          top: "-15px",
        },
        spotlight: {
          borderRadius: "8px",
        },
      },
      target: ".versionsJoyRide",
    },
    {
      disableBeacon: true,
      content: (
        <TourIntroduction
          tourRef={tourRef}
          stepsLength={stepsLength}
          stepIndex={5}
          headerText="Admin profile"
          bodyText="This section displays details about the current administrator."
        />
      ),
      placement: "bottom",
      styles: {
        tooltip: {
          display: "flex",
          flexDirection: "column",
          justifyContent: "top",
          width: "400px",
          top: "-15px",
        },
        spotlight: {
          borderRadius: "8px",
        },
      },
      target: ".profileJoyRide",
    },
    {
      disableBeacon: true,
      content: (
        <TourIntroduction
          tourRef={tourRef}
          stepsLength={stepsLength}
          stepIndex={6}
          headerText="Logout"
          bodyText="Use this button to log out of the admin panel."
        />
      ),
      placement: "bottom",
      styles: {
        tooltip: {
          display: "flex",
          flexDirection: "column",
          justifyContent: "top",
          width: "400px",
          top: "-15px",
        },
        spotlight: {
          borderRadius: "8px",
        },
      },
      target: ".logoutJoyRide",
    },
  ]);

  const handleJoyrideCallback = (data) => {
    const { status } = data;
    const finishedStatuses = [STATUS.FINISHED, STATUS.SKIPPED];
    if (finishedStatuses.includes(status)) {
      setSettingJoyrideRun(dispatch, false);
      tourRef.current.store.skip();
    }
  };
  return (
    <Joyride
      callback={handleJoyrideCallback}
      ref={tourRef}
      continuous
      // disableScrolling
      hideCloseButton
      hideBackButton
      scrollOffset={100}
      run={settingJoyrideRun}
      steps={steps}
      styles={{
        buttonNext: {
          display: "none",
        },
        tooltipContent: {
          padding: "0px",
        },
        tooltipContainer: {
          padding: "0px",
        },
        tooltipFooter: {
          display: "none",
        },
        options: {
          width: "fit-content",
          arrowColor: "transparent",
          zIndex: 10000,
        },
      }}
    />
  );
}
