// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import DataTable from "examples/TablesLawyersLive/DataTable";
import authorsTableData from "layouts/sessions/clientsSessionTable/data/authorsTableData";
import { useMaterialUIController, setClientsSessionsData, setCircularProgress } from "context";
import { useLocation, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { getAllClientSessions } from "api/sessionApi";
import { Pagination } from "@mui/material";

function Tables() {
  const [controller, dispatch] = useMaterialUIController();
  const { columns, rows } = authorsTableData();
  const { pathname } = useLocation();
  const { searchBarValueSession } = controller;
  const [paginationCount, setPaginationCount] = useState(1);
  const [sessionsPage, setSessionsPage] = useState(1);
  const navigate = useNavigate();

  useEffect(() => {
    let url = `/sessions?clients`;
    if (searchBarValueSession !== "") url += `&search=${searchBarValueSession}`;
    url += `&page=${sessionsPage}`;
    navigate(url);
  }, [searchBarValueSession, sessionsPage]);
  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const paramsObj = Array.from(params.keys()).reduce(
      (acc, val) => ({ ...acc, [val]: params.get(val) }),
      {}
    );
    if (!paramsObj.page) setSessionsPage(1);
    else setSessionsPage(parseInt(paramsObj.page, 10));
    let param = "";
    param += `?page=${paramsObj.page}`;
    if (paramsObj.search !== "" && paramsObj.search) param += `&search=${paramsObj.search}`;
    setCircularProgress(dispatch, true);
    getAllClientSessions((isOk, data) => {
      if (!isOk) {
        console.log("get Clients sessions List from api is not working", data);
      } else {
        setClientsSessionsData(dispatch, data.data);
        setPaginationCount(data.meta.last_page);
      }
      setCircularProgress(dispatch, false);
    }, param);
  }, [pathname, window.location.search]);
  const handlePaginationChange = (event, value) => {
    let url = "";
    url += "/sessions?clients";
    url += `&page=${value}`;
    if (searchBarValueSession !== "") url += `&search=${searchBarValueSession}`;
    navigate(url);
  };
  useEffect(() => {
    setSessionsPage(1);
  }, [searchBarValueSession]);

  return (
    <MDBox pt={6} pb={3}>
      <Grid container spacing={6}>
        <Grid item xs={12}>
          <Card>
            <MDBox
              mx={2}
              mt={-3}
              py={3}
              px={2}
              variant="gradient"
              bgColor="info"
              borderRadius="lg"
              coloredShadow="info"
            >
              <MDTypography variant="h6" color="white">
                Clients Sessions Table
              </MDTypography>
            </MDBox>
            <MDBox pt={3}>
              <DataTable
                table={{ columns, rows }}
                isSorted={false}
                entriesPerPage={false}
                showTotalEntries={false}
                noEndBorder
              />
            </MDBox>
          </Card>
        </Grid>
      </Grid>
      <MDBox style={{ display: "flex", justifyContent: "center" }}>
        <Pagination
          page={sessionsPage}
          onChange={handlePaginationChange}
          count={paginationCount}
          color="info"
        />
      </MDBox>
    </MDBox>
  );
}

export default Tables;
