// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
// Material Dashboard 2 React components
import { FormControl, InputLabel, MenuItem, Pagination, Select } from "@mui/material";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import DataTable from "examples/TablesLawyersLive/DataTable";
import authorsTableData from "layouts/sessions/all/data/authorsTableData";
import { useMaterialUIController, setSessionsData, setCircularProgress } from "context";
import { useLocation, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { getAllSessions } from "api/sessionApi";
import Modal from "./modal";

function Tables() {
  const [controller, dispatch] = useMaterialUIController();
  const { searchBarValueSession } = controller;
  const [allSessionsPage, setAllSessionsPage] = useState(1);
  const [allSessionsPageCount, setAllSessionsPageCount] = useState(1);
  const navigate = useNavigate();
  const [status, setStatus] = useState("");
  const { columns, rows } = authorsTableData();
  const { pathname } = useLocation();
  const handlePaginationChange = (event, value) => {
    setAllSessionsPage(parseInt(value, 10));
    let url = "";
    if (allSessionsPage) url += `/sessions?all&page=${value}`;
    if (searchBarValueSession !== "") url += `&search=${searchBarValueSession}`;
    navigate(url);
  };
  useEffect(() => {
    setAllSessionsPage(1);
  }, [searchBarValueSession, status]);
  useEffect(() => {
    let url = `/sessions?all&page=${allSessionsPage}`;
    if (searchBarValueSession !== "") url += `&search=${searchBarValueSession}`;
    if (status !== "") url += `&status=${status}`;
    navigate(url);
  }, [searchBarValueSession, status, allSessionsPage]);
  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const paramsObj = Array.from(params.keys()).reduce(
      (acc, val) => ({ ...acc, [val]: params.get(val) }),
      {}
    );
    let param = "";
    param += `?page=${paramsObj.page}`;
    if (paramsObj.status !== "" && paramsObj.status) param += `&status=${paramsObj.status}`;
    if (paramsObj.search !== "" && paramsObj.search) param += `&search=${paramsObj.search}`;
    setCircularProgress(dispatch, true);
    getAllSessions((isOk, data) => {
      if (!isOk) {
        console.log("get all Sessions List from api is not working", data);
      } else {
        setAllSessionsPageCount(data.meta.last_page);
        setSessionsData(dispatch, data.data);
        console.log("DSDS", data.data);
      }
      setCircularProgress(dispatch, false);
    }, param);
  }, [pathname, window.location.search]);
  return (
    <MDBox pt={6} pb={3}>
      <Modal />
      <Grid container spacing={6}>
        <Grid item xs={12}>
          <Card>
            <MDBox
              mx={2}
              mt={-3}
              py={3}
              px={2}
              variant="gradient"
              bgColor="info"
              borderRadius="lg"
              coloredShadow="info"
              style={{ display: "flex", justifyContent: "center" }}
            >
              <MDTypography variant="h6" color="white">
                All Sessions Table
              </MDTypography>
              <MDBox style={{ marginLeft: "auto" }}>
                <FormControl style={{ width: "150px" }}>
                  <InputLabel id="Status" style={{ color: "white", textAlign: "center" }}>
                    Status
                  </InputLabel>
                  <Select
                    variant="standard"
                    labelId="Status"
                    id="Status"
                    style={{
                      backgroundColor: "transparent",
                      color: "white",
                      marginTop: "10px",
                    }}
                    inputProps={{
                      textAlign: "center",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                    value={status}
                    label="Status"
                    onChange={(e) => setStatus(e.target.value)}
                  >
                    <MenuItem value="">All</MenuItem>
                    <MenuItem value="pending">Pending</MenuItem>
                    <MenuItem value="accepted-by-client">Accepted By Client</MenuItem>
                    <MenuItem value="rejected-by-client">Rejected By Client</MenuItem>
                    <MenuItem value="rejected-by-lawyer">Rejected By Lawyer</MenuItem>
                  </Select>
                </FormControl>
              </MDBox>
            </MDBox>
            <MDBox pt={3}>
              <DataTable
                table={{ columns, rows }}
                isSorted={false}
                entriesPerPage={false}
                showTotalEntries={false}
                noEndBorder
              />
            </MDBox>
          </Card>
        </Grid>
      </Grid>
      <MDBox py={3} style={{ display: "flex", justifyContent: "center" }}>
        <Pagination
          page={allSessionsPage}
          onChange={handlePaginationChange}
          count={allSessionsPageCount}
          color="info"
        />
      </MDBox>
    </MDBox>
  );
}

export default Tables;
