// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDAvatar from "components/MDAvatar";
import PropTypes from "prop-types";
// Images
import person from "assets/images/person.png";
import { useMaterialUIController } from "context";
import { useState, useEffect } from "react";
import { Link } from "react-router-dom";

export default function data() {
  function Author({ image, name, email, id }) {
    return (
      <Link to={`/lawyers/detail/${id}`}>
        <MDBox display="flex" alignItems="center" lineHeight={1}>
          <MDTypography
            style={{ minWidth: "50px" }}
            display="block"
            variant="button"
            fontWeight="medium"
          >
            #{id}
          </MDTypography>
          <MDAvatar src={image} name={name} size="sm" />
          <MDBox ml={2} lineHeight={1}>
            <MDTypography display="block" variant="button" fontWeight="medium">
              {name}
            </MDTypography>
            <MDTypography variant="caption">{email}</MDTypography>
          </MDBox>
        </MDBox>
      </Link>
    );
  }
  Author.propTypes = {
    image: PropTypes.string,
    name: PropTypes.string,
    email: PropTypes.string,
    id: PropTypes.number,
  };
  Author.defaultProps = {
    image: "",
    name: "",
    email: "",
    id: "",
  };
  const [controller] = useMaterialUIController();
  const { clientPaymentDetails } = controller;
  const [listOfData, setListOfData] = useState([]);
  useEffect(() => {
    if (clientPaymentDetails.length > 0) {
      setListOfData(clientPaymentDetails);
    } else {
      setListOfData([]);
    }
  }, [clientPaymentDetails]);
  const getFullName = (first, last) => {
    if (first === null && last === null) return "not Found";
    let returndText = "";
    if (first === null) returndText += "";
    else returndText += first;
    returndText += " ";
    if (last === null) returndText += "";
    else returndText += last;
    return returndText;
  };
  const getStatusColor = (value) => {
    switch (value) {
      case "accepted":
        return "#17d41a";
      case "denied":
        return "#780b01";
      case "pending":
        return "#bfd611";
      case "pending_office":
        return "#634c01";
      default:
        return "#000d07";
    }
  };
  const monthNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  const getRows = () => {
    const personsInfo = listOfData.map((value) => ({
      lawyer: value.lawyer ? (
        <Author
          image={value.lawyer.avatars === null ? person : value.lawyer.avatars.thumbnail}
          name={getFullName(value.lawyer.first_name, value.lawyer.last_name)}
          email={value.lawyer.email}
          id={value.lawyer.id}
        />
      ) : (
        <div className="text-red-500 font-bold flex items-center justify-center w-[200px]">
          deleted Lawyer
        </div>
      ),
      "live start at": (
        <MDBox lineHeight={1} textAlign="left">
          <MDTypography display="block" variant="caption" color="text" fontWeight="medium">
            {value.created_at.split("-")[2].split("T")[0]}{" "}
            {monthNames[value.created_at.split("-")[1] - 1]} {value.created_at.split("-")[0]}
          </MDTypography>
        </MDBox>
      ),
      "spend time": (
        <MDBox lineHeight={1} textAlign="left">
          <MDTypography display="block" variant="caption" color="text" fontWeight="medium">
            {Math.floor(value.spend_time) > 0
              ? `${Math.floor(value.spend_time)} min`
              : `${Math.floor(value.spend_time * 60)} secondes`}
          </MDTypography>
        </MDBox>
      ),
      status: (
        <MDBox
          style={{
            fontSize: "0.7rem",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            color: getStatusColor(value.status),
          }}
        >
          {value.status && value.status[0].toUpperCase()}
          {value.status && value.status.substring(1)}
        </MDBox>
      ),
      amount: (
        <MDBox lineHeight={1} textAlign="left">
          <MDTypography display="block" variant="caption" color="text" fontWeight="medium">
            {Math.round((value.amount / 100) * 100) / 100}$
          </MDTypography>
        </MDBox>
      ),
      "order number": (
        <MDBox lineHeight={1} textAlign="left">
          <MDTypography display="block" variant="caption" color="text" fontWeight="medium">
            #{value.order_number}
          </MDTypography>
        </MDBox>
      ),
      type: (
        <MDBox lineHeight={1} textAlign="left">
          <MDTypography display="block" variant="caption" color="text" fontWeight="medium">
            {value.live_id ? "Live" : ""}
            {value.session_id ? "Session" : ""}
            {value.case_id ? "Per-Case" : ""}
            {!value.live_id && !value.session_id && !value.case_id && "Other"}
          </MDTypography>
        </MDBox>
      ),
      "product id": (
        <MDBox lineHeight={1} textAlign="left">
          <MDTypography display="block" variant="caption" color="text" fontWeight="medium">
            {value.live_id ? `#${value.live_id}` : ""}
            {value.session_id ? `#${value.session_id}` : ""}
            {value.case_id ? `#${value.case_id}` : ""}
            {!value.live_id && !value.session_id && !value.case_id && "-"}
          </MDTypography>
        </MDBox>
      ),
    }));
    return personsInfo;
  };
  return {
    columns: [
      { Header: "lawyer", accessor: "lawyer", width: "45%", align: "left" },
      { Header: "live started at", accessor: "live start at", align: "center" },
      { Header: "spent time", accessor: "spend time", align: "center" },
      { Header: "status", accessor: "status", align: "center" },
      { Header: "amount", accessor: "amount", align: "center" },
      { Header: "order number", accessor: "order number", align: "center" },
      { Header: "type", accessor: "type", align: "center" },
      { Header: "product id", accessor: "product id", align: "center" },
    ],

    rows: getRows(),
  };
}
