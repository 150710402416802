import React from "react";

import PropTypes from "prop-types";

function Header({ userType }) {
  return (
    <div className="w-full flex flex-row p-3 items-center justify-between gap-4 bg-white rounded-md shadow-md hover:shadow-lg">
      <div className="text-[13px] w-[80px]">Id</div>
      <div className="text-[13px] w-[200px]">{userType}</div>
      <div className="text-gray-500 text-[14px] text-left w-[200px]">Phone Number</div>
      <div className="text-gray-500 text-[14px] text-left w-[200px]">Email</div>
      <div className="text-gray-500 text-[14px] text-left w-[100px]">Birthday</div>
      <div className="text-gray-500 text-[14px] text-left w-[80px]">Status</div>
    </div>
  );
}

Header.propTypes = {
  userType: PropTypes.string,
};
Header.defaultProps = {
  userType: "",
};

export default Header;
