import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import React from "react";
import MDButton from "components/MDButton";
import {
  setAlertColor,
  setAlertText,
  setAlertOpen,
  useMaterialUIController,
  setCircularProgress,
  setFAQData,
  setDeleteFaqModal,
} from "context";
import { deleteAnFAQ, getAllFAQs } from "api/api_faq";

function Modal() {
  const [controller, dispatch] = useMaterialUIController();
  const { faqId, deleteFaqModal } = controller;
  const deleteFaq = () => {
    setCircularProgress(dispatch, true);
    setDeleteFaqModal(dispatch, false);
    deleteAnFAQ((isOk, data) => {
      if (!isOk) {
        console.log("delete faq information is not successful: ", data);
        setCircularProgress(dispatch, false);
        setAlertText(dispatch, data.response.data.message);
        setAlertOpen(dispatch, true);
        setAlertColor(dispatch, "error");
      } else {
        getAllFAQs((isOk2, data2) => {
          if (!isOk2) {
            console.log("get faq data from api is not working", data2);
            setCircularProgress(dispatch, false);
            setAlertText(dispatch, data2.response.data.message);
            setAlertOpen(dispatch, true);
            setAlertColor(dispatch, "success");
          } else {
            setFAQData(dispatch, data2.data);
            setCircularProgress(dispatch, false);
            setAlertColor(dispatch, "error");
          }
        }, `${window.location.search}`);
      }
    }, faqId);
  };
  return (
    <Dialog
      open={deleteFaqModal}
      onClose={() => setDeleteFaqModal(dispatch, false)}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">Confirm Delete this Faq</DialogTitle>
      <DialogContent style={{ width: "500px" }}>
        <DialogContentText id="alert-dialog-description">
          <div className="text-center text-[20px] flex">Are you sure?</div>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <MDButton
          variant="outlined"
          color="info"
          onClick={() => {
            setDeleteFaqModal(dispatch, false);
          }}
        >
          NO
        </MDButton>
        <MDButton variant="contained" color="info" onClick={() => deleteFaq()} autoFocus>
          YES
        </MDButton>
      </DialogActions>
    </Dialog>
  );
}
export default Modal;
