import FilterMenu from "components/FilterMenu";
import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useMaterialUIController, setClientsPage, setLawyersPage } from "context";
import Pagination from "@mui/material/Pagination";
import Skeleton from "@mui/material/Skeleton";
import Box from "@mui/material/Box";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";

import LawyerCard from "./design/Lawyer/LawyerCard";
import LawyerRow from "./design/Lawyer/LawyerRow";
import ClientCard from "./design/Client/ClientCard";
import ClientRow from "./design/Client/ClientRow";
import Header from "./design/Header";

export default function DataTable({ api, type }) {
  // eslint-disable-next-line no-unused-vars
  const [controller, dispatch] = useMaterialUIController();
  const {
    clientsPage,
    clientsData,
    lawyersData,
    lawyersPage,
    searchBarValueLawyer,
    searchBarValueClient,
  } = controller;
  const [data, setData] = useState([]);
  const [cardRenderStyle, setCardRenderStyle] = useState(false);
  const [paginationCount, setPaginationCount] = useState(1);
  const [searchValue, setSearchValue] = useState("");
  const [reload, setReload] = useState(false);

  const getInitialPage = () => {
    switch (type) {
      case "Client":
        return clientsPage;
      case "Lawyer":
        return lawyersPage;
      default:
        return 1;
    }
  };

  const [currentPage, setCurrentPage] = useState(getInitialPage());

  useEffect(() => {
    switch (type) {
      case "Client":
        setClientsPage(dispatch, currentPage);
        break;
      case "Lawyer":
        setLawyersPage(dispatch, currentPage);
        break;
      default:
        break;
    }
  }, [currentPage]);

  useEffect(() => {
    switch (type) {
      case "Client":
        setSearchValue(searchBarValueClient);
        break;
      case "Lawyer":
        setSearchValue(searchBarValueLawyer);
        break;
      default:
        break;
    }
  }, [searchBarValueLawyer, searchBarValueClient]);

  useEffect(() => {
    setReload(true);
  }, [clientsData, lawyersData]);

  const renderCard = (renderType, cardData) => {
    switch (renderType) {
      case "Lawyer":
        return <LawyerCard item={cardData} key={cardData.id} />;
      case "Client":
        return <ClientCard item={cardData} key={cardData.id} />;
      default:
        return <div>no item</div>;
    }
  };

  const renderRow = (renderType, rowData) => {
    switch (renderType) {
      case "Lawyer":
        return <LawyerRow item={rowData} key={rowData.id} />;
      case "Client":
        return <ClientRow item={rowData} key={rowData.id} />;
      default:
        return <div>no item</div>;
    }
  };

  const handleDataUpdate = (updatedData) => {
    setReload(false);
    setData(updatedData.data);
    setPaginationCount(updatedData.meta.last_page);
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  return (
    <div className="w-full flex flex-col gap-5">
      <FilterMenu
        api={api}
        updateData={handleDataUpdate}
        updateTableStyle={(style) => setCardRenderStyle(style)}
        currentPage={currentPage}
        updateCurrentPage={(number) => setCurrentPage(number)}
        reload={reload}
        searchValue={searchValue}
      />
      <div
        className={`grid ${
          cardRenderStyle
            ? "sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-4"
            : "grid-cols-1 gap-4"
        }`}
      >
        {!cardRenderStyle && <Header userType={type} />}
        {data.length ? (
          data.map((item) => (cardRenderStyle ? renderCard(type, item) : renderRow(type, item)))
        ) : (
          <Box sx={{ width: "100%", height: "100%" }}>
            <Skeleton />
            <Skeleton animation="wave" />
            <Skeleton animation={false} />
          </Box>
        )}
      </div>
      <MDBox style={{ display: "flex", justifyContent: "center" }}>
        <Pagination
          page={currentPage}
          onChange={(e, v) => setCurrentPage(v)}
          count={paginationCount}
          showFirstButton
          showLastButton
        />
      </MDBox>
    </div>
  );
}

DataTable.propTypes = {
  api: PropTypes.any.isRequired,
  type: PropTypes.any.isRequired,
};
