import { Grid } from "@mui/material";
import PropTypes from "prop-types";

import React, { useEffect } from "react";

function PaymentTable({ paymentsList, setTotal, payList, setPayList }) {
  useEffect(() => {
    const temp = [];
    for (let i = 0; i < paymentsList.length; i += 1) {
      temp.push(paymentsList[i].id);
    }
    setPayList(temp);
  }, [paymentsList]);
  useEffect(() => {
    let tempTotal = 0;
    for (let i = 0; i < payList.length; i += 1) {
      for (let j = 0; j < paymentsList.length; j += 1) {
        if (payList[i] === paymentsList[j].id) {
          tempTotal += paymentsList[j].amount;
        }
      }
    }
    setTotal(tempTotal);
  }, [payList]);
  const isActive = (id, list) => {
    for (let i = 0; i < list.length; i += 1) {
      if (list[i] === id) return true;
    }
    return false;
  };
  const itemClicked = (id, list) => {
    let flag = 0;
    const temp = [];
    for (let i = 0; i < list.length; i += 1) {
      if (list[i] === id) {
        flag += 1;
      } else {
        temp.push(list[i]);
      }
    }
    if (flag === 0) {
      temp.push(id);
    }
    setPayList(temp);
  };
  return (
    <Grid container>
      {paymentsList.map((value) => (
        <Grid className="flex my-1 items-center justify-center" xs={3} key={JSON.stringify(value)}>
          <div
            role="presentation"
            onClick={() => itemClicked(value.id, payList)}
            className={`w-[95%] flex flex-col ${
              isActive(value.id, payList) ? "bg-gray-100" : "bg-white"
            } items-center justify-center p-2 border-[1px] cursor-pointer border-gray-500 rounded-[10px]`}
          >
            <div className="w-[100%] flex justify-between">
              <div
                className={`text-[11px] text-white rounded-[5px] font-bold px-2 ${
                  isActive(value.id, payList) ? "bg-green-500" : "bg-yellow-500"
                }`}
              >
                {isActive(value.id, payList) ? "selected" : "not selected"}
              </div>
              <div className="text-[10px] text-black">#{value.id}</div>
              <div className="text-[10px] text-black">{value.created_at.split("T")[0]}</div>
            </div>
            <div className="flex items-center justify-center">
              <div className="text-[10px]">Total Money: </div>
              <div className="text-[12px] ml-1 font-bold">{value.amount / 100}$</div>
            </div>
            <div className="flex items-center justify-center">
              <div className="text-[10px]">Total Time: </div>
              <div className="text-[12px] ml-1 font-bold">{value.spend_time}</div>
            </div>
            <div className="flex flex-col border-[1px] px-4 rounded-full bg-gray-200 items-center justify-center">
              <div className="text-[10px]">#{value.client.id}</div>
              <div className="text-[12px] ml-1 font-bold">
                {value.client.first_name} {value.client.last_name}
              </div>
            </div>
            <div className="flex items-center justify-center">
              <div className="text-[10px]">Order Number: </div>
              <div className="text-[12px] ml-1 font-bold">{value.order_number}</div>
            </div>
            <div className="flex items-center justify-center">
              <div className="text-[10px]">
                {value.session_id && "session id: "}
                {value.case_id && "case id: "}
                {value.live_id && "live id: "}
              </div>
              <div className="text-[12px] ml-1 font-bold">
                {value.live_id || value.case_id || value.session_id}
              </div>
            </div>
          </div>
        </Grid>
      ))}
    </Grid>
  );
}
PaymentTable.propTypes = {
  paymentsList: PropTypes.array,
  setTotal: PropTypes.func,
  payList: PropTypes.array,
  setPayList: PropTypes.func,
};
PaymentTable.defaultProps = {
  paymentsList: [],
  setTotal: () => {},
  payList: 0,
  setPayList: () => {},
};

export default PaymentTable;
