import { AxiosInstanceWithXAuthToken } from "./api";

export const getAllMessages = (callback, params) => {
  AxiosInstanceWithXAuthToken()
    .get(`/api/v1/admins/contact-us${params}`)
    .then((res) => callback(true, res.data))
    .catch((err) => {
      if (err.response.status === 401) {
        localStorage.clear();
        window.location.pathname = "/authentication/verify-number";
      }
      callback(false, err);
    });
};
export const getAMessageDetail = (callback, id) => {
  AxiosInstanceWithXAuthToken()
    .get(`/api/v1/admins/contact-us/${id}`)
    .then((res) => callback(true, res.data))
    .catch((err) => {
      if (err.response.status === 401) {
        localStorage.clear();
        window.location.pathname = "/authentication/verify-number";
      }
      callback(false, err);
    });
};
export const getAMessageReplies = (callback, id) => {
  AxiosInstanceWithXAuthToken()
    .get(`/api/v1/admins/contact-us/${id}/replies`)
    .then((res) => callback(true, res.data))
    .catch((err) => {
      if (err.response.status === 401) {
        localStorage.clear();
        window.location.pathname = "/authentication/verify-number";
      }
      callback(false, err);
    });
};
export const postReplyForMessage = (callback, body) => {
  AxiosInstanceWithXAuthToken()
    .post(`/api/v1/admins/contact-us/reply`, body)
    .then((res) => callback(true, res.data))
    .catch((err) => {
      if (err.response.status === 401) {
        localStorage.clear();
        window.location.pathname = "/authentication/verify-number";
      }
      callback(false, err);
    });
};
