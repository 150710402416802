import { useMaterialUIController } from "context";
import { useLocation } from "react-router-dom";
// import { useEffect } from "react";
// import { useEffect } from "react";
import StatusDrawerLawyer from "./StatusDrawerLawyer";
import StatusDrawerClient from "./StatusDrawerClient";
import DrawerRightForUpdateClient from "./DrawerForUpdateClient";
import DrawerRightForUpdatEditCertificateLawyer from "./DrawerRightForUpdatEditCertificateLawyer";
import DrawerRightForUpdateEditOfficeLawyer from "./DrawerRightForUpdatEditOfficeLawyer";
import DrawerRightForUpdateEditOfficeLawyerInDetail from "./DrawerRightForUpdatEditOfficeLawyerInDetail";
import DrawerRightForUpdateLawyer from "./DrawerForUpdateLawyer";
import DrawerRightForCreateLawyer from "./DrawerForCreateLawyer";
import DrawerRightForCreateClient from "./DrawerForCreateClient";
import DrawerRightForUpdateLawyerInDetail from "./DrawerForUpdateLawyerInDetail";
import DrawerRightForUpdateClientInDetail from "./DrawerForUpdateClientInDetail";
import StatusDrawerLawyerInDetail from "./StatusDrawerLawyerInDetail";
import StatusDrawerClientInDetail from "./StatusDrawerClientInDetail";
import CostDrawerInDetail from "./CostDrawerInDetail";
import CreateRegionDrawer from "./CreateRegionDrawer";
import CreateCategoryDrawer from "./CreateCategoryDrawer";
import CreateFaqDrawer from "./CreateFaqDrawer";
import EditFaqDrawer from "./EditFaqDrawer";
import EditRegionDrawer from "./EditRegionDrawer";
import EditCategoryDrawer from "./EditCategoryDrawer";
import DrawerForCreateClientAddress from "./DrawerForCreateClientAddress";
import DrawerForEditClientAddress from "./DrawerForEditClientAddress";

function DrawerRight() {
  const [controller] = useMaterialUIController();
  const { pathname } = useLocation();
  const { createOrUpdate, openDrawerReason } = controller;
  if (
    openDrawerReason === "editOrCreate" &&
    createOrUpdate &&
    (pathname === "/lawyers" || pathname.split("/")[1] === "lawyers")
  )
    return <DrawerRightForUpdateLawyer />;
  if (
    openDrawerReason === "editOrCreate" &&
    createOrUpdate &&
    (pathname === "/clients" || pathname.split("/")[1] === "clients")
  )
    return <DrawerRightForUpdateClient />;
  if (openDrawerReason === "editCertificateLawyer")
    return <DrawerRightForUpdatEditCertificateLawyer />;
  if (openDrawerReason === "editOfficeLawyer") return <DrawerRightForUpdateEditOfficeLawyer />;
  if (openDrawerReason === "editOfficeLawyerInDetail")
    return <DrawerRightForUpdateEditOfficeLawyerInDetail />;
  if (
    openDrawerReason === "editOrCreateInDetail" &&
    (pathname === "/lawyers" || pathname.split("/")[1] === "lawyers")
  )
    return <DrawerRightForUpdateLawyerInDetail />;
  if (
    openDrawerReason === "editOrCreateInDetail" &&
    (pathname === "/client" || pathname.split("/")[1] === "clients")
  )
    return <DrawerRightForUpdateClientInDetail />;
  if (openDrawerReason === "editOrCreate" && !createOrUpdate && pathname === "/lawyers")
    return <DrawerRightForCreateLawyer />;
  if (openDrawerReason === "editOrCreate" && !createOrUpdate && pathname === "/clients")
    return <DrawerRightForCreateClient />;
  if (openDrawerReason === "statusLawyerInDetail") return <StatusDrawerLawyerInDetail />;
  if (openDrawerReason === "statusClientInDetail") return <StatusDrawerClientInDetail />;
  if (openDrawerReason === "statusLawyer") return <StatusDrawerLawyer />;
  if (openDrawerReason === "statusClient") return <StatusDrawerClient />;
  if (openDrawerReason === "costInDetail") return <CostDrawerInDetail />;
  if (openDrawerReason === "createRegion") {
    return <CreateRegionDrawer />;
  }
  if (openDrawerReason === "createCategory") {
    return <CreateCategoryDrawer />;
  }
  if (openDrawerReason === "createFaq") {
    return <CreateFaqDrawer />;
  }
  if (openDrawerReason === "editFaq") {
    return <EditFaqDrawer />;
  }
  if (openDrawerReason === "editRegion") return <EditRegionDrawer />;
  if (openDrawerReason === "editCategory") return <EditCategoryDrawer />;
  if (openDrawerReason === "createAddressForClient") return <DrawerForCreateClientAddress />;
  if (openDrawerReason === "editAddressForClient") return <DrawerForEditClientAddress />;
}
export default DrawerRight;
