// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
// Material Dashboard 2 React components
import "../../styles/generalStyles.css";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import DataTable from "examples/TablesDetailPaymentLawyer/DataTable";
import authorsTableData from "layouts/transactions/data/authorsTableData";
import { useMaterialUIController, setCircularProgress, setTransationsData } from "context";
import { useLocation, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { Pagination } from "@mui/material";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import TransactionsNavbar from "examples/Navbars/TransactionsNavbar";
import { getAllTransactions } from "api/api_transactions";

function Transactions() {
  const [controller, dispatch] = useMaterialUIController();
  const { searchBarValueTransactions } = controller;
  const navigate = useNavigate();
  const { columns, rows } = authorsTableData();
  const [paginationCount, setPaginationCount] = useState(1);
  const [periodOfTransactions, setPeriodOfTransactions] = useState("monthly");
  const [transactionsPage, setTransactionsPage] = useState(1);
  const { pathname } = useLocation();
  useEffect(() => {
    let url = "/transactions";
    url += `?reference=${periodOfTransactions}`;
    if (searchBarValueTransactions !== "") url += `&search=${searchBarValueTransactions}`;
    navigate(url);
  }, [searchBarValueTransactions, periodOfTransactions]);
  useEffect(() => {
    let url = "/transactions";
    url += `?reference=${periodOfTransactions}`;
    if (searchBarValueTransactions !== "") url += `&search=${searchBarValueTransactions}`;
    navigate(url);
  }, [searchBarValueTransactions, periodOfTransactions]);
  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const paramsObj = Array.from(params.keys()).reduce(
      (acc, val) => ({ ...acc, [val]: params.get(val) }),
      {}
    );
    let param = "";
    if (paramsObj.reference !== "" && paramsObj.reference)
      param += `?reference=${paramsObj.reference}`;
    if (paramsObj.search !== "" && paramsObj.search) param += `&search=${paramsObj.search}`;
    if (!paramsObj.page) param += `&page=1`;
    else param += `&page=${paramsObj.page}`;
    setCircularProgress(dispatch, true);
    getAllTransactions((isOk, data) => {
      if (!isOk) {
        console.log("get Transactions List from api is not working", data);
      } else {
        setTransationsData(dispatch, data.data);
        setPaginationCount(data.meta.last_page);
      }
      setCircularProgress(dispatch, false);
    }, param);
  }, [pathname, window.location.search]);

  const handleChange = (event) => {
    setPeriodOfTransactions(event.target.value);
  };
  const handlePaginationChange = (event, value) => {
    setTransactionsPage(value);
    const params = new URLSearchParams(window.location.search);
    const paramsObj = Array.from(params.keys()).reduce(
      (acc, val) => ({ ...acc, [val]: params.get(val) }),
      {}
    );
    let url = "/transactions";
    url += `?reference=${paramsObj.reference}`;
    url += `&page=${value}`;
    if (searchBarValueTransactions !== "") url += `&search=${searchBarValueTransactions}`;
    navigate(url);
  };
  return (
    <DashboardLayout>
      <TransactionsNavbar />
      <MDBox pt={6} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
                style={{ display: "flex" }}
              >
                <MDTypography variant="h6" color="white">
                  Transactions Table
                </MDTypography>
                <MDBox style={{ marginLeft: "auto" }}>
                  <FormControl style={{ width: "80px" }}>
                    <InputLabel id="Period">Period</InputLabel>
                    <Select
                      variant="standard"
                      labelId="Period"
                      id="Period"
                      style={{
                        backgroundColor: "transparent",
                        color: "white",
                        marginTop: "10px",
                      }}
                      inputProps={{
                        textAlign: "center",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                      value={periodOfTransactions}
                      label="Period"
                      onChange={handleChange}
                    >
                      <MenuItem value="monthly">Monthly</MenuItem>
                      <MenuItem value="weekly">Weekly</MenuItem>
                      <MenuItem value="daily">Daily</MenuItem>
                    </Select>
                  </FormControl>
                </MDBox>
              </MDBox>
              <MDBox pt={3}>
                <DataTable
                  table={{ columns, rows }}
                  isSorted={false}
                  entriesPerPage={false}
                  showTotalEntries={false}
                  noEndBorder
                />
              </MDBox>
            </Card>
          </Grid>
        </Grid>
        <MDBox style={{ display: "flex", justifyContent: "center" }}>
          <Pagination
            page={transactionsPage}
            onChange={handlePaginationChange}
            count={paginationCount}
            color="info"
          />
        </MDBox>
      </MDBox>
    </DashboardLayout>
  );
}

export default Transactions;
