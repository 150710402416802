import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDAvatar from "components/MDAvatar";
import PropTypes from "prop-types";
import person from "assets/images/person.png";
import { useMaterialUIController, setClientTab } from "context";
import { useState, useEffect } from "react";
import { Link } from "react-router-dom";

export default function data() {
  const [controller, dispatch] = useMaterialUIController();
  function Author({ image, name, email, id }) {
    return (
      <Link
        onClick={() => {
          setClientTab(dispatch, 5);
        }}
        to={`/clients/detail/${id}`}
        style={{ cursor: "pointer", display: "flex", alignItems: "center" }}
      >
        <MDTypography
          style={{ minWidth: "50px" }}
          display="block"
          variant="button"
          fontWeight="medium"
        >
          #{id}
        </MDTypography>
        <MDAvatar src={image} name={name} size="sm" />
        <MDBox ml={2} lineHeight={1}>
          <MDTypography display="block" variant="button" fontWeight="medium">
            {name}
          </MDTypography>
          <MDTypography variant="caption">{email}</MDTypography>
        </MDBox>
      </Link>
      // </Link>
    );
  }
  Author.propTypes = {
    image: PropTypes.string,
    name: PropTypes.string,
    email: PropTypes.string,
    id: PropTypes.number,
  };
  Author.defaultProps = {
    image: "",
    name: "",
    email: "",
    id: "",
  };
  function TotalIncome({ income }) {
    return (
      <MDBox lineHeight={1} textAlign="left">
        <MDTypography display="block" variant="caption" color="text" fontWeight="medium">
          {income}$
        </MDTypography>
      </MDBox>
    );
  }
  TotalIncome.propTypes = {
    income: PropTypes.string,
  };
  TotalIncome.defaultProps = {
    income: "",
  };
  function TotalLiveTime({ totalLiveTime }) {
    return (
      <MDBox lineHeight={1} textAlign="center">
        {totalLiveTime}
      </MDBox>
    );
  }
  TotalLiveTime.propTypes = {
    totalLiveTime: PropTypes.number,
  };
  TotalLiveTime.defaultProps = {
    totalLiveTime: "",
  };
  const { clientPaymentList } = controller;
  const [currentList, setCurrentList] = useState([]);
  useEffect(() => {
    setCurrentList(clientPaymentList);
  }, [clientPaymentList]);
  const getFullName = (first, last) => {
    if (first === null && last === null) return "not Found";
    let returndText = "";
    if (first === null) returndText += "";
    else returndText += first;
    returndText += " ";
    if (last === null) returndText += "";
    else returndText += last;
    return returndText;
  };
  const getRows = () => {
    const personsInfo = currentList.map((value) => ({
      name: (
        <Author
          image={value.client.avatars === null ? person : value.client.avatars.thumbnail}
          name={getFullName(value.client.first_name, value.client.last_name)}
          email={value.client.email}
          id={value.client.id}
        />
      ),
      "total income": <TotalIncome income={Math.round((value.total_money / 100) * 100) / 100} />,
      "total live time": (
        <MDBox ml={-1} variant="gradient" size="small">
          {Math.round(value.total_time * 100) / 100} min
        </MDBox>
      ),
      "live count": <TotalLiveTime totalLiveTime={value.count} />,
    }));
    return personsInfo;
  };
  return {
    columns: [
      { Header: "name", accessor: "name", width: "45%", align: "left" },
      { Header: "total income", accessor: "total income", align: "center" },
      { Header: "total time", accessor: "total live time", align: "center" },
      { Header: "total payments count", accessor: "live count", align: "center" },
    ],

    rows: getRows(),
  };
}
