import MDAvatar from "components/MDAvatar";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import { useMaterialUIController, setCaseId, setOpenOffersModal } from "context";
import { useState, useEffect } from "react";
import PropTypes from "prop-types";
import person from "assets/images/person.png";
// import MDButton from "components/MDButton";
import { Tooltip } from "@mui/material";
import MDButton from "components/MDButton";

export default function data() {
  const [controller, dispatch] = useMaterialUIController();
  const { casesData } = controller;
  const [currentList, setCurrentList] = useState([]);
  useEffect(() => {
    setCurrentList(casesData);
  }, [casesData]);

  const getFullName = (first, last) => {
    if (first === null && last === null) return "not Found";
    let returndText = "";
    if (first === null) returndText += "";
    else returndText += first;
    returndText += " ";
    if (last === null) returndText += "";
    else returndText += last;
    return returndText;
  };
  function Author({ image, name, email, id }) {
    return (
      <Tooltip title={`id: ${id}`}>
        <MDBox display="flex" alignItems="center" lineHeight={1}>
          <MDAvatar ml={2} src={image} name={name} size="sm" />
          <MDBox ml={2} lineHeight={1}>
            <MDTypography display="block" variant="button" fontWeight="medium">
              {name}
            </MDTypography>
            <MDTypography variant="caption">{email}</MDTypography>
          </MDBox>
        </MDBox>
      </Tooltip>
    );
  }
  Author.propTypes = {
    image: PropTypes.string,
    name: PropTypes.string,
    email: PropTypes.string,
    id: PropTypes.number,
  };
  Author.defaultProps = {
    image: "",
    name: "",
    email: "",
    id: "",
  };
  function Category({ name }) {
    return (
      <MDBox display="flex" alignItems="center" lineHeight={1}>
        <MDBox ml={2} lineHeight={1}>
          <MDTypography display="block" variant="button" fontWeight="medium">
            {name}
          </MDTypography>
        </MDBox>
      </MDBox>
    );
  }
  Category.propTypes = {
    name: PropTypes.string,
  };
  Category.defaultProps = {
    name: "",
  };
  const getRows = () => {
    const personsInfo = currentList.map((value) => ({
      caseId: (
        <MDTypography
          onClick={() => {
            setCaseId(dispatch, value.id);
            setOpenOffersModal(dispatch, true);
          }}
          style={{ minWidth: "50px", cursor: "pointer" }}
          display="block"
          variant="button"
          fontWeight="medium"
        >
          #{value.id}
        </MDTypography>
      ),
      client: value.client ? (
        <Author
          image={value.client.avatars === null ? person : value.client.avatars.thumbnail}
          name={getFullName(value.client.first_name, value.client.last_name)}
          email={value.client.email}
          id={value.client.id}
        />
      ) : (
        <MDTypography component="span" variant="caption" color="text" fontWeight="medium">
          no client
        </MDTypography>
      ),
      status: (
        <MDBox
          ml={-1}
          color={value.status === "active" ? "success" : "dark"}
          variant="gradient"
          size="small"
        >
          {value.status === "assign-to-lawyer" ? "Assigned" : value.status}
        </MDBox>
      ),
      lawyer: value.lawyer ? (
        <Author
          image={value.lawyer.avatars === null ? person : value.lawyer.avatars.thumbnail}
          name={getFullName(value.lawyer.first_name, value.lawyer.last_name)}
          email={value.lawyer.email}
          id={value.lawyer.id}
        />
      ) : (
        <MDTypography component="span" variant="caption" color="text" fontWeight="medium">
          Not found
        </MDTypography>
      ),
      category: value.category ? (
        <Category name={value.category.name} />
      ) : (
        <MDTypography
          component="span"
          variant="caption"
          fontWeight="medium"
          style={{ color: "red" }}
        >
          Deleted Category
        </MDTypography>
      ),
      "create time": (
        <MDTypography component="span" variant="caption" color="text" fontWeight="medium">
          {value.created_at.split("T")[0]}
        </MDTypography>
      ),
      "number of offers": (
        <MDButton
          // color="text"
          variant="gradient"
          fullWidth
          onClick={() => {
            setCaseId(dispatch, value.id);
            setOpenOffersModal(dispatch, true);
          }}
          fontWeight="medium"
          style={{ cursor: "pointer", backgroundColor: "#267FEA", color: "white" }}
        >
          {value.offers.length}
        </MDButton>
      ),
    }));
    return personsInfo;
  };
  return {
    columns: [
      { Header: "case Id", accessor: "caseId", align: "center" },
      { Header: "client", accessor: "client", align: "center" },
      { Header: "status", accessor: "status", align: "center" },
      { Header: "lawyer", accessor: "lawyer", align: "center" },
      { Header: "category", accessor: "category", align: "center" },
      { Header: "create time", accessor: "create time", align: "center" },
      { Header: "number of offers", accessor: "number of offers", align: "center" },
    ],

    rows: getRows(),
  };
}
