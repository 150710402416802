import Divider from "@mui/material/Divider";
import Icon from "@mui/material/Icon";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
// Custom styles for the Configurator
import DrawerRightRoot from "examples/DrawerRight/DrawerRightRoot";
import {
  useMaterialUIController,
  setOpenDrawerRight,
  setCircularProgress,
  setAllRegionsData,
  setAlertText,
  setAlertOpen,
  setAlertColor,
} from "context";
// import { useLocation } from "react-router-dom";
import { useState, useEffect } from "react";
// import MDInput from "components/MDInput";
import Switch from "@mui/material/Switch";
import { createNewRegion, getAllRegions } from "api/api_region";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
// import { FormControlLabel, Radio, RadioGroup } from "@mui/material";

function CreateRegionDrawer() {
  // const [localStatus, setLocalStatus] = useState(0);
  const [localRegionShortName, setLocalRegionShortName] = useState("");
  const [localSubscribe, setLocalSubscribe] = useState(false);
  const [localWallet, setLocalWallet] = useState(false);
  const [localSubPrice, setLocalSubPrice] = useState(null);
  const [USRegionsListData, setUSRegionsListData] = useState([]);
  const [controller, dispatch] = useMaterialUIController();
  const { openDrawerRight, darkMode, USRegionsList } = controller;
  useEffect(() => {
    const list = [];
    for (let i = 0; i < USRegionsList.length; i += 1) {
      list.push(USRegionsList[i].regionName);
    }
    setUSRegionsListData(list);
  }, [USRegionsList]);
  const createRegion = () => {
    const foundedIndex = USRegionsList.findIndex(
      (value) => value.regionName === localRegionShortName
    );
    const body = {
      regionCode: USRegionsList[foundedIndex].regionCode,
      status: "0",
      subscribeAllow: localSubscribe,
      walletAllow: localWallet,
      subscriptionPrice: localSubPrice * 100,
    };
    setCircularProgress(dispatch, true);
    setOpenDrawerRight(dispatch, false);
    createNewRegion((isOk, data) => {
      if (!isOk) {
        console.log("create a new region api is not working", data);
        setAlertText(dispatch, data?.response?.data?.message);
        setAlertOpen(dispatch, true);
        setAlertColor(dispatch, "error");
        setCircularProgress(dispatch, false);
      } else {
        setAlertText(dispatch, "new region is created successfully!");
        setAlertOpen(dispatch, true);
        setAlertColor(dispatch, "success");
        getAllRegions((isOk2, data2) => {
          if (!isOk2) {
            console.log("get regions data from api is not working", data);
            setAlertText(dispatch, data2?.response?.data?.message);
            setAlertOpen(dispatch, true);
            setAlertColor(dispatch, "error");
          } else {
            setAllRegionsData(dispatch, data2);
          }
          setCircularProgress(dispatch, false);
        }, window.location.search);
      }
    }, body);
    setLocalRegionShortName("");
    setLocalSubPrice(null);
    setLocalSubscribe(false);
    setLocalWallet(false);
  };
  useEffect(() => {
    if (!localSubscribe) {
      setLocalSubPrice(null);
    }
  }, [localSubscribe]);
  const handleCloseDrawerRight = () => setOpenDrawerRight(dispatch, false);
  return (
    <DrawerRightRoot variant="permanent" ownerState={{ openDrawerRight }}>
      <MDBox
        display="flex"
        justifyContent="space-between"
        alignItems="baseline"
        pt={4}
        pb={0.5}
        px={3}
      >
        <MDBox>
          <MDTypography variant="h5">Admin Panel Editor</MDTypography>
          <MDTypography variant="body2" color="text">
            Create new region
          </MDTypography>
        </MDBox>

        <Icon
          sx={({ typography: { size }, palette: { dark, white } }) => ({
            fontSize: `${size.lg} !important`,
            color: darkMode ? white.main : dark.main,
            stroke: "currentColor",
            strokeWidth: "2px",
            cursor: "pointer",
            transform: "translateY(5px)",
          })}
          onClick={handleCloseDrawerRight}
        >
          close
        </Icon>
      </MDBox>
      <Divider />
      <MDBox style={{ display: "flex", flexDirection: "row" }}>
        <MDBox style={{ flex: 1 }}>
          <Switch
            checked={localSubscribe}
            onChange={(e) => setLocalSubscribe(e.target.checked)}
            inputProps={{ "aria-label": "controlled" }}
          />
          <MDBox style={{ fontSize: "0.8rem" }}>Allow Subscribe</MDBox>
        </MDBox>
        <MDBox style={{ flex: 1 }}>
          <Switch
            checked={localWallet}
            onChange={(e) => setLocalWallet(e.target.checked)}
            inputProps={{ "aria-label": "controlled" }}
          />
          <MDBox style={{ fontSize: "0.8rem" }}>Allow Wallet</MDBox>
        </MDBox>
      </MDBox>
      <Autocomplete
        disablePortal
        isOptionEqualToValue={(option, value) => option.regionName === value.regionName}
        value={localRegionShortName}
        onChange={(event, newValue) => {
          setLocalRegionShortName(newValue);
        }}
        className="mt-[20px]"
        options={USRegionsListData}
        renderInput={(params) => <TextField {...params} label="Region" />}
        fullWidth
      />
      {localSubscribe && (
        <MDBox className="mt-[20px]" style={{ display: "flex", flexDirection: "row" }} mt={1}>
          <MDBox style={{ flex: 1 }}>
            <MDInput
              value={localSubPrice}
              onChange={(e) => setLocalSubPrice(e.target.value)}
              id="priceInput"
              type="number"
              label="subscription price"
              fullWidth
            />
          </MDBox>
        </MDBox>
      )}
      <MDBox mt={4} mb={1}>
        <MDButton onClick={() => createRegion()} variant="gradient" color="info" fullWidth>
          Create
        </MDButton>
      </MDBox>
    </DrawerRightRoot>
  );
}

export default CreateRegionDrawer;
