import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/RegionNavbar";
import { useEffect } from "react";
import {
  setCircularProgress,
  setAllRegionsData,
  useMaterialUIController,
  setRegionsPageCount,
  setRegionsPage,
} from "context";

import MDBox from "components/MDBox";
import { Pagination } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import Grid from "@mui/material/Grid";
import { getAllRegions } from "../../api/api_region";
import RegionCard from "./RegionCard";

function Regions() {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const [controller, dispatch] = useMaterialUIController();
  const { regionsData, regionPage, regionsPageCount, searchBarValueRegion } = controller;
  useEffect(() => {
    setRegionsPage(dispatch, 1);
  }, [searchBarValueRegion]);
  useEffect(() => {
    let url = "";
    if (regionPage && searchBarValueRegion)
      url += `/regions?page=${regionPage}&search=${searchBarValueRegion}`;
    if (regionPage && !searchBarValueRegion) url += `/regions?page=${regionPage}`;
    navigate(url);
  }, [searchBarValueRegion, regionPage]);

  const handlePaginationChange = (event, value) => {
    setRegionsPage(dispatch, value);
  };
  useEffect(() => {
    setCircularProgress(dispatch, true);
    getAllRegions((isOk, data) => {
      if (!isOk) {
        console.log("get regions data from api is not working", data);
      } else {
        setRegionsPageCount(dispatch, data.meta.last_page);
        setAllRegionsData(dispatch, data);
      }
      setCircularProgress(dispatch, false);
    }, window.location.search);
  }, [pathname, window.location.search]);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
        {regionsData.data &&
          regionsData.data.map((value, index) => (
            <Grid item md={6} lg={3} sm={12}>
              <RegionCard value={value} index={index} />
            </Grid>
          ))}
      </Grid>
      <MDBox py={3} style={{ display: "flex", justifyContent: "center" }}>
        <Pagination
          page={regionPage}
          onChange={handlePaginationChange}
          count={regionsPageCount}
          color="info"
        />
      </MDBox>
    </DashboardLayout>
  );
}

export default Regions;
