import Divider from "@mui/material/Divider";
import Icon from "@mui/material/Icon";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
// Custom styles for the Configurator
import DrawerRightRoot from "examples/DrawerRight/DrawerRightRoot";
import {
  useMaterialUIController,
  setOpenDrawerRight,
  setCircularProgress,
  setLawyersData,
  setAlertText,
  setAlertOpen,
  setAlertColor,
} from "context";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
// import { useLocation } from "react-router-dom";
import { useState, useEffect } from "react";
import { approveLawyerStatus, getLawyersList, blockLawyerStatus } from "api/api_lawyer";

function StatusDrawer() {
  const [controller, dispatch] = useMaterialUIController();
  // const { pathname } = useLocation();
  const { openDrawerRight, darkMode, currentLawyerStatusId, firstNameInDrawerRight, lawyerStatus } =
    controller;
  const [localStatus, setLocalStatus] = useState("");
  const [originalStatus, setOriginalStatus] = useState("");
  const [userName, setUserName] = useState("");
  const [openStatusDialog, setOpenStatusDialog] = useState(false);
  useEffect(() => {
    setLocalStatus(lawyerStatus || "");
    setUserName(firstNameInDrawerRight || "");
    setOriginalStatus(lawyerStatus || "");
  }, [openDrawerRight]);
  const saveStatus = () => {
    setOpenStatusDialog(false);
    setOpenDrawerRight(dispatch, false);
    setCircularProgress(dispatch, true);
    if (localStatus === originalStatus) {
      setCircularProgress(dispatch, false);
    } else if (localStatus === "active") {
      approveLawyerStatus((isOk, data) => {
        if (!isOk) {
          console.log("user approvment api does'nt work! ", data);
          setAlertText(dispatch, "user approvation does'nt work!");
          setAlertOpen(dispatch, true);
          setAlertColor(dispatch, "error");
        } else {
          setAlertText(dispatch, "user approved successfully!");
          setAlertOpen(dispatch, true);
          setAlertColor(dispatch, "success");
          getLawyersList((isOk2, data2) => {
            if (!isOk2) {
              console.log("get Lawyers data from api is not working", data2);
              setCircularProgress(dispatch, false);
            } else {
              setLawyersData(dispatch, data2);
              // setCircularProgress(dispatch, false);
            }
          }, `${window.location.search}`);
        }
      }, currentLawyerStatusId);
    } else {
      setCircularProgress(dispatch, true);
      blockLawyerStatus((isOk, data) => {
        if (!isOk) {
          console.log("user block api does'nt work! ", data);
          setCircularProgress(dispatch, false);
          setAlertText(dispatch, "user block does'nt work!");
          setAlertOpen(dispatch, true);
          setAlertColor(dispatch, "error");
        } else {
          setAlertText(dispatch, "user blocked successfully!");
          setAlertOpen(dispatch, true);
          setAlertColor(dispatch, "success");
          getLawyersList((isOk2, data2) => {
            if (!isOk2) {
              console.log("get Lawyers data from api is not working", data2);
              setCircularProgress(dispatch, false);
            } else {
              setLawyersData(dispatch, data2);
              // setCircularProgress(dispatch, false);
            }
          }, `${window.location.search}`);
        }
      }, currentLawyerStatusId);
    }
  };
  const handleCloseDrawerRight = () => setOpenDrawerRight(dispatch, false);
  return (
    <DrawerRightRoot variant="permanent" ownerState={{ openDrawerRight }}>
      <Dialog
        open={openStatusDialog}
        onClose={() => setOpenStatusDialog(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Activation/Deactivation User</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            you are try to{" "}
            <span style={{ color: localStatus === "active" ? "green" : "red" }}>{localStatus}</span>{" "}
            {userName} <br /> are you sure?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <MDButton
            variant="outlined"
            color="info"
            onClick={() => {
              setOpenDrawerRight(dispatch, false);
              setOpenStatusDialog(false);
            }}
          >
            NO
          </MDButton>
          <MDButton variant="contained" color="info" onClick={() => saveStatus()} autoFocus>
            YES
          </MDButton>
        </DialogActions>
      </Dialog>
      <MDBox
        display="flex"
        justifyContent="space-between"
        alignItems="baseline"
        pt={4}
        pb={0.5}
        px={3}
      >
        <MDBox>
          <MDTypography variant="h5">Admin Panel Editor</MDTypography>
          <MDTypography variant="body2" color="text">
            Edit the status of a Lawyer.
          </MDTypography>
        </MDBox>
        <Icon
          sx={({ typography: { size }, palette: { dark, white } }) => ({
            fontSize: `${size.lg} !important`,
            color: darkMode ? white.main : dark.main,
            stroke: "currentColor",
            strokeWidth: "2px",
            cursor: "pointer",
            transform: "translateY(5px)",
          })}
          onClick={handleCloseDrawerRight}
        >
          close
        </Icon>
      </MDBox>
      <Divider />
      <MDBox>
        <MDBox style={{ fontSize: "1rem" }}>
          select the status of {firstNameInDrawerRight}, then click on save
        </MDBox>
      </MDBox>
      <MDBox mb={2} fullWidth>
        <RadioGroup
          value={localStatus}
          onChange={(e) => setLocalStatus(e.target.value)}
          row
          aria-labelledby="Status"
          name="status"
          defaultValue="active"
        >
          <FormControlLabel
            value="active"
            control={<Radio />}
            label="active"
            labelPlacement="start"
          />
          <FormControlLabel
            value="deactive"
            control={<Radio />}
            label="deactive"
            labelPlacement="start"
          />
        </RadioGroup>
      </MDBox>
      <Divider />
      <MDBox mt={4} mb={1}>
        <MDButton
          onClick={() => {
            if (localStatus !== originalStatus) {
              setOpenStatusDialog(true);
            } else {
              setOpenDrawerRight(dispatch, false);
            }
          }}
          variant="gradient"
          color="info"
          fullWidth
        >
          Save
        </MDButton>
      </MDBox>
    </DrawerRightRoot>
  );
}

export default StatusDrawer;
