// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/PaymentNavbar";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import AccountBalanceIcon from "@mui/icons-material/AccountBalance";
import GroupIcon from "@mui/icons-material/Group";
import {
  useMaterialUIController,
  setPaymentTabValue,
  setSearchBarValuePayment,
  setSearchBarValueDetailPayment,
} from "context";
import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import LawyersPaymentTable from "./lawyersPaymentTable";
import ClientsPaymentTable from "./clientsPaymentTable";

function Payments() {
  const [controller, dispatch] = useMaterialUIController();
  const { paymentTabValue } = controller;
  const { pathname } = useLocation();
  const navigate = useNavigate();
  useEffect(() => {
    if (pathname === "/payments" && window.location.search === "") {
      if (paymentTabValue === 0) navigate("/payments?lawyers");
      else if (paymentTabValue === 1) navigate("/payments?clients");
    }
    if (window.location.search === "?lawyers") {
      setPaymentTabValue(dispatch, 0);
    } else if (window.location.search === "?clients") {
      setPaymentTabValue(dispatch, 1);
    }
  }, []);
  useEffect(() => {
    setSearchBarValuePayment(dispatch, "");
    setSearchBarValueDetailPayment(dispatch, "");
  }, [paymentTabValue]);
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <Tabs
        value={paymentTabValue}
        onChange={(e, newValue) => {
          setPaymentTabValue(dispatch, newValue);
          if (newValue === 0) navigate("/payments?lawyers");
          else navigate("/payments?clients");
        }}
        aria-label="User Type Tab"
      >
        <Tab
          icon={<AccountBalanceIcon />}
          style={{
            fontWeight: paymentTabValue === 0 && "bold",
          }}
          label="LAWYERS"
        />
        <Tab
          icon={<GroupIcon />}
          style={{
            fontWeight: paymentTabValue === 1 && "bold",
          }}
          label="CLIENTS"
        />
      </Tabs>
      {paymentTabValue === 0 ? <LawyersPaymentTable /> : <ClientsPaymentTable />}
    </DashboardLayout>
  );
}

export default Payments;
