import { useEffect } from "react";

// react-router-dom components
import { useLocation, NavLink } from "react-router-dom";

// prop-types is a library for typechecking of props.
import PropTypes from "prop-types";

// @mui material components
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import Link from "@mui/material/Link";
import Icon from "@mui/material/Icon";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
// eslint-disable-next-line no-unused-vars
import MDButton from "components/MDButton";

// Material Dashboard 2 React example components
import SidenavCollapse from "examples/Sidenav/SidenavCollapse";

// Custom styles for the Sidenav
import SidenavRoot from "examples/Sidenav/SidenavRoot";
import sidenavLogoLabel from "examples/Sidenav/styles/sidenav";

// Material Dashboard 2 React context
import {
  useMaterialUIController,
  setMiniSidenav,
  setTransparentSidenav,
  setWhiteSidenav,
  setSearchBarValueLawyer,
  setSearchBarValueRegion,
  setSearchBarValueCategory,
  setSearchBarValuePayment,
  setSearchBarValueDetailPayment,
  setSearchBarValueLive,
  setSearchBarValueDetailLive,
  setSearchBarValueCase,
  setSearchBarValueDetailCase,
  setSearchBarValueClient,
  setSearchOrderLawyer,
  setSearchOrderClient,
  setSortSearchLawyer,
  setSortSearchClient,
  setSearchBarValueTransactions,
  setSearchBarValueTransaction,
  setSettingJoyrideRun,
  setSearchBarValueMessages,
} from "context";
import { IconButton } from "@mui/material";
import { navbarIconButton } from "examples/Navbars/CasesNavbar/styles";

function Sidenav({ color, brand, brandName, routes, ...rest }) {
  const [controller, dispatch] = useMaterialUIController();
  const { miniSidenav, transparentSidenav, whiteSidenav, darkMode } = controller;
  const location = useLocation();
  const { pathname } = useLocation();
  const collapseName = location.pathname.replace("/", "");

  let textColor = "white";

  if (transparentSidenav || (whiteSidenav && !darkMode)) {
    textColor = "dark";
  } else if (whiteSidenav && darkMode) {
    textColor = "inherit";
  }

  const closeSidenav = () => setMiniSidenav(dispatch, true);

  useEffect(() => {
    // A function that sets the mini state of the sidenav.
    function handleMiniSidenav() {
      setMiniSidenav(dispatch, window.innerWidth < 1200);
      setTransparentSidenav(dispatch, window.innerWidth < 1200 ? false : transparentSidenav);
      setWhiteSidenav(dispatch, window.innerWidth < 1200 ? false : whiteSidenav);
    }

    window.addEventListener("resize", handleMiniSidenav);

    // Call the handleMiniSidenav function to set the state with the initial value.
    handleMiniSidenav();

    // Remove event listener on cleanup
    return () => window.removeEventListener("resize", handleMiniSidenav);
  }, [dispatch, location]);

  // const navigate = useNavigate();
  // Render all the routes from the routes.js (All the visible items on the Sidenav)
  const renderRoutes = routes.map(
    ({ type, name, icon, title, noCollapse, key, href, route, className }) => {
      let returnValue;
      let path = "";
      for (let i = 0; i < pathname.split("/").length - 1; i += 1) {
        path += pathname.split("/")[i];
        path += "/";
      }
      if (type === "collapse") {
        if (name !== "noSideNav") {
          returnValue = href ? (
            <Link
              href={href}
              className={className}
              key={key}
              target="_blank"
              rel="noreferrer"
              sx={{ textDecoration: "none" }}
              onClick={() => {
                setSearchBarValueLawyer(dispatch, "");
                setSearchBarValueRegion(dispatch, "");
                setSearchBarValueCategory(dispatch, "");
                setSearchBarValuePayment(dispatch, "");
                setSearchBarValueDetailPayment(dispatch, "");
                setSearchBarValueLive(dispatch, "");
                setSearchBarValueDetailLive(dispatch, "");
                setSearchBarValueCase(dispatch, "");
                setSearchBarValueDetailCase(dispatch, "");
                setSearchBarValueClient(dispatch, "");
                setSearchOrderLawyer(dispatch, "asc");
                setSearchOrderClient(dispatch, "asc");
                setSortSearchLawyer(dispatch, "id");
                setSortSearchClient(dispatch, "id");
              }}
            >
              <SidenavCollapse
                name={name === "Number Verification" ? "Sign In" : name}
                icon={icon}
                className={className}
                active={
                  key === collapseName ||
                  (key === "lawyers" && path === "/lawyers/detail/") ||
                  (key === "clients" && path === "/clients/detail/") ||
                  (key === "payments" && path === "/payments/lawyers/detail/") ||
                  (key === "payments" && path === "/payments/clients/detail/") ||
                  (key === "lives" && path === "/lawyers/lives/") ||
                  (key === "lives" && path === "/clients/lives/") ||
                  (key === "cases" && path === "/lawyers/cases/") ||
                  (key === "cases" && path === "/clients/cases/") ||
                  (key === "transactions" && path === "/transactions") ||
                  (key === "subscriptions" && path === "/subscriptions")
                }
                noCollapse={noCollapse}
              />
            </Link>
          ) : (
            <NavLink key={key} to={route}>
              <SidenavCollapse
                className={className}
                name={name === "Number Verification" ? "Sign In" : name}
                icon={icon}
                onClick={() => {
                  setSearchBarValueLawyer(dispatch, "");
                  setSearchBarValueTransactions(dispatch, "");
                  setSearchBarValueTransaction(dispatch, "");
                  setSearchBarValueMessages(dispatch, "");
                  setSearchBarValueRegion(dispatch, "");
                  setSearchBarValueCategory(dispatch, "");
                  setSearchBarValuePayment(dispatch, "");
                  setSearchBarValueDetailPayment(dispatch, "");
                  setSearchBarValueLive(dispatch, "");
                  setSearchBarValueDetailLive(dispatch, "");
                  setSearchBarValueCase(dispatch, "");
                  setSearchBarValueDetailCase(dispatch, "");
                  setSearchBarValueClient(dispatch, "");
                  setSearchOrderLawyer(dispatch, "asc");
                  setSearchOrderClient(dispatch, "asc");

                  setSortSearchLawyer(dispatch, "id");
                  setSortSearchClient(dispatch, "id");
                  if (key === "Logout") {
                    localStorage.clear();
                  }
                }}
                active={
                  key === collapseName ||
                  (key === "lawyers" && path === "/lawyers/detail/") ||
                  (key === "clients" && path === "/clients/detail/") ||
                  (key === "payments" && path === "/payments/lawyers/detail/") ||
                  (key === "payments" && path === "/payments/clients/detail/") ||
                  (key === "lives" && path === "/lawyers/lives/") ||
                  (key === "lives" && path === "/clients/lives/") ||
                  (key === "cases" && path === "/lawyers/cases/") ||
                  (key === "cases" && path === "/clients/cases/") ||
                  (key === "transactions" && path === "/transactions") ||
                  (key === "subscriptions" && path === "/subscriptions")
                }
              />
            </NavLink>
          );
        }
      } else if (type === "title") {
        returnValue = (
          <MDBox display="flex" alignItems="center">
            <MDTypography
              className={className}
              key={key}
              style={{ fontStyle: "italic", color: "gainsboro" }}
              color={textColor}
              display="block"
              variant="caption"
              fontWeight="bold"
              textTransform="uppercase"
              pl={1}
              mt={2}
              mb={1}
              ml={1}
            >
              {title}
            </MDTypography>
            <MDTypography pl={1} mt={2} mb={1} mr={1} ml="auto">
              <IconButton
                style={{ marginRight: "10px" }}
                sx={navbarIconButton}
                size="small"
                disableRipple
              >
                <Icon
                  style={{ color: "white", opacity: "80%" }}
                  color="white"
                  onClick={() => setSettingJoyrideRun(dispatch, true)}
                >
                  info
                </Icon>
              </IconButton>
            </MDTypography>
          </MDBox>
        );
      } else if (type === "divider") {
        returnValue = (
          <Divider
            key={key}
            light={
              (!darkMode && !whiteSidenav && !transparentSidenav) ||
              (darkMode && !transparentSidenav && whiteSidenav)
            }
          />
        );
      }

      if (name !== "Sign In" && name !== "Number Verification") {
        return returnValue;
      }
      return "";
    }
  );

  return (
    <SidenavRoot
      {...rest}
      variant="permanent"
      ownerState={{ transparentSidenav, whiteSidenav, miniSidenav, darkMode }}
    >
      <MDBox pt={3} pb={1} px={4} textAlign="center">
        <MDBox
          display={{ xs: "block", xl: "none" }}
          position="absolute"
          top={0}
          right={0}
          p={1.625}
          onClick={closeSidenav}
          sx={{ cursor: "pointer" }}
        >
          <MDTypography variant="h6" color="secondary">
            <Icon sx={{ fontWeight: "bold" }}>close</Icon>
          </MDTypography>
        </MDBox>
        <MDBox component={NavLink} to="/" display="flex" alignItems="center">
          {brand && <MDBox component="img" src={brand} alt="Brand" width="1.5rem" />}
          <MDBox
            width={!brandName && "100%"}
            sx={(theme) => sidenavLogoLabel(theme, { miniSidenav })}
          >
            <MDTypography component="h6" variant="button" fontWeight="medium" color={textColor}>
              {brandName}
            </MDTypography>
          </MDBox>
        </MDBox>
      </MDBox>
      <Divider
        light={
          (!darkMode && !whiteSidenav && !transparentSidenav) ||
          (darkMode && !transparentSidenav && whiteSidenav)
        }
      />
      <List>{renderRoutes}</List>
    </SidenavRoot>
  );
}

// Setting default values for the props of Sidenav
Sidenav.defaultProps = {
  color: "info",
  brand: "",
};

// Typechecking props for the Sidenav
Sidenav.propTypes = {
  color: PropTypes.oneOf(["primary", "secondary", "info", "success", "warning", "error", "dark"]),
  brand: PropTypes.string,
  brandName: PropTypes.string.isRequired,
  routes: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default Sidenav;
