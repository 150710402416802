import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import MDBox from "components/MDBox";
import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import {
  setSearchBarValueLawyer,
  setSearchBarValueClient,
  setStateContent,
  useMaterialUIController,
} from "context";
import FilterItem from "./FiltersItem";

import grid from "../../../assets/images/grid.png";
import burgerMenu from "../../../assets/images/menu-burger.png";
// import search from "../../../assets/images/search.png";

export default function FiltersUnit({
  filters,
  returnFilters,
  updateTableStyle,
  generalSearch,
  order,
}) {
  const [controller, dispatch] = useMaterialUIController();
  const { stateContent } = controller;
  const [payload, setPayload] = useState([]);
  const [isCardRenderStyle, setIsCardRenderStyle] = useState(false);
  useEffect(() => {
    setTimeout(() => {
      setIsCardRenderStyle(
        JSON.parse(
          document.cookie
            ?.split("; ")
            ?.filter((value) => value.split("=")[0] === "cardRenderingMode")[0]
            ?.split("=")[1] || false
        )
      );
    }, 1);
  }, []);
  const [isSearchActive] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const [sortValue, setSortValue] = useState(order ? "desc " : "desc");
  const [sortMessages, setSortMessages] = useState("Transaction");
  const [orderMessages, setOrderMessages] = useState(
    sortMessages === "Transaction" || sortMessages === "" ? "desc" : "asc"
  );

  useEffect(() => {
    if (sortMessages === "Transaction" || sortMessages === "") {
      setOrderMessages("desc");
    } else {
      setOrderMessages("asc");
    }
  }, [sortMessages]);
  useEffect(() => {
    if (orderMessages === "asc") {
      document.getElementById("orderArrow").style.transform = "rotateZ(0)";
    } else {
      document.getElementById("orderArrow").style.transform = "rotateZ(180deg)";
    }
  }, [orderMessages]);

  useEffect(() => {
    setSortValue(`${orderMessages} ${sortMessages}`);
  }, [orderMessages, sortMessages]);
  useEffect(() => {
    setStateContent(dispatch, "");
    returnFilters(payload);

    const dataTable = localStorage.getItem("tableStyle");

    if (dataTable !== null) {
      if (dataTable.style === "card") setIsCardRenderStyle(true);
      else setIsCardRenderStyle(false);
    }
  }, []);

  useEffect(() => {
    localStorage.setItem(
      "tableStyle",
      JSON.stringify({ style: isCardRenderStyle ? "card" : "row" })
    );
    updateTableStyle(isCardRenderStyle);
  }, [isCardRenderStyle]);

  useEffect(() => {
    const query = {};
    const data = {};

    if (order) {
      if (sortValue.split(" ")[1] === "Transaction") {
        query.sort = "";
      } else {
        // eslint-disable-next-line prefer-destructuring
        query.sort = sortValue.split(" ")[1];
      }
      // eslint-disable-next-line prefer-destructuring
      query.order = sortValue.split(" ")[0];
    } else {
      query.order = sortValue;
    }

    // eslint-disable-next-line guard-for-in, no-restricted-syntax
    for (const item in payload) {
      if (payload[item].type === "query") {
        query[payload[item].name] = payload[item].value;
      } else data[payload[item].name] = payload[item].value;
    }

    if (searchValue !== "") query.search = searchValue;
    returnFilters({ query, data });
  }, [payload, searchValue, sortValue]);

  useEffect(() => setSearchValue(generalSearch), [generalSearch]);
  useEffect(() => {
    setSearchValue("");
    setSearchBarValueLawyer(dispatch, "");
    setSearchBarValueClient(dispatch, "");
  }, [sortMessages, stateContent]);
  const appendFilter = (inputFilter) => {
    if (inputFilter.value === "") {
      const payloadCopy = [];
      // eslint-disable-next-line no-restricted-syntax
      for (const item in payload) {
        if (payload[item].name !== inputFilter.name) payloadCopy.push(payload[item]);
      }
      setPayload(payloadCopy);
    } else if (inputFilter) setPayload([...payload, inputFilter]);
  };
  const handleChangeSortSelect = (event) => {
    setSortMessages(event.target.value);
  };
  const renderMainFilters = () =>
    filters.map(
      (filter, index) =>
        index < 2 &&
        filter.name.toUpperCase() !== "SEARCH" && (
          <FilterItem filter={filter} returnFilter={appendFilter} key={filter.name} />
        )
    );

  const renderOrderBy = () => {
    const getName = (name) => {
      if (name === "created_at") return "Join Time";
      if (name === "status") return "Status";
      if (name === "last_name") return "Last Name";
      return name;
    };
    return (
      <>
        <MDBox
          onClick={() => {
            if (orderMessages === "asc") setOrderMessages("desc");
            else setOrderMessages("asc");
          }}
          className="flex cursor-pointer items-center justify-center flex-col"
        >
          <ArrowUpwardIcon
            style={{
              transition: "200ms",
              position: "relative",
              top: "1px",
            }}
            fontSize="small"
            id="orderArrow"
          />
          <div className="text-[10px]">{orderMessages}</div>
        </MDBox>
        <MDBox sx={{ minWidth: 120, margin: "10px" }}>
          <FormControl fullWidth>
            <InputLabel id="sortSelect">Sort By:</InputLabel>
            <Select
              labelId="sortSelect"
              id="sortSelectBox"
              value={sortMessages}
              label="Sort By:"
              onChange={handleChangeSortSelect}
              style={{ height: "44px" }}
            >
              <MenuItem value="Transaction">Transaction</MenuItem>
              {order.map((value) => (
                <MenuItem key={JSON.stringify(value)} value={value}>
                  {getName(value)}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </MDBox>
      </>
    );
  };

  return (
    <div className="flex flex-row items-center justify-between text-[15px]">
      <div className={`flex flex-row gap-3 items-center ${isSearchActive ? "hidden" : "block"}`}>
        {renderMainFilters()}
      </div>

      <div className="flex flex-row gap-3 items-center">
        {renderOrderBy()}
        <div
          className={`h-[30px] rounded-md bg-gray-300 flex flex-row justify-between items-center py-[3px] px-[3px] ${
            isSearchActive ? "hidden" : "block"
          }`}
        >
          <div
            className={`${isCardRenderStyle ? "bg-white" : ""} py-[4px]  px-3 rounded-md`}
            onClick={() => {
              setIsCardRenderStyle(true);
              document.cookie = `cardRenderingMode=true`;
            }}
            onKeyPress={(event) => {
              if (event.key === "Enter") {
                setIsCardRenderStyle(true);
              }
            }}
            tabIndex={0}
            role="button"
          >
            <img src={grid} className="w-[16px]" alt="" />
          </div>
          <div
            className={`${!isCardRenderStyle ? "bg-white" : ""} py-[2px]  px-3 rounded-md`}
            onClick={() => {
              setIsCardRenderStyle(false);
              document.cookie = `cardRenderingMode=false`;
            }}
            onKeyPress={(event) => {
              if (event.key === "Enter") {
                setIsCardRenderStyle(true);
              }
            }}
            tabIndex={0}
            role="button"
          >
            <img src={burgerMenu} className="w-[20px]" alt="" />
          </div>
        </div>
      </div>
    </div>
  );
}

FiltersUnit.propTypes = {
  filters: PropTypes.any.isRequired,
  returnFilters: PropTypes.any.isRequired,
  updateTableStyle: PropTypes.any.isRequired,
  generalSearch: PropTypes.any.isRequired,
  order: PropTypes.any.isRequired,
};
