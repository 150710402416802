import * as yup from "yup";

const updateInformatonSchema = yup.object().shape({
  first_name: yup.string("first name must be string").required("first name is required"),
  last_name: yup.string("last name must be string").required("last name is required"),
  birthday: yup.string("birthday must be string").required("birthday is required"),
  email: yup.string("email format in wrong!").required("email is required"),
  mobile: yup.string("phone number must be number type").required("phone number is required"),
  sex: yup.string("gender must be string").required("gender is required"),
  status: yup.string("device name must be string"),
});

export default updateInformatonSchema;
