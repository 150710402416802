import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import MDBox from "components/MDBox";
import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import { useMaterialUIController, setStateContent } from "context";

export default function FilterItem({ filter, returnFilter }) {
  const [data, setData] = useState({ name: filter.name, type: filter.type, value: "" });
  const [controller, dispatch] = useMaterialUIController();
  const { stateContent } = controller;

  useEffect(() => {
    setData({ ...data, value: filter.defaultValue });
  }, []);

  useEffect(() => {
    returnFilter(data);
  }, [data]);

  const handleSelectInput = (e) => {
    if (e.target.value !== "ALL") {
      setData({ ...data, value: e.target.value });
    } else {
      setData({ ...data, value: "" });
    }
    setStateContent(dispatch, e.target.value);
  };

  const renderFilterByType = () => {
    switch (filter.inputType) {
      case "select":
        return (
          <MDBox sx={{ minWidth: 320, margin: "10px" }}>
            <FormControl fullWidth>
              <InputLabel id="sortSelect">SELECT THE STATE</InputLabel>
              <Select
                labelId="sortSelect"
                id="sortSelectBox"
                value={stateContent}
                label="SELECT THE STATE"
                onChange={handleSelectInput}
                style={{ height: "44px" }}
              >
                <MenuItem value="ALL">ALL</MenuItem>
                {filter.options.map((value) => (
                  <MenuItem key={JSON.stringify(value.value)} value={value.value}>
                    {value.name.toUpperCase()}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </MDBox>
        );
      case "buttonGroup":
        return (
          <div className="flex flex-row items-center">
            {filter.values.map((value, index) => (
              <button
                type="button"
                onClick={() => setData({ ...data, value })}
                key={value + index.toString()}
                className={`text-[11px] text-black font-[500] border-[1px] border-r-0 p-2 min-w-[40px] ${
                  data.value !== value ? "border-[#cacadc] bg-white" : "bg-gray-300 text-black"
                } ${index === 0 && "rounded-l-md"} ${
                  index === filter.values.length - 1 && "rounded-r-md border-r-[1px]"
                }`}
              >
                {value.toUpperCase()}
              </button>
            ))}
          </div>
        );
      default:
        return <div>{filter.name}</div>;
    }
  };

  return renderFilterByType();
}

FilterItem.propTypes = {
  filter: PropTypes.any.isRequired,
  returnFilter: PropTypes.any.isRequired,
};
