// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import SubscriptionsNavbar from "examples/Navbars/SubscriptionsNavbar";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import AccountBalanceIcon from "@mui/icons-material/AccountBalance";
import GroupIcon from "@mui/icons-material/Group";
import {
  useMaterialUIController,
  setSubscriptionsTabValue,
  setSearchBarValueSubscription,
  setSearchBarValueDetailSubscriptions,
} from "context";
import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import LawyersSubscriptionsTable from "./lawyersSubscriptionsTable";
import ClientsSubscriptionsTable from "./clientsSubscriptionsTable";

function Subscriptions() {
  const [controller, dispatch] = useMaterialUIController();
  const { subscriptionsTabValue } = controller;
  const { pathname } = useLocation();
  const navigate = useNavigate();
  useEffect(() => {
    if (pathname === "/subscriptions" && window.location.search === "") {
      if (subscriptionsTabValue === 0) navigate("/subscriptions?lawyers");
      else if (subscriptionsTabValue === 1) navigate("/subscriptions?clients");
    }
    if (window.location.search === "?lawyers") {
      setSubscriptionsTabValue(dispatch, 0);
    } else if (window.location.search === "?clients") {
      setSubscriptionsTabValue(dispatch, 1);
    }
  }, []);
  useEffect(() => {
    setSearchBarValueSubscription(dispatch, "");
    setSearchBarValueDetailSubscriptions(dispatch, "");
  }, [subscriptionsTabValue]);
  return (
    <DashboardLayout>
      <SubscriptionsNavbar />
      <Tabs
        value={subscriptionsTabValue}
        onChange={(e, newValue) => {
          setSubscriptionsTabValue(dispatch, newValue);
          if (newValue === 0) navigate("/subscriptions?lawyers");
          else navigate("/subscriptions?clients");
        }}
        aria-label="User Type Tab"
      >
        <Tab
          icon={<AccountBalanceIcon />}
          style={{
            fontWeight: subscriptionsTabValue === 0 && "bold",
          }}
          label="LAWYERS"
        />
        <Tab
          icon={<GroupIcon />}
          style={{
            fontWeight: subscriptionsTabValue === 1 && "bold",
          }}
          label="CLIENTS"
        />
      </Tabs>
      {subscriptionsTabValue === 0 ? <LawyersSubscriptionsTable /> : <ClientsSubscriptionsTable />}
    </DashboardLayout>
  );
}

export default Subscriptions;
