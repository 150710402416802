import Card from "@mui/material/Card";
import PropTypes from "prop-types";
import person from "assets/images/person.png";
import { Icon, Tooltip } from "@mui/material";
import { useEffect, useState } from "react";
import EditBtn from "./EditBtn";
import Navbar from "./Navbar";

function UserProfile({ personInfo, tab, setTab }) {
  const [p, setP] = useState(
    personInfo.completion_percentage ? Math.ceil(personInfo.completion_percentage) : 0
  );
  useEffect(() => {
    setP(personInfo.completion_percentage ? Math.ceil(personInfo.completion_percentage) : 0);
  }, [personInfo]);
  const [wallet, setWallet] = useState(0);
  useEffect(() => {
    const walletList = [];
    for (let i = 0; i < 100; i += 1) {
      walletList.push(Math.ceil(Math.random() * personInfo.wallet));
    }
    for (let i = 0; i < 100; i += 1) {
      for (let j = i + 1; j < 100; j += 1) {
        if (walletList[i] > walletList[j]) {
          const temp = walletList[i];
          walletList[i] = walletList[j];
          walletList[j] = temp;
        }
      }
    }
    for (let i = 0; i < 100; i += 1) {
      setTimeout(() => {
        setWallet(walletList[i] / 100);
      }, [10]);
    }
    setTimeout(() => {
      setWallet(personInfo.wallet / 100);
    }, [10]);
  }, [personInfo]);
  const getColorOfStatus = (status) => {
    if (status === "active") return "bg-[#4DA851]";
    if (status === "deactive") return "bg-[#313136]";
    if (status === "terminated") return "bg-[#DE2525]";
    return status;
  };
  const getGenderTooltipText = (sex, customGender) => {
    if (sex === 2) return `person is ${customGender}`;
    if (sex === 0) return `person is male`;
    if (sex === 1) return `person is female`;
    return sex;
  };
  const getBirthMonth = (birth) => {
    const months = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];
    const day = birth.split("-")[2];
    const month = parseInt(birth.split("-")[1], 10);
    return `${day} ${months[month - 1]}`;
  };
  return (
    <Card className="py-4 px-8">
      <div className="h-[200px] w-[100%]">
        <div className="h-[80%] flex flex-row">
          <div className="w-[160px] h-[160px]">
            <img
              className="w-[100%] h-[100%] rounded object-cover"
              src={personInfo?.avatars ? personInfo?.avatars?.large : person}
              alt={`${personInfo?.first_name}'sPhoto`}
            />
            <div className="flex justify-end">
              <Tooltip title={getGenderTooltipText(personInfo.sex, personInfo.custom_gender)}>
                <div className="flex p-3 border items-center relative top-[-145px] right-[-10px] justify-center h-[20px] w-[20px] rounded-full bg-white ">
                  <div className="h-[13px] w-[13px] rounded-full items-center flex justify-center">
                    {personInfo.sex === 0 && <Icon> male </Icon>}
                    {personInfo.sex === 1 && <Icon> female </Icon>}
                    {personInfo.sex !== 0 && personInfo.sex !== 1 && <Icon> question_mark </Icon>}
                  </div>
                </div>
              </Tooltip>
            </div>
            <div className="flex justify-end">
              <Tooltip title={`person is ${personInfo.status}`}>
                <div className="flex items-center relative top-[-45px] right-[-10px] justify-center h-[20px] w-[20px] rounded-full bg-white ">
                  <div
                    className={`h-[13px] w-[13px] rounded-full ${getColorOfStatus(
                      personInfo.status
                    )}`}
                  />
                </div>
              </Tooltip>
            </div>
          </div>
          <div className="w-[80%] px-4">
            <div className="flex flex-row">
              <div>
                <div className="flex flex-row items-center">
                  <div className="font-bold text-[20px]">
                    {personInfo.first_name} {personInfo.last_name}
                  </div>
                  {personInfo.mobile_verified_at && (
                    <Tooltip title={`verified at: ${personInfo.mobile_verified_at.split("T")[0]}`}>
                      <div className="ml-2 flex">
                        <Icon fontSize="small" className="cursor-pointer text-[#C5E0FF]">
                          verifiedIcon
                        </Icon>
                      </div>
                    </Tooltip>
                  )}
                </div>
                <div className="flex mt-1">
                  <div className="flex items-center justify-center">
                    <div className="flex items-center justify-center">
                      <Icon fontSize="small" className="text-[#919090]">
                        fingerprint
                      </Icon>
                    </div>
                    <div className="ml-1 text-[13px] text-[#919090]">{personInfo.id}</div>
                  </div>
                  <div className="flex items-center justify-center ml-4">
                    <div className="flex items-center justify-center">
                      <Icon fontSize="small" className="text-[#919090]">
                        cake
                      </Icon>
                    </div>
                    <div className="ml-1 text-[13px] text-[#919090]">
                      {personInfo.birthday ? getBirthMonth(personInfo.birthday) : "not found"}
                    </div>
                  </div>
                  <div className="flex items-center justify-center ml-4">
                    <div className="flex items-center justify-center">
                      <Icon fontSize="small" className="text-[#919090]">
                        email
                      </Icon>
                    </div>
                    <div className="ml-1 text-[13px] text-[#919090]">{personInfo.email}</div>
                  </div>
                  <div className="flex items-center justify-center ml-4">
                    <div className="flex items-center justify-center">
                      <Icon fontSize="small" className="text-[#919090]">
                        call
                      </Icon>
                    </div>
                    <div className="ml-1 text-[13px] text-[#919090]">{personInfo.mobile}</div>
                  </div>
                </div>
              </div>
              <EditBtn personInfo={personInfo} />
            </div>
            <div className="flex mt-5 items-center">
              <div className="ml-3 border border-dashed py-2 px-6  rounded flex flex-col">
                <div className="flex items-center">
                  <div className="flex">
                    <div className="flex text-yellow-500">$</div>
                  </div>
                  <div className="ml-1 text-[16px] font-bold text-black">{wallet}</div>
                </div>
                <div className="ml-1 text-[16px] text-[#919090]">Wallet</div>
              </div>
              <div className="ml-6 w-[100%] text-[14px]">
                <div className="flex">
                  <div className="text-[13px] text-[#919090]">Profile completion</div>
                  <div className="text-[15px] font-bold text-black ml-auto">{p}%</div>
                </div>
                <div>
                  <div className="w-[100%] h-[5px] mt-2 rounded-full bg-[#E1E1E1]" />
                  <div
                    style={{ width: `${p}%` }}
                    className="h-[5px] relative top-[-5px] z-50 rounded-full bg-green-500"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="h-[20%] mt-2 flex items-center">
          <Navbar tab={tab} setTab={setTab} />
        </div>
      </div>
    </Card>
  );
}
UserProfile.propTypes = {
  personInfo: PropTypes.object,
  tab: PropTypes.number,
  setTab: PropTypes.func,
};
UserProfile.defaultProps = {
  personInfo: {},
  tab: 1,
  setTab: () => {},
};
export default UserProfile;
