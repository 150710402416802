import { useState, useEffect } from "react";
import { Routes, Route, Navigate, useLocation } from "react-router-dom";
import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import Icon from "@mui/material/Icon";
import CircularProgress from "@mui/material/CircularProgress";
import MDBox from "components/MDBox";
import Sidenav from "examples/Sidenav";
import DrawerRight from "examples/DrawerRight";
import theme from "assets/theme";
import themeDark from "assets/theme-dark";
import routes from "routes";
import {
  useMaterialUIController,
  setMiniSidenav,
  setOpenDrawerRight,
  setFirstNameInDrawerRight,
  setLastNameInDrawerRight,
  setEmailInDrawerRight,
  setBirthdayInDrawerRight,
  setMobileInDrawerRight,
  setCustomGenderInDrawerRight,
  setGenderInDrawerRight,
  setCreateOrUpdate,
  setODR,
  setCircularProgress,
  setUSRegionsList,
} from "context";
import brandWhite from "assets/images/lawyerLogo.png";
import brandDark from "assets/images/lawyerLogo-dark.png";
import { getAllUSRegions } from "api/api_region";
import JoyrideComponent from "components/Joyride/JoyrideComponent";
import SettingJoyrideComponent from "components/Joyride/SettingJoyrideComponent";
import LawyersJoyrideComponent from "components/Joyride/LawyersJoyride";
import ClientsJoyrideComponent from "components/Joyride/ClientsJoyride";
// import AutoLogout from "AutoLogout";

export default function App() {
  const [controller, dispatch] = useMaterialUIController();
  const {
    miniSidenav,
    joyrideRun,
    lawyersjoyrideRun,
    clientsjoyrideRun,
    settingjoyrideRun,
    layout,
    openDrawerRight,
    sidenavColor,
    transparentSidenav,
    whiteSidenav,
    darkMode,
    circularProgress,
  } = controller;
  const [onMouseEnter, setOnMouseEnter] = useState(false);
  const { pathname } = useLocation();
  useEffect(() => {
    setOpenDrawerRight(dispatch, false);
  }, [pathname]);
  useEffect(() => {
    setOpenDrawerRight(dispatch, false);
  }, []);

  // Open sidenav when mouse enter on mini sidenav
  const handleOnMouseEnter = () => {
    if (miniSidenav && !onMouseEnter) {
      setMiniSidenav(dispatch, false);
      setOnMouseEnter(true);
    }
  };
  // Close sidenav when mouse leave mini sidenav
  const handleOnMouseLeave = () => {
    if (onMouseEnter) {
      setMiniSidenav(dispatch, true);
      setOnMouseEnter(false);
    }
  };

  // Change the openConfigurator state
  const handleDrawerOpen = () => {
    // if (pathname === "lawyers")
    setMobileInDrawerRight(dispatch, "");
    setFirstNameInDrawerRight(dispatch, "");
    setLastNameInDrawerRight(dispatch, "");
    setBirthdayInDrawerRight(dispatch, "");
    setEmailInDrawerRight(dispatch, "");
    setGenderInDrawerRight(dispatch, "");
    setCustomGenderInDrawerRight(dispatch, "");
    setCreateOrUpdate(dispatch, false);
    setOpenDrawerRight(dispatch, !openDrawerRight);
    if (pathname === "/regions") {
      setODR(dispatch, "createRegion");
      setCircularProgress(dispatch, true);
      getAllUSRegions((isOk, data) => {
        if (!isOk) {
          console.log("get all US regions api is not working", data);
          setCircularProgress(dispatch, false);
        } else {
          setUSRegionsList(dispatch, data);
          setCircularProgress(dispatch, false);
          setOpenDrawerRight(dispatch, true);
        }
      });
    } else if (pathname === "/categories") {
      setODR(dispatch, "createCategory");
    } else if (pathname === "/faq") {
      setODR(dispatch, "createFaq");
    } else {
      setODR(dispatch, "editOrCreate");
    }
  };
  // Setting page scroll to 0 when changing the route
  useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
  }, [pathname]);

  const getRoutes = (allRoutes) =>
    allRoutes.map((route) => {
      if (route.collapse) {
        return getRoutes(route.collapse);
      }

      if (route.route) {
        return <Route exact path={route.route} element={route.component} key={route.key} />;
      }

      return null;
    });
  const drawerButton = (
    <MDBox
      display="flex"
      justifyContent="center"
      alignItems="center"
      width="3.25rem"
      height="3.25rem"
      bgColor="white"
      shadow="sm"
      borderRadius="50%"
      position="fixed"
      right="2rem"
      bottom="2rem"
      zIndex={99}
      color="dark"
      sx={{ cursor: "pointer" }}
      onClick={handleDrawerOpen}
      style={{ backgroundColor: "#2E87EC" }}
    >
      <Icon fontSize="small" style={{ color: "white" }}>
        add
      </Icon>
    </MDBox>
  );
  return (
    <div>
      {pathname === "/dashboard" && joyrideRun && <JoyrideComponent />}
      {settingjoyrideRun && <SettingJoyrideComponent />}
      {pathname.split("/")[1] === "lawyers" &&
        pathname.split("/")[2] === "detail" &&
        lawyersjoyrideRun && <LawyersJoyrideComponent />}
      {pathname.split("/")[1] === "clients" &&
        pathname.split("/")[2] === "detail" &&
        clientsjoyrideRun && <ClientsJoyrideComponent />}
      <ThemeProvider theme={darkMode ? themeDark : theme}>
        <CssBaseline />
        {layout === "dashboard" && (
          <>
            <MDBox
              sx={{
                display: circularProgress ? "flex" : "none",
                position: "fixed",
                zIndex: 99,
                justifyContent: "center",
                alignItems: "center",
                height: "100vh",
                width: "100vw",
                opacity: "40%",
              }}
              style={{ backgroundColor: "black" }}
            >
              <CircularProgress color="info" />
            </MDBox>
            <Sidenav
              color={sidenavColor}
              brand={(transparentSidenav && !darkMode) || whiteSidenav ? brandDark : brandWhite}
              brandName="Lawyer Army Admin panel"
              routes={routes}
              onMouseEnter={handleOnMouseEnter}
              onMouseLeave={handleOnMouseLeave}
            />
            <DrawerRight />
            {(pathname === "/lawyers" ||
              pathname === "/clients" ||
              pathname === "/regions" ||
              pathname === "/faq" ||
              pathname === "/categories") &&
              drawerButton}
          </>
        )}
        {layout === "vr" && <DrawerRight />}
        <Routes>
          <Route path="*" element={<Navigate to="/dashboard" />} />
          {getRoutes(routes)}
        </Routes>
      </ThemeProvider>
    </div>
  );
}
