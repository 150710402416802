// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import DataTable from "examples/TablesLawyersLive/DataTable";
import {
  useMaterialUIController,
  setCircularProgress,
  setLawyerTransfersData,
  setTab,
} from "context";
import { useLocation } from "react-router-dom";
import { useEffect } from "react";
import { Icon } from "@mui/material";
import MDButton from "components/MDButton";
import { getTransfersOfAnLawyer } from "api/api_payments";
import authorsTableData from "./data/authorsTableData";
import Modal from "../../sessions/all/modal";

function Tables() {
  const [controller, dispatch] = useMaterialUIController();
  const { columns, rows } = authorsTableData();
  const { pathname } = useLocation();
  const { lawyerTransfersData, reloadTransfer } = controller;
  let id = pathname.split("/");
  id = id[id.length - 1];
  id = id.split("?");
  [id] = id;

  useEffect(() => {
    const body = {
      userId: id,
    };
    setCircularProgress(dispatch, true);
    getTransfersOfAnLawyer((isOk, data) => {
      if (!isOk) {
        console.log("get lawyers Transfers detail List from api is not working", data);
      } else {
        setLawyerTransfersData(dispatch, data.data);
      }
      setCircularProgress(dispatch, false);
    }, body);
  }, [pathname, window.location.search, reloadTransfer]);

  if (lawyerTransfersData?.length === 0)
    return (
      <div className="flex flex-col mb-2 items-center h-[200px] justify-center">
        <div>
          <Icon fontSize="large">emoji_symbols</Icon>
        </div>
        <div className="font-bold text-[20px]">No Transfers were found for this lawyer </div>
        <MDButton
          onClick={() => setTab(dispatch, 1)}
          style={{ backgroundColor: "#0295E9", color: "white", marginTop: "20px" }}
        >
          Back To Oveview
        </MDButton>
      </div>
    );
  return (
    <>
      <Modal />
      <MDBox pb={3}>
        <Grid container>
          <Grid item xs={12}>
            <Card>
              <MDBox pt={3}>
                <DataTable
                  table={{ columns, rows }}
                  isSorted={false}
                  entriesPerPage={false}
                  showTotalEntries={false}
                  noEndBorder
                />
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
    </>
  );
}

export default Tables;
