import { AxiosInstanceWithXAuthToken, AxiosInstanceWithXAuthTokenStripe } from "./api";

export const getLawyerPayments = (callback, lawyerId, params) => {
  AxiosInstanceWithXAuthToken()
    .get(`/api/v1/admins/lawyers/payments/range/${lawyerId}${params}`)
    .then((res) => callback(true, res.data))
    .catch((err) => {
      if (err.response.status === 401) {
        localStorage.clear();
        window.location.pathname = "/authentication/verify-number";
      }
      callback(false, err);
    });
};

export const payForLawyer = (callback, body) => {
  AxiosInstanceWithXAuthToken()
    .post(`/api/v1/admins/lawyers/payments/transfer`, body)
    .then((res) => callback(true, res.data))
    .catch((err) => {
      if (err.response.status === 401) {
        localStorage.clear();
        window.location.pathname = "/authentication/verify-number";
      }
      callback(false, err);
    });
};

export const getAllTransfers = (callback, params = "") => {
  AxiosInstanceWithXAuthTokenStripe()
    .get(`/api/v1/lawyer/transfers${params}`)
    .then((res) => callback(true, res.data))
    .catch((err) => {
      if (err.response.status === 401) {
        localStorage.clear();
        window.location.pathname = "/authentication/verify-number";
      }
      callback(false, err);
    });
};
export const getTransfersOfAnLawyer = (callback, body, params = "") => {
  AxiosInstanceWithXAuthTokenStripe()
    .post(`/api/v1/lawyer/transfers/user${params}`, body)
    .then((res) => callback(true, res.data))
    .catch((err) => {
      if (err.response.status === 401) {
        localStorage.clear();
        window.location.pathname = "/authentication/verify-number";
      }
      callback(false, err);
    });
};
export const getTransferInfo = (callback, transferId, body, params = "") => {
  AxiosInstanceWithXAuthTokenStripe()
    .post(`/api/v1/lawyer/transfers/user/view/${transferId}${params}`, body)
    .then((res) => callback(true, res.data))
    .catch((err) => {
      if (err.response.status === 401) {
        localStorage.clear();
        window.location.pathname = "/authentication/verify-number";
      }
      callback(false, err);
    });
};
export const getStripeProfile = (callback, body) => {
  AxiosInstanceWithXAuthTokenStripe()
    .post(`/api/v1/lawyer/profile/user`, body)
    .then((res) => callback(true, res.data))
    .catch((err) => {
      if (err.response.status === 401) {
        localStorage.clear();
        window.location.pathname = "/authentication/verify-number";
      }
      callback(false, err);
    });
};
