import { useEffect, useState } from "react";
import Card from "@mui/material/Card";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";

// Authentication layout components
import BasicLayout from "layouts/authentication/components/BasicLayout";

// Images
// import bgImage from "assets/images/bg-sign-in-basic.jpeg";
import { getVerificationCode } from "api/api_auth";
import { useNavigate } from "react-router-dom";
import MDAlert from "components/MDAlert";
import Collapse from "@mui/material/Collapse";
import { useMaterialUIController, setCloseVerificationAlert, setAlertStatus } from "context";
import VerifySchema from "model/VerificationNumberSchema";

function Basic() {
  useEffect(() => {
    localStorage.removeItem("x-auth-token");
  }, []);
  const [mobileNumber, setMobileNumber] = useState(
    localStorage.getItem("mobileNumber") ? localStorage.getItem("mobileNumber") : ""
  );
  const [controller, dispatch] = useMaterialUIController();
  const { closeVerificationAlert } = controller;
  const navigate = useNavigate();
  const [ErrEmptyVerifyInput, setErrEmptyVerifyInput] = useState("");
  const handleCloseAlert = () => {
    setCloseVerificationAlert(dispatch, false);
  };
  useEffect(() => {
    setCloseVerificationAlert(dispatch, false);
  }, []);
  useEffect(() => {
    if (closeVerificationAlert) {
      setAlertStatus(dispatch, "mount");
    } else {
      setAlertStatus(dispatch, "fadeOut");
    }
  }, [closeVerificationAlert]);
  const changeInput = (e) => {
    setMobileNumber(e.target.value);
  };

  const VerifyBtnClicked = () => {
    const number = document.getElementById("phoneNumberInput").value;
    const body = { mobile: number };
    VerifySchema.validate(body, { abortEarly: false })
      .then((valid) => {
        getVerificationCode(valid, (isOk, data) => {
          if (!isOk) {
            setErrEmptyVerifyInput(data.response.data.message);
            setCloseVerificationAlert(dispatch, true);
          } else {
            localStorage.setItem("mobileNumber", number);
            navigate("/authentication/sign-in");
          }
        });
      })
      .catch((err) => {
        setErrEmptyVerifyInput(err.errors[0].split(",")[0]);
        setCloseVerificationAlert(dispatch, true);
      });
  };
  const submitVeifyForm = (e) => {
    e.preventDefault();
    VerifyBtnClicked();
  };

  return (
    <BasicLayout image="https://source.unsplash.com/random/1800x800?natural">
      <Collapse in={closeVerificationAlert}>
        <MDAlert color="warning" dismissible handleCloseAlert={handleCloseAlert}>
          <div>{ErrEmptyVerifyInput}</div>
        </MDAlert>
      </Collapse>
      <Card>
        <MDBox
          variant="gradient"
          bgColor="info"
          borderRadius="lg"
          coloredShadow="info"
          mx={2}
          mt={-3}
          p={2}
          mb={1}
          style={{ color: "blue", backgroundColor: "red" }}
          textAlign="center"
        >
          <MDTypography variant="h4" fontWeight="medium" color="white" mt={1}>
            Verify Number
          </MDTypography>
        </MDBox>
        <MDBox pt={4} pb={3} px={3}>
          <MDBox component="form" onSubmit={(e) => submitVeifyForm(e)} role="form">
            <MDBox mb={2}>
              <MDInput
                value={mobileNumber}
                onChange={(e) => changeInput(e)}
                id="phoneNumberInput"
                type="text"
                label="Phone Number"
                fullWidth
              />
            </MDBox>
            <MDBox mt={4} mb={1}>
              <MDButton
                onClick={() => VerifyBtnClicked()}
                variant="gradient"
                color="info"
                fullWidth
              >
                Verify
              </MDButton>
            </MDBox>
          </MDBox>
        </MDBox>
      </Card>
    </BasicLayout>
  );
}

export default Basic;
