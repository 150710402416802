// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import DataTable from "examples/TablesLawyersLive/DataTable";
import {
  useMaterialUIController,
  setCircularProgress,
  setLawyerLivesDetails,
  setTab,
} from "context";
import { useLocation, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { getAllLives } from "api/api_lives";
import { Icon, Pagination } from "@mui/material";
import MDButton from "components/MDButton";
import authorsTableData from "./data/authorsTableData";

function Tables() {
  const [controller, dispatch] = useMaterialUIController();
  const { searchBarValueDetailLive, lawyerLiveDetails } = controller;
  const [paginationCount, setPaginationCount] = useState(1);
  const [lawyerLivesPage, setLawyerLivesPage] = useState(1);
  const { columns, rows } = authorsTableData();
  const { pathname } = useLocation();
  let id = pathname.split("/");
  id = id[id.length - 1];
  id = id.split("?");
  [id] = id;
  const navigate = useNavigate();
  useEffect(() => {
    let url = "";
    url += `/lawyers/detail/${id}`;
    url += `?reference=monthly&page=${lawyerLivesPage}`;
    if (searchBarValueDetailLive !== "") url += `&search=${searchBarValueDetailLive}`;
    navigate(url);
  }, [searchBarValueDetailLive, lawyerLivesPage]);
  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const paramsObj = Array.from(params.keys()).reduce(
      (acc, val) => ({ ...acc, [val]: params.get(val) }),
      {}
    );
    if (!paramsObj.page) setLawyerLivesPage(1);
    else setLawyerLivesPage(parseInt(paramsObj.page, 10));
    let param = `?lawyer=${id}`;
    if (paramsObj.reference !== "" && paramsObj.reference)
      param += `&reference=${paramsObj.reference}`;
    param += `&page=${paramsObj.page}`;
    if (paramsObj.search !== "" && paramsObj.search) param += `&search=${paramsObj.search}`;
    setCircularProgress(dispatch, true);
    getAllLives((isOk, data) => {
      if (!isOk) {
        console.log("get Lawyers Lives List from api is not working", data);
        setCircularProgress(dispatch, false);
      } else {
        setPaginationCount(data.meta.last_page);
        setLawyerLivesDetails(dispatch, data.data);
        setCircularProgress(dispatch, false);
      }
    }, param);
  }, [pathname, window.location.search]);

  const handlePaginationChange = (event, value) => {
    let url = "";
    url += `/lawyers/detail/${id}`;
    url += `?reference=monthly&page=${value}`; // monthly is for test
    if (searchBarValueDetailLive !== "") url += `&search=${searchBarValueDetailLive}`;
    navigate(url);
  };
  useEffect(() => {
    setLawyerLivesPage(1);
  }, [searchBarValueDetailLive]);
  if (lawyerLiveDetails?.length === 0)
    return (
      <div className="flex flex-col mb-2 items-center h-[200px] justify-center">
        <div>
          <Icon fontSize="large">emoji_symbols</Icon>
        </div>
        <div className="font-bold text-[20px]">No Live were found for this lawyer </div>
        <MDButton
          onClick={() => setTab(dispatch, 1)}
          style={{ backgroundColor: "#0295E9", color: "white", marginTop: "20px" }}
        >
          Back To Oveview
        </MDButton>
      </div>
    );
  return (
    <>
      <MDBox pb={3}>
        <Grid container>
          <Grid item xs={12}>
            <Card>
              <MDBox pt={3}>
                <DataTable
                  table={{ columns, rows }}
                  isSorted={false}
                  entriesPerPage={false}
                  showTotalEntries={false}
                  noEndBorder
                />
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <MDBox style={{ display: "flex", justifyContent: "center" }}>
        <Pagination
          page={lawyerLivesPage}
          onChange={handlePaginationChange}
          count={paginationCount}
          color="info"
        />
      </MDBox>
    </>
  );
}

export default Tables;
