import { Box, Button, Icon } from "@mui/material";
import MDTypography from "components/MDTypography";
import {
  setDeleteFaqModal,
  setOpenDrawerRight,
  setODR,
  setFaqId,
  setFaqQuestion,
  setFaqAnswer,
  setFaqPublish,
  setFaqType,
  useMaterialUIController,
} from "context";

import { useState, useEffect } from "react";

export default function data() {
  const [controller, dispatch] = useMaterialUIController();
  const { faqData } = controller;
  const [currentList, setCurrentList] = useState([]);
  useEffect(() => {
    setCurrentList(faqData);
  }, [faqData]);
  const getIsoString = (date) => {
    const currentDate = new Date(
      date.split("T")[0].split("-")[0],
      parseInt(date.split("T")[0].split("-")[1], 10) - 1,
      date.split("T")[0].split("-")[2],
      date.split("T")[1].split(".")[0].split(":")[0],
      date.split("T")[1].split(".")[0].split(":")[1],
      date.split("T")[1].split(".")[0].split(":")[2]
    );
    const tzoffset = new Date().getTimezoneOffset() * 60000; // offset in milliseconds
    const localISOTime = new Date(currentDate - tzoffset - tzoffset);
    return localISOTime.toISOString();
  };
  const editFaqClicked = (value) => {
    setFaqId(dispatch, value.id);
    setFaqQuestion(dispatch, value.question);
    setFaqAnswer(dispatch, value.answer);
    setFaqPublish(dispatch, value.publish);
    setFaqType(dispatch, value.type);
    setODR(dispatch, "editFaq");
    setTimeout(() => {
      setOpenDrawerRight(dispatch, true);
    }, 1);
  };
  const deleteFaqClicked = (id) => {
    setFaqId(dispatch, id);
    setDeleteFaqModal(dispatch, true);
  };
  const getRows = () => {
    const personsInfo = currentList.map((value) => ({
      question: (
        <Box className="no" overflow="auto" width="400px" maxHeight="200px">
          {value.question}
        </Box>
      ),
      answer: (
        <Box className="no" overflow="auto" width="400px" maxHeight="200px">
          {value.answer}
        </Box>
      ),
      "is published": <div>{value.publish ? "YES" : "NO"}</div>,
      type: <div>{value.type}</div>,
      "creation time": (
        <div>
          {getIsoString(value.created_at).split("T")[0]}{" "}
          {getIsoString(value.created_at).split("T")[1].split(".")[0]}
        </div>
      ),
      edit: (
        <Button
          style={{ cursor: "pointer" }}
          onClick={() => editFaqClicked(value)}
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <MDTypography variant="caption" color="text" fontWeight="medium">
            Edit
          </MDTypography>
          <Icon style={{ cursor: "pointer", color: "gray", marginLeft: "5px" }}>edit</Icon>
        </Button>
      ),
      delete: (
        <Button
          style={{ cursor: "pointer" }}
          onClick={() => deleteFaqClicked(value.id)}
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <MDTypography variant="caption" color="text" fontWeight="medium">
            Delete
          </MDTypography>
          <Icon style={{ cursor: "pointer", color: "gray", marginLeft: "5px" }}>delete</Icon>
        </Button>
      ),
    }));
    return personsInfo;
  };
  return {
    columns: [
      { Header: "question", accessor: "question", align: "center" },
      { Header: "answer", accessor: "answer", align: "center" },
      { Header: "is published", accessor: "is published", align: "center" },
      { Header: "creation time", accessor: "creation time", align: "center" },
      { Header: "type", accessor: "type", align: "center" },
      { Header: "edit", accessor: "edit", align: "center" },
      { Header: "delete", accessor: "delete", align: "center" },
    ],

    rows: getRows(),
  };
}
