import { useState, useRef } from "react";
import { useMaterialUIController, setLawyersJoyrideRun } from "context";
import Joyride, { STATUS } from "react-joyride";
import TourIntroduction from "../components/TourIntroduction";

export default function LawyersJoyrideComponent() {
  const [controller, dispatch] = useMaterialUIController();
  const { lawyersJoyrideRun } = controller;
  const tourRef = useRef(null);
  const stepsLength = 9;
  const [steps] = useState([
    {
      disableBeacon: true,
      content: (
        <TourIntroduction
          tourRef={tourRef}
          stepsLength={stepsLength}
          stepIndex={0}
          headerText="Overview section"
          bodyText="In this section, we show the general statistics of this lawyer"
        />
      ),
      placement: "bottom",
      styles: {
        tooltip: {
          display: "flex",
          flexDirection: "column",
          justifyContent: "top",
          width: "400px",
          top: "-15px",
        },
        spotlight: {
          borderRadius: "8px",
        },
      },
      target: ".lawyerDetailOverviewTitle",
    },
    {
      disableBeacon: true,
      content: (
        <TourIntroduction
          tourRef={tourRef}
          stepsLength={stepsLength}
          stepIndex={1}
          headerText="Certificate table"
          bodyText="In this section, the lawyer's certificates and their details are shown"
        />
      ),
      placement: "bottom",
      styles: {
        tooltip: {
          display: "flex",
          flexDirection: "column",
          justifyContent: "top",
          width: "400px",
          top: "-15px",
        },
        spotlight: {
          borderRadius: "8px",
        },
      },
      target: ".lawyerDetailCertificateTitle",
    },
    {
      disableBeacon: true,
      content: (
        <TourIntroduction
          tourRef={tourRef}
          stepsLength={stepsLength}
          stepIndex={2}
          headerText="Offices table"
          bodyText="In this section, the lawyer's offices and their details are shown"
        />
      ),
      placement: "bottom",
      styles: {
        tooltip: {
          display: "flex",
          flexDirection: "column",
          justifyContent: "top",
          width: "400px",
          top: "-15px",
        },
        spotlight: {
          borderRadius: "8px",
        },
      },
      target: ".lawyerDetailOfficesTitle",
    },
    {
      disableBeacon: true,
      content: (
        <TourIntroduction
          tourRef={tourRef}
          stepsLength={stepsLength}
          stepIndex={3}
          headerText="Lives table"
          bodyText="The table of lawyer's lives and information about each live is given in the corresponding row"
        />
      ),
      placement: "bottom",
      styles: {
        tooltip: {
          display: "flex",
          flexDirection: "column",
          justifyContent: "top",
          width: "400px",
          top: "-15px",
        },
        spotlight: {
          borderRadius: "8px",
        },
      },
      target: ".lawyerDetailLivesTitle",
    },
    {
      disableBeacon: true,
      content: (
        <TourIntroduction
          tourRef={tourRef}
          stepsLength={stepsLength}
          stepIndex={4}
          headerText="Cases table"
          bodyText="The table of lawyer's Cases and information about each Case is given in the corresponding row"
        />
      ),
      placement: "bottom",
      styles: {
        tooltip: {
          display: "flex",
          flexDirection: "column",
          justifyContent: "top",
          width: "400px",
          top: "-15px",
        },
        spotlight: {
          borderRadius: "8px",
        },
      },
      target: ".lawyerDetailCasesTitle",
    },
    {
      disableBeacon: true,
      content: (
        <TourIntroduction
          tourRef={tourRef}
          stepsLength={stepsLength}
          stepIndex={5}
          headerText="Payments table"
          bodyText="The table of lawyer's Payments and information about each Payment is given in the corresponding row"
        />
      ),
      placement: "bottom",
      styles: {
        tooltip: {
          display: "flex",
          flexDirection: "column",
          justifyContent: "top",
          width: "400px",
          top: "-15px",
        },
        spotlight: {
          borderRadius: "8px",
        },
      },
      target: ".lawyerDetailPaymentsTitle",
    },
    {
      disableBeacon: true,
      content: (
        <TourIntroduction
          tourRef={tourRef}
          stepsLength={stepsLength}
          stepIndex={6}
          headerText="Sessions table"
          bodyText="The table of lawyer's Sessions and information about each Session is given in the corresponding row"
        />
      ),
      placement: "bottom",
      styles: {
        tooltip: {
          display: "flex",
          flexDirection: "column",
          justifyContent: "top",
          width: "400px",
          top: "-15px",
        },
        spotlight: {
          borderRadius: "8px",
        },
      },
      target: ".lawyerDetailSessionsTitle",
    },
    {
      disableBeacon: true,
      content: (
        <TourIntroduction
          tourRef={tourRef}
          stepsLength={stepsLength}
          stepIndex={7}
          headerText="Transfers table"
          bodyText="The table of lawyer's Transfers and information about each Transfer is given in the corresponding row"
        />
      ),
      placement: "bottom",
      styles: {
        tooltip: {
          display: "flex",
          flexDirection: "column",
          justifyContent: "top",
          width: "400px",
          top: "-15px",
        },
        spotlight: {
          borderRadius: "8px",
        },
      },
      target: ".lawyerDetailTransfersTitle",
    },
    {
      disableBeacon: true,
      content: (
        <TourIntroduction
          tourRef={tourRef}
          stepsLength={stepsLength}
          stepIndex={8}
          headerText="Subscriptions table"
          bodyText="The table of lawyer's Subscriptions and information about each Subscription is given in the corresponding row"
        />
      ),
      placement: "bottom",
      styles: {
        tooltip: {
          display: "flex",
          flexDirection: "column",
          justifyContent: "top",
          width: "400px",
          top: "-15px",
        },
        spotlight: {
          borderRadius: "8px",
        },
      },
      target: ".lawyerDetailSubscriptionsTitle",
    },
  ]);

  const handleJoyrideCallback = (data) => {
    const { status } = data;
    const finishedStatuses = [STATUS.FINISHED, STATUS.SKIPPED];
    if (finishedStatuses.includes(status)) {
      setLawyersJoyrideRun(dispatch, false);
      tourRef.current.store.skip();
    }
  };
  return (
    <Joyride
      callback={handleJoyrideCallback}
      ref={tourRef}
      continuous
      disableScrolling
      hideCloseButton
      hideBackButton
      scrollOffset={100}
      run={lawyersJoyrideRun}
      steps={steps}
      styles={{
        buttonNext: {
          display: "none",
        },
        tooltipContent: {
          padding: "0px",
        },
        tooltipContainer: {
          padding: "0px",
        },
        tooltipFooter: {
          display: "none",
        },
        options: {
          width: "fit-content",
          arrowColor: "transparent",
          zIndex: 10000,
        },
      }}
    />
  );
}
