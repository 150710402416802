// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
// Material Dashboard 2 React components
import "../../styles/generalStyles.css";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import DataTable from "examples/TablesLawyersPayment/DataTable";
import authorsTableData from "layouts/versionsTables/data/authorsTableData";
import { useMaterialUIController, setCircularProgress, setVersionsList } from "context";
import { useLocation, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { Pagination } from "@mui/material";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import { getAppVersions } from "api/api_admin";

function Tables() {
  const [controller, dispatch] = useMaterialUIController();
  if (false) console.log(controller);
  const navigate = useNavigate();
  const { columns, rows } = authorsTableData();
  const [paginationCount, setPaginationCount] = useState(1);
  const [versionsPage, setVersionsPage] = useState(1);
  const { pathname } = useLocation();
  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const paramsObj = Array.from(params.keys()).reduce(
      (acc, val) => ({ ...acc, [val]: params.get(val) }),
      {}
    );
    let url = "/versions";
    if (!paramsObj.page) url += `?page=1`;
    else {
      url += `?page=${paramsObj.page}`;
      setVersionsPage(parseInt(paramsObj.page, 10));
    }
    navigate(url);
  }, []);
  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const paramsObj = Array.from(params.keys()).reduce(
      (acc, val) => ({ ...acc, [val]: params.get(val) }),
      {}
    );
    let param = "";
    if (!paramsObj.page) param += `?page=1`;
    else param += `?page=${paramsObj.page}`;
    param += "sort=created_at";
    setCircularProgress(dispatch, true);
    getAppVersions((isOk, data) => {
      if (!isOk) {
        console.log("get Lawyers Paymens List from api is not working", data);
      } else {
        console.log("getAppVersions: ", data);
        setVersionsList(dispatch, data.data);
        setPaginationCount(data.meta.last_page);
      }
      setCircularProgress(dispatch, false);
    }, param);
  }, [pathname, window.location.search]);
  const handlePaginationChange = (event, value) => {
    setVersionsPage(value);
    let url = "/versions";
    url += `?page=${value}`;
    navigate(url);
  };
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
                style={{ display: "flex" }}
              >
                <MDTypography variant="h6" color="white">
                  Versions Table
                </MDTypography>
              </MDBox>
              <MDBox pt={3}>
                <DataTable
                  table={{ columns, rows }}
                  isSorted={false}
                  entriesPerPage={false}
                  showTotalEntries={false}
                  noEndBorder
                />
              </MDBox>
            </Card>
          </Grid>
        </Grid>
        <MDBox style={{ display: "flex", justifyContent: "center" }}>
          <Pagination
            page={versionsPage}
            onChange={handlePaginationChange}
            count={paginationCount}
            color="info"
          />
        </MDBox>
      </MDBox>
    </DashboardLayout>
  );
}

export default Tables;
