import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { setCircularProgress, useMaterialUIController } from "context";
import { useLocation, useNavigate } from "react-router-dom";
import FiltersUnit from "./FiltersUnit";
import { getData, postData } from "./Api";

export default function FilterMenu({
  api,
  updateData,
  updateTableStyle,
  currentPage,
  updateCurrentPage,
  reload,
  searchValue,
}) {
  const [filterData, setFilterData] = useState();
  const [pathnameHistory, setPathnameHistory] = useState("");
  const [controller, dispatch] = useMaterialUIController();
  if (false) console.log(controller);
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const callAPI = async (payload, apiData) => {
    const { url: apiUrl } = apiData;
    let url = `${apiUrl}?`;

    let params = "";

    // eslint-disable-next-line no-restricted-syntax, guard-for-in
    for (const key in payload?.query) {
      if (params !== "") params += "&";
      params += `${key}=${payload.query[key]}`;
    }

    params += `&page=${payload?.query?.search && payload?.query?.search !== "" ? 1 : currentPage}`;

    url += params;
    let baseAddress;
    let queryList;
    const queryObj = {};
    let key;
    let value;
    switch (api.type) {
      case "GET":
        baseAddress = url.split("?")[0].split("/");
        baseAddress = baseAddress[baseAddress.length - 1];
        queryList = url.split("?")[1].split("&");
        for (let i = 0; i < queryList.length; i += 1) {
          [key, value] = queryList[i].split("=");
          queryObj[key] = value;
        }
        url = `/${baseAddress}`;
        if (!queryObj.page || queryObj.page === "") url += "?page=1";
        else url += `?page=${queryObj.page}`;
        if (queryObj.search) url += `&search=${queryObj.search}`;
        if (queryObj.order) url += `&order=${queryObj.order}`;
        if (queryObj.sort) url += `&sort=${queryObj.sort}`;
        if (queryObj.region) url += `&region=${queryObj.region}`;
        navigate(url);
        break;
      case "POST":
        setCircularProgress(dispatch, true);
        postData(
          (isOk, data) => {
            if (isOk) {
              setCircularProgress(dispatch, false);
              updateData(data);
              if (payload?.query?.search && payload?.query?.search !== "") updateCurrentPage(1);
            } else {
              setCircularProgress(dispatch, false);
            }
          },
          url,
          payload.data
        );
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    const url = `api/v1/admins${pathname}${window.location.search}`;
    if (pathnameHistory !== url) setPathnameHistory(url);
  }, [pathname, window.location.search]);

  useEffect(() => {
    if (pathnameHistory !== "") {
      setCircularProgress(dispatch, true);
      getData((isOk, data) => {
        if (isOk) {
          setCircularProgress(dispatch, false);
          updateData(data);
          if (filterData?.query?.search && filterData?.query?.search !== "") updateCurrentPage(1);
        } else {
          setCircularProgress(dispatch, false);
        }
      }, pathnameHistory);
    }
  }, [pathnameHistory, reload]);
  useEffect(() => {
    callAPI(filterData, api);
  }, [currentPage]);

  useEffect(() => {
    if (reload) callAPI(filterData, api);
  }, [reload]);

  const getFiltersValue = (data) => {
    setFilterData(data);
    callAPI(data, api);
  };

  return (
    <div className="w-full py-2 px-3 rounded-lg bg-white">
      <FiltersUnit
        filters={api.filters}
        returnFilters={getFiltersValue}
        updateTableStyle={updateTableStyle}
        generalSearch={searchValue}
        order={api?.order}
      />
    </div>
  );
}

FilterMenu.propTypes = {
  api: PropTypes.any.isRequired,
  updateData: PropTypes.any.isRequired,
  updateTableStyle: PropTypes.any.isRequired,
  currentPage: PropTypes.any.isRequired,
  updateCurrentPage: PropTypes.any.isRequired,
  reload: PropTypes.any.isRequired,
  searchValue: PropTypes.any.isRequired,
};
