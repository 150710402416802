import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDAvatar from "components/MDAvatar";
import PropTypes from "prop-types";
import person from "assets/images/person.png";
import { useMaterialUIController, setTab } from "context";
import { useState, useEffect } from "react";
import { Link } from "react-router-dom";

export default function data() {
  const [controller, dispatch] = useMaterialUIController();
  const { versionsList } = controller;
  function Author({ image, name, email, id }) {
    return (
      <Link
        onClick={() => {
          setTab(dispatch, 6);
        }}
        to={`/lawyers/detail/${id}`}
      >
        <MDBox display="flex" alignItems="center" lineHeight={1}>
          <MDTypography
            style={{ minWidth: "50px" }}
            display="block"
            variant="button"
            fontWeight="medium"
          >
            #{id}
          </MDTypography>
          <MDAvatar src={image} name={name} size="sm" />
          <MDBox ml={2} lineHeight={1}>
            <MDTypography display="block" variant="button" fontWeight="medium">
              {name}
            </MDTypography>
            <MDTypography variant="caption">{email}</MDTypography>
          </MDBox>
        </MDBox>
      </Link>
    );
  }
  Author.propTypes = {
    image: PropTypes.string,
    name: PropTypes.string,
    email: PropTypes.string,
    id: PropTypes.number,
  };
  Author.defaultProps = {
    image: "",
    name: "",
    email: "",
    id: "",
  };
  function TotalIncome({ income }) {
    return (
      <MDBox lineHeight={1} textAlign="left">
        <MDTypography display="block" variant="caption" color="text" fontWeight="medium">
          {income}$
        </MDTypography>
      </MDBox>
    );
  }
  TotalIncome.propTypes = {
    income: PropTypes.string,
  };
  TotalIncome.defaultProps = {
    income: "",
  };
  function TotalLiveTime({ totalLiveTime }) {
    return (
      <MDBox lineHeight={1} textAlign="center">
        {totalLiveTime}
      </MDBox>
    );
  }
  TotalLiveTime.propTypes = {
    totalLiveTime: PropTypes.number,
  };
  TotalLiveTime.defaultProps = {
    totalLiveTime: "",
  };
  const [currentList, setCurrentList] = useState([]);
  useEffect(() => {
    setCurrentList(versionsList);
  }, [versionsList]);
  /**
   * lkdnskldmfnldfsd
   * @param {number} first ekjfkjds
   * @param {string} last in dare test mikone
   * @returns c.,smd.,d'pposd
   */
  const getFullName = (first, last) => {
    if (first === null && last === null) return "not Found";
    let returndText = "";
    if (first === null) returndText += "";
    else returndText += first;
    returndText += " ";
    if (last === null) returndText += "";
    else returndText += last;
    return returndText;
  };
  const getIsoString = (date) => {
    const currentDate = new Date(
      date.split("T")[0].split("-")[0],
      parseInt(date.split("T")[0].split("-")[1], 10) - 1,
      date.split("T")[0].split("-")[2],
      date.split("T")[1].split(".")[0].split(":")[0],
      date.split("T")[1].split(".")[0].split(":")[1],
      date.split("T")[1].split(".")[0].split(":")[2]
    );
    const tzoffset = new Date().getTimezoneOffset() * 60000; // offset in milliseconds
    const localISOTime = new Date(currentDate - tzoffset - tzoffset);
    return localISOTime.toISOString();
  };
  const getRows = () => {
    const personsInfo = currentList.map((value) => ({
      admin: (
        <Author
          image={person}
          name={getFullName(value.admin.first_name, value.admin.last_name)}
          email={value.admin.email}
          id={value.admin.id}
        />
      ),
      "creation time": (
        <MDBox lineHeight={1} textAlign="left">
          <MDTypography display="block" variant="caption" color="text" fontWeight="medium">
            <div>{getIsoString(value.created_at).split("T")[0]}</div>
            <div>{getIsoString(value.created_at).split("T")[1].split(".")[0]}</div>
          </MDTypography>
        </MDBox>
      ),
      "minimum version": (
        <MDBox lineHeight={1} textAlign="left">
          <MDTypography display="block" variant="caption" color="text" fontWeight="medium">
            {value.min_version}
          </MDTypography>
        </MDBox>
      ),
      "current version": (
        <MDBox lineHeight={1} textAlign="left">
          <MDTypography display="block" variant="caption" color="text" fontWeight="medium">
            {value.current_version}
          </MDTypography>
        </MDBox>
      ),
      platform: (
        <MDBox lineHeight={1} textAlign="left">
          <MDTypography display="block" variant="caption" color="text" fontWeight="medium">
            {value.platform}
          </MDTypography>
        </MDBox>
      ),
      "user type": (
        <MDBox lineHeight={1} textAlign="left">
          <MDTypography display="block" variant="caption" color="text" fontWeight="medium">
            {value.type}
          </MDTypography>
        </MDBox>
      ),
    }));
    return personsInfo;
  };
  return {
    columns: [
      { Header: "admin", accessor: "admin", width: "45%", align: "left" },
      { Header: "creation time", accessor: "creation time", align: "center" },
      { Header: "minimum version", accessor: "minimum version", align: "center" },
      { Header: "current version", accessor: "current version", align: "center" },
      { Header: "platform", accessor: "platform", align: "center" },
      { Header: "user type", accessor: "user type", align: "center" },
    ],

    rows: getRows(),
  };
}
