import { AxiosInstanceWithXAuthToken } from "./api";

export const getAllRegions = (callback, params = "") => {
  AxiosInstanceWithXAuthToken()
    .get(`/api/v1/admins/regions${params}`)
    .then((res) => callback(true, res.data))
    .catch((err) => {
      if (err.response.status === 401) {
        localStorage.clear();
        window.location.pathname = "/authentication/verify-number";
      }
      callback(false, err);
    });
};
export const getAllUSRegions = (callback, data = "") => {
  AxiosInstanceWithXAuthToken()
    .get(`/api/v1/admins/regions/us${data}`)
    .then((res) => callback(true, res.data))
    .catch((err) => {
      if (err.response.status === 401) {
        localStorage.clear();
        window.location.pathname = "/authentication/verify-number";
      }
      callback(false, err);
    });
};
export const createNewRegion = (callback, body) => {
  AxiosInstanceWithXAuthToken()
    .post(`/api/v1/admins/regions`, body)
    .then((res) => callback(true, res.data))
    .catch((err) => {
      if (err.response.status === 401) {
        localStorage.clear();
        window.location.pathname = "/authentication/verify-number";
      }
      callback(false, err);
    });
};
export const editRegion = (callback, body, id) => {
  AxiosInstanceWithXAuthToken()
    .patch(`/api/v1/admins/regions/${id}`, body)
    .then((res) => callback(true, res.data))
    .catch((err) => {
      console.log(err);
      if (err.response.status === 401) {
        localStorage.clear();
        window.location.pathname = "/authentication/verify-number";
      }
      callback(false, err);
    });
};
export const deleteRegion = (callback, id) => {
  AxiosInstanceWithXAuthToken()
    .delete(`/api/v1/admins/regions/${id}`)
    .then((res) => callback(true, res.data))
    .catch((err) => {
      if (err.response.status === 401) {
        localStorage.clear();
        window.location.pathname = "/authentication/verify-number";
      }
      callback(false, err);
    });
};
