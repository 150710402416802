import * as yup from "yup";

const VerifySchema = yup.object().shape({
  mobile: yup
    .string("phone number must be string")
    .required("phone number is required") // "regex:/^([\+]?[0-9\s]*)$/"
    .matches(/^([+]?[0-9s]*)$/, "Is not in correct format")
    .min(10, "phone number must be have at least 10 digits"),
});

export default VerifySchema;
