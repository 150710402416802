// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import ClientsNavbar from "examples/Navbars/ClientsNavbar";
// import Footer from "examples/Footer";
import DataTable from "components/DataTable";

function Tables() {
  return (
    <DashboardLayout>
      <ClientsNavbar />
      <DataTable
        api={{
          url: "api/v1/admins/clients",
          type: "GET",
          order: ["status", "last_name", "created_at"],
          filters: [
            // {
            //   name: "sort",
            //   type: "query",
            //   inputType: "buttonGroup",
            //   values: ["id", "status", "first_name", "last_name", "birthday"],
            //   defaultValue: "id",
            // },
            { name: "Search", type: "query", inputType: "text" },
          ],
        }}
        type="Client"
      >
        <div className="flex flex-col">
          <div>matinkj:w</div>
        </div>
      </DataTable>
    </DashboardLayout>
  );
}

export default Tables;
