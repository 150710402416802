import MDAvatar from "components/MDAvatar";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import { useMaterialUIController } from "context";
import { useState, useEffect } from "react";
import PropTypes from "prop-types";
import person from "assets/images/person.png";
import MDButton from "components/MDButton";

export default function data() {
  const [controller] = useMaterialUIController();
  const { adminsData } = controller;
  const [currentList, setCurrentList] = useState([]);
  useEffect(() => {
    setCurrentList(adminsData);
  }, [adminsData]);

  const getFullName = (first, last) => {
    if (first === null && last === null) return "not Found";
    let returndText = "";
    if (first === null) returndText += "";
    else returndText += first;
    returndText += " ";
    if (last === null) returndText += "";
    else returndText += last;
    return returndText;
  };
  function Author({ image, name, email, id }) {
    return (
      <MDBox style={{ cursor: "pointer" }} display="flex" alignItems="center" lineHeight={1}>
        <MDTypography
          style={{ minWidth: "50px" }}
          display="block"
          variant="button"
          fontWeight="medium"
        >
          #{id}
        </MDTypography>
        <MDAvatar src={image} name={name} size="sm" />
        <MDBox ml={2} lineHeight={1}>
          <MDTypography display="block" variant="button" fontWeight="medium">
            {name}
          </MDTypography>
          <MDTypography variant="caption">{email}</MDTypography>
        </MDBox>
      </MDBox>
    );
  }
  function Mobile({ mobile }) {
    return (
      <MDBox lineHeight={1} textAlign="left">
        <MDTypography display="block" variant="caption" color="text" fontWeight="medium">
          {mobile}
        </MDTypography>
      </MDBox>
    );
  }
  Mobile.propTypes = {
    mobile: PropTypes.string,
  };
  Mobile.defaultProps = {
    mobile: "",
  };
  Author.propTypes = {
    image: PropTypes.string,
    name: PropTypes.string,
    email: PropTypes.string,
    id: PropTypes.number,
  };
  Author.defaultProps = {
    image: "",
    name: "",
    email: "",
    id: "",
  };
  const getRows = () => {
    const personsInfo = currentList.map((value) => ({
      fullName: (
        <Author
          image={value.avatars === null ? person : value.avatars.thumbnail}
          name={getFullName(value.first_name, value.last_name)}
          email={value.email}
          id={value.id}
        />
      ),
      "mobile number": <Mobile mobile={value.mobile} />,
      status: (
        <MDButton
          ml={-1}
          color={value.status === "active" ? "success" : "dark"}
          variant="gradient"
          size="small"
        >
          {value.status}
        </MDButton>
      ),
      "time join": (
        <MDTypography component="span" variant="caption" color="text" fontWeight="medium">
          {`${value.created_at.split("-")[0]}-${value.created_at.split("-")[1]}-${
            value.created_at.split("-")[2].split("T")[0]
          }`}
        </MDTypography>
      ),
    }));
    return personsInfo;
  };
  return {
    columns: [
      { Header: "Name", accessor: "fullName", width: "45%", align: "left" },
      { Header: "phone number", accessor: "mobile number", align: "center" },
      { Header: "status", accessor: "status", align: "center" },
      { Header: "Joined on", accessor: "time join", align: "center" },
    ],
    rows: getRows(),
  };
}
