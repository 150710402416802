import PropTypes from "prop-types";
import { Menu, MenuItem } from "@mui/material";
import { useState } from "react";
import {
  setUpdateLawyerId,
  setMobileInDrawerRight,
  setFirstNameInDrawerRight,
  setLastNameInDrawerRight,
  setBirthdayInDrawerRight,
  setEmailInDrawerRight,
  setLawyerStatusInDrawerRight,
  setCreateOrUpdate,
  setOpenDrawerRight,
  setGenderInDrawerRight,
  setCustomGenderInDrawerRight,
  setODR,
  useMaterialUIController,
  setCurrentLawyerInfo,
} from "context";

function EditBtn({ personInfo }) {
  const [controller, dispatch] = useMaterialUIController();
  if (false) console.log(controller);
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClose = () => {
    setAnchorEl(null);
  };
  const clickOnEdit = (id) => {
    setAnchorEl(null);
    setUpdateLawyerId(dispatch, id);
    setMobileInDrawerRight(dispatch, personInfo.mobile);
    setFirstNameInDrawerRight(dispatch, personInfo.first_name);
    setLastNameInDrawerRight(dispatch, personInfo.last_name);
    setBirthdayInDrawerRight(dispatch, personInfo.birthday);
    setEmailInDrawerRight(dispatch, personInfo.email);
    setLawyerStatusInDrawerRight(dispatch, personInfo.status);
    setCreateOrUpdate(dispatch, true);
    setTimeout(() => {
      setOpenDrawerRight(dispatch, true);
    }, 1);
    setGenderInDrawerRight(dispatch, personInfo.sex);
    setCustomGenderInDrawerRight(dispatch, personInfo.custom_gender);
    setODR(dispatch, "editOrCreateInDetail");
  };
  const statusIsClicked = () => {
    setAnchorEl(null);
    setODR(dispatch, "statusLawyerInDetail");
    setCurrentLawyerInfo(dispatch, personInfo);
    setTimeout(() => {
      setOpenDrawerRight(dispatch, true);
    }, 1);
  };
  const costIsClicked = () => {
    setAnchorEl(null);
    setODR(dispatch, "costInDetail");
    setCurrentLawyerInfo(dispatch, personInfo);
    setTimeout(() => {
      setOpenDrawerRight(dispatch, true);
    }, 1);
  };
  return (
    <div
      onMouseEnter={(event) => setAnchorEl(event.currentTarget)}
      className="ml-auto flex items-center justify-center"
    >
      <div className="bg-[#F9F9F9] cursor-pointer rounded h-[30px] w-[30px] pb-[10px] flex items-center justify-center">
        ...
      </div>
      <div>
        <Menu
          id="basic-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          MenuListProps={{
            "aria-labelledby": "basic-button",
          }}
        >
          <MenuItem onClick={() => clickOnEdit(personInfo.id)}>Edit</MenuItem>
          <MenuItem onClick={() => statusIsClicked(personInfo.id)}>Change Status</MenuItem>
          <MenuItem onClick={() => costIsClicked(personInfo.id)}>Change costs</MenuItem>
        </Menu>
      </div>
    </div>
  );
}
EditBtn.propTypes = {
  personInfo: PropTypes.object,
};
EditBtn.defaultProps = {
  personInfo: {},
};
export default EditBtn;
