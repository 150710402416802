import MDAvatar from "components/MDAvatar";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import { useMaterialUIController, setCircularProgress } from "context";
import { useState, useEffect } from "react";
import PropTypes from "prop-types";
import person from "assets/images/person.png";
import { Icon } from "@mui/material";
import { getDownloadLinkOfLive } from "api/api_lives";
// import { useNavigate } from "react-router-dom";

export default function data() {
  const [controller, dispatch] = useMaterialUIController();
  const { lawyerLiveDetails } = controller;
  const [currentList, setCurrentList] = useState([]);
  useEffect(() => {
    setCurrentList(lawyerLiveDetails);
  }, [lawyerLiveDetails]);

  const getIsoString = (date) => {
    const currentDate = new Date(
      date.split("T")[0].split("-")[0],
      parseInt(date.split("T")[0].split("-")[1], 10) - 1,
      date.split("T")[0].split("-")[2],
      date.split("T")[1].split(".")[0].split(":")[0],
      date.split("T")[1].split(".")[0].split(":")[1],
      date.split("T")[1].split(".")[0].split(":")[2]
    );
    const tzoffset = new Date().getTimezoneOffset() * 60000; // offset in milliseconds
    const localISOTime = new Date(currentDate - tzoffset - tzoffset);
    return localISOTime.toISOString();
  };
  const getFullName = (first, last) => {
    if (first === null && last === null) return "not Found";
    let returndText = "";
    if (first === null) returndText += "";
    else returndText += first;
    returndText += " ";
    if (last === null) returndText += "";
    else returndText += last;
    return returndText;
  };
  function Author({ image, name, email, id }) {
    return (
      <MDBox display="flex" alignItems="center" lineHeight={1}>
        <MDTypography
          style={{ minWidth: "50px" }}
          display="block"
          variant="button"
          fontWeight="medium"
        >
          #{id}
        </MDTypography>
        <MDAvatar src={image} name={name} size="sm" />
        <MDBox ml={2} lineHeight={1}>
          <MDTypography display="block" variant="button" fontWeight="medium">
            {name}
          </MDTypography>
          <MDTypography variant="caption">{email}</MDTypography>
        </MDBox>
      </MDBox>
    );
  }
  Author.propTypes = {
    image: PropTypes.string,
    name: PropTypes.string,
    email: PropTypes.string,
    id: PropTypes.number,
  };
  Author.defaultProps = {
    image: "",
    name: "",
    email: "",
    id: "",
  };
  const getDownloadLink = (id) => {
    setCircularProgress(dispatch, true);
    getDownloadLinkOfLive((isOk, result) => {
      if (!isOk) {
        console.log("get Lawyers Lives List from api is not working", result);
        setCircularProgress(dispatch, false);
      } else {
        window.open(result.url, "_blank", "noopener, noreferrer");
        setCircularProgress(dispatch, false);
      }
    }, id);
  };
  const getRows = () => {
    const personsInfo = currentList.map((value) => ({
      owner: (
        <Author
          image={value.owner.avatars === null ? person : value.owner.avatars.thumbnail}
          name={getFullName(value.owner.first_name, value.owner.last_name)}
          email={value.owner.email}
          id={value.owner.id}
        />
      ),
      private: (
        <MDBox lineHeight={1} textAlign="center">
          {!value.visibility ? "YES" : "NO"}
        </MDBox>
      ),
      status: (
        <MDBox
          ml={-1}
          color={value.status === "active" ? "success" : "dark"}
          variant="gradient"
          size="small"
        >
          {value.status === "active" ? "in progress" : "ended"}
        </MDBox>
      ),
      "room name": (
        <MDBox lineHeight={1} textAlign="center">
          {value.room_name}
        </MDBox>
      ),
      "created at": (
        <MDBox lineHeight={1} textAlign="center">
          {getIsoString(value.created_at).split("T")[0]}
          <div style={{ marginTop: "5px" }}>
            {getIsoString(value.created_at).split("T")[1].split(".")[0]}
          </div>
        </MDBox>
      ),
      category: value.category ? (
        <MDBox lineHeight={1} textAlign="center">
          {value.category.name}
        </MDBox>
      ) : (
        <MDBox lineHeight={1} textAlign="center" style={{ color: "red" }}>
          Deleted Category
        </MDBox>
      ),
      download: (
        <MDBox className="flex items-center justify-center" textAlign="center">
          <div onClick={() => getDownloadLink(value.id)} role="presentation">
            <Icon fontSize="medium" className="text-[#3790ED] cursor-pointer">
              download
            </Icon>
          </div>
        </MDBox>
      ),
    }));
    return personsInfo;
  };
  return {
    columns: [
      { Header: "owner", accessor: "owner", align: "left" },
      { Header: "private", accessor: "private", align: "center" },
      { Header: "status", accessor: "status", align: "center" },
      { Header: "room name", accessor: "room name", align: "center" },
      { Header: "start live", accessor: "created at", align: "center" },
      { Header: "category", accessor: "category", align: "center" },
      { Header: "download", accessor: "download", align: "center" },
    ],

    rows: getRows(),
  };
}
