import { AxiosInstanceWithXAuthToken } from "./api";

export const getClientsList = (callback, data) => {
  AxiosInstanceWithXAuthToken()
    .get(`/api/v1/admins/clients?${data}`)
    .then((res) => callback(true, res.data))
    .catch((err) => {
      if (err.response.status === 401) {
        localStorage.clear();
        window.location.pathname = "/authentication/verify-number";
      }
      callback(false, err);
    });
};
export const getClientActivities = (callback, id) => {
  AxiosInstanceWithXAuthToken()
    .get(`/api/v1/admins/clients/activities/${id}`)
    .then((res) => callback(true, res.data))
    .catch((err) => {
      if (err.response.status === 401) {
        localStorage.clear();
        window.location.pathname = "/authentication/verify-number";
      }
      callback(false, err);
    });
};
export const getClientAddresses = (callback, id) => {
  AxiosInstanceWithXAuthToken()
    .get(`/api/v1/admins/clients/${id}/address`)
    .then((res) => callback(true, res.data, res))
    .catch((err) => {
      if (err.response.status === 401) {
        localStorage.clear();
        window.location.pathname = "/authentication/verify-number";
      }
      callback(false, err, err.response.status);
    });
};
export const approveClientsStatus = (callback, clientId) => {
  AxiosInstanceWithXAuthToken()
    .get(`/api/v1/admins/users/approve/${clientId}`)
    .then((res) => callback(true, res.data))
    .catch((err) => {
      if (err.response.status === 401) {
        localStorage.clear();
        window.location.pathname = "/authentication/verify-number";
      }
      callback(false, err);
    });
};

export const blockClientsStatus = (callback, clientId) => {
  AxiosInstanceWithXAuthToken()
    .get(`/api/v1/admins/users/block/${clientId}`)
    .then((res) => callback(true, res.data))
    .catch((err) => {
      if (err.response.status === 401) {
        localStorage.clear();
        window.location.pathname = "/authentication/verify-number";
      }
      callback(false, err);
    });
};
export const createClient = (callback, body) => {
  AxiosInstanceWithXAuthToken()
    .post(`/api/v1/admins/clients`, body)
    .then((res) => callback(true, res.data))
    .catch((err) => {
      if (err.response.status === 401) {
        localStorage.clear();
        window.location.pathname = "/authentication/verify-number";
      }
      callback(false, err);
    });
};
export const updateClient = (callback, body, clientId) => {
  AxiosInstanceWithXAuthToken()
    .patch(`/api/v1/admins/clients/${clientId}`, body)
    .then((res) => callback(true, res.data))
    .catch((err) => {
      if (err.response.status === 401) {
        localStorage.clear();
        window.location.pathname = "/authentication/verify-number";
      }
      callback(false, err);
    });
};
export const createOrUpdateClientAddress = (callback, body, clientId) => {
  AxiosInstanceWithXAuthToken()
    .post(`/api/v1/admins/clients/${clientId}/address`, body)
    .then((res) => callback(true, res.data))
    .catch((err) => {
      if (err.response.status === 401) {
        localStorage.clear();
        window.location.pathname = "/authentication/verify-number";
      }
      callback(false, err);
    });
};
export const getAClientDetails = (callback, id) => {
  AxiosInstanceWithXAuthToken()
    .get(`/api/v1/admins/clients/${id}`)
    .then((res) => callback(true, res.data))
    .catch((err) => {
      if (err.response.status === 401) {
        localStorage.clear();
        window.location.pathname = "/authentication/verify-number";
      }
      callback(false, err);
    });
};
