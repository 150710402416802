/* eslint-disable no-nested-ternary */
// @mui material components
import Divider from "@mui/material/Divider";
import Icon from "@mui/material/Icon";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";

// Custom styles for the Configurator
import DrawerRightRoot from "examples/DrawerRight/DrawerRightRoot";

// Material Dashboard 2 React context
import {
  useMaterialUIController,
  setOpenDrawerRight,
  setFirstNameInDrawerRight,
  setLastNameInDrawerRight,
  setEmailInDrawerRight,
  setBirthdayInDrawerRight,
  setMobileInDrawerRight,
  setGenderInDrawerRight,
  setCircularProgress,
  setLawyersData,
  setPersonInfo,
  setAlertText,
  setAlertOpen,
  setAlertColor,
  setCustomGenderInDrawerRight,
} from "context";
import MDInput from "components/MDInput";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import { useLocation } from "react-router-dom";
import { useEffect } from "react";
import { getLawyersList, updateLawyer } from "api/api_lawyer";
import updateInformatonSchema from "model/updateInformationModel";

function DrawerRightForUpdate() {
  const [controller, dispatch] = useMaterialUIController();
  const {
    openDrawerRight,
    darkMode,
    customGenderInDrawerRight,
    firstNameInDrawerRight,
    lastNameInDrawerRight,
    birthdayInDrawerRight,
    emailInDrawerRight,
    mobileInDrawerRight,
    genderInDrawerRight,
    // createOrUpdate,
    openDrawerReason,
    updateLawyerId,
    lawyerStatus,
    personInfo,
  } = controller;
  const { pathname } = useLocation();
  const handleCloseDrawerRight = () => setOpenDrawerRight(dispatch, false);
  const updateUserBtnClicked = () => {
    const body = {
      first_name: firstNameInDrawerRight,
      last_name: lastNameInDrawerRight,
      birthday: birthdayInDrawerRight,
      email: emailInDrawerRight,
      mobile: mobileInDrawerRight,
      sex: parseInt(genderInDrawerRight, 10),
      status: lawyerStatus,
    };
    if (parseInt(genderInDrawerRight, 10) === 2) body.custom_gender = customGenderInDrawerRight;
    setOpenDrawerRight(dispatch, false);
    setCircularProgress(dispatch, true);
    updateInformatonSchema
      .validate(body, { abortEarly: false })
      .then((valid) => {
        updateLawyer(
          (isOk, data) => {
            if (!isOk) {
              setCircularProgress(dispatch, false);
              setAlertText(dispatch, data.response.data.message);
              setAlertOpen(dispatch, true);
              setAlertColor(dispatch, "error");
              console.log("fail:updating is unsuccessful: ", data);
            } else {
              console.log("updating is successful: ", data);
              setAlertText(dispatch, "updating lawyer is successful");
              setAlertOpen(dispatch, true);
              setAlertColor(dispatch, "success");
              getLawyersList((isOk2, data2) => {
                if (!isOk2) {
                  console.log("get Lawyers data from api is not working", data2);
                } else {
                  setLawyersData(dispatch, data2);
                  setCircularProgress(dispatch, false);
                  setPersonInfo(dispatch, {
                    ...personInfo,
                    first_name: firstNameInDrawerRight,
                    last_name: lastNameInDrawerRight,
                    birthday: birthdayInDrawerRight,
                    email: emailInDrawerRight,
                    mobile: mobileInDrawerRight,
                    sex: parseInt(genderInDrawerRight, 10),
                    custom_gender: customGenderInDrawerRight,
                    status: lawyerStatus,
                  });
                }
              }, `${window.location.search}`);
            }
          },
          valid,
          updateLawyerId
        );
      })
      .catch((err) => {
        setCircularProgress(dispatch, false);
        setAlertText(dispatch, err.errors[0].split(",")[0]);
        setAlertOpen(dispatch, true);
        setAlertColor(dispatch, "error");
      });
  };
  const setDateFunc = (e) => {
    const now = new Date();
    const selectedSDate = new Date(e.target.value);
    if (now < selectedSDate) {
      setAlertText(dispatch, "You can only select past dates");
      setAlertOpen(dispatch, true);
      setAlertColor(dispatch, "warning");
      return;
    }
    setBirthdayInDrawerRight(dispatch, e.target.value);
  };
  useEffect(() => {
    setOpenDrawerRight(dispatch, false);
  }, [pathname]);
  if (openDrawerReason === "editOrCreateInDetail")
    return (
      <DrawerRightRoot variant="permanent" ownerState={{ openDrawerRight }}>
        <MDBox
          display="flex"
          justifyContent="space-between"
          alignItems="baseline"
          pt={4}
          pb={0.5}
          px={3}
        >
          <MDBox>
            <MDTypography variant="h5">Admin Panel Editor</MDTypography>
            <MDTypography variant="body2" color="text">
              Edit a Lawyer information
            </MDTypography>
          </MDBox>

          <Icon
            sx={({ typography: { size }, palette: { dark, white } }) => ({
              fontSize: `${size.lg} !important`,
              color: darkMode ? white.main : dark.main,
              stroke: "currentColor",
              strokeWidth: "2px",
              cursor: "pointer",
              transform: "translateY(5px)",
            })}
            onClick={handleCloseDrawerRight}
          >
            close
          </Icon>
        </MDBox>
        <Divider />
        <MDBox component="form" role="form">
          <MDBox mb={2}>
            <MDInput
              value={mobileInDrawerRight}
              onChange={(e) => setMobileInDrawerRight(dispatch, e.target.value)}
              id="phoneNumberInput"
              type="text"
              label="Phone number"
              fullWidth
            />
          </MDBox>
          <MDBox mb={2}>
            <MDInput
              value={firstNameInDrawerRight}
              onChange={(e) => setFirstNameInDrawerRight(dispatch, e.target.value)}
              id="firstNameInput"
              type="text"
              label="First name"
              fullWidth
            />
          </MDBox>
          <MDBox mb={2}>
            <MDInput
              value={lastNameInDrawerRight}
              onChange={(e) => setLastNameInDrawerRight(dispatch, e.target.value)}
              id="lastNameInput"
              type="text"
              label="Last name"
              fullWidth
            />
          </MDBox>
          <MDBox mb={2}>
            <MDInput
              value={birthdayInDrawerRight}
              label="Birthday"
              onChange={(e) => setDateFunc(e)}
              id="date Input"
              type="date"
              fullWidth
            />
          </MDBox>
          <MDBox mb={2}>
            <MDInput
              value={emailInDrawerRight}
              onChange={(e) => setEmailInDrawerRight(dispatch, e.target.value)}
              id="emailInput"
              type="text"
              label="Email"
              fullWidth
            />
          </MDBox>
          <MDBox>
            <MDTypography style={{ fontSize: "1rem" }}>Select Your gender</MDTypography>
          </MDBox>
          <MDBox mb={2}>
            <RadioGroup
              value={genderInDrawerRight}
              onChange={(e) => setGenderInDrawerRight(dispatch, e.target.value)}
              row
              aria-labelledby="gender"
              name="gender"
              defaultValue="offline"
            >
              <FormControlLabel value={0} control={<Radio />} label="male" labelPlacement="start" />
              <FormControlLabel
                value={1}
                control={<Radio />}
                label="female"
                labelPlacement="start"
              />
              <FormControlLabel
                value={2}
                control={<Radio />}
                label="other"
                labelPlacement="start"
              />
            </RadioGroup>
          </MDBox>
          {parseInt(genderInDrawerRight, 10) === 2 && (
            <MDBox mb={2}>
              <MDInput
                value={customGenderInDrawerRight}
                onChange={(e) => setCustomGenderInDrawerRight(dispatch, e.target.value)}
                id="customGenderInput"
                type="text"
                label="Custom Gender"
                fullWidth
              />
            </MDBox>
          )}
          <MDBox mt={4} mb={1}>
            <MDButton
              onClick={(e) => updateUserBtnClicked(e)}
              variant="gradient"
              color="info"
              fullWidth
            >
              Update
            </MDButton>
          </MDBox>
        </MDBox>
      </DrawerRightRoot>
    );
}
export default DrawerRightForUpdate;
