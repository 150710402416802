// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import DataTable from "examples/Tables/DataTable";
import authorsTableData from "layouts/subscriptions/clientsSubscriptionsTable/data/authorsTableData";
import { useMaterialUIController, setCircularProgress, setClientSubscriptionList } from "context";
import { useLocation, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import "../../../styles/generalStyles.css";
import { Pagination } from "@mui/material";
import { getAllClientsSubscriptions } from "../../../api/api_subscription";

function Tables() {
  const [controller, dispatch] = useMaterialUIController();
  const { searchBarValueSubscription } = controller;
  const [paginationCount, setPaginationCount] = useState(1);
  const [subscriptionsPage, setSubscriptionsPage] = useState(1);
  const navigate = useNavigate();
  const { columns, rows } = authorsTableData();
  const { pathname } = useLocation();
  useEffect(() => {
    let url = "/subscriptions?clients";
    if (searchBarValueSubscription !== "") url += `&search=${searchBarValueSubscription}`;
    navigate(url);
  }, [searchBarValueSubscription]);
  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const paramsObj = Array.from(params.keys()).reduce(
      (acc, val) => ({ ...acc, [val]: params.get(val) }),
      {}
    );
    let param = "";
    if (!paramsObj.page) param += `?page=1`;
    else param += `?page=${paramsObj.page}`;
    if (paramsObj.search !== "" && paramsObj.search) param += `&search=${paramsObj.search}`;
    setCircularProgress(dispatch, true);
    console.log("param", param);
    getAllClientsSubscriptions((isOk, data) => {
      if (!isOk) {
        console.log("get Clients Subscription List from api is not working", data);
      } else {
        console.log(data.data);
        setClientSubscriptionList(dispatch, data.data);
        setPaginationCount(data.meta.last_page);
      }
      setCircularProgress(dispatch, false);
    }, param);
  }, [pathname, window.location.search]);

  const handlePaginationChange = (event, value) => {
    setSubscriptionsPage(value);
    let url = "/subscriptions?clients";
    url += `&page=${value}`;
    if (searchBarValueSubscription !== "") url += `&search=${searchBarValueSubscription}`;
    navigate(url);
  };
  useEffect(() => {
    setSubscriptionsPage(1);
  }, [searchBarValueSubscription]);

  return (
    <MDBox pt={6} pb={3}>
      <Grid container spacing={6}>
        <Grid item xs={12}>
          <Card>
            <MDBox
              mx={2}
              mt={-3}
              py={3}
              px={2}
              variant="gradient"
              bgColor="info"
              borderRadius="lg"
              coloredShadow="info"
              style={{ display: "flex" }}
            >
              <MDTypography variant="h6" color="white">
                Clients Subscription Table
              </MDTypography>
            </MDBox>
            <MDBox pt={3}>
              <DataTable
                table={{ columns, rows }}
                isSorted={false}
                entriesPerPage={false}
                showTotalEntries={false}
                noEndBorder
              />
            </MDBox>
          </Card>
        </Grid>
      </Grid>
      <MDBox style={{ display: "flex", justifyContent: "center" }}>
        <Pagination
          page={subscriptionsPage}
          onChange={handlePaginationChange}
          count={paginationCount}
          color="info"
        />
      </MDBox>
    </MDBox>
  );
}

export default Tables;
