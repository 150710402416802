// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
// Material Dashboard 2 React components
import MDBox from "components/MDBox";
// import MDTypography from "components/MDTypography";

// Material Dashboard 2 React example components
// import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
  useMaterialUIController,
  setCircularProgress,
  setLawyerPaymentDetails,
  setTab,
} from "context";
// import PaymentDetailNavbar from "examples/Navbars/paymentDetailNavbar";
import DataTable from "examples/TablesDetailPaymentLawyer/DataTable";
// import MDAvatar from "components/MDAvatar";
import { Icon, Pagination } from "@mui/material";
import MDButton from "components/MDButton";
import { getPaymentOfOneLawyer } from "../../../api/api_payment";
// import LawyerPaymentDetail from "./LawyerPaymentDetail";
import authorsTableData from "./data/authorsTableData";
import TransferDetailModal from "./modal";

function Tables() {
  const [controller, dispatch] = useMaterialUIController();
  const { columns, rows } = authorsTableData();
  const { searchBarValueDetailPayment, lawyerPaymentDetails } = controller;
  const [paginationCount, setPaginationCount] = useState(1);
  const [paymentsPage, setPaymentsPage] = useState(1);
  const { pathname } = useLocation();
  let id = pathname.split("/");
  id = id[id.length - 1];
  id = id.split("?");
  [id] = id;
  const navigate = useNavigate();
  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const paramsObj = Array.from(params.keys()).reduce(
      (acc, val) => ({ ...acc, [val]: params.get(val) }),
      {}
    );
    let url = `/lawyers/detail/${id}`;
    if (!paramsObj.page) url += `?page=1`;
    else url += `?page=${paramsObj.page}`;
    if (searchBarValueDetailPayment !== "") url += `&search=${searchBarValueDetailPayment}`;
    navigate(url);
  }, [searchBarValueDetailPayment]);

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const paramsObj = Array.from(params.keys()).reduce(
      (acc, val) => ({ ...acc, [val]: params.get(val) }),
      {}
    );
    let param = "";
    if (!paramsObj.page) param += `?page=1`;
    else param += `?page=${paramsObj.page}`;
    if (paramsObj.search !== "" && paramsObj.search) param += `&search=${paramsObj.search}`;

    setCircularProgress(dispatch, true);
    getPaymentOfOneLawyer(
      (isOk, data) => {
        if (!isOk) {
          console.log("get payment list for a lawyer from api is not working!", data);
          setCircularProgress(dispatch, false);
        } else {
          setLawyerPaymentDetails(dispatch, data.data);
          setPaginationCount(data.meta.last_page);
          setCircularProgress(dispatch, false);
        }
      },
      id,
      param
    );
  }, [pathname, window.location.search]);
  const handlePaginationChange = (event, value) => {
    setPaymentsPage(value);
    let url = `/lawyers/detail/${id}`;
    url += `?page=${value}`;
    if (searchBarValueDetailPayment !== "") url += `&search=${searchBarValueDetailPayment}`;
    navigate(url);
  };
  useEffect(() => {
    setPaymentsPage(1);
  }, [searchBarValueDetailPayment]);
  if (lawyerPaymentDetails?.length === 0)
    return (
      <div className="flex flex-col mb-2 items-center h-[200px] justify-center">
        <div>
          <Icon fontSize="large">emoji_symbols</Icon>
        </div>
        <div className="font-bold text-[20px]">No payment were found for this lawyer </div>
        <MDButton
          onClick={() => setTab(dispatch, 1)}
          style={{ backgroundColor: "#0295E9", color: "white", marginTop: "20px" }}
        >
          Back To Oveview
        </MDButton>
      </div>
    );
  return (
    <MDBox pb={3}>
      <TransferDetailModal />
      <Grid spacing={6}>
        <Grid item xs={12}>
          <Card>
            <MDBox pb={3}>
              <DataTable
                table={{ columns, rows }}
                isSorted={false}
                entriesPerPage={false}
                showTotalEntries={false}
                noEndBorder
              />
            </MDBox>
          </Card>
        </Grid>
      </Grid>
      <MDBox style={{ display: "flex", justifyContent: "center" }}>
        <Pagination
          page={paymentsPage}
          onChange={handlePaginationChange}
          count={paginationCount}
          color="info"
        />
      </MDBox>
    </MDBox>
  );
}

export default Tables;
