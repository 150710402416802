import { AxiosInstanceWithXAuthToken } from "./api";

export const getProfileInformation = (callback) => {
  AxiosInstanceWithXAuthToken()
    .get(`/api/v1/admins/personal-information`)
    .then((res) => callback(true, res.data))
    .catch((err) => {
      if (err.response.status === 401) {
        localStorage.clear();
        window.location.pathname = "/authentication/verify-number";
      }
      callback(false, err);
    });
};
export const updateAdminProfileAvatar = (callback, id, body) => {
  AxiosInstanceWithXAuthToken()
    .patch(`/api/v1/admins/${id}`, body)
    .then((res) => callback(true, res.data))
    .catch((err) => {
      if (err.response.status === 401) {
        localStorage.clear();
        window.location.pathname = "/authentication/verify-number";
      }
      callback(false, err);
    });
};
