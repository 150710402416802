// @mui material components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import LawyersNavbar from "examples/Navbars/LawyersNavbar";
import DataTable from "components/DataTable";
import { useEffect, useState } from "react";
import { getAllRegions } from "api/api_region";

function Tables() {
  const [api, setApi] = useState({
    url: "api/v1/admins/lawyers",
    type: "GET",
    order: ["status", "last_name", "created_at"],
    filters: [{ name: "Search", type: "query", inputType: "text" }],
  });

  useEffect(() => {
    getAllRegions((isOk, data) => {
      if (isOk) {
        const { filters } = api;
        const stateFilter = {
          name: "region",
          type: "query",
          inputType: "select",
          options: [],
          defaultValue: "",
        };

        // eslint-disable-next-line guard-for-in, no-restricted-syntax
        for (const item in data.data) {
          stateFilter.options.push({
            name: data.data[item].regionName,
            value: data.data[item].id,
          });
        }

        let updatedFilters = filters;
        updatedFilters = [...updatedFilters.slice(0, 1), stateFilter, ...updatedFilters.slice(1)];

        setApi({ ...api, filters: updatedFilters });
      }
    }, `?all=true${window.location.search}`);
  }, []);

  return (
    <DashboardLayout>
      <LawyersNavbar />
      <DataTable api={api} type="Lawyer">
        <div className="flex flex-col">
          <div>matinkj:w</div>
        </div>
      </DataTable>
    </DashboardLayout>
  );
}

export default Tables;
