import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDAvatar from "components/MDAvatar";
import PropTypes from "prop-types";
import person from "assets/images/person.png";
import { useMaterialUIController, setClientTab } from "context";
import { useState, useEffect } from "react";
import { Link } from "react-router-dom";

export default function data() {
  const [controller, dispatch] = useMaterialUIController();
  const { clientSubscriptionData } = controller;
  function Author({ image, name, email, id }) {
    return (
      <Link
        onClick={() => {
          setClientTab(dispatch, 7);
        }}
        to={`/clients/detail/${id}`}
      >
        <MDBox display="flex" alignItems="center" lineHeight={1}>
          <MDTypography
            style={{ minWidth: "50px" }}
            display="block"
            variant="button"
            fontWeight="medium"
          >
            #{id}
          </MDTypography>
          <MDAvatar src={image} name={name} size="sm" />
          <MDBox ml={2} lineHeight={1}>
            <MDTypography display="block" variant="button" fontWeight="medium">
              {name}
            </MDTypography>
            <MDTypography variant="caption">{email}</MDTypography>
          </MDBox>
        </MDBox>
      </Link>
    );
  }
  Author.propTypes = {
    image: PropTypes.string,
    name: PropTypes.string,
    email: PropTypes.string,
    id: PropTypes.number,
  };
  Author.defaultProps = {
    image: "",
    name: "",
    email: "",
    id: "",
  };
  const [currentList, setCurrentList] = useState([]);
  useEffect(() => {
    setCurrentList(clientSubscriptionData);
  }, [clientSubscriptionData]);
  const getFullName = (first, last) => {
    if (first === null && last === null) return "not Found";
    let returndText = "";
    if (first === null) returndText += "";
    else returndText += first;
    returndText += " ";
    if (last === null) returndText += "";
    else returndText += last;
    return returndText;
  };
  const getStatusColor = (value) => {
    switch (value) {
      case "active":
        return "text-[#17d41a]";
      case "denied":
        return "text-[#780b01]";
      case "pending":
        return "text-[#bfd611]";
      default:
        return "text-[#000d07]";
    }
  };
  const getPersonImage = (value) => {
    if (value === null || value.client === null || value.client.avatars === null) return person;
    return value.client.avatars.thumbnail;
  };
  const getIsoString = (date) => {
    const currentDate = new Date(
      date.split(" ")[0].split("-")[0],
      parseInt(date.split(" ")[0].split("-")[1], 10) - 1,
      date.split(" ")[0].split("-")[2],
      date.split(" ")[1].split(".")[0].split(":")[0],
      date.split(" ")[1].split(".")[0].split(":")[1],
      date.split(" ")[1].split(".")[0].split(":")[2]
    );
    const tzoffset = new Date().getTimezoneOffset() * 60000; // offset in milliseconds
    const localISOTime = new Date(currentDate - tzoffset - tzoffset);
    return localISOTime.toISOString();
  };
  const getRows = () => {
    const personsInfo = currentList.map((value) => ({
      client: (
        <Author
          image={getPersonImage(value)}
          name={getFullName(value.client.first_name, value.client.last_name)}
          email={value.client.email}
          id={value.client.id}
        />
      ),
      "current period end": (
        <div>
          {getIsoString(value.current_period_end).split("T")[0]}{" "}
          {getIsoString(value.current_period_end).split("T")[1].split(".")[0]}
        </div>
      ),
      "current period start": (
        <div>
          {getIsoString(value.current_period_start).split("T")[0]}{" "}
          {getIsoString(value.current_period_start).split("T")[1].split(".")[0]}
        </div>
      ),
      "customer id": <div>{value.customer_id || "not Found"}</div>,
      status: <div className={`${getStatusColor(value.status)}`}>{value.status}</div>,
    }));
    return personsInfo;
  };
  return {
    columns: [
      { Header: "client", accessor: "client", width: "45%", align: "left" },
      { Header: "current period end", accessor: "current period end", align: "center" },
      { Header: "current period start", accessor: "current period start", align: "center" },
      { Header: "customer id", accessor: "customer id", align: "center" },
      { Header: "status", accessor: "status", align: "center" },
    ],

    rows: getRows(),
  };
}
