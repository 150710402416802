import { Menu, Rating, Tooltip } from "@mui/material";
import { useState } from "react";
import InfoIcon from "@mui/icons-material/Info";
import { useMaterialUIController } from "context";

function RatingDetail() {
  const [controller] = useMaterialUIController();
  const { lawyerRating } = controller;
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClose = () => {
    setAnchorEl(null);
  };
  return (
    <>
      <div>
        <Menu
          id="basic-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          MenuListProps={{
            "aria-labelledby": "basic-button",
          }}
        >
          <div className="text-[20px] flex justify-center text-black font-bold">
            Rating Details!
          </div>
          <div className="bg-white px-4 pb-2 flex flex-col items-center justify-center w-[300px] h-[300px] overflow-auto">
            <div className="mr-auto text-black">Lives Rate:</div>
            <div className="ml-auto flex items-center justify-center w-[100%]">
              <Tooltip title={`${lawyerRating.liveCount} people has been voted to lives quality!`}>
                <div className="mr-[10px] text-[14px] text-gray-500">
                  peoples: <span className="text-yellow-500">{lawyerRating.liveCount}</span>
                </div>
              </Tooltip>
              <Tooltip
                title={`Rate of Lives is: ${
                  lawyerRating.live ? Math.ceil(lawyerRating.live * 100) / 100 : 0
                }`}
              >
                <div className="flex items-center justify-center">
                  <Rating
                    size="large"
                    name="read-only"
                    readOnly
                    value={lawyerRating.live ? Math.ceil(lawyerRating.live * 10) / 10 : 0}
                    precision={0.1}
                  />
                </div>
              </Tooltip>
            </div>
            <div className="mr-auto text-black mt-8">Cases Rate:</div>
            <div className="ml-auto flex items-center justify-center w-[100%]">
              <Tooltip title={`${lawyerRating.caseCount} people has been voted to cases quality!`}>
                <div className="mr-[10px] text-[14px] text-gray-500">
                  peoples: <span className="text-yellow-500">{lawyerRating.caseCount}</span>
                </div>
              </Tooltip>
              <Tooltip
                title={`Rate of cases is: ${
                  lawyerRating.case ? Math.ceil(lawyerRating.case * 100) / 100 : 0
                }`}
              >
                <div className="flex items-center justify-center">
                  <Rating
                    size="large"
                    name="read-only"
                    readOnly
                    value={lawyerRating.case ? Math.ceil(lawyerRating.case * 10) / 10 : 0}
                    precision={0.1}
                  />
                </div>
              </Tooltip>
            </div>
            <div className="mr-auto text-black mt-8">Sessions Rate:</div>
            <div className="ml-auto flex items-center justify-center w-[100%]">
              <Tooltip
                title={`${lawyerRating.sessionCount} people has been voted to sessions quality!`}
              >
                <div className="mr-[10px] text-[14px] text-gray-500">
                  peoples: <span className="text-yellow-500">{lawyerRating.sessionCount}</span>
                </div>
              </Tooltip>
              <Tooltip
                title={`Rate of sessions is: ${
                  lawyerRating.session ? Math.ceil(lawyerRating.session * 100) / 100 : 0
                }`}
              >
                <div className="flex items-center justify-center">
                  <Rating
                    size="large"
                    name="read-only"
                    readOnly
                    value={lawyerRating.session ? Math.ceil(lawyerRating.session * 10) / 10 : 0}
                    precision={0.1}
                  />
                </div>
              </Tooltip>
            </div>
          </div>
        </Menu>
      </div>
      <div
        role="presentation"
        onClick={(e) => setAnchorEl(e.currentTarget)}
        className="text-[15px] ml-[5px] text-gray-500 hover:text-black cursor-pointer"
      >
        <InfoIcon />
      </div>
    </>
  );
}
export default RatingDetail;
