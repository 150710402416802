import { AxiosInstanceWithXAuthToken } from "./api";

export const getAllCategories = (callback, params = "") => {
  AxiosInstanceWithXAuthToken()
    .get(`/api/v1/admins/category${params}`)
    .then((res) => callback(true, res.data))
    .catch((err) => {
      if (err.response.status === 401) {
        localStorage.clear();
        window.location.pathname = "/authentication/verify-number";
      }
      callback(false, err);
    });
};
export const createNewCategory = (callback, body) => {
  AxiosInstanceWithXAuthToken()
    .post(`/api/v1/admins/category`, body)
    .then((res) => callback(true, res.data))
    .catch((err) => {
      if (err.response.status === 401) {
        localStorage.clear();
        window.location.pathname = "/authentication/verify-number";
      }
      callback(false, err);
    });
};
export const editCategory = (callback, body, id) => {
  AxiosInstanceWithXAuthToken()
    .patch(`/api/v1/admins/category/${id}`, body)
    .then((res) => callback(true, res.data))
    .catch((err) => {
      if (err.response.status === 401) {
        localStorage.clear();
        window.location.pathname = "/authentication/verify-number";
      }
      callback(false, err);
    });
};
export const deleteCategory = (callback, id) => {
  AxiosInstanceWithXAuthToken()
    .delete(`/api/v1/admins/category/${id}`)
    .then((res) => callback(true, res.data))
    .catch((err) => {
      if (err.response.status === 401) {
        localStorage.clear();
        window.location.pathname = "/authentication/verify-number";
      }
      callback(false, err);
    });
};
