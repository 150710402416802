// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/CategoryNavbar";
import { useEffect, useState } from "react";
import {
  setCircularProgress,
  setAllCategoriesData,
  useMaterialUIController,
  setCategoriesPageCount,
} from "context";

import MDBox from "components/MDBox";
import { Pagination } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import Grid from "@mui/material/Grid";
import { getAllCategories } from "api/api_category";
import CategoryCard from "./CategoryCard";

function Category() {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const [controller, dispatch] = useMaterialUIController();
  const [categoryPage, setCategoriesPage] = useState(1);
  const { categoriesData, searchBarValueCategory, categoriesPageCount } = controller;
  useEffect(() => {
    setCategoriesPage(1);
  }, [searchBarValueCategory]);
  useEffect(() => {
    let url = "";
    if (categoryPage && searchBarValueCategory)
      url += `/categories?page=${categoryPage}&search=${searchBarValueCategory}`;
    if (categoryPage && !searchBarValueCategory) url += `/categories?page=${categoryPage}`;
    navigate(url);
  }, [searchBarValueCategory, categoryPage]);

  const handlePaginationChange = (event, value) => {
    setCategoriesPage(value);
  };
  useEffect(() => {
    setCircularProgress(dispatch, true);
    if (window.location.search === "") setCategoriesPage(1);
    else {
      const search = window.location.search.substring(1);
      const locationJson = JSON.parse(
        `{"${decodeURI(search).replace(/"/g, '\\"').replace(/&/g, '","').replace(/=/g, '":"')}"}`
      );
      setCategoriesPage(parseInt(locationJson.page, 10));
    }
    getAllCategories((isOk, data) => {
      if (!isOk) {
        console.log("get categories data from api is not working", data);
      } else {
        setCategoriesPageCount(dispatch, data.meta.last_page);
        setAllCategoriesData(dispatch, data);
      }
      setCircularProgress(dispatch, false);
    }, window.location.search);
  }, [pathname, window.location.search]);
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
        {categoriesData.data &&
          categoriesData.data.map((value, index) => (
            <Grid key={value.id} item md={6} lg={3} sm={12}>
              <CategoryCard value={value} index={index} />
            </Grid>
          ))}
      </Grid>
      <MDBox py={3} style={{ display: "flex", justifyContent: "center" }}>
        {categoryPage && (
          <Pagination
            page={categoryPage}
            onChange={handlePaginationChange}
            count={categoriesPageCount}
            color="info"
          />
        )}
      </MDBox>
    </DashboardLayout>
  );
}

export default Category;
