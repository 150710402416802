import * as yup from "yup";

const createUser = yup.object().shape({
  question: yup.string("question must be string").required("question is required"),
  answer: yup.string("answer must be string").required("answer is required"),
  publish: yup.bool("publish must be boolean").required("publish is required"),
  type: yup.string("type format in wrong!").required("type is required"),
});

export default createUser;
