import { useState, useRef } from "react";
import { useMaterialUIController, setClientsJoyrideRun } from "context";
import Joyride, { STATUS } from "react-joyride";
import TourIntroduction from "../components/TourIntroduction";

export default function ClientsJoyrideComponent() {
  const [controller, dispatch] = useMaterialUIController();
  const { clientsJoyrideRun } = controller;
  const tourRef = useRef(null);
  const stepsLength = 8;
  const [steps] = useState([
    {
      disableBeacon: true,
      content: (
        <TourIntroduction
          tourRef={tourRef}
          stepsLength={stepsLength}
          stepIndex={0}
          headerText="Overview section"
          bodyText="In this section, we show the general statistics of this client"
        />
      ),
      placement: "bottom",
      styles: {
        tooltip: {
          display: "flex",
          flexDirection: "column",
          justifyContent: "top",
          width: "400px",
          top: "-15px",
        },
        spotlight: {
          borderRadius: "8px",
        },
      },
      target: ".clientDetailOverviewTitle",
    },
    {
      disableBeacon: true,
      content: (
        <TourIntroduction
          tourRef={tourRef}
          stepsLength={stepsLength}
          stepIndex={1}
          headerText="Addreses table"
          bodyText="In this section, the client's Addreses and their details are shown"
        />
      ),
      placement: "bottom",
      styles: {
        tooltip: {
          display: "flex",
          flexDirection: "column",
          justifyContent: "top",
          width: "400px",
          top: "-15px",
        },
        spotlight: {
          borderRadius: "8px",
        },
      },
      target: ".clientDetailAddressTitle",
    },
    {
      disableBeacon: true,
      content: (
        <TourIntroduction
          tourRef={tourRef}
          stepsLength={stepsLength}
          stepIndex={2}
          headerText="Lives table"
          bodyText="The table of client's Lives and information about each Live is given in the corresponding row"
        />
      ),
      placement: "bottom",
      styles: {
        tooltip: {
          display: "flex",
          flexDirection: "column",
          justifyContent: "top",
          width: "400px",
          top: "-15px",
        },
        spotlight: {
          borderRadius: "8px",
        },
      },
      target: ".clientDetailLivesTitle",
    },
    {
      disableBeacon: true,
      content: (
        <TourIntroduction
          tourRef={tourRef}
          stepsLength={stepsLength}
          stepIndex={3}
          headerText="Cases table"
          bodyText="The table of client's Cases and information about each Case is given in the corresponding row"
        />
      ),
      placement: "bottom",
      styles: {
        tooltip: {
          display: "flex",
          flexDirection: "column",
          justifyContent: "top",
          width: "400px",
          top: "-15px",
        },
        spotlight: {
          borderRadius: "8px",
        },
      },
      target: ".clientDetailCasesTitle",
    },
    {
      disableBeacon: true,
      content: (
        <TourIntroduction
          tourRef={tourRef}
          stepsLength={stepsLength}
          stepIndex={4}
          headerText="Payments table"
          bodyText="The table of client's Payments and information about each Payment is given in the corresponding row"
        />
      ),
      placement: "bottom",
      styles: {
        tooltip: {
          display: "flex",
          flexDirection: "column",
          justifyContent: "top",
          width: "400px",
          top: "-15px",
        },
        spotlight: {
          borderRadius: "8px",
        },
      },
      target: ".clientDetailPaymentsTitle",
    },
    {
      disableBeacon: true,
      content: (
        <TourIntroduction
          tourRef={tourRef}
          stepsLength={stepsLength}
          stepIndex={5}
          headerText="Transactions table"
          bodyText="The table of client's Transactions and information about each Transaction is given in the corresponding row"
        />
      ),
      placement: "bottom",
      styles: {
        tooltip: {
          display: "flex",
          flexDirection: "column",
          justifyContent: "top",
          width: "400px",
          top: "-15px",
        },
        spotlight: {
          borderRadius: "8px",
        },
      },
      target: ".clientDetailTransactionsTitle",
    },
    {
      disableBeacon: true,
      content: (
        <TourIntroduction
          tourRef={tourRef}
          stepsLength={stepsLength}
          stepIndex={6}
          headerText="Subscriptions table"
          bodyText="The table of client's Subscriptions and information about each Subscription is given in the corresponding row"
        />
      ),
      placement: "bottom",
      styles: {
        tooltip: {
          display: "flex",
          flexDirection: "column",
          justifyContent: "top",
          width: "400px",
          top: "-15px",
        },
        spotlight: {
          borderRadius: "8px",
        },
      },
      target: ".clientDetailSubscriptionTitle",
    },
    {
      disableBeacon: true,
      content: (
        <TourIntroduction
          tourRef={tourRef}
          stepsLength={stepsLength}
          stepIndex={7}
          headerText="Sessions table"
          bodyText="The table of client's Sessions and information about each Session is given in the corresponding row"
        />
      ),
      placement: "bottom",
      styles: {
        tooltip: {
          display: "flex",
          flexDirection: "column",
          justifyContent: "top",
          width: "400px",
          top: "-15px",
        },
        spotlight: {
          borderRadius: "8px",
        },
      },
      target: ".clientDetailSessionsTitle",
    },
  ]);

  const handleJoyrideCallback = (data) => {
    const { status } = data;
    const finishedStatuses = [STATUS.FINISHED, STATUS.SKIPPED];
    if (finishedStatuses.includes(status)) {
      setClientsJoyrideRun(dispatch, false);
      tourRef.current.store.skip();
    }
  };
  return (
    <Joyride
      callback={handleJoyrideCallback}
      ref={tourRef}
      continuous
      disableScrolling
      hideCloseButton
      hideBackButton
      scrollOffset={100}
      run={clientsJoyrideRun}
      steps={steps}
      styles={{
        buttonNext: {
          display: "none",
        },
        tooltipContent: {
          padding: "0px",
        },
        tooltipContainer: {
          padding: "0px",
        },
        tooltipFooter: {
          display: "none",
        },
        options: {
          width: "fit-content",
          arrowColor: "transparent",
          zIndex: 10000,
        },
      }}
    />
  );
}
