import React, { useEffect } from "react";
import PropTypes from "prop-types";
import {
  setMobileInDrawerRight,
  setFirstNameInDrawerRight,
  setLastNameInDrawerRight,
  setBirthdayInDrawerRight,
  setEmailInDrawerRight,
  setGenderInDrawerRight,
  setOpenDrawerRight,
  setCreateOrUpdate,
  setODR,
  useMaterialUIController,
  setUpdateClientId,
  setClientStatusInDrawerRight,
  setCurrentClientStatusId,
  setCircularProgress,
  setCustomGenderInDrawerRight,
} from "context";

import { useNavigate } from "react-router-dom";
import verticalMenu from "../../../../assets/images/menu-dots-vertical-2.png";
import userAvatar from "../../../../assets/images/unkown-user.png";

export default function ClientCard({ item }) {
  // eslint-disable-next-line no-unused-vars
  const [controller, dispatch] = useMaterialUIController();

  useEffect(() => {
    if (!item) {
      setCircularProgress(dispatch, true);
    } else setCircularProgress(dispatch, false);
  }, [item]);
  const navigate = useNavigate();

  const clickOnEdit = () => {
    setUpdateClientId(dispatch, item.id);
    setMobileInDrawerRight(dispatch, item.mobile);
    setFirstNameInDrawerRight(dispatch, item.first_name);
    setLastNameInDrawerRight(dispatch, item.last_name);
    setBirthdayInDrawerRight(dispatch, item.birthday);
    setEmailInDrawerRight(dispatch, item.email);
    setClientStatusInDrawerRight(dispatch, item.status);
    setCustomGenderInDrawerRight(dispatch, item.custom_gender);
    setCreateOrUpdate(dispatch, true);
    setTimeout(() => {
      setOpenDrawerRight(dispatch, true);
    }, 1);
    setGenderInDrawerRight(dispatch, item.sex);
    setODR(dispatch, "editOrCreate");
  };

  const statusIsClicked = (id) => {
    setODR(dispatch, "statusClient");
    setCurrentClientStatusId(dispatch, id);
    setFirstNameInDrawerRight(dispatch, item.first_name);
    setClientStatusInDrawerRight(dispatch, item.status);
    setTimeout(() => {
      setOpenDrawerRight(dispatch, true);
    }, 1);
  };

  if (!item) return <div>Loading ...</div>;

  return (
    <div
      key={item.id}
      className="bg-white flex flex-col justify-between items-center rounded-md shadow-md hover:shadow-lg"
    >
      <div className="w-full flex flex-col justify-center items-center gap-1 p-3">
        <div className="flex flex-row w-full items-center justify-between">
          <div className="text-[13px]">#{item.id}</div>
          <div
            className="cursor-pointer"
            role="button"
            tabIndex={0}
            onClick={() => clickOnEdit(item)}
            onKeyPress={(event) => {
              if (event.key === "Enter") {
                clickOnEdit(item);
              }
            }}
          >
            <img src={verticalMenu} className="w-[25px] h-[20px]" alt="" />
          </div>
        </div>
        <img
          className="w-[100px] h-[100px] rounded-full object-cover"
          src={item?.avatars?.medium ? item?.avatars?.medium : userAvatar}
          alt=""
        />
        <div
          className="text-[16px] text-center font-semibold w-[200px] hover:underline hover:cursor-pointer"
          role="button"
          tabIndex={0}
          onClick={() => {
            navigate(`/clients/detail/${item.id}`);
          }}
          onKeyPress={(event) => {
            if (event.key === "Enter") {
              navigate(`/clients/detail/${item.id}`);
            }
          }}
        >{`${item.first_name} ${item.last_name}`}</div>
        <div className="text-gray-500 text-[14px] break-all">{item.email}</div>
      </div>
      <div
        className={`w-full border-t-[1px] border-gray-300 text-center p-2 rounded-b-md ${
          item.status === "active" ? "bg-green-700 text-white" : "bg-red-700 text-white"
        } `}
        role="button"
        tabIndex={0}
        onClick={() => statusIsClicked(item.id)}
        onKeyPress={(event) => {
          if (event.key === "Enter") {
            statusIsClicked(item.id);
          }
        }}
      >
        <div className="text-[15px]">{item.status}</div>
      </div>
    </div>
  );
}

ClientCard.propTypes = {
  item: PropTypes.any.isRequired,
};
