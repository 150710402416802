import MDButton from "components/MDButton";
import { setTab, useMaterialUIController } from "context";

import { useState, useEffect } from "react";
import { Link } from "react-router-dom";

export default function data() {
  const [controller, dispatch] = useMaterialUIController();
  const { transfersData } = controller;
  const [currentList, setCurrentList] = useState([]);
  useEffect(() => {
    setCurrentList(transfersData);
  }, [transfersData]);

  const getRows = () => {
    const personsInfo = currentList.map((value) => ({
      id: <div>{value.id}</div>,
      "lawyer id": (
        <Link
          onClick={() => {
            setTab(dispatch, 8);
          }}
          to={`/lawyers/detail/${value.lawyerArmy_id}`}
          style={{ cursor: "pointer", display: "flex", alignItems: "center" }}
        >
          <MDButton color="secondary">{value.lawyerArmy_id}</MDButton>
        </Link>
      ),
      "transfer time": (
        <div>
          <div>{value.createdAt.split("T")[0]}</div>
          <div>{value.createdAt.split("T")[1].split(".")[0]}</div>
        </div>
      ),
      amount: <div>{value.amount / 100}$</div>,
      commission: <div>{value.commission}</div>,
      destination: <div>{value.destination}</div>,
      "destination payment": <div>{value.destination_payment}</div>,
    }));
    return personsInfo;
  };
  return {
    columns: [
      { Header: "id", accessor: "id", width: "45%", align: "left" },
      { Header: "lawyer id", accessor: "lawyer id", width: "45%", align: "left" },
      { Header: "transfer time", accessor: "transfer time", align: "center" },
      { Header: "amount", accessor: "amount", align: "center" },
      { Header: "destination", accessor: "destination", align: "center" },
      { Header: "commission", accessor: "commission", align: "center" },
      { Header: "destination payment", accessor: "destination payment", align: "center" },
    ],

    rows: getRows(),
  };
}
