import Card from "@mui/material/Card";
import PropTypes from "prop-types";
import person from "assets/images/person.png";
import { Badge, Icon, Rating, Tooltip } from "@mui/material";
import { useEffect, useState } from "react";
import { useMaterialUIController } from "context";
import EditBtn from "./EditBtn";
import Navbar from "./Navbar";
import RatingDetail from "./RatingDetail";
import PayModal from "./PayModal";

function UserProfile({ personInfo, lawyerCostInfo, tab, setTab }) {
  console.log(lawyerCostInfo);
  const [income, setIncome] = useState(0);
  const [cost, setCost] = useState(0);
  const [sessionCost, setSessionCost] = useState(0);
  const [controller] = useMaterialUIController();
  const { lawyerStripeAccount } = controller;
  useEffect(() => {
    const costList = [];
    const incomeList = [];
    const sessionCoseList = [];
    for (let i = 0; i < 100; i += 1) {
      costList.push(Math.ceil(Math.random() * personInfo.cost_per_min));
      sessionCoseList.push(Math.ceil(Math.random() * personInfo.cost_per_session));
      incomeList.push(Math.ceil(Math.random() * personInfo.income));
    }
    for (let i = 0; i < 100; i += 1) {
      for (let j = i + 1; j < 100; j += 1) {
        if (costList[i] > costList[j]) {
          const temp = costList[i];
          costList[i] = costList[j];
          costList[j] = temp;
        }
        if (sessionCoseList[i] > sessionCoseList[j]) {
          const temp = sessionCoseList[i];
          sessionCoseList[i] = sessionCoseList[j];
          sessionCoseList[j] = temp;
        }
        if (incomeList[i] > incomeList[j]) {
          const temp = incomeList[i];
          incomeList[i] = incomeList[j];
          incomeList[j] = temp;
        }
      }
    }
    for (let i = 0; i < 100; i += 1) {
      setTimeout(() => {
        setCost(costList[i] / 100);
        setSessionCost(sessionCoseList[i] / 100);
        setIncome(incomeList[i] / 100);
      }, [10]);
    }
    setTimeout(() => {
      setCost(personInfo.cost_per_min / 100);
      setSessionCost(personInfo.cost_per_session / 100);
      setIncome(personInfo.income / 100);
    }, [10]);
  }, [personInfo]);
  const getGenderTooltipText = (sex, customGender) => {
    if (sex === 2) return `person is ${customGender}`;
    if (sex === 0) return `person is Male`;
    if (sex === 1) return `person is Female`;
    return sex;
  };
  const getColorOfStatus = (status) => {
    if (status === "active") return "bg-[#4DA851]";
    if (status === "deactive") return "bg-[#313136]";
    if (status === "terminated") return "bg-[#DE2525]";
    return status;
  };
  const getBirthMonth = (birth) => {
    const months = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];
    const day = birth.split("-")[2];
    const month = parseInt(birth.split("-")[1], 10);
    return `${day} ${months[month - 1]}`;
  };
  return (
    <Card className="py-4 px-8">
      <PayModal lawyerId={personInfo.id} />
      <div className="h-[200px] w-[100%]">
        <div className="h-[80%] flex flex-row">
          <div className="w-[160px] h-[160px]">
            <img
              className="w-[100%] h-[100%] rounded object-cover"
              src={personInfo?.avatars ? personInfo?.avatars?.large : person}
              alt={`${personInfo?.first_name}'sPhoto`}
            />
            <div className="flex justify-end">
              <Tooltip title={getGenderTooltipText(personInfo.sex, personInfo.custom_gender)}>
                <div className="flex p-3 border items-center relative top-[-145px] right-[-10px] justify-center h-[20px] w-[20px] rounded-full bg-white ">
                  <div className="h-[13px] w-[13px] rounded-full items-center flex justify-center">
                    {personInfo.sex === 0 && <Icon> male </Icon>}
                    {personInfo.sex === 1 && <Icon> female </Icon>}
                    {personInfo.sex !== 0 && personInfo.sex !== 1 && <Icon> question_mark </Icon>}
                  </div>
                </div>
              </Tooltip>
            </div>
            <div className="flex justify-end">
              <Tooltip title={`person is ${personInfo.status}`}>
                <div className="flex items-center relative top-[-45px] right-[-10px] justify-center h-[20px] w-[20px] rounded-full bg-white ">
                  <div
                    className={`h-[13px] w-[13px] rounded-full ${getColorOfStatus(
                      personInfo.status
                    )}`}
                  />
                </div>
              </Tooltip>
            </div>
          </div>
          <div className="w-[80%] px-4">
            <div className="flex flex-row">
              <div>
                <div className="flex flex-row items-center">
                  <div className="font-bold text-[20px]">
                    {personInfo.first_name} {personInfo.last_name}
                  </div>
                  {personInfo.mobile_verified_at && (
                    <Tooltip title={`verified at: ${personInfo.mobile_verified_at.split("T")[0]}`}>
                      <div className="ml-2 flex">
                        <Icon fontSize="small" className="cursor-pointer text-[#C5E0FF]">
                          verifiedIcon
                        </Icon>
                      </div>
                    </Tooltip>
                  )}
                  <div className="ml-auto flex items-center">
                    <Tooltip title={`${personInfo.starsCount} people has been voted!`}>
                      <div className="mr-[10px] text-[10px] text-gray-500">
                        peoples: <span className="text-yellow-500">{personInfo.starsCount}</span>
                      </div>
                    </Tooltip>
                    <Tooltip
                      title={`Rate of this lawyer is: ${
                        personInfo.stars ? Math.ceil(personInfo.stars * 100) / 100 : 0
                      }`}
                    >
                      <div className="flex items-center justify-center">
                        <Rating
                          name="read-only"
                          readOnly
                          value={personInfo.stars ? Math.ceil(personInfo.stars * 10) / 10 : 0}
                          precision={0.1}
                        />
                      </div>
                    </Tooltip>
                    <RatingDetail />
                  </div>
                </div>
                <div className="flex mt-1">
                  <div className="flex items-center justify-center">
                    <div className="flex items-center justify-center">
                      <Icon fontSize="small" className="text-[#919090]">
                        fingerprint
                      </Icon>
                    </div>
                    <div className="ml-1 text-[13px] text-[#919090]">{personInfo.id}</div>
                  </div>
                  <div className="flex items-center justify-center ml-4">
                    <div className="flex items-center justify-center">
                      <Icon fontSize="small" className="text-[#919090]">
                        cake
                      </Icon>
                    </div>
                    <div className="ml-1 text-[13px] text-[#919090]">
                      {personInfo.birthday ? getBirthMonth(personInfo?.birthday) : "not found"}
                    </div>
                  </div>
                  <div className="flex items-center justify-center ml-4">
                    <div className="flex items-center justify-center">
                      <Icon fontSize="small" className="text-[#919090]">
                        email
                      </Icon>
                    </div>
                    <div className="ml-1 text-[13px] text-[#919090]">{personInfo.email}</div>
                  </div>
                  <div className="flex items-center justify-center ml-4">
                    <div className="flex items-center justify-center">
                      <Icon fontSize="small" className="text-[#919090]">
                        call
                      </Icon>
                    </div>
                    <div className="ml-1 text-[13px] text-[#919090]">{personInfo.mobile}</div>
                  </div>
                  <div className="flex items-center justify-center ml-4">
                    <div className="flex items-center justify-center">
                      <img
                        alt="stripeIcon"
                        src="/stripeIcon.png"
                        className="w-[20px] h-[20px] rounded-[5px]"
                      />
                    </div>
                    <div className="ml-1 text-[13px] w-[200px] overflow-auto whitespace-nowrap text-[#919090]">
                      {lawyerStripeAccount ? lawyerStripeAccount.id : "stripe account not found"}
                    </div>
                  </div>
                </div>
              </div>
              <EditBtn personInfo={personInfo} />
            </div>
            <div className="flex mt-5 items-center">
              <div className="border border-dashed py-2 px-3 items-center rounded flex flex-col">
                <div className="flex items-center">
                  <div className="flex">
                    <div className="flex text-yellow-500">$</div>
                  </div>
                  <div className="ml-1 text-[16px] font-bold text-black">{income}</div>
                </div>
                <div className="ml-1 text-[16px] text-[#919090]">Income</div>
              </div>
              <Badge
                color={lawyerCostInfo.suggest_income_per_minute ? "error" : "transparent"}
                variant="dot"
              >
                <div className="ml-3 border border-dashed py-2 px-3 items-center rounded flex flex-col">
                  <div className="flex items-center">
                    <div className="flex text-yellow-500">$</div>
                    <div className="ml-1 text-[16px] font-bold text-black">{cost}</div>
                  </div>
                  <div className="ml-1 text-[16px] text-[#919090]">Cost/Min</div>
                </div>
              </Badge>
              <Badge
                color={lawyerCostInfo.suggest_cost_per_session ? "error" : "transparent"}
                variant="dot"
              >
                <div className="ml-3 border border-dashed py-2 px-3 items-center rounded flex flex-col">
                  <div className="flex items-center">
                    <div className="flex">
                      <div className="flex text-yellow-500">$</div>
                    </div>
                    <div className="ml-1 text-[16px] font-bold text-black">{sessionCost}</div>
                  </div>
                  <div className="ml-1 text-[16px] text-[#919090]">Cost/Session</div>
                </div>
              </Badge>
              <div className="text-[14px] h-[80px] overflow-auto ml-2 w-[100%]">
                {personInfo.summary || (
                  <div className="text-gray-300 text-center mt-3">
                    summary field is not filled by the lawyer
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="h-[20%] mt-2 flex items-center">
          <Navbar tab={tab} setTab={setTab} />
        </div>
      </div>
    </Card>
  );
}
UserProfile.propTypes = {
  lawyerCostInfo: PropTypes.object,
  personInfo: PropTypes.object,
  tab: PropTypes.number,
  setTab: PropTypes.func,
};
UserProfile.defaultProps = {
  lawyerCostInfo: {},
  personInfo: {},
  tab: 1,
  setTab: () => {},
};
export default UserProfile;
