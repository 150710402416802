/* eslint-disable react/no-array-index-key */
/* eslint-disable react/prop-types */
import MDBox from "components/MDBox";
import React, { useEffect } from "react";
import {
  setODR,
  useMaterialUIController,
  setOpenDrawerRight,
  setCityInDrawerRight,
  setPostCodeInDrawerRight,
  setAddressInDrawerRight,
  setCircularProgress,
  setRegionIdInDrawerRight,
  setClientAddresses,
} from "context";
import { Grid, Icon, Tooltip } from "@mui/material";
import { getClientAddresses } from "api/api_client";
import MDButton from "components/MDButton";

function Addresses({ id }) {
  const [controller, dispatch] = useMaterialUIController();
  const { officeInfo, clientAddresses } = controller;
  useEffect(() => {
    setCircularProgress(dispatch, true);
    getClientAddresses((isOk, data, status) => {
      if (!isOk) {
        if (status === 404) {
          setClientAddresses(dispatch, {});
          setCircularProgress(dispatch, false);
        } else {
          console.log("get client Addresses from api is not working!", data);
          setCircularProgress(dispatch, false);
        }
      } else {
        setClientAddresses(dispatch, data);
        setCircularProgress(dispatch, false);
      }
    }, id);
  }, [officeInfo]);
  const clickOnEditClientAddress = () => {
    setCityInDrawerRight(dispatch, clientAddresses.city);
    setPostCodeInDrawerRight(dispatch, clientAddresses.post_code);
    setAddressInDrawerRight(dispatch, clientAddresses.address);
    setRegionIdInDrawerRight(dispatch, clientAddresses.state);
    setOpenDrawerRight(dispatch, true);
    setODR(dispatch, "editAddressForClient");
    setTimeout(() => {
      setOpenDrawerRight(dispatch, true);
    }, 1);
  };
  const addNewAddress = () => {
    setCityInDrawerRight(dispatch, "");
    setPostCodeInDrawerRight(dispatch, "");
    setAddressInDrawerRight(dispatch, "");
    setRegionIdInDrawerRight(dispatch, "");
    setODR(dispatch, "createAddressForClient");
    setTimeout(() => {
      setOpenDrawerRight(dispatch, true);
    }, 1);
  };

  if (Object.keys(clientAddresses).length === 0)
    return (
      <div className="flex flex-col mb-2 items-center h-[200px] justify-center">
        <div className="font-bold text-[20px]">No Address has been Added</div>
        <MDButton
          onClick={() => addNewAddress()}
          style={{ backgroundColor: "#0295E9", color: "white" }}
        >
          Add New Address
        </MDButton>
      </div>
    );
  return (
    <MDBox
      pb={3}
      style={{
        overflow: "auto",
        display: "flex",
        flexDirection: "column",
        alignItems: "start",
      }}
    >
      <div className="py-4 px-8 w-[100%]">
        <Grid container pt={1} pb={2} px={2}>
          <Grid
            item
            xs={12}
            style={{
              padding: "10px",
            }}
          >
            <div
              style={{
                boxShadow: "0 0 20px grey",
                height: "300px",
                // display: "flex",
                borderRadius: "10px",
                width: "100%",
              }}
            >
              <Grid container className="w-[100%] h-[10%] flex justify-around align-center">
                <Grid xs={6} className="px-4 text-[20px] flex items-center">
                  #{clientAddresses.id}
                </Grid>
                <Grid xs={6} className="px-4 text-[20px] flex justify-end items-center">
                  <Icon
                    onClick={() => {
                      clickOnEditClientAddress();
                    }}
                    className="cursor-pointer"
                  >
                    edit
                  </Icon>
                </Grid>
              </Grid>
              <div className="w-[100%] h-[90%] items-end flex">
                <div className="w-[60%] h-[100%] items-end flex flex-col">
                  <div className="w-[100%] h-[30%]" />
                  <div className="text-black mr-[40%] text-[30px] font-bold">
                    {clientAddresses.state}
                  </div>
                  <div className="w-[100%] h-[80%] bg-black w-[10px] mr-[45%] " />
                </div>
                <div className="w-[10%] h-[100%] flex flex-col items-center justify-end">
                  <div className="h-[30px] w-[30px] bg-black flex items-center justify-center rounded-full">
                    <Tooltip title="location">
                      <Icon style={{ color: "white" }}>location_on</Icon>
                    </Tooltip>
                  </div>
                  <div className="h-[15%] w-[2px] bg-black" />
                  <div className="h-[30px] w-[30px] bg-black flex items-center justify-center rounded-full">
                    <Tooltip title="post code">
                      <Icon style={{ color: "white" }}>local_post_office</Icon>
                    </Tooltip>
                  </div>
                  <div className="h-[15%] w-[2px] bg-black" />
                  <div className="h-[30px] w-[30px] bg-black flex items-center justify-center rounded-full">
                    <Tooltip title="city">
                      <Icon style={{ color: "white" }}>location_city</Icon>
                    </Tooltip>
                  </div>
                  <div className="h-[25%] w-[2px] bg-black" />
                </div>
                <div className="w-[30%] h-[100%] flex flex-col justify-end">
                  <div className="h-[30px] text-[13px] w-[100%] flex flex-col items-center justify-center rounded-full">
                    {clientAddresses.address}
                    <div>{clientAddresses.building}</div>
                  </div>
                  <div className="h-[15%]" />
                  <div className="h-[30px] text-[13px] w-[100%] flex items-center justify-center rounded-full">
                    {clientAddresses.post_code}
                  </div>
                  <div className="h-[15%]" />
                  <div className="h-[30px] text-[13px] w-[100%] flex flex-col items-center justify-center rounded-full">
                    <div>{clientAddresses.city}</div>
                  </div>
                  <div className="h-[25%]" />
                </div>
              </div>
            </div>
          </Grid>
        </Grid>
      </div>
    </MDBox>
  );
}

export default Addresses;
