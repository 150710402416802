// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/SessionsNavbar";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import AccountBalanceIcon from "@mui/icons-material/AccountBalance";
import WorkIcon from "@mui/icons-material/Work";
import GroupIcon from "@mui/icons-material/Group";
import {
  useMaterialUIController,
  setSessionsTabValue,
  setSearchBarValueSession,
  setSearchBarValueDetailSession,
} from "context";
import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import LawyersSessionsTable from "./lawyersSessionTable";
import ClientsSessionsTable from "./clientsSessionTable";
import AllSessionsTable from "./all";

function Sessions() {
  const [controller, dispatch] = useMaterialUIController();
  const { sessionsTabValue } = controller;
  const { pathname } = useLocation();
  const navigate = useNavigate();
  useEffect(() => {
    setSearchBarValueSession(dispatch, "");
    setSearchBarValueDetailSession(dispatch, "");
  }, [sessionsTabValue]);
  useEffect(() => {
    if (window.location.search.split("?")[1].split("&")[0] === "all") {
      setSessionsTabValue(dispatch, 0);
    }
    if (window.location.search.split("?")[1].split("&")[0] === "lawyers") {
      setSessionsTabValue(dispatch, 1);
    }
    if (window.location.search.split("?")[1].split("&")[0] === "clients") {
      setSessionsTabValue(dispatch, 2);
    }
  }, [pathname, window.location.pathname]);
  useEffect(() => {
    if (pathname === "/sessions") {
      if (sessionsTabValue === 0) navigate(`/sessions?all`);
      else if (sessionsTabValue === 1) navigate(`/sessions?lawyers`);
      else if (sessionsTabValue === 2) navigate(`/sessions?clients`);
    } else {
      navigate(`/sessions?all`);
    }
  }, [sessionsTabValue]);

  const getRelatedTable = () => {
    if (window.location.search === "") return <AllSessionsTable />;
    if (window.location.search.split("?")[1].split("&")[0] === "all") return <AllSessionsTable />;
    if (window.location.search.split("?")[1].split("&")[0] === "lawyers")
      return <LawyersSessionsTable />;
    return <ClientsSessionsTable />;
  };
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <Tabs
        value={sessionsTabValue}
        onChange={(e, newValue) => {
          setSessionsTabValue(dispatch, newValue);
        }}
        aria-label="User Type Tab"
      >
        <Tab
          icon={<WorkIcon />}
          style={{
            fontWeight: sessionsTabValue === 0 && "bold",
          }}
          label="ALL"
        />
        <Tab
          icon={<AccountBalanceIcon />}
          style={{
            fontWeight: sessionsTabValue === 1 && "bold",
          }}
          label="LAWYERS"
        />
        <Tab
          icon={<GroupIcon />}
          style={{
            fontWeight: sessionsTabValue === 2 && "bold",
          }}
          label="CLIENTS"
        />
      </Tabs>
      {getRelatedTable()}
    </DashboardLayout>
  );
}

export default Sessions;
