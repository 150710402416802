import * as yup from "yup";

const SignInSchema = yup.object().shape({
  mobile: yup
    .string("phone number must be string")
    .required("phone number is required")
    .min(10, "phone number must be have at least 10 digits"),
  user_type: yup.string("user type must be string").required("user type is required"),
  device_name: yup.string("device name must be string").required("device name is required"),
  verification_code: yup
    .number("verification code must be number")
    .required("verification code is required")
    .moreThan(4, "verification code is at least 4 digits")
    .positive("verification code must be a positive number"),
});

export default SignInSchema;
