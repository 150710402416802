import * as React from "react";
import { getAppVersions } from "api/api_admin";
import PropTypes from "prop-types";
import { useEffect } from "react";

export default function GetSliderData({
  setVersionsInSlider,
  setVersionsValues,
  choosenPlatform,
  userType,
  setLoading,
  setMinVersionDefault,
  setCurrentVersionDefault,
  addedNewVersion,
}) {
  const sortVersions = (versions) => {
    const versionsList = versions;
    for (let i = 0; i < versionsList.length - 1; i += 1) {
      for (let j = i + 1; j < versionsList.length; j += 1) {
        const fVersion = versionsList[i].split(".");
        const lVersion = versionsList[j].split(".");
        if (
          parseInt(fVersion[0], 10) > parseInt(lVersion[0], 10) ||
          (parseInt(fVersion[0], 10) === parseInt(lVersion[0], 10) &&
            parseInt(fVersion[1], 10) > parseInt(lVersion[1], 10)) ||
          (parseInt(fVersion[0], 10) === parseInt(lVersion[0], 10) &&
            parseInt(fVersion[1], 10) === parseInt(lVersion[1], 10) &&
            parseInt(fVersion[2], 10) > parseInt(lVersion[2], 10))
        ) {
          const temp = versionsList[i];
          versionsList[i] = versionsList[j];
          versionsList[j] = temp;
        }
      }
    }
    return versionsList;
  };
  useEffect(() => {
    const params = `?type=${userType}&platform=${choosenPlatform}`;
    setLoading(true);
    getAppVersions((isOk, data) => {
      if (!isOk) {
        console.log("get app versions List from api is not working", data);
        setLoading(false);
      } else {
        setMinVersionDefault(data.data[0].min_version);
        setCurrentVersionDefault(data.data[0].current_version);
        let tempVersions = [];
        for (let i = 0; i < data.data.length; i += 1) {
          tempVersions.push(data.data[i].min_version);
          tempVersions.push(data.data[i].current_version);
        }
        tempVersions = sortVersions(Array.from(new Set(tempVersions)));
        if (tempVersions.length === 0) {
          setVersionsInSlider([]);
        }
        if (tempVersions.length <= 5) {
          const tempVer = [];
          if (tempVersions.length === 1) {
            setVersionsInSlider([
              {
                value: 0,
                label: tempVersions[0].min_version,
              },
              {
                value: 100,
                label: tempVersions[0].min_version,
              },
            ]);
            setVersionsValues([0, 100]);
          } else {
            for (let j = 0; j < tempVersions.length; j += 1) {
              tempVer.push({
                value: Math.ceil(j * (100 / (tempVersions.length - 1))),
                label: tempVersions[j],
              });
            }
            setVersionsInSlider(tempVer);
            const tempVal = [];
            for (let i = 0; i < tempVer.length; i += 1) {
              if (tempVer[i].label === data.data[0].min_version) {
                tempVal.push(tempVer[i].value);
              }
              if (tempVer[i].label === data.data[0].current_version) {
                tempVal.push(tempVer[i].value);
              }
            }
            setVersionsValues(tempVal);
          }
        } else {
          let tempVer = [];
          let kk = 0;
          tempVer.push(data.data[0].min_version);
          tempVer.push(data.data[0].current_version);
          const lastVersion = tempVersions[tempVersions.length - 1];
          if (lastVersion !== tempVer[0] && lastVersion !== tempVer[1]) {
            kk += 1;
            tempVer.push(lastVersion);
          }
          while (kk !== 3) {
            const randNum = tempVersions[Math.floor(Math.random() * (tempVersions.length - 1))];
            let flag = 0;
            for (let j = 0; j < tempVer.length; j += 1) {
              if (tempVer[j] === randNum) flag += 1;
            }
            if (flag === 0) {
              tempVer.push(randNum);
              kk += 1;
            }
          }
          tempVer = sortVersions(tempVer);
          const tempVer2 = [];
          for (let j = 0; j < tempVer.length; j += 1) {
            tempVer2.push({
              value: Math.ceil(j * (100 / (tempVer.length - 1))),
              label: tempVer[j],
            });
          }
          setVersionsInSlider(tempVer2);
          const tempVal = [];
          for (let i = 0; i < tempVer2.length; i += 1) {
            if (tempVer2[i].label === data.data[0].min_version) {
              tempVal.push(tempVer2[i].value);
            }
            if (tempVer2[i].label === data.data[0].current_version) {
              tempVal.push(tempVer2[i].value);
            }
          }
          setVersionsValues(tempVal);
        }
        setLoading(false);
      }
    }, params);
  }, [choosenPlatform, userType, addedNewVersion]);
  return <div />;
}
GetSliderData.propTypes = {
  setVersionsInSlider: PropTypes.func,
  setVersionsValues: PropTypes.func,
  setLoading: PropTypes.func,
  setMinVersionDefault: PropTypes.func,
  setCurrentVersionDefault: PropTypes.func,
  choosenPlatform: PropTypes.string,
  userType: PropTypes.string,
  addedNewVersion: PropTypes.bool,
};
GetSliderData.defaultProps = {
  setVersionsInSlider: () => {},
  setVersionsValues: () => {},
  setLoading: () => {},
  setMinVersionDefault: () => {},
  setCurrentVersionDefault: () => {},
  choosenPlatform: "",
  userType: "",
  addedNewVersion: true,
};
