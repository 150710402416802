/* eslint-disable jsx-a11y/label-has-associated-control */
import {
  Box,
  Card,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  Icon,
} from "@mui/material";
import PropTypes from "prop-types";
import { getLawyerPayments, payForLawyer } from "api/api_payments";
import {
  setCircularProgress,
  useMaterialUIController,
  setOpenPayModal,
  setAlertText,
  setAlertOpen,
  setAlertColor,
  setReloadTransfer,
  setPaymentsList,
} from "context";

import React, { useEffect, useState } from "react";
import MDBox from "components/MDBox";
import PaymentTable from "./PaymentTable";

function PayModal({ lawyerId }) {
  const [controller, dispatch] = useMaterialUIController();
  const [total, setTotal] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [click, setClick] = useState(0);
  const [commision, setCommision] = useState(10);
  const { openPayModal, circularProgress, paymentsList, reloadTransfer, lawyerStripeAccount } =
    controller;
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");
  const [paymentsData, setPaymentsData] = useState({});
  const [payList, setPayList] = useState([]);
  const [startChecked, setStartChecked] = useState(true);
  const [nowChecked, setNowChecked] = useState(true);
  const handleClose = () => setOpenPayModal(dispatch, false);
  const payClicked = () => {
    if (!lawyerStripeAccount) return;
    if (click === 0) {
      setClick(1);
    } else {
      const body = {
        lawyer_id: lawyerId,
        commission: commision,
        payment_list: payList,
      };
      setCircularProgress(dispatch, true);
      payForLawyer((isOk, data) => {
        if (!isOk) {
          console.log("post pay for lawyer is not working!", data);
          setCircularProgress(dispatch, false);
        } else {
          setOpenPayModal(dispatch, false);
          setAlertText(dispatch, "pay for this admin is successful!");
          setAlertOpen(dispatch, true);
          setAlertColor(dispatch, "success");
          setReloadTransfer(dispatch, !reloadTransfer);
        }
        setCircularProgress(dispatch, false);
      }, body);
    }
  };
  const setDateFunc = (e) => {
    const now = new Date();
    const selectedSDate = new Date(e.target.value);
    if (now < selectedSDate) {
      setAlertText(dispatch, "You can only select past dates");
      setAlertOpen(dispatch, true);
      setAlertColor(dispatch, "warning");
      return;
    }
    setFromDate(e.target.value);
  };
  useEffect(() => {
    if (startChecked) {
      setFromDate("start");
    } else {
      setFromDate("");
    }
  }, [startChecked]);
  useEffect(() => {
    if (nowChecked) {
      setToDate("now");
    } else {
      setToDate("");
    }
  }, [nowChecked]);
  useEffect(() => {
    setClick(0);
    if (fromDate !== "" && toDate !== "" && openPayModal) {
      let params = `?status=pending`;
      if (fromDate !== "start") params += `&from=${fromDate}`;
      if (fromDate !== "now") params += `&to=${toDate}`;
      setCircularProgress(dispatch, true);
      setIsLoading(true);
      getLawyerPayments(
        (isOk, data) => {
          if (!isOk) {
            console.log("get Payments of this lawyer is not working!", data);
            setCircularProgress(dispatch, false);
            setIsLoading(false);
          } else {
            setPaymentsData(data);
            setPaymentsList(dispatch, data.data);
          }
          setCircularProgress(dispatch, false);
          setIsLoading(false);
        },
        lawyerId,
        params
      );
    }
  }, [fromDate, toDate, openPayModal]);
  useEffect(() => {
    setPaymentsList(dispatch, []);
  }, [lawyerId]);
  useEffect(() => {
    if (openPayModal) {
      setStartChecked(true);
      setNowChecked(true);
    }
  }, [openPayModal]);
  const getButtonText = () => {
    if (!lawyerStripeAccount) return "The lawyer does not have Stripe Account";
    if (circularProgress) return "loading...";
    if (click === 0) return "Pay Now";
    return "are you sure? tap again!";
  };
  const setDateFunc2 = (e) => {
    const now = new Date();
    const selectedSDate = new Date(e.target.value);
    if (now < selectedSDate) {
      setAlertText(dispatch, "You can only select past dates");
      setAlertOpen(dispatch, true);
      setAlertColor(dispatch, "warning");
      return;
    }
    setToDate(e.target.value);
  };
  const getBgColor = () => {
    if (!lawyerStripeAccount) return "bg-gray-500";
    if (click === 0) return "bg-[#0075FF]";
    return "bg-[#011452]";
  };
  return (
    <div>
      <Dialog
        open={openPayModal}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
        maxWidth="80vw"
      >
        <DialogTitle>
          <div className="flex">
            <div className="flex">
              <div>Invoice Paper</div>
              <div className="ml-[50px] text-[16px]">Select Period:</div>
              <div className="ml-[20px] flex items-center justify-center">
                <div className="text-[13px] mr-4">From:</div>
                <div className="flex flex-col items-center justify-center">
                  <input
                    className={`h-[25px] ${
                      startChecked ? "text-gray-500" : "text-black"
                    } border-[1px] rounded-[5px] border-gainsboro-500 px-4 py-2 text-[13px]`}
                    onChange={(e) => setDateFunc(e)}
                    value={fromDate}
                    type="date"
                    disabled={startChecked}
                  />
                  <div className="flex">
                    <input
                      checked={startChecked}
                      onChange={() => setStartChecked((prev) => !prev)}
                      className="ml-[5px]"
                      type="checkbox"
                      id="start"
                    />
                    <label htmlFor="start" className="text-[13px] ml-[2px]">
                      Start
                    </label>
                  </div>
                </div>
              </div>
              <div className="ml-[20px] flex items-center justify-center">
                <div className="text-[13px] mr-4">To:</div>
                <div className="flex flex-col items-center justify-center">
                  <input
                    className={`h-[20px] ${
                      nowChecked ? "text-gray-500" : "text-black"
                    } border-[1px] rounded-[5px] border-gainsboro-500 px-4 py-2 text-[13px]`}
                    onChange={(e) => setDateFunc2(e)}
                    value={toDate}
                    type="date"
                    disabled={nowChecked}
                  />
                  <div className="flex">
                    <input
                      checked={nowChecked}
                      onChange={() => setNowChecked((prev) => !prev)}
                      className="ml-[5px]"
                      type="checkbox"
                      id="start"
                    />
                    <label htmlFor="start" className="text-[13px] ml-[2px]">
                      Now
                    </label>
                  </div>
                </div>
              </div>
            </div>
            <div className="ml-auto">
              <Icon className="cursor-pointer" onClick={handleClose}>
                close
              </Icon>
            </div>
          </div>
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            <Divider />
            <Box style={{ width: "80vw" }}>
              {isLoading ? (
                <div className="flex w-[100%] items-center justify-center">loading...</div>
              ) : (
                <div className="flex">
                  <div className="flex w-[60%] justify-around">
                    <div className="border border-dashed py-2 px-8 bg-gray-500 text-white items-center justify-center rounded flex flex-col">
                      <div className="flex items-center">
                        <div className="flex">
                          <div className="flex text-yellow-500">$</div>
                        </div>
                        <div className="ml-1 text-[16px] font-bold text-white">
                          {paymentsData.total_money / 100}
                        </div>
                      </div>
                      <div className="ml-1 text-[16px] text-white">Total Money</div>
                    </div>
                    <div className="border border-dashed py-2 px-8 bg-gray-500 text-white items-center justify-center rounded flex flex-col">
                      <div className="flex items-center">
                        <div className="flex">
                          <div className="flex text-yellow-500">$</div>
                        </div>
                        <div className="ml-1 text-[16px] font-bold text-white">
                          {paymentsData.last_transaction / 100}
                        </div>
                      </div>
                      <div className="ml-1 text-[16px] text-white">Last Transaction</div>
                    </div>
                    <div className="border border-dashed py-2 px-8 bg-gray-500 text-white items-center justify-center rounded flex flex-col">
                      <div className="flex items-center">
                        <div className="ml-1 text-[16px] font-bold text-white">
                          {paymentsData.total_time}
                        </div>
                      </div>
                      <div className="ml-1 text-[16px] text-white">Total Time</div>
                    </div>
                  </div>
                  <div className="w-[40%] border-[1px] border-gainsboro-500 rounded-[10px] p-2 flex items-center justify-center flex-col">
                    <div className="flex items-center justify-center">
                      <div className="text-[14px] text-black flex">
                        <div>commision percent:</div>
                        <div>
                          <input
                            min={0}
                            max={100}
                            className="ml-2 border-[1px] border-gainsboro-500 outline-none"
                            type="number"
                            value={commision}
                            onChange={(e) => setCommision(e.target.value)}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="flex items-center justify-center">
                      <div className="text-[10px]">Number of pay item: </div>
                      <div className="text-[12px] ml-1">{payList.length}</div>
                    </div>
                    <div className="flex items-center justify-center">
                      <div className="text-[10px]">Total Money To Pay: </div>
                      <div className="text-[12px] ml-1">{total / 100}$</div>
                    </div>
                    <div
                      role="presentation"
                      onClick={() => payClicked()}
                      className={`w-[60%] mt-2 text-[14px] text-white font-bold text-center ${getBgColor()} px-4 rounded-[10px] ${
                        lawyerStripeAccount && "cursor-pointer"
                      }`}
                    >
                      {getButtonText()}
                    </div>
                  </div>
                </div>
              )}
              <Card>
                <MDBox pt={3}>
                  <PaymentTable
                    payList={payList}
                    setPayList={setPayList}
                    paymentsList={paymentsList}
                    setTotal={setTotal}
                  />
                </MDBox>
              </Card>
            </Box>
          </DialogContentText>
        </DialogContent>
      </Dialog>
    </div>
  );
}
PayModal.propTypes = {
  lawyerId: PropTypes.number,
};
PayModal.defaultProps = {
  lawyerId: 1,
};

export default PayModal;
