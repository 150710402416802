import { useMaterialUIController } from "context";
import { useState, useEffect } from "react";

export default function data() {
  const [controller] = useMaterialUIController();
  const { lawyerTransfersData } = controller;
  const [currentList, setCurrentList] = useState([]);
  useEffect(() => {
    setCurrentList(lawyerTransfersData);
  }, [lawyerTransfersData]);

  const getRows = () => {
    const personsInfo = currentList.map((value) => ({
      id: <div>{value.id}</div>,
      "lawyer id": <div>{value.lawyerArmy_id}</div>,
      "transfer time": (
        <div>
          <div>{value.createdAt.split("T")[0]}</div>
          <div>{value.createdAt.split("T")[1].split(".")[0]}</div>
        </div>
      ),
      commission: <div>{value.commission}</div>,
      amount: <div>{value.amount / 100}$</div>,
      destination: <div>{value.destination}</div>,
      "destination payment": <div>{value.destination_payment}</div>,
    }));
    return personsInfo;
  };
  return {
    columns: [
      { Header: "id", accessor: "id", width: "45%", align: "left" },
      { Header: "lawyer id", accessor: "lawyer id", width: "45%", align: "left" },
      { Header: "transfer time", accessor: "transfer time", align: "center" },
      { Header: "amount", accessor: "amount", align: "center" },
      { Header: "commission", accessor: "commission", align: "center" },
      { Header: "destination", accessor: "destination", align: "center" },
      { Header: "destination payment", accessor: "destination payment", align: "center" },
    ],

    rows: getRows(),
  };
}
