// import { AxiosInstanceWithXAuthToken } from "../api";
import { AxiosInstanceWithXAuthToken } from "api/api";

export const getData = (callback, url) => {
  AxiosInstanceWithXAuthToken()
    .get(url)
    .then((res) => callback(true, res.data))
    .catch((err) => {
      console.log(err);
      if (err.response.status === 401) {
        localStorage.clear();
        window.location.pathname = "/authentication/verify-number";
      }
      callback(false, err);
    });
};

export const postData = (callback, url, data) => {
  AxiosInstanceWithXAuthToken()
    .post(url, data)
    .then((res) => callback(true, res.data))
    .catch((err) => {
      if (err.response.status === 401) {
        localStorage.clear();
        window.location.pathname = "/authentication/verify-number";
      }
      callback(false, err);
    });
};
